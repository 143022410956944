
import axios from "axios";
import {useAuthentication} from "../../hooks/authToken";

const AxiosService = () => {
  const token = useAuthentication();
  // Set default headers for all Axios requests
  // axios.defaults.headers.common["Content-Type"] = "application/json";
  // axios.defaults.headers.common["Authorization"] = token;
  const axiosInstance = axios.create({
    headers: {
      common: {
        "Content-Type": "application/json",
        "Authorization": token
      }
    }
  });
  return axiosInstance;
};

export default AxiosService;