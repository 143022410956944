import React, { useEffect, useRef, useState } from "react";
import Stack from "react-bootstrap/Stack";
import { useGlobalContext } from "../context/global";
import Dropdownbutton from "../components/Buttons/dropdownbutton";
import { Gear } from "react-bootstrap-icons";
import { masterApi } from "../apis/masterApi";
import Overview from "../components/overview";
import PerformanceSummary from "../components/performanceSummary/performanceSummary";
import TargettingSection from "../components/targettingSection/targettingSection";
import ProductSummary from "../components/productSummary/productSummary";
import KeyInsightsSection from "../components/keyInsightsSection/keyInsightsSection";
import LowperformingSerchTerm from "../components/lowPerformingSearchTerm/lowperformingSerchTerm";
import LineGraph from "../components/lineGraph/lineGraph";
import PieChartsAll from "../components/pieChartsAll/pieChartsAll";
import SettingDashboardLayout from "../components/settingDashboardLayout.js/settingDashboardLayout";
import { dashboardActions } from "../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import "./homeDashboard.css";
// import getClientData from "../helper/clientData/getClientData";
// import calenderIcon from "../assets/icons/calendar_month.png";
import calenderIcon from "../assets/icons/trailing-icon.svg";
import arrowdown from "../assets/icons/down_arrow.svg";
// import PieChart from "../components/pieChart/pieChart";
import CalenderComponent from "../components/calender/Calender";
import closeIcon from "../assets/icons/close_icon.png";
// import ReactDOM from "react-dom";
// import useUserDetails from "../helper/userData/useUserDetails";
import { Link } from "react-router-dom";
import Breadcrumb from '../components/Breadcrumb/ReportsBreadCrumb';
import MultiSelect from '../components/DropDown/Multiselect';

const HomeDashboard = (props) => {
  const {
    calendarDate,
    setCalendarDate,
    applyCalendar,
    selectedPortfolios,
    setselectedPortfolios,
    dashboardLayoutSequence,
    setDashboardLayoutSequence,
    itemList,
    clientId,
    setItemList,
    overlayoutput,
    axios,
    userToken
  } = useGlobalContext();
  const [portfolios, setPortfolios] = useState([]);
  const [Optionactive, setOptionactive] = useState(false);
  const [Brandoption, setBrandoption] = useState(false);
  const [modalShow, setModalDashboard] = useState(false);
  const [dateInFocus, setDateInFocus] = useState("text");
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [datecomp, setdatecomp] = useState(false);
  const closeRef = useRef();
  const { dateDifference, setDateDifference } = useGlobalContext();
  const dispatch = useDispatch();
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const dasboardLayoutSettingModalShow = useSelector(
    (state) => state.dashboardData.dasboardLayoutSettingModal
  );
  const client_id = JSON.parse(localStorage.getItem("clientId"));
  const [selectedPortfolioName, setSelectedPortfolioName] =
    useState([]);
  const calenderIconRefForDashboard = useRef(null);
  // const [dashboardLayoutSequence, setDashboardLayoutSequence] = useState([ "trend_graph", "campaign_summary", "category_product_performance_summary", "targeting", "low_performing_search_terms", "product_summary"]);
  const handledateremove = (e) => {
    setShowCustomDateBox(false);
  };

  const allPortfoliosName = portfolios?.map((portfolio ) => portfolio?.name);
  const portfoliosIds = getPortfoliosIdsByName(portfolios,selectedPortfolioName);
  function getPortfoliosIdsByName (portfolioData,selectedPortfolio) {
   return [...portfolioData]?.reduce((acc,curr) => {
    if(selectedPortfolio.includes(curr.name)) {
      acc.push(curr.platform_id)
    }
    return acc
   },[])
  }
  const client_type = JSON.parse(localStorage.getItem("client_account_type"))

  const getPortfolios = () => {
    axios
      .get(
        `${process.env.REACT_APP_ADS_API}/client/${client_id}/portfolios?source=amazon`
      )
      .then((res) => {
        setPortfolios(res.data.data);
      });
  };

  const datecount = JSON.parse(localStorage.getItem("datecount"));
  // const [userDetails] = useUserDetails();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        Optionactive &&
        closeRef.current &&
        !closeRef.current.contains(e.target)
      ) {
        setOptionactive(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [Optionactive]);
  // const getClientData = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_ADS_API}/client/${client_id}/accounts?type=amazon_ad_account&fields=account_id,client_id,type,default_bid,region,brand_id,name,brand_keywords,suggested_bid,asins`
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       if (response?.data?.data) {
  //         const {
  //           name,
  //           brand_keywords,
  //           brand_id,
  //           account_id,
  //           default_bid,
  //         } = response.data.data[0];
  //         localStorage.setItem("defaultBid", JSON.stringify(default_bid));
  //         localStorage.setItem("brandId", JSON.stringify(brand_id));
  //         localStorage.setItem("clientId", JSON.stringify(account_id));
  //         localStorage.setItem("clientName", JSON.stringify(name));

  //         // Another API Call for Reports API
  //         axios
  //           .get(
  //             `${process.env.REACT_APP_LOGIN_API}/api/amzn/getCustomReports?accountId=${account_id}`
  //           )
  //           .then((response) => {
  //             console.log(response.data);
  //             setReportData(response.data);
  //             localStorage.setItem("reportData", JSON.stringify(response.data));
  //           });
  //         // Setting the Necessary ID's in Predefault Function For create campaign
  //         setCampaignData({
  //           ...campaignData,
  //           tenant_id: client_id,
  //           account_id: account_id,
  //         });
  //         setAdGroupData({
  //           ...adGroupData,
  //           account_id: account_id,
  //           amz_default_bid: default_bid,
  //         });
  //         setAdSetData({ ...adSetData, tenant_id: client_id });
  //       }
  //     });
  // };

  const updatedLayoutIfNoConfig = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_ADS_API}/client/${clientId}/custom-config`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: userToken,
        },
        body: JSON.stringify({
          key: "amz_dashboard_config",
          value: {
            overview_metrices: [
              "impressions",
              "clicks",
              "orders",
              "sale_value",
            ],
            layout_order: [
              "overview",
              "trend_graph",
              "campaign_summary",
              "category_product_performance_summary",
              "targeting",
              "low_performing_search_terms",
              "product_summary",
            ],
          },
        }),
      }
    );
    const result = await response.json();
  };

  const getoverviewdata = async () => {
    const url = `
         ${process.env.REACT_APP_ADS_API}/client/${JSON.parse(
           localStorage.getItem("clientId")
         )}/custom-config?key=amz_dashboard_config`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();
      if (result.message) {
        updatedLayoutIfNoConfig();
      } else {
        let overdata = result.value.layout_order;
        localStorage.setItem("layoutId", JSON.stringify(result.id));
        let sp = overdata.filter(function (item) {
          return item !== "overview";
        });
        // If account Type vendor then hide product_summary
        const list = client_type === "vendor" ? sp.filter((item) => item!=="product_summary"):sp
        setDashboardLayoutSequence(list);
        setItemList([...list]);
      }
    } catch (error) {
      console.log(error);
    }

    // axios
    //   .get(
    //     `
    //     ${process.env.REACT_APP_ADS_API}/client/${JSON.parse(
    //       localStorage.getItem("clientId")
    //     )}/custom-config?key=amz_dashboard_config`
    //   )
    //   .then((res) => {
    //     console.log(res.data,"Line also");
    //     console.log(res,"from global context")
    //     let overdata = res.data.value.layout_order;

    //     localStorage.setItem("layoutId", JSON.stringify(res.data.id));
    //     let sp = overdata.filter(function (item) {
    //       return item !== "overview";
    //     });
    //     setDashboardLayoutSequence(sp);
    //   });
  };
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [showCustomDateBox]);

  useEffect(() => {
    getPortfolios();
    getoverviewdata();
  }, [overlayoutput, clientId]);

  useEffect(() => {
    setModalDashboard(dasboardLayoutSettingModalShow);
  }, [dasboardLayoutSettingModalShow, applyCalendar, datecount]);//[dasboardLayoutSettingModalShow, calendarDate, datecount]

  const onDateFOcusHandler = () => {
    setDateInFocus("date");
  };

  const getSelectedPortfolios = (data) => {
    setSelectedPortfolioName(data)
  }
  const focusOutHandler = () => {
    setDateInFocus("text");
  };
  const settingBtnClickHandler = () => {
    // setShow(true)
    dispatch(dashboardActions?.dasboardLayoutSettingModalShow(true));
  };

  const closeOpenMenus = (e) => {
    if (
      calenderIconRefForDashboard.current &&
      showCustomDateBox &&
      !calenderIconRefForDashboard.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const dashboardLayoutSequencedata = (sequence) => {
    setDashboardLayoutSequence(itemList);
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
    setOptionactive(false);
    setBrandoption(false);
  };

  const dateChageHandler = () => {
    setShowCustomDateBox(true);
  };
  return (
    <>
      <div
        className={
          dashboardSideBarVisibiltyState
            ? "main-content-container-onopen-mode"
            : "main-content-container-oncolapse-mode"
        }
        id="main-box"
      >
        <Breadcrumb />
        {modalShow ? (
          <SettingDashboardLayout
            dashboardLayoutSequencedata={dashboardLayoutSequencedata}
            show={modalShow}
          ></SettingDashboardLayout>
        ) : null}

        <Stack
          direction="horizontal"
          className="d-flex flex-wrap flex-row horizontal-fixed"
        >
          {/* <div className="dash-drodown-btn-container">
            <div className="dropdown" id="dropdown" ref={closeRef}>
              <div
                className="dropdown-btn"
                id="dropdown-btn"
                onClick={(e) => {
                  setOptionactive(!Optionactive);
                  setBrandoption(false);
                  setShowCustomDateBox(false);
                }}
              >
                {selectedPortfolioName}
                <span style={{ paddingLeft: "20px" }}>
                  {" "}
                  <img src={arrowdown}></img>
                </span>
              </div>
              {Optionactive && (
                <>
                  <div className="dropdown-content">
                    {portfolios?.map((ele, index) => (
                      <>
                        <div
                          className={index === 0 ? "dropdown-item" : ""}
                          id="All Portfolios"
                          onClick={(e) => {
                            // setselectedPortfolios(ele.id);
                            setOptionactive(!Optionactive);
                            setSelectedPortfolioName("All Portfolios");
                            setShowCustomDateBox(false);
                          }}
                        >
                          {index === 0 && "All Portfolios"}
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={(e) => {
                            setselectedPortfolios(ele.id);
                            setOptionactive(!Optionactive);
                            setSelectedPortfolioName(ele.name);
                            setShowCustomDateBox(false);
                          }}
                          key={ele.name}
                        >
                          <div>{ele.name}</div>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              )}
            </div>

            <button
              className="dropdown-btn d-none"
              id="cal-main-dash"
              onClick={dateInputClickHandler}
            >
              {dateDifference}

              <span>
                <button
                  id="calender-img-icon"
                  className={
                    dateDifference === "Today" ? "todayclass" : "regularclass"
                  }
                >
                  <img src={calenderIcon} onClick={dateInputClickHandler}></img>
                </button>
          
              </span>
            </button>
          </div> */}
         <div className="d-flex flex-column">
          <small className="small-greyed-out-text">Choose Portfolios</small>
          <MultiSelect isSearch={true} placeHolder={"Enter portfolio name"} title={""} options={allPortfoliosName} defaultSelected={selectedPortfolioName} onApply={getSelectedPortfolios}/>
          </div>
          {/* <div className="filter-icon-container-main"> */}
          <div
            className="filter-icon-container-main"
            style={{ cursor: "pointer" }}
          >
            <Gear onClick={settingBtnClickHandler} />
          </div>
          {/* </div> */}
        </Stack>

        <div
          ref={calenderIconRefForDashboard}
          className={
            showCustomDateBox ? "calender-indashboard-container" : "hide"
          }
        >
          <CalenderComponent setShowCustomDateBox={setShowCustomDateBox} className="dashboard-calender-main"></CalenderComponent>
          <span className="calender-close-icon">
            <img
              onClick={() => setShowCustomDateBox(false)}
              src={closeIcon}
            ></img>
          </span>
        </div>

        <Overview
          showCustomDateBox={showCustomDateBox}
          setShowCustomDateBox={setShowCustomDateBox}
          portfolios={selectedPortfolioName}
          portfoliosIds={portfoliosIds}
        ></Overview>
        <div className="d-flex flex-column align-items-end justify-content-end w-100 small mt-2"> 
        <Link to={"/account-overview"} target="_blank" rel="noopener noreferrer" className="text-muted">
          Click here for detailed analysis
        </Link>
        <span className="text-muted">Trend comparisons shown above is for current period vs preceding period*</span>
        </div>

        {dashboardLayoutSequence.map((el, i) => {
          if (el === "Keyword Low 10") {
          } else if (el === "trend_graph") {
            return <LineGraph portfolios={selectedPortfolioName} portfoliosIds={portfoliosIds} key={el}></LineGraph>;
          } else if (el === "campaign_summary") {
            return <PieChartsAll portfolios={selectedPortfolioName} portfoliosIds={portfoliosIds} key={el}></PieChartsAll>;
          } else if (el === "category_product_performance_summary") {
            return <PerformanceSummary portfolios={selectedPortfolioName} portfoliosIds={portfoliosIds} key={el}></PerformanceSummary> 
            ;
          } else if (el === "targeting") {
            return <TargettingSection portfolios={selectedPortfolioName} portfoliosIds={portfoliosIds} key={el}></TargettingSection>;
          } else if (el === "low_performing_search_terms") {
            return <LowperformingSerchTerm portfolios={selectedPortfolioName} portfoliosIds={portfoliosIds} key={el}></LowperformingSerchTerm>;
          } else if (el === "product_summary") {
            return (client_type === "seller") ? <ProductSummary portfoliosIds={portfoliosIds} portfolios={selectedPortfolioName} key={el}></ProductSummary>:false;
          } else if (el === "Top Selling Product") {
          } else if (el === "Key Insights") {
          }
        })}
      </div>
    </>
  );
};

export default HomeDashboard;
