export default async function getCampaignData (clientId,campaignId) {
    const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/campaigns/${campaignId}?source=amazon`  
    try {
        const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: getCookieValue('_auth'),
            },
          };
        const respsonse = await fetch(url, options);
        const result = await respsonse.json();
        return result
    } catch (error) {
        return error.message
    }
}

function getCookieValue(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
    return '';
  }