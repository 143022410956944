import React from "react";
import { useState } from "react";
import { useGlobalContext } from "../../../../context/global";
import { useAuthHeader } from "react-auth-kit";
import { useSelector } from "react-redux";
import CheckboxRb from "../../../Buttons/checkbox/checkbox";

const BrandAudienceTabs = () => {
  const [page, setPage] = useState("fourth");
  const [isVisible, setIsVisible] = useState(false);
  const authHeader = useAuthHeader();
  const token = authHeader();
  const SPProductTargetingProductData = useSelector(
    (state) => state.CampaignCreationData.SPProductTargetingProductData
  );
  const [brandAudienceData, setBrandAudienceData] = useState([]);
  const { clientData, adGroupData, setAdGroupData, selectedCategory, userToken } =
    useGlobalContext();
  const { brand_id } = clientData;
  const default_bid = JSON.parse(localStorage.getItem("defaultBid"));
  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const brandId = JSON.parse(localStorage.getItem("brandId"));
  const [activeTabs, setActiveTabs] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(null);

  const getBidRecommendation = async (dataPassed) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      body: JSON.stringify(dataPassed),
    };
    const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}:fetch-bid-recommendation?source=amazon`;
    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (response?.status === 200) {
        return result;
      } else {
        alert("Error while fetching bid");
      }
    } catch (error) {
      console.log(error);
    }
  };


  const tabContents = [
    {
      content: "People who have viewed your product  in last 30 days",
      name: "select-Campaign-Logics",
      heading: "View Content Last 30 Days",
      value: "content-last-30",
    },
    {
      content: "People who have purchased your product in last 365 days",
      name: "select-Campaign-Logics",
      heading: "Purchase Last 365 Days",
      value: "purchase-last-365",
    },
    {
      content: "People who have viewed similar products in last 30 days",
      name: "select-Campaign-Logics",
      heading: "Related View Content Last 30 Days",
      value: "view-last-30-adgroup",
    },
    {
      content: "People who have purchased similar products in last 365 days",
      name: "select-Campaign-Logics",
      heading: "Related Purchase Last 365 Days",
      value: "similar-last-365",
    },
  ];

  function arraysAreEqual(array1, array2) {
    // Check if the arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }

    // Sort the arrays to ensure the order doesn't affect the comparison
    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();

    // Compare each element of the sorted arrays
    for (let i = 0; i < sortedArray1.length; i++) {
      if (JSON.stringify(sortedArray1[i]) !== JSON.stringify(sortedArray2[i])) {
        return false;
      }
    }

    return true;
  }

  const tabChange = async (isSelected, recievedIndex, value) => {
    const bidAPIData = {
      type: "SD_CATEGORY",
      categories: Array.isArray(selectedCategory)
        ? selectedCategory
        : [selectedCategory],
      targeting_clauses: [
        {
          type:
            value === "content-last-30" || value === "view-last-30-adgroup"
              ? "views"
              : "purchases",
          expressions: [
            {
              type:
                value === "content-last-30" || value === "purchase-last-365"
                  ? "exactProduct"
                  : value === "similar-last-365" ? "relatedProduct"
                  : "similarProduct",
            },
            {
              type: "lookback",
              value:
                value === "content-last-30" || value === "view-last-30-adgroup"
                  ? "30"
                  : "365",
            },
          ],
        },
      ],
    };
    const Data = {
      status: "ENABLED",
      type:
        value === "content-last-30" || value === "view-last-30-adgroup"
          ? "views"
          : "purchases",
      audience_expression: [
        {
          type: "lookback",
          value:
            value === "content-last-30" || value === "view-last-30-adgroup"
              ? "30"
              : "365",
        },
        {
          type:
            value === "content-last-30" || value === "purchase-last-365"
              ? "exactProduct"
              : value === "similar-last-365" ? "relatedProduct"
              : "similarProduct",
          value: "",
        },
      ],
      value: "string",
    };
    if (isSelected) {
      setAdGroupData((prev) => {
        return {
          ...prev,
          amz_product_audience_targeting: [
            ...prev["amz_product_audience_targeting"],
          ]?.filter((data, index) => {
            return !(
              data?.type === Data?.type &&
              arraysAreEqual(
                Data?.audience_expression,
                data?.audience_expression
              )
            );
          }),
        };
      });
      setActiveTabs((prev) => {
        return prev?.includes(recievedIndex)
          ? [...prev]?.filter((data, i) => data !== recievedIndex)
          : [...prev, recievedIndex];
      });
    } else {
      setLoadingIndex(recievedIndex);
      const result = await getBidRecommendation(bidAPIData);
      const { recommended_suggested_bid } = result?.data[0];
      Data["bid"] = recommended_suggested_bid ?? default_bid;
      setAdGroupData((prev) => {
        return {
          ...prev,
          amz_product_audience_targeting: [
            ...prev["amz_product_audience_targeting"] ?? [],
            Data,
          ],
        };
      });
      setActiveTabs((prev) => {
        return prev?.includes(recievedIndex)
          ? [...prev]?.filter((data, i) => data !== recievedIndex)
          : [...prev, recievedIndex];
      });
      setLoadingIndex(null);
    }
  };

  const CheckBoxTab = ({
    content,
    handleChange,
    isSelected,
    heading,
    name,
    isDisabled,
  }) => {
    return (
      <>
        <div
          className={`redioButton ${isSelected ? "active" : ""} ${
            isDisabled ? "disable-pointer-events" : ""
          }`}
          onClick={(e) => {
            handleChange();
          }}
        >
          <CheckboxRb
            name={name}
            checked={isSelected}
            onChange={(e) => {
              return null; // Returning Null as there is no need to use this just want to trigger the above function
            }}
            BD={"BD"}
            label={<Leavle heading={`${isDisabled?heading+' Loading...':heading}`} para={content} />}
          ></CheckboxRb>
        </div>
      </>
    );
  };

  return (
    <>
      {tabContents?.map((data, index) => {
        const { content, heading, name, value } = data;
        const isSelected = activeTabs?.includes(index);
        const loading = loadingIndex === index;
        return (
          <>
            <div className="col-lg-6" key={heading}>
              <CheckBoxTab
                heading={heading}
                isDisabled={loading}
                isSelected={isSelected}
                handleChange={() => tabChange(isSelected, index, value)}
                content={content}
                name={name}
              />
            </div>
          </>
        );
      })}
    </>
  );
};

export default BrandAudienceTabs;

const Leavle = ({ heading, para }) => {
  return (
    <>
      <span className="leavle-hover leavle-heading">{heading}</span>
      <br />
      {para && <span className="leavle-hover leavle-para">{para}</span>}
    </>
  );
};
