import React, { useEffect, useState } from "react";
import "./automationsList.css";
import { Stack } from "react-bootstrap";
import SectionTitle from "../../../components/sectionTitle";
import TableRb from "../../../components/Tables/tableRb";
import defaultDataHeads from "../../../constant/Automations/defaultAutomationsListDataheads";
import { masterApi } from "../../../apis/masterApi";
import SwitchBtn from "../../../components/Buttons/switchBtn";
import MinusIcon from "../../../assets/svgs/minus_box.svg";
import AddIcon from "../../../assets/svgs/plus_box.svg";
import useHighlightList from "../../../hooks/useHighlightList";
import {AiFillEye, AiOutlineEdit } from "react-icons/ai";
import ModalRb from "../../../components/modal/ModalRb";
import Loader from "../../../components/loader";
import { useGlobalContext } from "../../../context/global";
import editIcon from '../../../assets/icons/edit.svg'
import { useNavigate } from "react-router-dom";
import {TOAST_DANGER,TOAST_SUCCESS,TOAST_POSITION_TOP_RIGHT} from '../../../constant/HardTypes';
import { DEFAULT_OPERATOR_CONFIGS } from "../../../constant/Automations/rulesConfig";
import useUserDetails from "../../../helper/userData/useUserDetails";
import { PlayCircle } from "react-bootstrap-icons";
import {toast} from 'react-toastify';

const AutomationsList = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [automationsRulesList, setAutomationsRulesList] = useState([]);
  const [automationsRulesSource, setAutomationsRulesSource] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [userDetails] = useUserDetails();
  const {user} = userDetails;
  const {email} = user;
  const [handleHighlightList] = useHighlightList(
    automationsRulesList,
    setAutomationsRulesList
  );
  const [filtersToShow, setFiltersToShow] = useState([]);
  const [ruleCampaigns,setRuleCampaigns] = useState([]);
  const [showCampaigns,setShowCampaigns] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const {showAlert,clientId} = useGlobalContext();
  const filterHeads = [
    {
      id: "filter",
      fieldName: "Field Name",
    },
    {
      id: "condition",
      fieldName: "Condition",
    },
    {
      id: "value",
      fieldName: "Value",
    },
  ];

  useEffect(() => {
    getRuleSetList();
    getAllCampaigns();
  }, []);

  useEffect(() => {
    if (automationsRulesSource?.length && allCampaigns?.length) {
      const newData = mapCampaignNames(allCampaigns, automationsRulesSource);
      setAutomationsRulesList(newData);
    }
  }, [automationsRulesSource, allCampaigns]);

  function sortByStartDateDescending(data) {
    return data?.sort((a, b) => {
      const dateA = new Date(a?.schedule_config?.start_date);
      const dateB = new Date(b?.schedule_config?.start_date);
  
      return dateB - dateA;
    });
  }

  async function getRuleSetList() {
    try {
      const reqData = {
        source: "amazon",
      };
      setIsLoading(true);
      const result = await masterApi(reqData, "automationsRule", false);
      if (result?.data) {
        const rulesList = sortByStartDateDescending(result?.data)
        setAutomationsRulesList(rulesList);
        setAutomationsRulesSource(rulesList);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  async function getAllCampaigns() {
    try {
      const reqData = {
        source: "amazon",
        fields: "id,name",
      };
      const result = await masterApi(reqData, "campaignList", false);
      if (result?.data?.data) {
        setAllCampaigns(result?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleExpandRow(index) {
    const parsedData = JSON.parse(JSON.stringify(automationsRulesList));
    parsedData[index].isExpanded = !parsedData[index]?.isExpanded;
    setAutomationsRulesList(parsedData);
  }

  function mapCampaignNames(campaigns, rulesets) {
    return rulesets?.map((data) => {
      const filters =
        data?.entity_filter?.entity_filter_rule?.filters[0]?.value;
      const newData = filters?.map((filterId) => {
        const foundedCampaign = campaigns?.find((campaign) => {
          return filterId === campaign?.id;
        });
        return foundedCampaign;
      });
      data.entity_filter.entity_filter_rule.filters[0] = newData;
      data.allCampaigns = newData?.map((data) => data?.name)?.join(",");
      return data;
    });
  }

  function handleModal(childIndex, parentIndex) {
    setIsVisible(!isVisible);
    const childRulesMapper = {
      0: "INCREASE_BID",
      1: "DECREASE_BID",
      2: "PAUSE",
    };
    const ruleFor = childRulesMapper[childIndex];
    const filtersToShow = automationsRulesList[parentIndex]?.rule_tasks.filter(
      (data) => {
        return (
          data.rule_action_config.entity_action_config.action_type === ruleFor
        );
      }
    );
    setFiltersToShow([...filtersToShow]);
  }

  function hideModal() {
    setIsVisible(false);
  }

  function handleRedirectForUpdate (index) {
    const data = automationsRulesList[index]
    navigate('/automations/update-rule',{state:data})
  }

  const handleShowCampaigns = (data) => {
    setRuleCampaigns(data?.split(","))
    setShowCampaigns(true)
  }

  const handleResumeRule = async (data) => { 
    const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/automation_rule_set/${data?.id}:execute-automation-rule-set?type=AMAZON_Bid_Optimisation_Rule&client_id=${clientId}&TRIGGER_BY=${email}`
    try {
      const response = await fetch(url,{method:'POST'}) ;
      if(response?.status!==200) {
        toast.error('Some error occurred while executing the rule')
      }
      if(response?.status === 200) {
        toast.success('Rule executed successfully')
      }
    } catch (error) {
      toast.error('Some error occurred while executing the rule')
      console.log(error)
    }
  }

  let tableBody = (
    <>
      <div className="main-campaignlist-table-body-container automations-main-table-wrapper">
        {automationsRulesList?.length ? (
          <>
            <table className="automations-list-table">
              <thead className="fixed-table">
                <tr>
                  {defaultDataHeads?.map((th) => {
                    return <th>{th.fieldName}</th>;
                  })}
                </tr>
              </thead>
              <tbody className="another-body">
                {automationsRulesList?.map((data, index) => {
                  const frequencyValues = data?.schedule_config?.frequency_values
                  const frequencyType = frequencyValues?.length === 7 ? "Daily": "Weekly:-" + frequencyValues?.join(",");
                  const startDate = data?.schedule_config?.start_date
                  const endDate = data?.schedule_config?.end_date
                  return (
                    <React.Fragment key={data?.id}>
                      <tr
                        className={`cursor-pointer-global ${
                          data?.isHighlighted ? "highlight" : ""
                        }`}
                        onClick={() => handleHighlightList(index)}
                      >
                        <td>
                          <SwitchBtn
                            onChange={(e) => {
                              e.stopPropagation();
                              handleStatusUpdate(index);
                            }}
                            checked={data?.status === "Active"}
                          />
                        </td>
                        <td
                          className={
                            "d-flex justify-content-between align-items-center gap-3"
                          }
                        >
                          <div className="d-flex align-items-center">
                            <div
                              className="selected-icon-hover"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleExpandRow(index);
                              }}
                            >
                              <img
                                className="cursor-pointer-global"
                                src={data?.isExpanded ? MinusIcon : AddIcon}
                                alt="action"
                              />
                            </div>
                            <span>{data?.name}</span>{" "}
                          </div>
                          {
                            <div style={{display:'flex',gap:'10px'}}>
                            <PlayCircle size={16}  onClick={() => {
                              handleResumeRule(data)
                            }} color="black"/>
                            <img
                              src={editIcon}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRedirectForUpdate(index);
                              }}
                              alt="main-edit-icon"
                            />

                            </div>
                          }
                        </td>
                        <td title={'Click to see all campaigns'} onClick={() => {
                          handleShowCampaigns(data?.allCampaigns ?? [])
                          }}>
                          {(data?.allCampaigns?.length > 100
                            ? data?.allCampaigns?.slice(0, 100)
                            : data?.allCampaigns) ?? "-"}
                        </td>
                        <td>{frequencyType}</td>
                        <td>{startDate ?? "-"}</td>
                        <td>{endDate ?? "-"}</td>
                      </tr>
                      {data?.isExpanded && data?.rule_tasks?.length > 0 ? (
                        <>
                          {[
                            "Increase Rules",
                            "Decrease Rules",
                            "Pause Rules",
                          ]?.map((ruleData, ruleIndex) => {
                            return (
                              <tr key={ruleData}>
                                <td>-</td>
                                <td className="d-flex justify-content-between">
                                  <span>{ruleData}</span>
                                  <AiFillEye
                                    style={{ color: "var(--primary-color)" }}
                                    onClick={() =>
                                      handleModal(ruleIndex, index)
                                    }
                                    size={15}
                                    cursor={"pointer"}
                                  />
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            );
                          })}
                        </>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <> 
          { (!isLoading && !automationsRulesList?.length) && <h1>No Data Found</h1>}
          </>
        )}
      </div>
    </>
  );

  async function handleStatusUpdate(index) {
    const parsedData = JSON.parse(JSON.stringify(automationsRulesList));
    const statusToUpdate =
      parsedData[index]?.status === "Active" ? "Paused" : "Active";
    parsedData[index].status = statusToUpdate;
    const dataToUpdate = [
      {
        id: parsedData[index]?.id,
        status: statusToUpdate,
      },
    ];
    setIsLoading(true);
    try {
      const result = await masterApi(
        dataToUpdate,
        "automationsRule",
        false,
        "patch"
      );
      if (result?.statusCode === 200) {
        showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
          backgroundColor: TOAST_SUCCESS,
          title: "Success",
          description: "Updated Successfully!",
        });
        setAutomationsRulesList(parsedData);
      }
      if (result?.statusCode !== 200) {
        showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
          backgroundColor: TOAST_DANGER,
          title: "Error",
          description: "Some unknown error occurred while updating!",
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  let renderMainTableBody = (filtersToShow) => (
    <>
      <div className="main-campaignlist-table-body-container automations-main-table-wrappe automation-filter-table-body">
        {filtersToShow?.length ? (
          <>
            <table className="automations-list-table">
              <thead className="fixed-table">
                <tr>
                  {filterHeads?.map((th) => {
                    return <th key={th.fieldName}>{th.fieldName}</th>;
                  })}
                </tr>
              </thead>
              <tbody className="another-body">
                {filtersToShow?.map((data) => {
                  const operator = DEFAULT_OPERATOR_CONFIGS[data?.operator]
                  return (
                    <tr key={data?.field_name}>
                      <td>{data?.field_name}</td>
                      <td>{operator}</td>
                      <td>{data?.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
    </>
  );

  let mainModalBodyToShow = (
    <div className="d-flex gap-5">
      {filtersToShow.length ? (
        <>
          {filtersToShow.map((filter, index) => {
            const filterDuration = filter?.entity_metric_filter?.time_period + "S"
            const type = filter?.rule_action_config?.entity_action_config?.action_type
            const percentageChange = filter?.rule_action_config?.entity_action_config?.percentage_change
            const data = filter?.entity_metric_filter?.filters;
            const filterDetails = type === "PAUSE" ? `(Duration: ${filterDuration})` : `(${type}_BY: ${percentageChange}% || Duration: ${filterDuration})`
            return (
              <Stack key={index}>
                <p className="text-center my-1">Filter {index + 1}  </p> 
                <small className="text-center">{filterDetails}</small>  
                <hr className="m-0" />
                <div className="automations-table-wrapper">
                  <div className="automations-main-table">
                    <TableRb table={renderMainTableBody(data)} />
                  </div>
                </div>
              </Stack>
            );
          })}
        </>
      ) : (
        <h2>No Data To Show</h2>
      )}
    </div>
  );
  
  const campaignTableBody =(data) => {
    return (
      <>
          <div className="main-campaignlist-table-body-container automations-main-table-wrappe automation-filter-table-body">
    {data?.length ? (
      <>
        <table className="automations-list-table">
          <thead className="fixed-table">
            <tr >
              {[{fieldName:'Campaign Name'}]?.map((th) => {
                return <th style={{position:'sticky',top:0}} key={th.fieldName}>{th.fieldName}</th>;
              })}
            </tr>
          </thead>
          <tbody className="another-body">
            {data?.map((name) => {
              return (
                <tr key={name}>
                  <td>{name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : null}
  </div>
      </>
    )
  }
  const campaignModalBody = (
    <div className="d-flex gap-5">
    {ruleCampaigns.length ? (
      <>
         <div className="automations-table-wrapper">
         <small className="text-center">{`Total Campaigns:- (${ruleCampaigns?.length})`}</small>  
                  <div className="automations-main-table">
                    <TableRb table={campaignTableBody(ruleCampaigns)} />
                  </div>
          </div>
      </>
    ) : (
      <h2>No Data To Show</h2>
    )}
  </div>
  )

  return (
    <>
      <Loader visible={isLoading} />
      <ModalRb
        dialogClassName={"automation-filter-modal"}
        Modalheading={"Filters"}
        show={isVisible}
        ModalMainBody={mainModalBodyToShow}
        onHide={hideModal}
      />
      <ModalRb
        dialogClassName={"automation-filter-modal"}
        Modalheading={"Campaigns"}
        show={showCampaigns}
        ModalMainBody={campaignModalBody}
        onHide={() => {
          setShowCampaigns(false)
        }}
      />
      <div className="main-content-container-oncolapse-mode">
        <div className="campaign-list-container">
          <div className="d-flex justify-content-between align-items-center w-100">
            <SectionTitle sectionTitleHeading={"Automation List"} />
          </div>
          <Stack>
            <div className="automations-table-wrapper">
              <div className="automations-main-table">
                <TableRb table={tableBody} />
              </div>
            </div>
          </Stack>
        </div>
      </div>
    </>
  );
};

export default AutomationsList;