import React, { useEffect, useState } from "react";
import cubejs from "@cubejs-client/core";
import useToken from "../../../helper/Reports/useToken";
import { useGlobalContext } from "../../../context/global";
import downloadXls from "../../../assets/svgs/download.svg";
import loader from "../../../assets/svgs/button-loader.svg";
import {
  formatTableData,
  getComparisonHeader,
  getComparisonData,
  getPercentageChange,
  getKeyWiseTotal,
  metricStrings,
  allMetrics,
} from "./utils";
import { previousDaysDifference } from "../../../helper/Reports/common";

import { useDeepCompareMemo } from "use-deep-compare";
import ExcelJS from "exceljs";

export const Download = ({ query, type, onDownload,page }) => {
  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken, multiCalendarDate,calendarDate } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });
  const [resultSet, setResultSet] = useState({});

  const [header, data] = useDeepCompareMemo(() => {
    let paginationColumns = [];
    let formattedData = [];

    if (resultSet && typeof resultSet.tableColumns === "function") {
      paginationColumns = resultSet.tableColumns({});
      formattedData = formatTableData(
        paginationColumns,
        resultSet.tablePivot({})
      );
    }

    return [paginationColumns, formattedData];
  }, [resultSet, {}]);

  const [tableData, setTableData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  var totals = {};
  var previousTotals = {};

  const formatDate = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0].toString();
  };

  useEffect(() => {
    if (type === "multiple" && Object.keys(resultSet).length > 0) {
      setTableData(getComparisonData(resultSet, "", "parent"));
      setTableColumn(getComparisonHeader(header));
    }
  }, [resultSet]);

  let startDate = formatDate(multiCalendarDate.selection1.startDate);
  let endDate = formatDate(multiCalendarDate.selection1.endDate);
  let startDatePrevious = formatDate(multiCalendarDate.selection2.startDate);
  let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
  var daysDifference = previousDaysDifference([startDate, endDate]);
  var daysDifferencePrevious = previousDaysDifference([
    startDatePrevious,
    endDatePrevious,
  ]);

  function getTotals() {
    if (data.length > 0 && Object.keys(totals).length === 0) {
      Object.values(data[0])?.map((totalrow, index) => {
        totals[header[index].key.split(".").pop()] = data
          .reduce((acc, obj) => {
            let key = header[index].key;
            return acc + parseFloat(obj[key]);
          }, 0)
          .toFixed( page==="marketing-stream" ? 2 : 0);
      });
    }
  }

  function avgResultSet(cubeResult) {
    const date1 = new Date(calendarDate[0].startDate);
    const date2 = new Date(calendarDate[0].endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))+1;
  
    
    const divide = ['ams_sp_traffic.TotalOrderItemCount','ams_sp_traffic.clicks','ams_sp_traffic.conversions14d','ams_sp_traffic.cost','ams_sp_traffic.impressions','ams_sp_traffic.sales14d']
    const formulate = ['ams_sp_traffic.acos','ams_sp_traffic.cpc','ams_sp_traffic.cpm','ams_sp_traffic.ctr','ams_sp_traffic.roas','ams_sp_traffic.average_order_value']
    console.log(cubeResult,"Prabs")
    let data = cubeResult?.loadResponse?.results[0]?.data
    
  const transformedData = data?.map(obj => {
  const transformedObj = {};
  for (const key in obj) {
    if (divide.includes(key)) {
      transformedObj[key] = (parseFloat(obj[key]) / diffDays)
    } else if (formulate.includes(key)) {
      const sales14d = parseFloat(transformedObj['ams_sp_traffic.sales14d']);
      const cost = parseFloat(transformedObj['ams_sp_traffic.cost']);
      const clicks = parseFloat(transformedObj['ams_sp_traffic.clicks']);
      const impressions = parseFloat(transformedObj['ams_sp_traffic.impressions']);
      const conversions14d = parseFloat(transformedObj['ams_sp_traffic.conversions14d']);
      switch (key) {
        case 'ams_sp_traffic.roas':
          transformedObj[key] = sales14d >= 1 ? (sales14d / cost).toFixed(2) : 0;
          break;
        case 'ams_sp_traffic.acos':
          transformedObj[key] = sales14d >= 1 ? ((cost / sales14d) * 100).toFixed(2) :0;
          break;
        case 'ams_sp_traffic.cpc':
          transformedObj[key] = (cost / clicks).toFixed(3);
          break;
        case 'ams_sp_traffic.cpm':
          transformedObj[key] = ((cost * 1000) / impressions).toFixed(2);
          break;
        case 'ams_sp_traffic.ctr':
          transformedObj[key] = ((clicks / impressions) * 100).toFixed(2);
          break;
        case 'ams_sp_traffic.average_order_value':
          transformedObj[key] = sales14d >= 1 && conversions14d >= 1 ? (sales14d / conversions14d).toFixed(0) : 0;
          break;
        default:
          transformedObj[key] = obj[key];
      }
    } else {
      transformedObj[key] = obj[key];
    }
  }
  return transformedObj;
  })
  return transformedData;
  } 

  

  const getCalculatedValue = (key) => {
    getTotals();
    return getKeyWiseTotal(key, totals, daysDifference,page);
  };

  function getComparisonTotals() {
    if (tableData.length > 0 && Object.keys(totals).length === 0) {
      Object.values(tableData[0])?.map((totalrow, index) => {
        totals[tableColumn[index].key.split(".").pop()] = tableData
          .reduce((acc, obj) => {
            let key = tableColumn[index].key;
            let value =
              typeof obj[key] === "object" && obj[key] !== null
                ? obj[key].current.value
                : obj[key];
            return acc + parseFloat(value);
          }, 0)
          .toFixed(0);
      });
    }
  }

  const getComparisonCalculatedValue = (key) => {
    getComparisonTotals();
    return getKeyWiseTotal(key, totals, daysDifference);
  };

  function getComparisonTotalsPrevious() {
    if (tableData.length > 0 && Object.keys(previousTotals).length === 0) {
      Object.values(tableData[0])?.map((totalrow, index) => {
        previousTotals[tableColumn[index].key.split(".").pop()] = tableData
          .reduce((acc, obj) => {
            let key = tableColumn[index].key;
            let value =
              typeof obj[key] === "object" && obj[key] !== null
                ? obj[key].previous
                : obj[key];
            return acc + parseFloat(value);
          }, 0)
          .toFixed(0);
      });
    }
  }

  const getPreviousCalculatedValue = (key) => {
    getComparisonTotalsPrevious();
    return getKeyWiseTotal(key, previousTotals, daysDifferencePrevious);
  };

  useEffect(() => {
    if (header.length) {
      if (type !== "multiple") {
        handleDownloadExcel();
      }
    }
  }, [data]);

  useEffect(() => {
    if (tableData.length > 0) {
      handleDownloadComparisonExcel();
    }
  }, [tableData]);

  const getPercentageChangeDiv = (value, type) => {
    if (!isFinite(value) || isNaN(value)) {
      return "";
    }
    let chartType = type.split(".")[1];
    var different = ["cpm", "cpc", "acos"];
    let color = "";
    if (value >= 0) {
      color = different.includes(chartType) ? "B91C1C" : "047857";
    } else {
      color = different.includes(chartType) ? "047857" : "B91C1C";
    }
    let icon = value > 0 ? "↑" : "↓";
    const formattedText = `${parseFloat(Math.abs(value)).toFixed(1)}% ${icon}`;
    return { color: color, text: formattedText };
  };

  function handleDownloadComparisonExcel() {
    if (!tableColumn || !tableData) {
      console.error("Header or data is undefined.");
      return;
    }
    const withoutParsingKeysOfObjects = ['productNameWithImage']
    const headings = tableColumn
      .map((item) => {
        const dataIndex = item.dataIndex.split(",");
        if (dataIndex.length === 2) {
          return [
            item.shortTitle.toUpperCase(),
            "% Change",
            "Previous " + item.shortTitle.toUpperCase(),
          ];
        }
        return item.shortTitle; // If dataIndex doesn't contain two values, return null
      })
      .flat();

    const summery = tableColumn
      ?.map((item) => {
        const dataIndex = item.dataIndex.split(",");
        if (dataIndex.length === 2) {
          return [
            parseToNumber(
              getComparisonCalculatedValue(item["dataIndex"].split(".").pop())
            ),
            getPercentageChangeDiv(
              getPercentageChange(
                getPreviousCalculatedValue(item["dataIndex"].split(".").pop()),
                getComparisonCalculatedValue(item["dataIndex"].split(".").pop())
              ),
              item["dataIndex"]
            ),
            parseToNumber(
              getPreviousCalculatedValue(item["dataIndex"].split(".").pop())
            ),
          ];
        }
        return "Summary";
      })
      .flat();
    const transformedArray = tableData.map((obj) => {
      const arr = [];
      for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          if (
            metricStrings.includes(key.split(".").pop()) &&
            key.split(".").pop().includes("DRR")
          ) {
            arr.push(
              parseToNumber(parseFloat(obj[key].current.value).toFixed(2)),
              getPercentageChangeDiv(obj[key].current.change, key),
              parseToNumber(parseFloat(obj[key].previous).toFixed(2))
            );
          } else if (metricStrings.includes(key.split(".").pop())) {
            arr.push(
              parseToNumber(parseFloat(obj[key].current.value).toFixed(0)),
              getPercentageChangeDiv(obj[key].current.change, key),
              parseToNumber(parseFloat(obj[key].previous).toFixed(0))
            );
          } else if ( withoutParsingKeysOfObjects.includes( key?.split('.')?.pop() )) {
              arr.push(obj[key]?.current?.value)
          } else {
            arr.push(
              parseToNumber(obj[key].current.value),
              getPercentageChangeDiv(obj[key].current.change, key),
              parseToNumber(obj[key].previous)
            );
          }
        } else {
          arr.push(obj[key]);
        }
      }
      return arr;
    });
    transformedArray.unshift(summery);
    downloadCsv(headings, transformedArray);
  }

  function handleDownloadExcel() {
    if (!header || !data) {
      console.error("Header or data is undefined.");
      return;
    }
    getTotals();
    const metricsToFormatForReport = ["campaignBudget","obDate","obDateMonth",...allMetrics]
    const headings = header.map((item) => item.shortTitle.toUpperCase());
    const summery = header?.map((item) => {
      return page==='marketing-stream' ? (getCalculatedValue(item["dataIndex"].split(".").pop())) : parseToNumber(
        getCalculatedValue(item["dataIndex"].split(".").pop())
      );
    });
    summery[0] = "Summary";
    const body = data?.map((item) => {
      return header?.map((headerItem) => {
        let value = item[headerItem.dataIndex]
        if (
          metricStrings.includes(headerItem.dataIndex.split(".").pop()) &&
          headerItem.dataIndex.split(".").pop().includes("DRR")
        ) {
          value = parseFloat(item[headerItem.dataIndex]).toFixed(2);
        } else if (metricStrings.includes(headerItem.dataIndex.split(".").pop())) {
          value = parseFloat(item[headerItem.dataIndex]).toFixed(page==='marketing-stream' ? 2 : 0);
        } 
        // Run ParseToNumber Function for selected metrics
        return metricsToFormatForReport?.includes(headerItem?.dataIndex?.split(".")?.pop()) ? parseToNumber(value) : value
      });
    });
    
    body.unshift(summery);
    downloadCsv(headings, body);
  }

  function parseToNumber(inputString) {
    const dateRangePattern = /^\d{4}-\d{2}-\d{2}\s+to\s+\d{4}-\d{2}-\d{2}$/;
    if (dateRangePattern.test(inputString)) {
      return inputString;
    }
    const isoDateTimeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}$/;
    if (isoDateTimeFormat.test(inputString)) {
      return new Date(inputString).toLocaleDateString("en-US", {
        day: "numeric",
        year: "numeric",
        month: "long",
      });
    }

    const customDateFormat = /^(\d{4})\s(\d{2})\s(\w+)$/;
    // /^(\d{2})\s(\w+)\s(\d{4})$/;
    if (customDateFormat.test(inputString)) {
      let month = inputString.split(" ")[2]; // Extract the month (captured by the third group)
      let year = inputString.split(" ")[0] // Extract the year (captured by the first group)
      const formedString = `${month} ${year}`
      return formedString;
    }
    const floatValue = parseFloat(inputString);

    if (!isNaN(floatValue) && isFinite(floatValue)) {
      return floatValue;
    }
    const intValue = parseInt(inputString, 10);
    if (!isNaN(intValue) && isFinite(intValue)) {
      return intValue;
    }
    return inputString;
  }

  async function downloadCsv(headings, data) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1", {
      views: [{ state: "frozen", xSplit: 1, ySplit: 1 }],
      properties: { tabColor: { argb: "FF00FF00" } },
    });
    const headingRow = worksheet.addRow(headings);
    headingRow.eachCell((cell) => {
      cell.value = cell.text.toUpperCase();
      cell.font = { bold: true };
    });


    data.forEach((rowData) => {
      const dataRow = worksheet.addRow(rowData);
      dataRow.eachCell((cell, index) => {
        const cellValue = cell.text;
        let parsedValue;
        try {
          parsedValue = JSON.parse(cellValue);
          // Check if the parsed value is an object
          if (typeof parsedValue === "object") {
            const textColor = parsedValue.color;
            const textContent = parsedValue.text;
            // Set the cell's value to the text content
            cell.value = textContent;
            cell.font = {
              color: { argb: textColor },
            };
          }
        } catch (error) {
          if (cellValue.includes("https://")) {
            const parts = cellValue.split("||");
            cell.value = parts[1];
          } else {
            cell.value = cellValue;
          }
        }
      });
    });

    worksheet.columns = headings.map((header, index) => ({
      header,
      key: `col${index + 1}`,
      width: index === 0 ? 30 : 10,
      style: { font: { bold: index === 0 } },
    }));
    const blob = await workbook.xlsx.writeBuffer();
    const url = URL.createObjectURL(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download =
      window.location.pathname +
      "-" +
      new Date().toLocaleDateString("en-US", {
        day: "numeric",
        year: "numeric",
        month: "long",
      });
    a.click();
    URL.revokeObjectURL(url);
    setLoading(false);
  }

  const handleDownload = () => {
    setLoading(true);
    if (cubeToken) {
      const formattedOrder = query?.order?.map((item) => [
        item.id,
        item.desc ? "desc" : "asc",
      ]);
      if (type === "multiple") {
        let startDate = formatDate(multiCalendarDate.selection1.startDate);
        let endDate = formatDate(multiCalendarDate.selection1.endDate);
        let startDatePrevious = formatDate(
          multiCalendarDate.selection2.startDate
        );
        let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
        query.timeDimensions = [
          {
            dimension: query?.timeDimensions[0].dimension,
            compareDateRange: [
              [startDate, endDate],
              [startDatePrevious, endDatePrevious],
            ],
          },
        ];

        query.dimensions = query?.dimensions.filter(
          (item) => item !== "compareDateRange"
        );
      }
      cubejsApi
        .load(
          {
            measures: query?.measures,
            order: formattedOrder,
            dimensions: query?.dimensions,
            filters: query?.filters,
            timeDimensions: query?.timeDimensions,
           ...( page==="marketing-stream" && 
            {timezone:'Asia/Kolkata',
            limit:500,})
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((result) => {
          if(page==='marketing-stream') {
            let parsedResult = avgResultSet(result)
            result.loadResponse.results[0].data = parsedResult
            result.loadResponses[0].data = parsedResult
          }
          setResultSet(result);
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    }
  };
  return (
    <div>
      {loading ? (
        <>
          <button
            disabled={loading ? true : false}
            className={
              "primary-inactive-btn apply-filter d-flex align-items-center"
            }
          >
            <div className="d-flex gap-2 align-items-center">
              <img
                src={loader}
                className="cursor-pointer-global filterIcon"
                alt="overview-settings-tile"
              ></img>
              <div>Downloading</div>
            </div>
          </button>
        </>
      ) : (
        <>
          <button
            className={
              "primary-inactive-btn apply-filter d-flex align-items-center"
            }
            onClick={handleDownload}
            title="All Records will be downloaded"
          >
            <div className="d-flex gap-2 align-items-center">
              {" "}
              <img
                src={downloadXls}
                className="cursor-pointer-global filterIcon"
                alt="overview-settings-tile"
              ></img>{" "}
              <div>Download</div>
            </div>
          </button>
        </>
      )}
    </div>
  );
};