import React from "react";
import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSignIn } from "react-auth-kit";
import getClientData from "../../../helper/clientData/getClientData";
import Spinner from '../../spinners/spinner'
import { useGlobalContext } from "../../../context/global";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../../constant/HardTypes";
import "./ForgetPassword.css";
import { useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';

const ForgetPasswordModal = (props) => {
  // States
  const [payload, setPayload] = useState({
    email: props.email ? props.email : "",
    token: "",
    password: "",
    rePassword: "",
  });

  // Hooks Other Than State
  const {
    showAlert,
    setClientData,
    setClientId,
    setAdGroupData,
    adGroupData,
    campaignData,
    setCampaignData,
    adSetData,
    setAdSetData,
    setClientAccountId,
    setReportData,
  } = useGlobalContext();
  const SignIn = useSignIn();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);

  // Methods

  function setCurrentClientData(mainData) {
    const { reportData, clientData } = mainData;
    const { client_id, account_id, default_bid } = clientData;
    setClientData(clientData);
    localStorage.setItem("reference",JSON.stringify(clientData))
    setClientId(client_id);
    setAdGroupData({
      ...adGroupData,
      account_id: account_id,
      amz_default_bid: default_bid,
    });
    setCampaignData({
      ...campaignData,
      tenant_id: client_id,
      account_id: account_id,
    });
    setAdSetData({ ...adSetData, tenant_id: client_id });
    setClientAccountId(account_id);
    setReportData(reportData);
  }

  const updatePayload = (value, field) => {
    setPayload((prev) => {
      const copy = { ...prev };
      copy[field] = value;
      return copy;
    });
  };

  async function postdata(email, password) {
    try {
      const response = await fetch(
        process.env.REACT_APP_LOGIN_API+"/api/login/generateToken",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "AMZN-D1457662DFDC43CD8CB7D856C341D",
          },
          body: JSON.stringify({
            // your expected POST request payload goes here
            email: email,
            password: password,
          }),
        }
      );
      const data = await response.json();
      // enter you logic when the fetch is successful

      if (data.success === true) {
        if (
          SignIn({
            token: data.token,
            expiresIn: 4800,
            tokenType: "Bearer",
            authState: data,
          })
        ) {
          localStorage.setItem(
            "clientId",
            JSON.stringify(data?.user.clientId)
          );
          const mainData = await getClientData(data?.user.clientId, true, data?.user.email);
          setCurrentClientData(mainData);
          navigate("/");
          // if (data?.user?.multiUser === "no") {
          //   localStorage.setItem(
          //     "clientId",
          //     JSON.stringify(data?.user.clientId)
          //   );
          //   const mainData = await getClientData(data?.user.clientId, true, data?.user.email);
          //   setCurrentClientData(mainData);
          //   navigate("/");
          // } else {
          //   localStorage.setItem(
          //     "clientId",
          //     JSON.stringify(data?.user.clientId)
          //   );
          //   navigate("/client-details");
          //   showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //     backgroundColor: TOAST_SUCCESS,
          //     title: "Success",
          //     description: "Please Choose Client",
          //   });
          // }
        }
      } else {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Invalid Credentials, Please Check!",
        // });
        toast.error('Invalid Credentials,Please Check!')
      }
    } catch (error) {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error);
    }
  }

  // Method  For Resetting the Password
  async function confirmPassword() {
    const payloadToBeSent = JSON.parse(JSON.stringify(payload));
    payloadToBeSent["email"] = props.email;
    if (payloadToBeSent.token?.length < 6) {
      setError("token");
      return false;
    }
    if (payloadToBeSent.rePassword !== payloadToBeSent.password) {
      setError("passNotMatch");
      return false;
    }
    if (
      payloadToBeSent.rePassword?.length < 6 ||
      payloadToBeSent.password?.length < 6
    ) {
      setError("passwordLength");
    }
    try {
      const url = process.env.REACT_APP_LOGIN_API+"/api/login/resetPassword";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadToBeSent),
      };
      setButtonStatus(true);
      const jsonResponse = await fetch(url, requestOptions);
      const response = await jsonResponse.json();
      if (response.success) {
        try {
          await postdata(payloadToBeSent.email, payloadToBeSent.password);
          setButtonStatus(false);
        } catch (error) {
          console.log(error);
        }
      } else if (response.error) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: response.error,
        // });
        toast.error(`${response.error}`)
        setButtonStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      className="forget-password-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reset Password
        </Modal.Title>
      </Modal.Header>
      <hr></hr>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>OTP</Form.Label>
            <Form.Control
               autoComplete="off"
              type="text"
              placeholder={`Enter OTP Received on your mail (${props.email})`}
              onChange={(e) => updatePayload(e.target.value, "token")}
              autoFocus
            />
            <span
              className={error === "token" ? "error-forget-password" : "d-none"}
            >
              Please check! OTP should be of 6 characters
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              autoComplete="off"
              type="password"
              placeholder="Enter Password"
              onChange={(e) => updatePayload(e.target.value, "password")}
              autoFocus
            />
            <span
              className={
                error === "passwordLength" ? "error-forget-password" : "d-none"
              }
            >
              Oops! Password Length should be more than or equal to 6 characters
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Re-enter New Password</Form.Label>
            <Form.Control
              autoComplete="off"
              type="password"
              placeholder="Enter Same Password Again"
              onChange={(e) => updatePayload(e.target.value, "rePassword")}
              autoFocus
            />
            <span
              className={
                error === "passNotMatch" ? "error-forget-password" : "d-none"
              }
            >
              Please check! Password Doesn't Match
            </span>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gap-2 align-items-end justify-content-end btns-grp container">
          <button type="button" className="btns btn-greyish btn btn-outline-success" onClick={props.onHide}>Cancel</button>
          <button disabled={buttonStatus ? true : false} onClick={confirmPassword} type="button" className="btns btn btn-primary" >{buttonStatus ? ( <>
            <Spinner size={"sm"} shape={"border"}/>
            Resetting...
            </>
            )
             : "Reset Password"}</button>
        </div>
        {/* <Button
          disabled={buttonStatus ? true : false}
          onClick={confirmPassword}
        >
          {buttonStatus ? ( <>
            <Spinner size={"sm"} shape={"border"}/>
            Resetting...
            </>
            )
             : "Reset Password"}
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ForgetPasswordModal;
