import React, { useEffect } from "react";
import { useState } from "react";
import { useGlobalContext } from "../../../context/global";
import TableRb from "../../Tables/tableRb";
import AddKeywordButton from "../../../assets/svgs/plus_box.svg";
import { useAuthHeader } from "react-auth-kit";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_DANGER,
} from "../../../constant/HardTypes";
import removeDuplicateObjectsFromArray from "../../../helper/validators/removeDuplicateObjectsFromArray";
import { useSearchParams } from "react-router-dom";
import {toast} from 'react-toastify';

const SPkeywordTable1 = (props) => {
  const {
    keywordsToBeShow,
    setKeywordsToBeShow,
    keywordsTobeAdded,
    setKeywordsTobeAdded,
    // keywordsPosted,,
    adGroupData,
    setAdGroupData,
    selectedCategory,
    clientId,
    editKeywordsToBeAdded,
    setEditKeywordsToBeAdded,
    editWholeKeywords,
    setEditWholeKeywords,
    editKeywordsToBeShow,
    setEditKeywordsToBeShow,
    showAlert,
    buttonStatus,
    setButtonStatus,
    userToken
  } = useGlobalContext();
  const [dynamicHeaders, setDynamicHeaders] = useState([
    "Keywords",
    "Match Type",
  ]);
  const default_bid = JSON.parse(localStorage.getItem("defaultBid"));
  const {amz_default_bid} = adGroupData
  const authHeader = useAuthHeader();
  const token = authHeader();
  
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);


  useEffect(() => {
      setKeywordsToBeShow([]);
      setKeywordsTobeAdded([]);
      setEditKeywordsToBeAdded([]);
      setEditWholeKeywords([]);
      setEditKeywordsToBeShow([]);
  },[])

  const addAll = async () => {
    // Format the words to for API Call
    let arrayOfFormattedWords = props.editkeywords
      ? editKeywordsToBeShow.map((keyword) => {
          return {
            type: `${keyword.match.toUpperCase()}`,
            value: keyword.text,
          };
        })
      : keywordsToBeShow.map((keyword) => {
          return {
            type: `${keyword.match.toUpperCase()}`,
            value: keyword.text,
          };
        });

      // Data To Be Sent 
      const keywordsTobeSent = removeDuplicateObjectsFromArray(arrayOfFormattedWords,keywordsTobeAdded)
      

    // Data for API Call

    let keywordsPosted;

    if (props.editkeywords) {
      keywordsPosted = {
        type: "SP_KEYWORD",
        adset_id: props?.adsetId,
        keywords: keywordsTobeSent,
        bidding_strategy: "LEGACY_FOR_SALES",
      };
    } else {
      keywordsPosted = {
        type: "SP_KEYWORD",
        categories: selectedCategory,
        keywords: keywordsTobeSent,
        bidding_strategy: "LEGACY_FOR_SALES",
      };
    }

    // API Call will be made here
    setButtonStatus({ status: true, text: "Adding-keywords" });
    const result = await getSuggestedBidForKeywords(keywordsPosted);
    if(result?.data===undefined) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: "Error While Getting Suggested Bid For All Keywords",
      // });
      toast.error('Error While Getting Suggested Bid For All Keywords')
      setButtonStatus({status:false,text:""})
    }

    // API  Result

    let arrayToBeSet = [];
    result?.data.forEach((data) => {
      if (props.editkeywords) {
        editKeywordsToBeShow.forEach((keyword) => {
          if (data["keyword"] === keyword.text) {
            arrayToBeSet.push({
              match: keyword.match.toLowerCase(),
              bid: data.recommended_suggested_bid ?? default_bid,
              userBid: data.recommended_suggested_bid ?? default_bid,
              lowest: data.lower_suggested_bid ?? 0.0,
              heighest: data.upper_suggested_bid ?? 0.0,
              text: data.keyword,
              status: "ENABLED",
            });
          }
        });
      } else {
        keywordsToBeShow.forEach((keyword) => {
          if (data["keyword"] === keyword.text) {
            arrayToBeSet.push({
              match: keyword.match.toLowerCase(),
              bid: data.recommended_suggested_bid ?? default_bid,
              userBid: amz_default_bid ?? default_bid,
              lowest: data.lower_suggested_bid ?? 0.0,
              heighest: data.upper_suggested_bid ?? 0.0,
              text: data.keyword,
              status: "ENABLED",
            });
          }
        });
      }
    });
    let arrayTObeUpdated = [];
    if (props.editkeywords) {
      arrayTObeUpdated = arrayToBeSet.filter((word) => {
        let keywordKey1 = `${word.text}-${word.match}`;
        for (let i = 0; i < editKeywordsToBeAdded.length; i++) {
          let keywordKey2 = `${editKeywordsToBeAdded[i].text}-${editKeywordsToBeAdded[i].match}`;
          if (keywordKey1 === keywordKey2) {
            return false;
          }
        }
        return true;
      });
    } else {
      arrayTObeUpdated = arrayToBeSet.filter((word) => {
        let keywordKey1 = `${word.text}-${word.match}`;
        for (let i = 0; i < keywordsTobeAdded.length; i++) {
          let keywordKey2 = `${keywordsTobeAdded[i].text}-${keywordsTobeAdded[i].match}`;
          if (keywordKey1 === keywordKey2) {
            return false;
          }
        }
        return true;
      });
    }

    const finalData = arrayTObeUpdated.filter(
      (thing, index, self) =>
        index ===
        self.findIndex((t) => t.text === thing.text && t.match === thing.match)
    );

    if (props.editkeywords) {
      setEditKeywordsToBeAdded((current) => {
        if (current.length > 0) {
          return current.concat(finalData);
        } else {
          return finalData;
        }
      });
      setEditKeywordsToBeShow([]);
    } else {
      setKeywordsTobeAdded((current) => {
        if (current.length > 0) {
          setAdGroupData({
            ...adGroupData,
            amz_keyword_targeting: current.concat(finalData),
          });
          return current.concat(finalData);
        } else {
          setAdGroupData({ ...adGroupData, amz_keyword_targeting: finalData });
          return finalData;
        }
      });
      setKeywordsToBeShow([]);
      setButtonStatus({ status: false, text: "" });
    }
  };

  const getSuggestedBidForKeywords = async (dataPassed) => {
    const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}:fetch-bid-recommendation?source=amazon`;
    const data = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: userToken,
      },
      body: JSON.stringify(dataPassed),
    };
    try {
      const response = await fetch(url, data);
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async (id) => {
    // Reusable function for adding keywords

    // Editing keywords manually checking if there

    // In This Method We're checking on which page we're at For Adset Details and Adding keywords in another Table Logic is Dependent on the Pages.

    // const word = keywordsToBeShow.filter((keyword) => {return keyword.id === id})
    const { text, match } = id;
    const formattedWord = {
      type: `${match.toUpperCase()}`,
      value: `${text}`,
    };
    const addedword = props.editkeywords
      ? editKeywordsToBeAdded.filter((keyword) => {
          return keyword.text?.toLowerCase() === text?.toLowerCase() && keyword.match?.toLowerCase() === match?.toLowerCase();
        })
      : keywordsTobeAdded.filter((keyword) => {
          return keyword.text?.toLowerCase() === text?.toLowerCase() && keyword.match?.toLowerCase() === match?.toLowerCase();
        });
    if (addedword.length === 0) {
      // setKeywordsTobeFormatted([...keywordsTobeFormatted,formattedWord])
      // getSuggestedBidForKeywords(keywordsPosted);
      // Set up for making an API Call from here
      const formattedKeywords = [];
      formattedKeywords.push(formattedWord);
      let keywordsPosted;
      if (props.editkeywords) {
        keywordsPosted = {
          type: "SP_KEYWORD",
          adset_id: props?.adsetId ?? "LT CHAPPALS",
          keywords: formattedKeywords,
          bidding_strategy: "LEGACY_FOR_SALES",
        };
      } else {
        keywordsPosted = {
          type: "SP_KEYWORD",
          categories: selectedCategory,
          keywords: formattedKeywords,
          bidding_strategy: "LEGACY_FOR_SALES",
        };
      }

      // API Call will be made here
      setButtonStatus({ status: true, text: "Adding-keywords" });
      const result = await getSuggestedBidForKeywords(keywordsPosted);
      if(result?.data === undefined) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Error While Getting Suggested Bid",
        // });
        toast.error(result.message)
        setButtonStatus({status:false,text:""})
      }

      // API Result we'll get

      const {
        recommended_suggested_bid,
        lower_suggested_bid,
        upper_suggested_bid,
      } = result?.data[0];
      setButtonStatus({ status: false, text: "" });

      if (props.editkeywords) {
        setEditKeywordsToBeAdded([
          ...editKeywordsToBeAdded,
          {
            text,
            match: match.toLowerCase(),
            bid: recommended_suggested_bid ?? default_bid,
            userBid: recommended_suggested_bid ?? default_bid,
            lowest: lower_suggested_bid ?? 0.0,
            heighest: upper_suggested_bid ?? 0.0,
            status: "ENABLED",
          },
        ]);
        setEditWholeKeywords([
          ...editWholeKeywords,
          {
            text,
            match: match.toLowerCase(),
            bid: recommended_suggested_bid ?? default_bid,
            userBid: recommended_suggested_bid ?? default_bid,
            lowest: lower_suggested_bid ?? 0.0,
            heighest: upper_suggested_bid ?? 0.0,
            status: "ENABLED",
          },
        ]);
        setEditKeywordsToBeShow((current) => {
          return current.filter((keyword) => {
            const keywordKey = `${keyword.text}-${keyword.match}`;
            const keyPair = `${text}-${match}`;
            return keyPair !== keywordKey;
          });
        });
      } else {
        setKeywordsTobeAdded([
          ...keywordsTobeAdded,
          {
            text,
            match: match.toLowerCase(),
            bid: recommended_suggested_bid ?? default_bid,
            userBid: amz_default_bid ?? default_bid,
            lowest: lower_suggested_bid ?? 0.0,
            heighest: upper_suggested_bid ?? 0.0,
            status: "ENABLED",
          },
        ]);
        setAdGroupData({
          ...adGroupData,
          amz_keyword_targeting: [
            ...keywordsTobeAdded,
            {
              text,
              match: match.toLowerCase(),
              bid: recommended_suggested_bid ?? default_bid,
              userBid: amz_default_bid ?? default_bid,
              lowest: lower_suggested_bid ?? 0.0,
              heighest: upper_suggested_bid ?? 0.0,
              status: "ENABLED",
            },
          ],
        });
        setKeywordsToBeShow((current) => {
          return current.filter((keyword) => {
            const keywordKey = `${keyword.text}-${keyword.match}`;
            const keyPair = `${text}-${match}`;
            return keyPair !== keywordKey;
          });
        });
      }
    } else {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 10000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: "The word is already added",
      // });
      toast.error('The word is already added')
    }
  };

  const tableBody = (
    <>
      <div className="generic-main-table-body-container">
        <table className="sp-product-targetting-table table-bordered">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="float-left text-dark p-3">
                  {props.editkeywords
                    ? editKeywordsToBeShow && editKeywordsToBeShow.length
                    : keywordsToBeShow && keywordsToBeShow.length}{" "}
                  Added
                </div>
                <div className="float-right">
                  <button
                    type="button"
                    onClick={addAll}
                    className={
                      keywordsToBeShow && keywordsToBeShow.length !== 0
                        ? "add-all-btn-dark"
                        : "add-all-btn"
                    }
                  >
                    {buttonStatus.status &&
                    buttonStatus.text === "fetching-keywords"
                      ? "Fetching..."
                      : "Add all"}
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              {dynamicHeaders.map((th, index) => {
                if (index === dynamicHeaders.length - 1) {
                  return (
                    <th
                      className="border border-right-white"
                      onClick={() => {
                        // setIsVisible(true)
                      }}
                    >
                      {th}
                    </th>
                  );
                }
                return <th className="border border-right-white">{th}</th>;
              })}
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {props.editkeywords
              ? editKeywordsToBeShow &&
                editKeywordsToBeShow.map((word, index) => {
                  const keywordKey = `${word.text}-${word.match}`;
                  return (
                    <tr key={keywordKey} className="row-height">
                      <td className="tabledata-td">{word.text}</td>
                      <td className="tabledata-td">
                        <div className="d-flex gap-2 align-items-center">
                          <span>{word.match}</span>
                          <span onClick={() => handleAdd(word)} className={"cursor-pointer-global selected-icon-hover"}>
                            <img src={AddKeywordButton} alt="keyword add" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : keywordsToBeShow &&
                keywordsToBeShow.map((word, index) => {
                  const keywordKey = `${word.text}-${word.match}`;
                  return (
                    <tr key={keywordKey} className="row-height">
                      <td className="tabledata-td">{word.text}</td>
                      <td className="tabledata-td">
                        <div className="d-flex gap-2 align-items-center">
                          <span>{word.match} </span>
                          <span onClick={() => handleAdd(word)} className={"cursor-pointer-global selected-icon-hover"}>
                            <img src={AddKeywordButton} alt="keyword add" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <>
  <TableRb table={tableBody} />
  </>
  );
};

export default SPkeywordTable1;
