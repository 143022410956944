import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import moment from "moment/moment";
import PrimaryBtnRB from "../Buttons/primaryBtnRB.js/primaryBtnRB";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import SectionTitle from "../sectionTitle";
import DataTable from "../Tables/table";
import TableRb from "../../components/Tables/tableRb";
import "./performanceSummary.css";
import { masterApi } from "../../apis/masterApi";
import Loader from "../loader";
import "./performanceSummary.css";
import { useGlobalContext } from "../../context/global";
import ProductNameTd from "../Tables/TableComponents/productNameTd";
import { getCurrencySymbol } from "../../constant/defaultCurrencyConfigurations";
import { encodeData } from "../../helper/common/encodeData";
const PerformanceSummary = ({portfolios}) => {
  // const dateData = useSelector((state) => state.genralDataData.selectedDateRange);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [performnaceSummaryByCategory, setPerformnaceSummaryByCategory] =
    useState([]);
  const [performnaceSummaryByProduct, setPerformnaceSummaryByProduct] =
    useState([]);
  const [performnaceSummaryActiveBtn, setPerformnaceSummaryactiveBtn] =
    useState("performance-summary-category-btn");
  const { calendarDate, setCalendarDate, applyCalendar } = useGlobalContext();

  // Derived Variables From State
  const group_by_for_APIs = performnaceSummaryActiveBtn ===  "performance-summary-category-btn" ? "sub_category_name":"product_id";
  const setDataForRelevantState = group_by_for_APIs === "sub_category_name" ? setPerformnaceSummaryByCategory:setPerformnaceSummaryByProduct
  const fieldsForProduct = "product_id,spends,orders,sale_value,roas,product_name,image_url"
  const fieldsForCategory = "sub_category_name,spends,orders,sale_value,roas";
  const fieldsToBeSetForActiveState = group_by_for_APIs === "product_id" ? fieldsForProduct : fieldsForCategory
  const currencySymbol = getCurrencySymbol();

  const performnaceSummaryByCategoryHeads = [
    "Category Name",
    "Spends",
    "Orders",
    "Revenue",
    "ROAS"
  ];

  const performnaceSummaryByProductHeads = [
    "Product Id",
    "Product Name",
    "Spends",
    "Orders",
    "Revenue",
    "ROAS"
  ];
  // useEffect(() => {
  //     performanceSummaryByCategory(dateData);
  //     performanceSummaryByProduct();

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  // useEffect(() => {
  //   formatDateFn(calendarDate[0].startDate);
  // }, []);

  // useEffect(() => {
  //     performanceSummaryByCategory()
  // },[calendarDate])

  const handleClick = () => {
    formatDateFn(calendarDate);
  };

  async function performanceSummaryByCategory() {
    setIsLoaderVisible(true);
    const filtering = [{field_name:"portfolio_name",operator:"IN",values:portfolios}]
    try {
      const data = {
        level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        // date_preset: "last_30d",
        fields: fieldsToBeSetForActiveState,
        group_by: group_by_for_APIs,
        order_by: "spends",
        limit: 10,
        ...(portfolios?.length && {filtering:encodeData(filtering)})
      };
      const result = await masterApi(data);
      setDataForRelevantState(result?.data?.data)
      setIsLoaderVisible(false);
      return result;
    } catch (e) {
      setIsLoaderVisible(false);
    }
  }

  // async function performanceSummaryByProduct() {
  //   setIsLoaderVisible(true);
  //   try {
  //     const data = {
  //       level: "account",
  //       platform: "amazon",
  //       start_date: `${formatDateFn(calendarDate[0].startDate)}`,
  //       end_date: `${formatDateFn(calendarDate[0].endDate)}`,
  //       // date_preset: "last_30d",
  //       fields:
  //         "product_id,spends,orders,sale_value,roas,product_name,image_url",
  //       group_by: "product_id",
  //       order_by: "spends",
  //       limit: "10",
  //     };
  //     const result = await masterApi(data);
  //     console.log(result);
  //     setPerformnaceSummaryByProduct(result.data.data);
  //     // console.log(result.data.data);
  //     setIsLoaderVisible(false);
  //     return result;
  //   } catch (e) {
  //     // console.warn("Error in  Targetting Default Low 10 Api :-", e);
  //     setIsLoaderVisible(false);
  //   }
  // }
  useEffect(() => {
      performanceSummaryByCategory();
  }, [performnaceSummaryActiveBtn, applyCalendar , portfolios]);//[performnaceSummaryActiveBtn, calendarDate]

  let performanceSummaryByCategoryTableData = (
    <table className="w-100 performance-summary-table">
      <thead>
        <tr className="colspan-head">
          {performnaceSummaryByCategoryHeads.map((th,index) => {
            return <th key={th + index}>{th} </th>;
          })}
        </tr>
      </thead>

      <tbody>
        {performnaceSummaryByCategory?.map((psbcdata) => {
          return (
            <tr key={psbcdata?.sub_category_name}>
              <td>{psbcdata?.sub_category_name}</td>
              <td>{currencySymbol}{Math.round(psbcdata.spends * 100) / 100}</td>
              <td>{psbcdata.orders}</td>
              <td>{currencySymbol}{Math.round(psbcdata.sale_value * 100) / 100}</td>
              <td>{Math.round(psbcdata.roas * 100) / 100}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  let performanceSummaryByProductTableData = (
    <table className="w-100 performance-summary-table">
      <thead>
        <tr className="colspan-head">
          {performnaceSummaryByProductHeads?.map((th,index) => {
            return <th key={th + index}>{th} </th>;
          })}
        </tr>
      </thead>

      <tbody>
        {performnaceSummaryByProduct?.map((psbcdata) => {
          return (
            <tr key={psbcdata?.product_id}>
              <td className="performance-sum-first-column">
                {psbcdata.product_id}
              </td>
              <td>
              <ProductNameTd
                asin={psbcdata?.product_id}
                product_name={psbcdata?.product_name}
                image_url={psbcdata?.image_url}
              />
              </td>
              <td>{currencySymbol}{Math.round(psbcdata.spends * 100) / 100}</td>
              <td>{psbcdata.orders}</td>
              <td>{currencySymbol}{Math.round(psbcdata.sale_value * 100) / 100}</td>
              <td>{Math.round(psbcdata.roas * 100) / 100}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const onButtonClickHandler = (e) => {
    setPerformnaceSummaryactiveBtn(e.target.id);
  };

  return (
    <div className="section-main-body">
      <Loader visible={isLoaderVisible}></Loader>
      <Stack className="performance-sum-container">
        <Stack direction="horizontal" gap={3}>
          <SectionTitle sectionTitleHeading="Performance Summary"></SectionTitle>
        </Stack>
        <Stack direction="vertical">
          <Stack
            direction="horizontal"
            gap={3}
            className="performance-sum-btn-container"
          >
            <SecondaryBtnRb
              secondaryBtnText="Category"
              className={
                performnaceSummaryActiveBtn ===
                "performance-summary-category-btn"
                  ? "primary-active-btn"
                  : "primary-inactive-btn"
              }
              id="performance-summary-category-btn"
              onClick={onButtonClickHandler}
            ></SecondaryBtnRb>
            <SecondaryBtnRb
              secondaryBtnText="Product"
              className={
                performnaceSummaryActiveBtn ===
                "performance-summary-product-btn"
                  ? "primary-active-btn"
                  : "primary-inactive-btn"
              }
              id="performance-summary-product-btn"
              onClick={onButtonClickHandler}
            ></SecondaryBtnRb>
          </Stack>
          <div className="performance-sum-category-table">
            <TableRb
              table={
                performnaceSummaryActiveBtn ===
                "performance-summary-category-btn"
                  ? performanceSummaryByCategoryTableData
                  : performnaceSummaryActiveBtn ===
                    "performance-summary-product-btn"
                  ? performanceSummaryByProductTableData
                  : null
              }
            ></TableRb>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};

export default PerformanceSummary;
