export default async function  getAdsetData (campaignId,adsetId,clientId) {
    const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/campaigns/${campaignId}/adsets/${adsetId}?source=amazon`
    try {
        const options = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: getCookieValue('_auth'),
            },
          };
        const response = await fetch(url, options);
        const result = await response.json();
        return result
    } catch (error) {
        return error
    }
}

function getCookieValue(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
    return '';
  }