import React, { useEffect, useState, useRef } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import TableRb from "../../../components/Tables/tableRb";
import externalLink from "../../../assets/icons/external_link.svg";
import AddIcon from "../../../assets/svgs/plus_box.svg";
import MinusIcon from "../../../assets/svgs/minus_box.svg";
import { Stack } from "react-bootstrap";
import overviewSeiingIcon from "../../../assets/svgs/filter.svg";
import ascendingIconSvg from "../../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../../assets/svgs/descendingIcon.svg";

import SpinnerShimmer from "../../../components/spinners/spinner";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dragIcon from "../../../assets/svgs/filter-bars-black.svg";
import Button from "react-bootstrap/Button";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../context/global";
import { useDownloadExcel } from "react-export-table-to-excel";
import previousDays, {previousDaysDifference} from "../../../helper/Reports/common";
import {
  humanize,
  formatTableData,
  allMetrics,
  ComparisonViewFunel,ComparisonViewFunelDRR,
  linkedStrings,
  getClassNameThead,
  expendableStrings,
  defaultRadioValues,
  formatDate, getComparisonHeader, format, formatDecimal, getDivideData, getComparisonData,
  metricStrings, summaryMapping, getProductWithImage, getPercentageChangeDiv, checkObject
} from "./utils";
import {
  ChildTableComparisonRenderer,
  Download
} from "./";
import Loader from "../../../components/loader";
import LazyLoadSpinner from "../../../components/spinners/LazyLoadSpinner";
import numeral from 'numeral';
import SearchWithSelect from '../../../components/Search/SearchWithSelect';


export const ComparisonTableRenderer = ({
    resultSet,
    pivotConfig,
    customClasses,
    percentageShow,
    displayType,
    props,
    children_1,
    children_1_header,
    page,
    FunnelViews,
    shimmerEffect,paginationResultSet,showFilter,
    summaryComparisonTotal,
    summaryTotal,
    getHeadingsOnViewChange,
    predefinedHeadings
  }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [hiddenKeys, setHiddenKeys] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [defaultHiddenKeys, setDefaultHiddenKeys] = useState([]);
    const [sortKey, setSortKey] = useState(1);
    const [sortType, setSortType] = useState("desc");
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [hideImages, setHideImages] = useState(false);
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [resultOrder, setResultOrder] = useState({});
    const tableRefScroll = useRef(null);
    const [lazyLoading, setLazyLoading] = useState(false);
    const {setReportsTime, setReportsFunnelType, multiCalendarDate,setDateDifference,dateDifference } = useGlobalContext();
    const queryParams = new URLSearchParams(window.location.search)
    const drrViewType = queryParams.get('metricViewType');
    const [drrView, setDrrView] = useState(drrViewType ? drrViewType : props?.drrViewType  ?? "")
    const [pagination, setPagination] = useState({
      limit:0,
      offset:0
    })
    const [visibleData, setVisibleData] = useState([]);
    const [startIdx, setStartIdx] = useState(0);
    const [pageSize] = useState(30);
    const [totalRecords, setTotalRecords] = useState(0)
    const [tableRefKey, setTableRefKey] = useState(0)
    const hoverTxt = "Category not mapped as few advertised asins are not added in catalog";

  
    const handleCellClick = (rowIndex) => {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.includes(rowIndex)
          ? prevSelectedRows.filter((row) => row !== rowIndex)
          : [...prevSelectedRows, rowIndex]
      );
    };
  
    const [tableColumnsCalculated, dataSourceCalculated] =
      useDeepCompareMemo(() => {
        const columns = resultSet.tableColumns(pivotConfig);
        return [
          columns,
          formatTableData(columns, resultSet.tablePivot(pivotConfig)),
        ];
      }, [resultSet, pivotConfig]);
    const comparisonHeader = getComparisonHeader(tableColumnsCalculated);
    const comparisonData = getComparisonData(resultSet, page, "parent");
    var totals = {};
    var previousTotals = {};
    const [tableColumns, setTableColumns] = useState(comparisonHeader);
    const [dataSource, setDataSource] = useState(comparisonData);
    const [viewType, setViewType] = useState("Comparison View");
    const [headings, setHeadings] = useState({});
    const [onKeyChange,setOnKeyChange] = useState(false)


    
    // Using this effect if the result set changes then change this state so that it will trigger re-render of the hiddenkeys useEffect with new tablecolumns.
    useEffect(() => {
      setOnKeyChange(!onKeyChange);
     },[resultSet])
    const [filterHeadings,setFilterHeadings] = useState({});
    const [tempHeadings,setTempHeadings] = useState(predefinedHeadings);
  
  
    useEffect(() => {
      if (paginationResultSet && typeof paginationResultSet.tableColumns === 'function') {
        setDataSource((prev) => {
          const comparisonDataPagination = getComparisonData(paginationResultSet, page, "parent");
          return [...prev, ...comparisonDataPagination]
        })
        setLazyLoading(false);
      } else {
        loadMoreData();
      }
    },[paginationResultSet])
  
    useEffect(() => {
      loadMoreData();
    },[dataSource])
  
    useEffect(()=> {
      setStartIdx(0);
      setVisibleData([]);
        
    }, [resultSet])
  
    useEffect(()=> {
      if(visibleData.length === 0) {
        if(dataSource.length > 0) {
          loadMoreData();
        }
      }
    }, [visibleData])
  
    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const funnelValue = searchParams.get("funnel");
      if (funnelValue) {
        setViewType(funnelValue);
      }
    }, [window.location.search]);
  
    useEffect(() => {
      setSelectedRows([])
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete("funnel", viewType);
      navigate({ search: queryParams.toString(), replace: true });
  
      
      let add = [];
  
      if (viewType === "Comparison View") {
        setReportsFunnelType(viewType)
  
        if(drrView === 'drr') {
          add = tableColumns.reduce((acc, item, index) => {
            if (ComparisonViewFunelDRR.includes(item.key.split(".").pop())) {
              acc.push(index);
            }
            return acc;
          }, []);
        } else {
          add = tableColumns.reduce((acc, item, index) => {
            if (ComparisonViewFunel.includes(item.key.split(".").pop())) {
              acc.push(index);
            }
            return acc;
          }, []);
        }
        
        setHiddenKeys((prev) => {
          add.push(
            tableColumns
              .map(function (o) {
                return o.shortTitle;
              })
              .indexOf("month_numeric")
          );
          return add;
        });
      } else {
        if (typeof props.handleComparisonView === "function") {
          props.handleComparisonView(viewType);
        }
      }
      setDefaultHiddenKeys(hiddenKeys);
    }, [viewType, onKeyChange ,drrView , resultSet]);
  
    useEffect(()=>{
      if(viewType === "Comparison View") {
        // const startDate = formatDate(resultSet.loadResponse.pivotQuery.timeDimensions[0].dateRange[0]);
        // const endDate = formatDate(resultSet.loadResponse.pivotQuery.timeDimensions[0].dateRange[1]);
        let startDate = formatDate(multiCalendarDate.selection1.startDate);
        let endDate = formatDate(multiCalendarDate.selection1.endDate);
        let startDatePrevious = formatDate(
              multiCalendarDate.selection2.startDate
            );
        let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
        // setReportsTime([[startDate, endDate], previousDays([startDate, endDate])])
        // Save the date difference first and then change it 
        //so later if the user changes the view it should be reflected.
        window.sessionStorage.setItem('dateDiff',dateDifference)
        setReportsTime([[startDate, endDate], [startDatePrevious, endDatePrevious]])
        setDateDifference('Custom')
      }
    },[viewType])
  
    let startDate = formatDate(multiCalendarDate.selection1.startDate);
    let endDate = formatDate(multiCalendarDate.selection1.endDate);
    let startDatePrevious = formatDate(multiCalendarDate.selection2.startDate);
    let endDatePrevious = formatDate(multiCalendarDate.selection2.endDate);
    var daysDifference = previousDaysDifference([startDate, endDate])
    var daysDifferencePrevious = previousDaysDifference([startDatePrevious, endDatePrevious])
    function getTotals() {
      if(Object.keys(summaryTotal).length > 0) {
        totals = summaryTotal
      } else if (dataSource.length > 0 && Object.keys(totals).length === 0) {
        Object.values(dataSource[0])?.map((totalrow, index) => {
          totals[tableColumns[index].key.split(".").pop()] = dataSource
            .reduce((acc, obj) => {
              let key = tableColumns[index].key;
              let value =
                typeof obj[key] === "object" && obj[key] !== null
                  ? obj[key].current.value
                  : obj[key];
              return acc + parseFloat(value);
            }, 0)
            .toFixed(0);
        });
      }
    }
  
    const getCalculatedValue = (key) => {
      getTotals();
      if (summaryMapping[key]) {
          const { numeratorKey, denominatorKey, multiplier, symbol, decimal } = summaryMapping[key];
          let numerator;
          if(denominatorKey === "dateDiff") {
             numerator = parseFloat(getDivideData(totals[numeratorKey], daysDifference));
          } else {
             numerator = parseFloat(getDivideData(totals[numeratorKey], totals[denominatorKey]));
          }
          const result = isNaN(numerator) ? "-" : (numerator * (multiplier || 1));
          let resultValue = parseFloat(result);
          if(decimal === 0) {
            resultValue = numeral(resultValue).format(format)
          } else {
            resultValue = numeral(resultValue).format(formatDecimal)
          }
          return resultValue + (symbol ? "%" : "")
      } else {
        return isNaN(totals[key]) ? "-" : numeral(totals[key]).format(format);
      }
    };
  
    function getTotalsPrevious() {
      if(Object.keys(summaryComparisonTotal).length > 0) {
        previousTotals = summaryComparisonTotal
      } else if (dataSource.length > 0 && Object.keys(previousTotals).length === 0) {
        Object.values(dataSource[0])?.map((totalrow, index) => {
          previousTotals[tableColumns[index].key.split(".").pop()] = dataSource
            .reduce((acc, obj) => {
              let key = tableColumns[index].key;
              let value =
                typeof obj[key] === "object" && obj[key] !== null
                  ? obj[key].previous
                  : obj[key];
              return acc + parseFloat(value);
            }, 0)
            .toFixed(0);
        });
      }
    }
  
    const getPreviousCalculatedValue = (key) => {
      getTotalsPrevious();
      if (summaryMapping[key]) {
          const { numeratorKey, denominatorKey, multiplier, symbol, decimal } = summaryMapping[key];
          let numerator;
          if(denominatorKey === "dateDiff") {
             numerator = parseFloat(getDivideData(previousTotals[numeratorKey], daysDifferencePrevious));
          } else {
             numerator = parseFloat(getDivideData(previousTotals[numeratorKey], previousTotals[denominatorKey]));
          }
          const result = isNaN(numerator) ? "-" : (numerator * (multiplier || 1));
          let resultValue = parseFloat(result);
          if(decimal === 0) {
            resultValue = numeral(resultValue).format(format)
          } else {
            resultValue = numeral(resultValue).format(formatDecimal)
          }
          return resultValue + (symbol ? "%" : "")
      } else {
        return isNaN(previousTotals[key]) ? "-" : numeral(previousTotals[key]).format(format);
      }
    };
  
    const loadMoreData = () => {
      const newData = dataSource.slice(startIdx, startIdx + pageSize);
      setVisibleData([...visibleData, ...newData]);
      setStartIdx(startIdx + pageSize);
    };
  
    const handleScroll = () => {
      if (tableRefScroll.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRefScroll.current;
        if (scrollTop === 0) {
          if (startIdx >= pageSize) {
            // setStartIdx(startIdx - pageSize); 
          }
        } else if (scrollTop + clientHeight >= scrollHeight - 50) {
          if (startIdx < dataSource.length && lazyLoading===false && visibleData?.length<totalRecords) {
            loadMoreData();
          } else if(startIdx + pageSize >= dataSource.length && dataSource.length < totalRecords && lazyLoading===false) {
            setLazyLoading(true);
            props.pagination(pagination)
          }
        }
      }
    };
  
    useEffect(() => {
      setExpandedRows([])
      setTableRefKey((prev)=> { return prev+1 })
    },[dataSource,tableColumns])
    useEffect(() => {
      setTableRefKey((prev)=> { return prev+1 })
      setLazyLoading(false);
      if(resultSet.loadResponse.pivotQuery.order) {
        setResultOrder(resultSet.loadResponse.pivotQuery.order[0])
      }
      setPagination({limit:resultSet.loadResponse.pivotQuery?.limit,offset:resultSet.loadResponse.pivotQuery?.offset/resultSet.loadResponse.pivotQuery?.limit})
    },[resultSet])
  
    const handleSort = (index, title, type) => {
      setSortKey(index);
      setSortType(type);
      if(customClasses.includes("sortByApi")) {
        setStartIdx(0);
        setVisibleData([])
        props.sortData(title,type)
      } else {
        if(resultOrder.id === title) {
          setResultOrder((prev) => {
            let data = {...prev}
            data.desc = !data.desc
            return data
          })
        } else{
          setResultOrder({
            id:title,desc:true
          })
        }
        setIsLoaderVisible(true);
        sortByKeyIndex(index, type);
      }
    };
  
    function sortByKeyIndex(keyIndex, order) {
      const keys = Object.keys(dataSource[0]);
      const key = keys[keyIndex];
      dataSource.sort(function (a, b) {
        let valueA =
          typeof a[key] === "object" && a[key] !== null
            ? a[key].current.value
            : a[key];
        let valueB =
          typeof b[key] === "object" && b[key] !== null
            ? b[key].current.value
            : b[key];
        if (typeof valueA === "string" && typeof valueB === "string") {
          if (valueA.includes("%") && valueB.includes("%")) {
            valueA = parseFloat(valueA.replace("%", ""));
            valueB = parseFloat(valueB.replace("%", ""));
          } else if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
            valueA = new Date(valueA).getTime();
            valueB = new Date(valueB).getTime();
          } else if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
            valueA = parseFloat(valueA);
            valueB = parseFloat(valueB);
          } else {
            return order === "asc"
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
            // return order === 'asc' ? valueA - valueB : valueB - valueA;
          }
        }
        return order === "asc" ? valueA - valueB : valueB - valueA;
      });
      setIsLoaderVisible(false);
      setStartIdx(0);
        setVisibleData([]);
      // return arr;
    }
  
    const handleChange = (event, value, type, index) => {
      if (type === "expend" || type === "campaign_format") {
        if (expandedRows.includes(value)) {
          setExpandedRows(prevExpandedRows => 
            prevExpandedRows.filter(expandedIndex => expandedIndex !== value)
          );
        } else {
          setExpandedRows(prevExpandedRows => [...prevExpandedRows, value]);
        }
      }
      event.stopPropagation();
      props.handleChange(value, type, index);
    };
  
    var linkedKeys = tableColumns.map(function (o, index) {
      if (linkedStrings.includes(o.shortTitle)) {
        return index;
      }
    });
    linkedKeys = linkedKeys.filter(function (item) {
      return item !== undefined;
    });
    
    var expendableKeys = tableColumns.map(function (o, index) {
      if (expendableStrings.includes(o.shortTitle)) {
        return index;
      }
    });
    expendableKeys = expendableKeys.filter(function (item) {
      return item !== undefined;
    });
  
  
    useEffect(() => {
      setHiddenKeys((prev) => {
        let data = [...new Set(prev)];
        data.push(
          tableColumnsCalculated
            .map(function (o) {
              return o.shortTitle;
            })
            .indexOf("month_numeric")
        );
        return data;
      });
  
      setTableColumns(comparisonHeader);
      setDataSource(comparisonData);
    }, [resultSet]);
  
    var imageDataKeys = tableColumns.map(function (o, index) {
      if (o.meta?.image) {
        return index;
      }
    }).filter(function (item) {
      return item !== undefined;
    });
  
    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename:
        new Date().toLocaleDateString("en-US", {
          day: "numeric",
          year: "numeric",
          month: "long",
        }) + window.location.pathname,
      sheet: viewType,
    });
  
    useEffect(() => {
      if (hideImages) {
        onDownload();
        setHideImages(false);
      }
    }, [hideImages]);

    
    useEffect(() => {
      if(drrView!=="") {
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.get('metricViewType')) {
          // 'viewtype' parameter exists, update its value to 'newType'
          queryParams.set('metricViewType', drrView);
        } else {
          // 'viewtype' parameter doesn't exist, add it with a default value of 'defaultValue'
          queryParams.set('metricViewType', drrView);
        }      
        // Update the browser URL
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, null, newUrl);
      }
    },[drrView])
  
    function onDownloadReady() {
      setHideImages(true);
    }
    useEffect(()=> {
      if(customClasses.includes("sortByApi")) {
        setTotalRecords(resultSet.loadResponse?.results[0]?.total)
      } else {
        setTotalRecords(dataSource.length)
      }
    },[dataSource])
  
    var tableBody = (
      <div
        className={
          "main-targeting-table-body-container listinng-table comparison-view-wrapper " +
          customClasses +
          "-table-wrapper"
        }
        ref={tableRefScroll}
        onScroll={handleScroll}
      >
        {tableColumns?.length ? (
          <React.Fragment key={tableRefKey}>
          <table
            ref={tableRef}  
            className={"mt-table comparisonView " + customClasses}
          >
            <thead className="fixed-table"  >
              <tr className="main-table-row-fixed">
                {tableColumns?.map((row, mainIndex) => {
                  return (
                    <>
                      {hideImages && hiddenKeys.includes(mainIndex) ? (
                        ""
                      ) : (
                        <>
                          <th
                            colSpan={mainIndex > 0 ? hideImages ? 3 : 2 : 1}
                            style={{
                              borderRight:
                                mainIndex === 0
                                  ? "1px solid #FFF ! important"
                                  : "",
                              zIndex: mainIndex === 0 ? 99 : 77,
                            }}
                            className={getClassNameThead(hiddenKeys,sortKey,mainIndex)}
                          >
                            <div className="d-flex gap-1 align-items-center">
                              <div>{humanize(row.shortTitle)}</div>
                              <div
                                className={
                                  sortKey === mainIndex
                                    ? "cursor-pointer-global sortSelected"
                                    : "cursor-pointer-global"
                                }
                              >
                                {!hideImages && (
                                  <img
                                    onClick={() =>
                                      handleSort(mainIndex,row.key,(row.key === resultOrder.id) ? resultOrder.desc ? 'asc' : 'desc' : 'desc')
                                    }
                                    className={row.key === resultOrder.id ? "searchIcon sortSelected" :"searchIcon"}
                                    src={(row.key === resultOrder.id) ? resultOrder.desc ? descendingIconSvg : ascendingIconSvg : ascendingIconSvg}
                                    alt="ASC"
                                  />
                                )}
                              </div>
                            </div>
                          </th>
                        </>
                      )}
                      {displayType === "campaign_format" &&
                      expendableKeys.length > 0 &&
                      mainIndex === 0 ? (
                        <th className="targetinng-table-heads">
                          <div className="d-flex gap-1">
                            <div>{humanize(children_1_header)}</div>
                          </div>
                        </th>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </tr>
            </thead>
            {dataSource?.length ? (
              <tbody className="another-tbody" >
                <tr
                  onClick={() => handleCellClick("summary")}
                  className={
                    selectedRows.includes("summary")
                      ? "highlight cursor-pointer"
                      : "cursor-pointer"
                  }
                >
                  {Object.values(dataSource[0])?.map((totalrow, index) => {
                    return (
                      <React.Fragment key={index + "total"}>
                        <>
                          {hideImages && hiddenKeys.includes(index) ? (
                            ""
                          ) : (
                            <>
                              {index === 0 ? (
                                <td className={"targetinng-second-column "+ props.page}>
                                  <big className="font-weight-bold tableSummary">
                                    Summary
                                  </big>
                                </td>
                              ) : page === "product-child" && index === 1 ? 
                              <><td colSpan={hideImages ? 3 : 2}>-</td></>
                              : (
                                <>
                                  <td
                                    className={
                                      hiddenKeys.includes(index)
                                        ? "d-none"
                                        : "text-center"
                                    }
                                  >
                                    <big className="d-flex justify-content-center font-weight-bold tableSummary">
                                      <span className="d-none"></span>
                                      {getCalculatedValue(
                                        tableColumns[index].key.split(".").pop()
                                      )}
                                    </big>
                                  </td>
                                  {hideImages && <><td></td></>}
                                  <td
                                    className={
                                      hiddenKeys.includes(index)
                                        ? "d-none"
                                        : "text-center"
                                    }
                                  >
                                    <big className="d-flex justify-content-center font-weight-bold tableSummary">
                                      <span className="d-none"></span>
                                      {getPreviousCalculatedValue(
                                        tableColumns[index].key.split(".").pop()
                                      )}
                                    </big>
                                  </td>
                                </>
                              )}
                            </>
                          )}
                          {displayType === "campaign_format" &&
                          expendableKeys.length > 0 &&
                          index === 0 ? (
                            <td></td>
                          ) : (
                            ""
                          )}
                        </>
                      </React.Fragment>
                    );
                  })}
                </tr>
                <tr
                  className={
                    selectedRows.includes("summary")
                      ? "highlight cursor-pointer"
                      : "cursor-pointer"
                  }
                >
                  {Object.entries(dataSource[0])?.map(([datakey,totalrow], index) => {
                    return (
                      <React.Fragment key={index + "total"}>
                        <>
                          {index === 0 ? (
                            <>
                              {" "}
                              <td className={"targetinng-second-column " + props.page}></td>
                            </>
                          ) : displayType === "campaign_format" &&
                            expendableKeys.length > 0 &&
                            index === 1 && drrView !== 'drr'? (
                            <>
                              <td> </td>
                              <td className={index}>Current</td>{hideImages?<><td>% Change</td></>:''} <td>Previous</td>
                            </>
                          ) : displayType === "campaign_format" &&
                          expendableKeys.length > 0 &&
                          index === 1 && drrView === 'drr'? (
                          <>
                            <td> </td>
                          </>
                        ) : page === "product-child" && index === 1 ? (
                            <td colSpan={hideImages ? 3 : 2}></td>
                          ) : hiddenKeys.includes(index) ? (
                            ""
                          ) : (
                            <>
                              <td className={index}>Current</td>{hideImages?<><td>% Change</td></>:''} <td>Previous</td>
                            </>
                          )}
                        </>
                      </React.Fragment>
                    );
                  })}
                </tr>
                {visibleData?.map((tr, index1) => {
                  return (
                    <React.Fragment key={index1 + 1}>
                      <tr
                        index={index1 + "-row"}
                        className={
                          selectedRows.includes(index1 + "data")
                            ? "highlight cursor-pointer"
                            : "cursor-pointer"
                        }
                      >
                        {Object.entries(tr).map(([datakey, td], columnindex) => {
                          const isHoverCondition = (page==='category' || page==='expendable') && columnindex === 0 && !td
                            return (
                              <>
                              {hideImages && hiddenKeys.includes(columnindex) ? (
                                ""
                              ) : (
                                <td
                                  onClick={() => handleCellClick(index1 + "data")}
                                  index={columnindex + "-column"}
                                  title={isHoverCondition ? hoverTxt : null}
                                  className={
                                    columnindex === 0
                                      ? "targetinng-second-column " + props.page
                                      : hiddenKeys.includes(columnindex)
                                      ? "d-none"
                                      : ""
                                  }
                                  colSpan={
                                    page === "product-child" && columnindex === 1
                                      ? hideImages ? 3 : 2 : 1
                                  }
                                >
                                  {linkedKeys.includes(columnindex) &&
                                  expendableKeys.includes(columnindex) &&
                                  columnindex === 0 ? (
                                    <>
                                      <div className="d-flex gap-1 align-items-center justify-content-between abc">
                                        <div>{checkObject(td, "current")}</div>
                                        <div className="d-flex">
                                          {!hideImages ? (
                                            <>
                                              <div className="selected-icon-hover"
                                                onClick={(event) =>
                                                  handleChange(
                                                    event,
                                                    td,
                                                    "expend",
                                                    index1
                                                  )
                                                }
                                              >
                                                <img
                                                  className="tdIcon"
                                                  src={expandedRows.includes(Object.entries(tr)[0][1]) ? MinusIcon : AddIcon}
                                                  alt="action"
                                                />
                                              </div>
                                              <div className="icon-hover"
                                                onClick={(event) =>
                                                  handleChange(
                                                    event,
                                                    td,
                                                    "link",
                                                    index1
                                                  )
                                                }
                                              >
                                                <img
                                                  className="tdIcon"
                                                  src={externalLink}
                                                  alt="link"
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : linkedKeys.includes(columnindex) ? (
                                    <>
                                      <div
                                        className="d-flex gap-1 align-items-center justify-content-between"
                                        onClick={(event) =>
                                          handleChange(
                                            event,
                                            td,
                                            displayType,
                                            columnindex
                                          )
                                        }
                                      >
                                        <div>{checkObject(td, "current")}</div>
                                        <div className="icon-hover">
                                          {!hideImages && (
                                            <img
                                              className="tdIcon"
                                              src={externalLink}
                                              alt="link"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : expendableKeys.includes(columnindex) &&
                                    columnindex === 0 ? (
                                    <>
                                      <div
                                        className="d-flex gap-1 align-items-center justify-content-between"
                                        onClick={(event) =>
                                          handleChange(
                                            event,
                                            td,
                                            displayType,
                                            index1
                                          )
                                        }
                                      >
                                        <div>{checkObject(td, "current")}</div>
                                        <div className="selected-icon-hover">
                                          {!hideImages && (
                                            <img
                                              className="tdIcon"
                                              src={ expandedRows.includes(Object.entries(tr)[0][1]) ? MinusIcon : AddIcon
                                              }
                                              alt="action"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : imageDataKeys.includes(columnindex) && checkObject(td, "current") !== null  ?
                                  <div style={{minWidth:"250px"}}>
                                    {getProductWithImage(checkObject(td, "current"),hideImages)}
                                  </div> 
                                  :  columnindex === 0 ? (
                                    <>{checkObject(td, "current")}</>
                                  ) : page === "product-child" &&
                                    columnindex === 1 ? (
                                    <>{checkObject(td, "current")}</>
                                  ) : (
                                    <>
                                      {hideImages ? (
                                        <span className="">
                                          {metricStrings.includes(datakey.split(".").pop()) && datakey.split(".").pop().includes("DRR")  ? numeral(checkObject(td, "current")).format(formatDecimal) : metricStrings.includes(datakey.split(".").pop()) ? numeral(checkObject(td, "current")).format(format) : checkObject(td, "current")}
    
                                          {getPercentageChangeDiv(
                                            checkObject(td, "change"),
                                            tableColumns[columnindex].key,
                                            hideImages
                                          )}
                                        </span>
                                      ) : (
                                        <div className="d-flex gap-3 align-items-center justify-content-between">
                                          <div>
                                          {metricStrings.includes(datakey.split(".").pop()) && datakey.split(".").pop().includes("DRR")  ? numeral(checkObject(td, "current")).format(formatDecimal) : metricStrings.includes(datakey.split(".").pop()) ? numeral(checkObject(td, "current")).format(format) : checkObject(td, "current")}
                                          </div>
                                          <div>
                                            {getPercentageChangeDiv(
                                              checkObject(td, "change"),
                                              tableColumns[columnindex].key,
                                              hideImages
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </td>
                              )}
                              {columnindex > 0 ? (
                                page === "product-child" && columnindex === 1 ? (
                                  ""
                                ) : hideImages &&
                                  hiddenKeys.includes(columnindex) ? (
                                  ""
                                ) : (
                                  <>
                                    <td
                                      onClick={() =>
                                        handleCellClick(index1 + "data")
                                      }
                                      index={columnindex + "-column"}
                                      className={
                                        columnindex === 0
                                          ? "targetinng-second-column " +props.page
                                          : hiddenKeys.includes(columnindex)
                                          ? "d-none"
                                          : ""
                                      }
                                    >
                                    {metricStrings.includes(datakey.split(".").pop()) && datakey.split(".").pop().includes("DRR")  ? numeral(checkObject(td, "previous")).format(formatDecimal) : metricStrings.includes(datakey.split(".").pop()) ? numeral(checkObject(td, "previous")).format(format) : checkObject(td, "previous")}
                                    </td>
                                  </>
                                )
                              ) : (
                                ""
                              )}
    
                              {displayType === "campaign_format" &&
                              expendableKeys.length > 0 &&
                              columnindex === 0 ? (
                                <td
                                  onClick={() => handleCellClick(index1 + "data")}
                                >
                                  -
                                </td>
                              ) : (
                                ""
                              )}
                            </>
                            )
                     
                })}
                      </tr>
                      {expandedRows.includes(Object.entries(tr)[0][1]) &&
                      !children_1.hasOwnProperty(Object.entries(tr)[0][1]) ? (
                        <>
                          {Array.from({ length: 3 }, () => (
                            <tr className="td-with-loader">
                              {tableColumns?.map((row, mainIndex) => {
                                return !hiddenKeys.includes(mainIndex) ? (
                                  mainIndex === 0 ? (
                                    <>
                                      <td>
                                        <SpinnerShimmer shimmer={true} />
                                      </td>
                                      <td>
                                        <SpinnerShimmer shimmer={true} />
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        <SpinnerShimmer shimmer={true} />
                                      </td>{" "}
                                      <td>
                                        <SpinnerShimmer shimmer={true} />
                                      </td>
                                    </>
                                  )
                                ) : null;
                              })}
                            </tr>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                      {expandedRows.includes(Object.entries(tr)[0][1]) &&
                      children_1.hasOwnProperty(Object.entries(tr)[0][1]) ? (
                        <React.Fragment key={'child'+Object.entries(tr)[0][1]+index1}>
                        <ChildTableComparisonRenderer
                          resultSet={children_1[Object.entries(tr)[0][1]]}
                          pivotConfig={pivotConfig}
                          customClasses={customClasses}
                          percentageShow={percentageShow}
                          displayType={displayType}
                          props={props}
                          viewType={viewType}
                          page={page}
                          hideImages={hideImages}
                          drrView={drrView}
                        />
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            ) : (
              <div>
                <p>NO DATA FOUND</p>
              </div>
            )}
          </table>
          {lazyLoading && (
                  <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
                    <LazyLoadSpinner text={`Loading ${pagination.limit} More Items...`} />
                  </div>
                )}
                </React.Fragment>
        ) : (
          <div>
            <p>NO DATA FOUND</p>
            {/* <Spinner size={"lg"} shape={"border"}/> */}
          </div>
        )}
      </div>
    );
    if (FunnelViews.length > 0) {
      var radioValues = FunnelViews;
    } else {
      var radioValues = defaultRadioValues
    }
  
    useEffect(() => {
      const filterHeadings = {};
      // let viewWiseMetrics = [];
      for (let i = 0; i < tableColumns.length; i++) {
        const item = tableColumns[i];
        if (allMetrics.includes(item.key.split(".")[1])) {
          filterHeadings[item.key.split(".")[1]] = {
            value: humanize(item.shortTitle),
            min: tempHeadings[item.key.split(".")[1]]?.min ?? "",
            max: tempHeadings[item.key.split(".")[1]]?.max ?? "",
            enable: !hiddenKeys.includes(i),
          };
          // viewWiseMetrics.push(item.key.split('.')[1])
        }
      }
      setHeadings(filterHeadings);
      setFilterHeadings(filterHeadings)
      // setItemList(viewWiseMetrics)
    }, [defaultHiddenKeys]);
  
    const inputValueChange = (event, type, key) => {
      setFilterHeadings((prev) => {
        let data = JSON.parse(JSON.stringify({ ...prev }));
        data[key][type] = event;
        return {...data};
      });
    };
  
    const onDownloadComplete = () => {
      console.log("downalod complete")
    };
  
    const applyFilter = () => {
      const parsedHeadings = JSON.parse(JSON.stringify(filterHeadings))
      setTempHeadings(parsedHeadings);
      setHeadings(JSON.parse(JSON.stringify(parsedHeadings)))
      props.dataFilter(parsedHeadings);
      setIsVisible(!isVisible);
      setStartIdx(0);
    };
    const overviewSettingBtnHandler = () => {
      setIsVisible(!isVisible);
    };
    const closeFilters = () => {
      setIsVisible(false);
      setFilterHeadings(JSON.parse(JSON.stringify(headings)))
    };
    const ref = useDetectClickOutside({ onTriggered: closeFilters });
    const resetFilter = () => {
      let data = structuredClone(filterHeadings);
      Object.entries(data).map(([key,metric]) => {
      data[key].min = ""
      data[key].max = ""
      })
      setFilterHeadings(data);
    };
    var tableFilterBody = (
      <>
        <div className="campaign-list-table-data">
          <Stack className="campaign-list-main-table">
            <div className={" reports-filter-table-wrapper filter"}>
              {tableColumns?.length ? (
                <table
                  className={
                    "table table reports-filter-table table-sm table-responsive"
                  }
                >
                  <thead className="fixed-table">
                    <tr className="text-center">
                      <th
                        className={viewType === "Custom View" ? "" : "d-none"}
                      ></th>
                      <th className={viewType === "Custom View" ? "" : "d-none"}>
                        Action
                      </th>
                      <th>Metric</th>
                      <th>Min</th>
                      <th>Max</th>
                    </tr>
                  </thead>
                  <DragDropContext>
                    <Droppable droppableId="list-container">
                      {(provided) => (
                        <tbody
                          className="another-tbody list-container"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{ overflowY: "auto" }}
                        >
                          {Object.keys(filterHeadings).length > 0 && <>
                          {Object.keys(filterHeadings).map((key, index) => (
                            <Draggable
                              key={key}
                              draggableId={key}
                              index={index}
                              isDragDisabled={
                                viewType === "Custom View" ? false : true
                              }
                            >
                              {(provided) => (
                                <tr
                                  className="cursor-pointer"
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <td
                                    className={
                                      viewType === "Custom View" ? "" : "d-none"
                                    }
                                  >
                                    <img src={dragIcon} className="filterIcon" alt="filter" />
                                  </td>
                                  <td
                                    className={
                                      viewType === "Custom View" ? "" : "d-none"
                                    }
                                  ></td>
                                  <td>{filterHeadings[key].value}</td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="d-flex justify-center actionn-text">
                                        <input
                                          className="form-control-sm"
                                          type="number"
                                          inputMode="number"
                                          disabled={!filterHeadings[key].enable}
                                          value={filterHeadings[key].min}
                                          onChange={(e) =>
                                            inputValueChange(
                                              e.target.value,
                                              "min",
                                              key
                                            )
                                          }
                                          id={key + "min"}
                                        ></input>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-1">
                                      <div className="d-flex justify-center actionn-text">
                                        <input
                                          className="form-control-sm"
                                          type="number"
                                          inputMode="number"
                                          disabled={!filterHeadings[key].enable}
                                          value={filterHeadings[key].max}
                                          onChange={(e) =>
                                            inputValueChange(
                                              e.target.value,
                                              "max",
                                              key
                                            )
                                          }
                                          id={key + "max"}
                                        ></input>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          </>}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>
              ) : (
                <div></div>
              )}
            </div>
          </Stack>
        </div>
      </>
    );
  
    useEffect(() => {
      if(drrView === '') {
      } else {
        props.drrView(drrView,'parent')
      }
      setExpandedRows([])
      setSelectedRows([])
  },[drrView])
    function searchBoxvalue(data) {
      props.handleSearch(data)
    }
    return (
      <>
      <Loader visible={isLoaderVisible}></Loader>
      <div className="campaign-list-table-data">
      <div className="d-flex align-items-center justify-content-between my-3">
        <div className="d-flex gap-3">
        {props.drrView && <>
                  <div className="data-toggle-buttons">
                  <button className={(drrView === "aggregate" || drrView === "") ? "primary-active-btn ennable-paused-btns-height square" : "primary-inactive-btn ennable-paused-btns-height square"} onClick={()=>{setDrrView('aggregate')}}>Aggregate View</button>
                  </div>
                  <div className="data-toggle-buttons">
                  <button className={drrView === "drr" ? "primary-active-btn ennable-paused-btns-height square" : "primary-inactive-btn ennable-paused-btns-height square"}  onClick={()=>{setDrrView('drr')}}>DRR View</button>
                  </div>
                  </> }
        </div>
        <div className="paginationtext">Showing {dataSource.length}  Out Of {totalRecords} Records</div>
      </div>
        <div className="d-flex justify-content-between align-items-center mt-4 mb-1 w-100 gap-3">
          <div className="reports d-flex">
          {   
            radioValues.map((radio, index) => {
              return (
                <div className="data-toggle-buttons">
                  <button className={viewType === radio ? "primary-active-btn ennable-paused-btns-height" : "primary-inactive-btn ennable-paused-btns-height"} id={radio} onClick={()=>{
                    if(!radio?.includes("Comparison")) {
                      getHeadingsOnViewChange(headings)
                      setViewType(radio)
                    }
                  }}>{radio}</button>
                  </div>
              )
            })
          }
          </div>
          {showFilter && <SearchWithSelect Value={searchBoxvalue} default={showFilter} /> }
          <div className="d-flex">
          <div className="position-relative mx-3" ref={ref}>
            <button
              className="primary-inactive-btn apply-filter d-flex align-items-center"
              onClick={overviewSettingBtnHandler}
            >
              <div className="d-flex gap-2 align-items-center">
                {" "}
                <img
                  src={overviewSeiingIcon}
                  className="cursor-pointer-global filterIcon"
                  alt="overview-settings-tile"
                ></img>{" "}
                <div>Filters</div>
              </div>
            </button>
            {isVisible && (
              <div
                className=""
                style={{
                  position: "absolute",
                  right: "120px",
                  zIndex: "9999",
                  top: "-16px",
                }}
              >
                <div className="tooltip-arrow"></div>
                <div className="shadow-lg p-1 mb-5 rounded tooltip-content">
                  <div className="d-flex justify-content-between align-items-center py-3 px-3">
                    <div className="text-left">Filter Logic</div>
                    <div className="d-flex gap-2 align-items-center">
                    <button className="primary-inactive-btn"  onClick={resetFilter}>Reset</button>
                      <Button
                        size="sm"
                        className="primary-active-btn"
                        onClick={applyFilter}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                  <div className="bg-white py-2 rounded">{tableFilterBody}</div>
                </div>
              </div>
            )}
          </div>
          <div>
          {/* {!customClasses.includes("sortByApi") ?<>
            <button
              className="primary-inactive-btn apply-filter d-flex align-items-center"
              onClick={onDownloadReady}
            >
              <div className="d-flex gap-2 align-items-center">
                {" "}
                <img
                  src={downloadXls}
                  className="cursor-pointer-global filterIcon"
                  alt="overview-settings-tile"
                ></img>{" "}
                <div>Download</div>
              </div>
            </button>
            </> : 
            <Download query={resultSet?.loadResponse?.pivotQuery} type={'multiple'} onDownload={onDownloadComplete} /> 
            } */}
            <Download query={resultSet?.loadResponse?.pivotQuery} type={'multiple'} onDownload={onDownloadComplete} />
          </div>
          </div>
          
        </div>
        <Stack className="campaign-list-main-table">
          <TableRb shimmer={shimmerEffect} noDataFound={!dataSource?.length} tableHeads={tableColumns} table={tableBody} hiddenKeys={hiddenKeys}></TableRb>
        </Stack>
      </div>
      </>
    );
  };