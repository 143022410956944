import React, { useState } from "react";
import { useSelector } from "react-redux";
import RadioButtonRb from "../../../Buttons/radioButtonRb/radioButtonRb";
import CheckboxRb from "../../../Buttons/checkbox/checkbox";
import { Nav, Tab } from "react-bootstrap";
import TableRb from "../../../Tables/tableRb";
import SearchbarForEditCampaign from "../../../searchbarForEditCampaign";
import FilterkeywordModal from "../../campaignCreation/SpKeywordTargeting/FilterkeywordModal";
import AddProductButton from "../../../../assets/svgs/plus_box.svg";
import AddProductTable1 from "../SP_Product_Targeting/AddProductTable1";
import AsinsTable from "../SP_Product_Targeting/AsinsTable";
import CreateCampaign from "../CreateCampaign";
import ChoosePortfolio from "../ChoosePortfolio";
import CreateAdGroup from "../CreateAdGroup";
import BrandAudienceTabs from "./BrandAudienceTabs";
import { useGlobalContext } from "../../../../context/global";
import { useAuthHeader } from "react-auth-kit";

const SDBrandAudience = ({ show, setShow }) => {
  // States
  const [page, setPage] = useState("fourth");
  const [isVisible, setIsVisible] = useState(false);
  const authHeader = useAuthHeader();
  const token = authHeader();
  const SPProductTargetingProductData = useSelector(
    (state) => state.CampaignCreationData.SPProductTargetingProductData
  );
  const [brandAudienceData, setBrandAudienceData] = useState([]);
  const { clientData, adGroupData, setAdGroupData, selectedCategory, userToken } =
    useGlobalContext();
  const { brand_id } = clientData;
  const default_bid = JSON.parse(localStorage.getItem("defaultBid"));
  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const brandId = JSON.parse(localStorage.getItem("brandId"));
  const [activeTabs, setActiveTabs] = useState([]);
  const [loadingIndex,setLoadingIndex] = useState(null);

  // Refs

  // Methods

  const getBidRecommendation = async (dataPassed) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      body: JSON.stringify(dataPassed),
    };
    const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}:fetch-bid-recommendation?source=amazon`;
    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (response?.status === 200) {
        return result;
      } else {
        alert("Error while fetching bid");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const radioChange = async (e, value, i) => {
    // API Predefined Data Structures that we need to post in the API.

    const bidAPIData = {
      type: "SD_CATEGORY",
      categories: Array.isArray(selectedCategory)
        ? selectedCategory
        : [selectedCategory],
      targeting_clauses: [
        {
          type:
            value === "content-last-30" || value === "view-last-30-adgroup"
              ? "views"
              : "purchases",
          expressions: [
            {
              type:
                value === "content-last-30" || value === "purchase-last-365"
                  ? "exactProduct"
                  : "similarProduct",
            },
            {
              type: "lookback",
              value:
                value === "content-last-30" || value === "view-last-30-adgroup"
                  ? "30"
                  : "365",
            },
          ],
        },
      ],
    };

    const mainBtn = e.target.closest(".redioButton-box");
    const allBtn = mainBtn.querySelectorAll(".redioButton");
    allBtn.forEach((item) => {
      item.classList.remove("active");
    });
    const add = e.target.closest(".redioButton");
    add.classList.add("active");
    if (
      add.children[0]?.children[0]?.children[0]?.children[0]?.children[0]
        ?.checked === true
    ) {
      // Here We're checking the if the checkbox is true then make it false and remove the particular option from the Main Adgroup and brand state with the help of index which we're passing through the radio change function
      add.children[0].children[0].children[0].children[0].children[0].checked = false;
      setBrandAudienceData((current) => {
        let copy = [...current];
        copy = copy.filter((data, index) => {
          return index !== i;
        });
        return copy;
      });
      setAdGroupData((current) => {
        let copy = { ...current };
        let existingData = copy["amz_product_audience_targeting"];
        existingData = existingData.filter((data, index) => {
          return index !== i;
        });
        copy["amz_product_audience_targeting"] = existingData;
        return copy;
      });
    } else {
      // Here we're checking if the checkbox is false then make it true and as well as append the data in the array and global state of Adgroup as well
      const result = await getBidRecommendation(bidAPIData);
      const { recommended_suggested_bid } = result?.data[0];
      const Data = {
        status: "ENABLED",
        type:
          value === "content-last-30" || value === "view-last-30-adgroup"
            ? "views"
            : "purchases",
        audience_expression: [
          {
            type: "lookback",
            value:
              value === "content-last-30" || value === "view-last-30-adgroup"
                ? "30"
                : "365",
          },
          {
            type:
              value === "content-last-30" || value === "purchase-last-365"
                ? "exactProduct"
                : "similarProduct",
            value: "",
          },
        ],
        value: "string",
        bid: recommended_suggested_bid ?? default_bid,
      };
      add.children[0].children[0].children[0].children[0].children[0].checked = true;
      setBrandAudienceData([...brandAudienceData, Data]);
      setAdGroupData({
        ...adGroupData,
        amz_product_audience_targeting: [...brandAudienceData, Data],
      });
    }
  };

  const tabContents = [
    {
      content:"People who have viewed your product  in last 30 days",
      name:"select-Campaign-Logics",
      heading:"View Content Last 30 Days",
      value:"content-last-30"
    },
    {
      content:"People who have purchased your product in last 365 days",
      name:"select-Campaign-Logics",
      heading:"Purchase Last 365 Days",
      value:"purchase-last-365"
    },
    {
      content:"People who have viewed similar products in last 30 days",
      name:"select-Campaign-Logics",
      heading:"Related View Content Last 30 Days",
      value:"view-last-30-adgroup"
    },
    {
      content:"People who have purchased similar products in last 365 days",
      name:"select-Campaign-Logics",
      heading:"Related Purchase Last 365 Days",
      value:"similar-last-365"
    },
  ]

  function arraysAreEqual(array1, array2) {
    // Check if the arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }
  
    // Sort the arrays to ensure the order doesn't affect the comparison
    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();
  
    // Compare each element of the sorted arrays
    for (let i = 0; i < sortedArray1.length; i++) {
      if (JSON.stringify(sortedArray1[i]) !== JSON.stringify(sortedArray2[i])) {
        return false;
      }
    }
  
    return true;
  }
  

  const tabChange = async (isSelected,recievedIndex , value) => {
    const bidAPIData = {
      type: "SD_CATEGORY",
      categories: Array.isArray(selectedCategory)
        ? selectedCategory
        : [selectedCategory],
      targeting_clauses: [
        {
          type:
            value === "content-last-30" || value === "view-last-30-adgroup"
              ? "views"
              : "purchases",
          expressions: [
            {
              type:
                value === "content-last-30" || value === "purchase-last-365"
                  ? "exactProduct"
                  : "similarProduct",
            },
            {
              type: "lookback",
              value:
                value === "content-last-30" || value === "view-last-30-adgroup"
                  ? "30"
                  : "365",
            },
          ],
        },
      ],
    };
    const Data = {
      status: "ENABLED",
      type:
        value === "content-last-30" || value === "view-last-30-adgroup"
          ? "views"
          : "purchases",
      audience_expression: [
        {
          type: "lookback",
          value:
            value === "content-last-30" || value === "view-last-30-adgroup"
              ? "30"
              : "365",
        },
        {
          type:
            value === "content-last-30" || value === "purchase-last-365"
              ? "exactProduct"
              : "similarProduct",
          value: "",
        },
      ],
      value: "string",
    };
    if(isSelected) {
      setAdGroupData((prev) => {
        return {
          ...prev,
          amz_product_audience_targeting:[...prev["amz_product_audience_targeting"]]?.filter((data,index) => {
            return !(data?.type===Data?.type && arraysAreEqual(Data?.audience_expression,data?.audience_expression))
          })
        }
      });
      setActiveTabs((prev) => {
        return  prev?.includes(recievedIndex) ? [...prev]?.filter((data,i) => data!==recievedIndex):[...prev,recievedIndex]
        })
    } else {
      setLoadingIndex(recievedIndex)
      const result = await getBidRecommendation(bidAPIData);
      const { recommended_suggested_bid } = result?.data[0];
      Data["bid"] = recommended_suggested_bid ?? default_bid
      setAdGroupData((prev) => {
        return {
          ...prev,
          amz_product_audience_targeting:[...prev["amz_product_audience_targeting"],Data]
        }
      });
      setActiveTabs((prev) => {
        return  prev?.includes(recievedIndex) ? [...prev]?.filter((data,i) => data!==recievedIndex):[...prev,recievedIndex]
        })
      setLoadingIndex(null)
    }
  };

  const CheckBoxTab = ({ content, handleChange, isSelected, heading , name , isDisabled }) => {
    return (
      <>
        <div
          className={`redioButton ${isSelected?'active':''} ${isDisabled?'disable-pointer-events':''}`}
          onClick={(e) => {
            handleChange()
          }}
          
        >
          <CheckboxRb
            name={name}
            checked={isSelected}
            onChange={(e) => {
              return null // Returning Null as there is no need to use this just want to trigger the above function
            }}
            BD={"BD"}
            label={
              <Leavle
                heading={heading}
                para={content}
              />
            }
          ></CheckboxRb>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <div>
          <span className="heading-font">Sponsored Display Brand Audience</span>
        </div>
        <CreateCampaign show={show} setShow={show} SD={"SD"} />
      </div>
      <div className="sponsored-product-keyword-targeting">
        <CreateAdGroup />
        <div className="key-wrap">
          <span className="common-head">
            Re-Targeting
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1178_14743"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="20"
              >
                <rect
                  x="0.585693"
                  y="0.0175781"
                  width="20"
                  height="19.9643"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_1178_14743)">
                <path
                  d="M10.5856 17.9022C9.48839 17.9022 8.45728 17.6943 7.49228 17.2783C6.52672 16.8624 5.68978 16.3009 4.98145 15.5939C4.27311 14.8868 3.71061 14.0513 3.29395 13.0875C2.87728 12.1242 2.66895 11.095 2.66895 9.9997C2.66895 8.90443 2.87728 7.87489 3.29395 6.91106C3.71061 5.94778 4.27311 5.11261 4.98145 4.40554C5.68978 3.69847 6.52672 3.13697 7.49228 2.72105C8.45728 2.30513 9.48839 2.09717 10.5856 2.09717C11.6828 2.09717 12.7142 2.30513 13.6798 2.72105C14.6448 3.13697 15.4814 3.69847 16.1898 4.40554C16.8981 5.11261 17.4606 5.94778 17.8773 6.91106C18.2939 7.87489 18.5023 8.90443 18.5023 9.9997C18.5023 11.095 18.2939 12.1242 17.8773 13.0875C17.4606 14.0513 16.8981 14.8868 16.1898 15.5939C15.4814 16.3009 14.6448 16.8624 13.6798 17.2783C12.7142 17.6943 11.6828 17.9022 10.5856 17.9022ZM10.5856 16.6545C12.4328 16.6545 14.0059 16.0065 15.3048 14.7104C16.6031 13.4139 17.2523 11.8436 17.2523 9.9997C17.2523 8.15577 16.6031 6.58553 15.3048 5.28896C14.0059 3.99294 12.4328 3.34494 10.5856 3.34494C8.73839 3.34494 7.16561 3.99294 5.86728 5.28896C4.56839 6.58553 3.91895 8.15577 3.91895 9.9997C3.91895 11.8436 4.56839 13.4139 5.86728 14.7104C7.16561 16.0065 8.73839 16.6545 10.5856 16.6545Z"
                  fill="#4D4184"
                />
                <path
                  d="M10.559 13.9509C10.7396 13.9509 10.889 13.8921 11.0073 13.7745C11.1251 13.6564 11.184 13.5072 11.184 13.327V9.77083C11.184 9.60446 11.1251 9.46221 11.0073 9.34409C10.889 9.22652 10.7396 9.16774 10.559 9.16774C10.3784 9.16774 10.2293 9.22652 10.1115 9.34409C9.99317 9.46221 9.934 9.61139 9.934 9.79162V13.3478C9.934 13.5141 9.99317 13.6564 10.1115 13.7745C10.2293 13.8921 10.3784 13.9509 10.559 13.9509ZM10.559 7.7536C10.7534 7.7536 10.9132 7.68761 11.0382 7.55562C11.1632 7.42419 11.2257 7.26143 11.2257 7.06733C11.2257 6.8871 11.1632 6.73099 11.0382 6.599C10.9132 6.46757 10.7534 6.40186 10.559 6.40186C10.3646 6.40186 10.2048 6.46757 10.0798 6.599C9.95483 6.73099 9.89233 6.8871 9.89233 7.06733C9.89233 7.26143 9.95483 7.42419 10.0798 7.55562C10.2048 7.68761 10.3646 7.7536 10.559 7.7536Z"
                  fill="#4D4184"
                />
              </g>
            </svg>
          </span>
          <div className="tab-wrap">
            <div className="redioButton-box">
              <div className="row g-">
                {/* <div className="col-lg-6">
                  <div
                    className="redioButton"
                    onClick={(e) => {
                      radioChange(e, "content-last-30", 0);
                    }}
                  >
                    <CheckboxRb
                      name="select-Campaign-Logics"
                      BD={"BD"}
                      label={
                        <Leavle
                          heading="View Content Last 30 Days"
                          para="People who have viewed your product  in last 30 days"
                        />
                      }
                    ></CheckboxRb>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="redioButton"
                    onClick={(e) => {
                      radioChange(e, "purchase-last-365", 1);
                    }}
                  >
                    <CheckboxRb
                      name="select-Campaign-Logics"
                      BD={"BD"}
                      label={
                        <Leavle
                          heading="Purchase Last 365 Days"
                          para="People who have purchased your product in last 365 days"
                        />
                      }
                    ></CheckboxRb>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="redioButton"
                    onClick={(e) => {
                      radioChange(e, "view-last-30-adgroup", 2);
                    }}
                  >
                    <CheckboxRb
                      name="select-Campaign-Logics"
                      BD={"BD"}
                      label={
                        <Leavle
                          heading="Related View Content Last 30 Days"
                          para="People who have viewed similar products in last 365 days"
                        />
                      }
                    ></CheckboxRb>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="redioButton"
                    onClick={(e) => {
                      radioChange(e, "similar-last-365", 3);
                    }}
                  >
                    <CheckboxRb
                      name="select-Campaign-Logics"
                      BD={"BD"}
                      label={
                        <Leavle
                          heading="Related Purchase Last 365 Days"
                          para="People who have purchased similar products in last 365 days"
                        />
                      }
                    ></CheckboxRb>
                  </div>
                </div> */}
                {/* {
                  tabContents?.map((data,index) => {
                    const {content,heading,name,value} = data
                    const isSelected = activeTabs?.includes(index)
                    const loading = loadingIndex === index
                    return (
                      <>
                        <div className="col-lg-6" key={heading}>
                          <CheckBoxTab heading={heading} isDisabled={loading} isSelected={isSelected} handleChange={() => tabChange(isSelected,index,value)} content={content} name={name}/>
                        </div>
                      </>
                    )
                  })
                } */}
                <BrandAudienceTabs/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Leavle = ({ heading, para }) => {
  return (
    <>
      <span className="leavle-hover leavle-heading">{heading}</span>
      <br />
      {para && <span className="leavle-hover leavle-para">{para}</span>}
    </>
  );
};

const Product = ({ image, Name, Desc }) => {
  return (
    <>
      <div className="product-container">
        <div className="product-img-container">
          <img src={image} alt="image" />
        </div>
        <div className="product-description-container">
          <span className="category-heading">Category</span>
          <span className="product-name">{Name && Name}</span>
          <span className="product-desc">Product:{Desc}</span>
        </div>
      </div>
    </>
  );
};

export default SDBrandAudience;
