export default async function pullUploadData (clientId) {
    const API_URL = `${process.env.REACT_APP_ADS_API}/client/${clientId}:pull-amazon-client-catalog-entities?source=amazon&type=amazon_ad_account`;
    const options = {
        method:"POST",
        headers: {
            Authorization: getCookieValue('_auth'),
          },
    }
    try {
        const response = await fetch(API_URL,options)
        const result = response?.status === 200
        return result
    } catch (error) {
        return error;
    }
}

function getCookieValue(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
    return '';
  }