import "./MasterTargetingList.css";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Stack, Tab, Table } from "react-bootstrap";
import parseData from "../../../helper/Graphs/parseData";
import SectionTitle from "../../../components/sectionTitle";
import "./../campaignList.css";
import settingIcon from "../../../assets/icons/overviewSeiingIcon.svg";
import SecondaryBtnRb from "../../../components/Buttons/secondaryBtnRb/secondaryBtnRb";
import CalenderComponent from "../../../components/calender/Calender";
import { useDispatch, useSelector } from "react-redux";
import { campaignListActions } from "../../../redux/slices/campaignList";
import FilterLogicModal from "../../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import { useGlobalContext } from "../../../context/global";
import dropdownIcon from "../../../assets/icons/downArrowForSplitButton.svg";
import selectSideIcon from "../../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../../assets/icons/calender-icon-for-campaignlist.svg";
import closeIcon from "../../../assets/icons/close_icon.png";
import Loader from "../../../components/loader";
import moment from "moment/moment";
import TableRb from "../../../components/Tables/tableRb";
import SwitchBtn from "../../../components/Buttons/switchBtn";
import addIcon from "../../../assets/svgs/plus_box.svg";
import editIcon from "../../../assets/icons/edit.svg";
import checkCircle from "../../../assets/svgs/check_circle.svg";
import CheckboxRb from "../../../components/Buttons/checkbox/checkbox";
import ascendingIconSvg from "../../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../../assets/svgs/descendingIcon.svg";
// import dashIcon from "../../../assets/icons/dash-icon.png";
import dashIcon from "../../../assets/svgs/minus_box.svg";
import pauseIcon from "../../../assets/icons/pause_circle.svg";
import resumeIcon from "../../../assets/icons/play_circle.png";
import findIconActive from "../../../assets/svgs/findIcon.svg";
import lessIcon from "../../../assets/svgs/minus_box.svg";
// import copyIcon from "../../../assets/icons/copy-icon.png";
import copyIcon from "../../../assets/icons/copy_icon.svg";
import valueCopiedIcon from "../../../assets/icons/content_copy_Icon.png";
import { roundFigureNum } from "../../../constant/roundFigureNum";
import searchIcon from "../../../assets/icons/search_icon.svg";
import MasterTagetinngActionAlertMModal from "../../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import { masterApi } from "../../../apis/masterApi";
import EditBidModalForTargeting from "../../../components/modal/editBidModalForTargeting";
import { useAuthHeader } from "react-auth-kit";
import e from "cors";
import SearchBarForCampaignListOnly from "../../../components/searchBarForCampaignListOnly";
import Spinner from "../../../components/spinners/spinner";
import TooltipRb from "../../../components/Tooltip/TooltipRb";
import { useNavigate, useSearchParams } from "react-router-dom";
import TrendGraphMaster from "../../../components/Graphs/TrendGraphMaster";
import LegendComponent from "../../../components/legend/legend";
import FilterChips from "../../../components/FilterChips/FilterChips";
import customDropDownWithCheckboxDefaultDataArray from "../../../constant/dropDownWIthCheckDefaultStateList";
import MultiSelectCustomDropDown from "../../../components/DropDown/MultiSelectCustomDropDown";
import LazyLoadSpinner from "../../../components/spinners/LazyLoadSpinner";
import useInitialLoad from "../../../hooks/useInitialLoad";
import modifyValueByPercentage from "../../../helper/common/modifyValueByPercentage";
import getCustomDataHeads from "../../../helper/CampaignList/getCustomDataHeads";
import { getCurrencySymbol } from "../../../constant/defaultCurrencyConfigurations";
import Breadcrumb from '../../../components/Breadcrumb/ReportsBreadCrumb';
import { useMemo } from "react";
import {toast} from 'react-toastify';

const MasterTargetingList = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authHeader = useAuthHeader();
  const token = authHeader();
  const searchInColumnRef = useRef(null);
  const masterTargetinngCalenderIconRef = useRef(null);
  const dummyCountForChildLoaderRow = [1, 2, 3];
  const limit = 200
  const [
    showSelectColumnSearchToggleBtnState,
    setShowSelectColumnSearchToggleBtnState,
  ] = useState(false);
  const btnNameForChipAccordingToFilteringFiledName = [
    {
      fieldName: "adyogi_targeting_type",
      btnNameAsPerFieldName: "Targeting Type",
    },
    { fieldName: "campaign_type", btnNameAsPerFieldName: "Campaign Type" },
    { fieldName: "portfolio_id", btnNameAsPerFieldName: "Portfolio" },
    { fieldName: "campaign_id", btnNameAsPerFieldName: "Campaign" },
    { fieldName: "adgroup_id", btnNameAsPerFieldName: "Ad Group" },
    { fieldName: "campaign_objective", btnNameAsPerFieldName: "Objective" },
    { fieldName: "spends", btnNameAsPerFieldName: "Spends" },
    { fieldName: "impressions", btnNameAsPerFieldName: "Impressions" },
    { fieldName: "clicks", btnNameAsPerFieldName: "Clicks" },
    { fieldName: "orders", btnNameAsPerFieldName: "Orders" },
    { fieldName: "sale_value", btnNameAsPerFieldName: "Revenue" },
    { fieldName: "cpm", btnNameAsPerFieldName: "CPM" },
    { fieldName: "ctr", btnNameAsPerFieldName: "CTR" },
    { fieldName: "cpc", btnNameAsPerFieldName: "CPC" },
    { fieldName: "cvr", btnNameAsPerFieldName: "CVR" },
    { fieldName: "aov", btnNameAsPerFieldName: "AOV" },
    { fieldName: "roas", btnNameAsPerFieldName: "ROAS" },
    { fieldName: "acos", btnNameAsPerFieldName: "ACOS" },
  ];


  let NumberFilteringFieldNameWithDispalyName = [
    { fieldName: "spends", btnNameAsPerFieldName: "Spends" },
    { fieldName: "impressions", btnNameAsPerFieldName: "Impressions" },
    { fieldName: "clicks", btnNameAsPerFieldName: "Clicks" },
    { fieldName: "orders", btnNameAsPerFieldName: "Orders" },
    { fieldName: "sale_value", btnNameAsPerFieldName: "Revenue" },
    { fieldName: "cpm", btnNameAsPerFieldName: "CPM" },
    { fieldName: "ctr", btnNameAsPerFieldName: "CTR" },
    { fieldName: "cpc", btnNameAsPerFieldName: "CPC" },
    { fieldName: "cvr", btnNameAsPerFieldName: "CVR" },
    { fieldName: "aov", btnNameAsPerFieldName: "AOV" },
    { fieldName: "roas", btnNameAsPerFieldName: "ROAS" },
    { fieldName: "acos", btnNameAsPerFieldName: "ACOS" },
  ];
  const [targetingDataForTable, setTargetingDataForTable] = useState([]);
  const [selectedBtn, setselectedBtn] = useState("all-btn");
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [findClickedColumnName, setFindClickedColumnName] = useState(null);
  const [checkedColumnSearchData, setCheckedColumnSearchData] = useState([]);
  const [cancelSpecifiNumberFilter, setCancelSpecifiNumberFilter] =
    useState(null);

  const [DropDownDataList, setDropDownDataList] = useState(
    customDropDownWithCheckboxDefaultDataArray
  );
  const [
    multiSelectDropDownSelectedDataCount,
    setMultiSelectDropDownSelecteddDataCount,
  ] = useState({
    adyogi_targeting_type: 0,
    campaign_type: 0,
    campaign_objective: 0,
  });

  const [droDownFilterDataForApi, setDroDownFilterDataForApi] = useState([]);
  const [lazyLoading, setLazyLoading] = useState(false)
  const [checkedDropDownFilterButtonData, setCheckedDropDownFilterButtonData] =
    useState([]);
  const [
    arrayOfCheckedDropDownFilteringForApiRequest,
    setArrayOfCheckedDropDownFilteringForApiRequest,
  ] = useState([]);

  const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);

  // const [selectedcampaignDataTpRedirectOnAdgroup, setSelectedcampaignDataTpRedirectOnAdgroup] = useState([]);
  const [
    IsloaderVisibiltyNeededForMainTargeting,
    setIsloaderVisibiltyNeededForMainTargeting,
  ] = useState(true);
  const [parentDetailToGetChildData, setparentDetailToGetChildData] = useState(
    {}
  );
  const [childDataFiltering, setChildDataFiltering] = useState([]);
  const [allCheckStateInColumnSearchBox, setAllCheckStateInColumnSearchBox] =
    useState(false);

  const [sortingDetails, setSortingDetails] = useState({
    sortByOrder: "DESC",
    sortingColumnName: "spends",
  });
  const [targetingApiFilteringData, serTargetingApiFilteringData] =
    useState(null);
  const [selectedViewType, setSelectedViewType] =  useState("Parent");
  const [filteredtargettingTypeValue, setFilteredtargettingTypeValue] =
    useState("All");

  const [campaignObjectiveType, setCampaignObjectiveType] = useState("Both");
  const [
    tableColumnFindIconSearchInputText,
    setTableColumnFindIconSearchInputText,
  ] = useState(null);
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const { portfolio, setCampaignData, campaignData, userToken } = useGlobalContext();
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [
    chckedCampaignDataForFilteringPortfolioAdgroup,
    setChckedCampaignDataForFilteringPortfolioAdgroup,
  ] = useState({ selectedCampaignId: [], selectedPlatformId: [] });

  const [checkedCampaignIdForFilter, setCheckedCampaignIdForFilter] = useState(
    []
  );
  const [checkedPlatforIdForFilter, setCheckedPlatforIdForFilter] = useState(
    []
  );
  const [sortingType, setSortingType] = useState("");
  const currencySymbol = getCurrencySymbol();
  const [checkedAdGroupData, setCheckedAdGroupData] = useState([]);
  const [isTargetingBoxChecked, setsTargetingBoxChecked] = useState(false);
  const [currentClickedTargeting, setCurrentClickedTargeting] = useState({
    clikedTargeting: "",
    clickedTargetingMatch: "",
  });
  const [showAlertMsgOf, setShowAlertMsgOf] = useState("");
  const [alertModalCauseAction, setAlertModalCauseAction] = useState(null);
  const [isEditBidModalVisible, setIsEditBidModalVisible] = useState(false);
  const [mainTargetingSelected, setMainTargetingSelceted] = useState(false);
  const [targetingFiltering, setTargetingFiltering] = useState([]);
  const [updateTargetDataForBulk, setUpdateTargetDataForBulk] = useState([]);
  const [totalEnabledCampaign, setTotalEnabledCampaign] = useState([]);
  const [totalPausedCampaign, setTotalPausedCampaign] = useState([]);
  const [portfolioListData, setPortfolioListData] = useState([]);
  const [adgroupListData, setAdgroupListData] = useState([]);
  const [initialLoad,setInitialLoad] = useInitialLoad()
  const [campaignListData, setCampaignListData] = useState([]);
  const [
    listForSearchAnndFilterTargetingBy,
    setListForSearchAnndFilterTargetingBy,
  ] = useState([]);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const { dateDifference, setDateDifference } = useGlobalContext();
  const { calendarDate, setCalendarDate,applyCalendar } = useGlobalContext();
  const [allFetchLoaders, setAllFetchLoaders] = useState({
    mainPageLoader: false,
    targetingChildLoaderForPlusAndCheckIcon: false,
  });
  // const [isCustomHeadsRequired,setIsCustomHeadsRequired] = useState(false)

  const [paginationAction, setPaginationAction] = useState({
    actionnType: "",
    currentPageCount: 1,
  });
  const [offesetDataAsPerCurretPage, setoffesetDataAsPerCurretPage] =
    useState(0);
  const [runChildData, setRunChildData] = useState(false);
  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const bulkEditBidModalVisibilty = useSelector(
    (state) => state.campaignListData.bulkEditBidModalVisibilty
  );
  const actionEditCollectiveBidForMasterTargeting = useSelector(
    (state) => state.campaignListData.actionEditCollectiveBidForMasterTargeting
  );

  const getUpdatedConfigHeadsForMasterTargetingListSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForMasterTargetingListSection
  );

  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );
  const tableRef = useRef(null)
  const [dataHeads, setdataHeads] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [rowCount,setRowCount] = useState({});

  const client_id =
    JSON.parse(localStorage.getItem("clientId")) === null
      ? "aiekYz4hQy"
      : JSON.parse(localStorage.getItem("clientId"));
    

  
  // Function to fetch all the rows count at once.

  async function getTargetingRowsCount () {
    try {
      const childParentRow = selectedViewType === "Parent" ? true:false
      const dataForAPI = {
        source:"amazon",
        master_row:childParentRow,
        order_by:sortingDetails.sortingColumnName,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        sorting_order: sortingDetails.sortByOrder,
        filtering:
        targetingApiFilteringData.length ||
          targetingApiFilteringData !== "" ||
          targetingApiFilteringData !== null
          ? encodeData(targetingApiFilteringData)
          : null,
        fetch_count:true
      }

    const result = await masterApi(dataForAPI,
      "masterTargeting",
      false)
      if(result?.data?.data) {
        setRowCount(result?.data?.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columnsToHide = ["mt_sugg_bid","mmt_currennt_bid"]

  const isCustomHeadsRequired = useMemo(() => {
    return targetingDataForTable?.some((data) => !data?.isPlusIconDisabled)
  },[targetingDataForTable]) 

  const customDataHeads = !isCustomHeadsRequired ? getCustomDataHeads(dataHeads,columnsToHide) : dataHeads

  //tyhis will give main targeting parent row data
  async function getMasterTargetingData() {
    setIsLoaderVisible(true);
    let master_row = "true";
    if(selectedViewType === "Parent") {
      master_row = "true"
    } else {
      master_row = "false"
    }
    try {
      const parentRowData = {
        source: "amazon",
        master_row: master_row,
        order_by: sortingDetails.sortingColumnName,
        limit: limit,
        offset: offesetDataAsPerCurretPage,
        filtering:
          targetingApiFilteringData.length ||
            targetingApiFilteringData !== "" ||
            targetingApiFilteringData !== null
            ? encodeData(targetingApiFilteringData)
            : null,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        sorting_order: sortingDetails.sortByOrder,
      };

      const parentResult = await masterApi(
        parentRowData,
        "masterTargeting",
        false
      );

      consolidateDataWithDefaultStateKey(parentResult?.data?.data);
      createFilteringChipAccordingToFiltering(targetingApiFilteringData);
      setIsLoaderVisible(false);
      // setInitialLoad(false)
      // return result;
    } catch (e) {
      setIsLoaderVisible(false);
      // setInitialLoad(false)
    }
  }


  const handleScroll = async () => {
    const container = tableRef.current;
    let { currentPage, currentOffSet } = paginationAction;
    // Keep track of last scroll position
    const lastScrollTop = container.getAttribute("data-scroll-top") || 0;

    // Determine scroll direction
    const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";

    // Update last scroll position
    container.setAttribute("data-scroll-top", container.scrollTop);
    if (
      scrollDirection === "down" &&
      Math.ceil(container.scrollTop + container.clientHeight) >=
      container.scrollHeight
      && targetingDataForTable?.length >= 50
    ) {

      if (!lazyLoading) {
        getPaginationOnForwordArrowClickHandler("forword")
      }
    }
  };

  async function loadMoreData() {
    setLazyLoading(true);
    const masterRow = selectedViewType === "Parent" ? true:false
    try {
      const parentRowData = {
        source: "amazon",
        master_row: masterRow,
        order_by: sortingDetails.sortingColumnName,
        limit: limit,
        offset: offesetDataAsPerCurretPage,
        filtering:
          targetingApiFilteringData.length ||
            targetingApiFilteringData !== "" ||
            targetingApiFilteringData !== null
            ? encodeData(targetingApiFilteringData)
            : null,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        sorting_order: sortingDetails.sortByOrder,
      };

      const parentResult = await masterApi(
        parentRowData,
        "masterTargeting",
        false
      );
      const data = parentResult?.data?.data
      for (let i = 0; i < data.length; i++) {
        data[i].isPlusIconDisabled = true;
        data[i].isTargetChecked = false;
        data[i].isEditCurrenntBidValueDisabled = true
        data[i].current_bid_copy = data[i]?.current_bid
      }
      setTargetingDataForTable([...targetingDataForTable, ...data]);
      // consolidateDataWithDefaultStateKey(parentResult.data.data);
      // createFilteringChipAccordingToFiltering(targetingApiFilteringData);
      setLazyLoading(false);
    } catch (e) {
      setIsLoaderVisible(false);
    }
  }



  async function fetchTargetingDataForGraph() {
    setIsLoaderVisible(true);
    
    const data = {
      level: "account",
      platform: "amazon",
      start_date: `${formatDateFn(moment().subtract(42, "days")._d)}`,
      end_date: `${formatDateFn(moment().subtract(1, "days")._d)}`,
      fields: `spends,target_type,sale_value,week `,
      sorting_order: "asc",
      time_increment: "week",
      order_by: "week",
      group_by: "target_type",
      // filtering: window.encodeURI(`[{"field_name":"campaign_type","operator":"IN","values":["${campaignData?.campaign_type}"]}]`),
    };
    const result = await masterApi(data);
    const returnedData = parseData(result?.data?.data);
    setGraphData(returnedData);
    // setIsLoaderVisible(false);
    setInitialLoad(false)
  }

  //this wil give targettings child row data
  async function getMasterTargetingChildData() {
    // setIsLoaderVisible(true);
    setAllFetchLoaders({
      ...allFetchLoaders,
      mainPageLoader: false,
      targetingChildLoaderForPlusAndCheckIcon: true,
    });
    try {
      const ChildRowData = {
        source: "amazon",
        master_row: "false",
        order_by: "spends",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: childDataFiltering ? encodeData(childDataFiltering) : "",
      };

      const childResult = await masterApi(
        ChildRowData,
        "masterTargeting",
        false
      );

      childResult.data.data &&
        consolidateChilddataWithParent(childResult.data.data);
      // consolidateAllChilddataWithParent(childResult.data.data)
      // setIsLoaderVisible(false);
      setAllFetchLoaders({
        ...allFetchLoaders,
        mainPageLoader: false,
        targetingChildLoaderForPlusAndCheckIcon: false,
      });
      // return result;
    } catch (e) {
      // setIsLoaderVisible(false);
      setAllFetchLoaders({
        ...allFetchLoaders,
        mainPageLoader: false,
        targetingChildLoaderForPlusAndCheckIcon: false,
      });
    }
  }

  async function getCampaignIdWhenClickedOnAdgroupToRedirect(
    childData,
    parentData
  ) {
    const dataForCampaignList = {
      source: "amazon",

      filtering: encodeData([
        {
          field_name: "name",
          operator: "EQUALS",
          value: childData.campaign_name,
        },
      ]),
      fields: "id,name",

      // limit: 5000,
    };

    const result = await masterApi(dataForCampaignList, "campaignList", false);

    const campaignId = result?.data?.data[0];
    // navigate(
    //   `/edit-adset-targeting?campaignId=${campaignId.id}&adsetId=${childData.targeting_expression.adset_id}&numericCampaignId=${childData.campaign_id}&numericAdsetId=${childData.ad_group_id}&name=${childData?.adgroup_name}&adsetPlatformId=${childData.ad_group_id}&campName=${childData.campaign_name}&targetingName=${childData.targeting}`
    // );
    if (campaignId) {
      window.open(
        `/edit-adset-targeting?campaignId=${campaignId.id}&adsetId=${childData.targeting_expression.adset_id}&numericCampaignId=${childData.campaign_id}&numericAdsetId=${childData.ad_group_id}&name=${childData?.adgroup_name}&adsetPlatformId=${childData.ad_group_id}&campName=${childData.campaign_name}&targetingName=${childData.targeting}`,
        "_blank",
        "noreferrer"
      );
    }
  }
  //this API Functionn gets called when clicked onn search icon in column
  async function getDataForSearchListeinng(
    clickedColumnSearchName,
    filteringForSearch
  ) {
    const getPortfolioRequest = {
      source: "amazon",
      filtering: filteringForSearch ? filteringForSearch : null,
    };
    const getPortfolioRequestBySelectedCampaign = {
      source: "amazon",
      filtering: encodeData({
        field_name: "amz_portfolio_id",
        operator: "IN",
        values:
          chckedCampaignDataForFilteringPortfolioAdgroup.selectedPlatformId,
      }),
    };

    const getAdsetRequestBody = {
      source: "amazon",
      fields: "id,name,platform_id",
      filtering: filteringForSearch,
    };
    const getCammpaignsRequestBody = {
      source: "amazon",
      fields: "id,name,platform_id",
      filtering: filteringForSearch,
    };

    let result;
    switch (clickedColumnSearchName) {
      case "PORTFOLIO":
        if (
          chckedCampaignDataForFilteringPortfolioAdgroup.selectedPlatformId
            .length
        ) {
          result = await masterApi(
            getPortfolioRequestBySelectedCampaign,
            "campaignList",
            false
          );
          setPortfolioListData(result.data.data);
        } else {
          result = await masterApi(getPortfolioRequest, "getPortfolios", false);
          setPortfolioListData(result?.data?.data);
        }
        break;
      case "CAMPAIGNS":
        result = await masterApi(
          getCammpaignsRequestBody,
          "campaignList",
          false
        );
        setCampaignListData(result?.data?.data);
        break;
      case "AD GROUP":
        result = await masterApi(getAdsetRequestBody, "adsetList", false);
        setAdgroupListData(result?.data?.data);
        break;

      default:
        break;
    }
    setListForSearchAnndFilterTargetingBy(result.data.data);
    checkedFiltering(result.data.data, clickedColumnSearchName);
  }

  const createFilteringChipAccordingToFiltering = (currentAppliedFiltering) => {
    setDroDownFilterDataForApi([]);
    let listForChip = [];
    let minMaxFilters = [];

    setMultiSelectDropDownSelecteddDataCount({
      adyogi_targeting_type: 0,
      campaign_type: 0,
      campaign_objective: 0,
    });
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentAppliedFiltering.map((filList) => {
      btnNameForChipAccordingToFilteringFiledName.map((listToCheckType) => {
        if (
          filList.field_name === "portfolio_id" ||
          filList.field_name === "campaign_id" ||
          filList.field_name === "adgroup_id"
        ) {
          let dataListForLoop =
            filList.field_name === "portfolio_id"
              ? portfolioListData
              : filList.field_name === "campaign_id"
                ? campaignListData
                : filList.field_name === "adgroup_id"
                  ? adgroupListData
                  : null;

          let getNamesFromPlatformIdSendInAPIFiltering = [];
          if (filList.field_name === listToCheckType.fieldName) {
            dataListForLoop.map((portfList) => {
              filList.values.map((filVal) => {
                if (portfList.platform_id === filVal) {
                  getNamesFromPlatformIdSendInAPIFiltering.push(portfList.name);
                }
              });
            });
            if (
              (filList.field_name === "campaign_id" &&
                getNamesFromPlatformIdSendInAPIFiltering.length > 1) ||
              (filList.field_name === "adgroup_id" &&
                getNamesFromPlatformIdSendInAPIFiltering.length > 1)
            ) {
              //this if selected Campaign or adgroup for filter length is less then one then chip name will be Plural else Singular

              listForChip.push({
                fieldNameOfSelectedBtn: `${listToCheckType.btnNameAsPerFieldName}s`,
                field_name: filList.field_name,
                checkedValue: getNamesFromPlatformIdSendInAPIFiltering,
              });
            } else {
              listForChip.push({
                fieldNameOfSelectedBtn: listToCheckType.btnNameAsPerFieldName,
                field_name: filList.field_name,
                checkedValue: getNamesFromPlatformIdSendInAPIFiltering,
              });
            }
          }
        } else if (filList.field_name === listToCheckType.fieldName) {
          if (
            filList.operator === "GREATER_THAN_EQUAL_TO" ||
            filList.operator === "LESS_THAN_EQUAL_TO"
          ) {
            //this will create array which containns min max  filter nnumbers
            minMaxFilters.push({
              fieldNameOfSelectedBtn: listToCheckType.btnNameAsPerFieldName,
              field_name: filList.field_name,
              checkedValue: filList,
            });
          } else {
            listForChip.push({
              fieldNameOfSelectedBtn: listToCheckType.btnNameAsPerFieldName,
              field_name: filList.field_name,
              checkedValue: filList.values,
            });
          }
        }
      });
    });

    //now we will create array of same field_name with different values( this case is only for min max numbers)
    const newArray = [];
    minMaxFilters.forEach((obj) => {
      let newObj = {
        fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        checkedValue: obj.checkedValue,
      };
      let foundIndex = newArray.findIndex(
        (x) => x.field_name === obj.field_name
      );
      if (foundIndex === -1) {
        newArray.push({
          field_name: obj.field_name,
          values: [newObj],
          fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        });
      } else {
        newArray[foundIndex].values.push(newObj);
      }
    });

    //now we will loop through created array for numbers and create required object with min & max key of same field_name & set it to main state list for chip list
    newArray.map((currObj) => {
      let minValueForCurrentObj = 0;
      let maxValueForCurrentObj = "Infinity";
      currObj.values.map((currentDaata) => {
        if (currentDaata.checkedValue.operator === "LESS_THAN_EQUAL_TO") {
          maxValueForCurrentObj = currentDaata.checkedValue.value;
        }
        if (currentDaata.checkedValue.operator === "GREATER_THAN_EQUAL_TO") {
          minValueForCurrentObj = currentDaata.checkedValue.value;
        }
      });

      listForChip.push({
        field_name: currObj.field_name.toLowerCase(),
        fieldNameOfSelectedBtn: currObj.fieldNameOfSelectedBtn,
        minValue: minValueForCurrentObj,
        maxValue: maxValueForCurrentObj,
      });
    });

    //this loop will show count of selected value of drodpdown data in dropdown filtering data

    currentAppliedFiltering.map((filList) => {
      DropDownDataList.map((dropDownnData) => {
        if (
          dropDownnData.apiFieldNameForFilteringRequest === filList.field_name
        ) {
          dropDownnData.isDataChecked = true;
          filList.values.map((mainValue) => {
            let valueToSend =
              filList.field_name === "adyogi_targeting_type"
                ? dropDownnData.valueToSnedInApiCallForFilter
                : dropDownnData.name;

            if (mainValue == valueToSend) {
              setMultiSelectDropDownSelecteddDataCount({
                ...multiSelectDropDownSelectedDataCount,
                [filList.field_name]: filList.values.length,
              });
            }
          });
        }
      });
    });

    setDropDownDataList(currentList);
    setDroDownFilterDataForApi(listForChip);
  };

  const updateTargetingDataOnServer = (updateDataArray,successMsg,rejectedMsg) => {
    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: userToken,
    };
    let dataToUpdate = updateDataArray;
    var raw = JSON.stringify(dataToUpdate);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setInitialLoad(true);
    fetch(
      `${process.env.REACT_APP_ADS_API}/client/${client_id}:update-amazon-bulk-target?source=amazon`,
      requestOptions
    )
      .then((response) => response.json())

      .then((result) => {
        setIsLoaderVisible(false);
        setInitialLoad(false)
        if (mainTargetingSelected) {
          setMainTargetingSelceted(false);
        }
        if(result?.some((el) => el.success)) {
          toast.success(successMsg)
        } else {
          toast.error(rejectedMsg)
        }
        return result;
      })
      .catch((error) => {
        setIsLoaderVisible(false);

        toast.error('Some unknown error occurred!')
        console.log("error", error);
      });
  };

  //Main API Call

  useEffect(() => {
    getMasterTargetingData();
    getTargetingRowsCount();
  }, [
    targetingApiFilteringData,
    applyCalendar,
    sortingDetails,
  ]);

  useEffect(() => {
    if (offesetDataAsPerCurretPage > 0) {
      loadMoreData()
    }
  }, [offesetDataAsPerCurretPage])

  useEffect(() => {
    fetchTargetingDataForGraph();
  }, []);

  useEffect(() => {
    dispatch(campaignListActions.appliedFilterData([]));
  }, []);

  useEffect(() => {
    //if appliedFilterData has length then we will send that data in api if not then we will remove all existing number filtering from api filtering
    setMinMaxFilterActivated(false);
    if (targetingFiltering.length) {
      let dataToRemove = [];
      targetingFiltering.map((tf, index) => {
        if (tf.operator === "GREATER_THAN_EQUAL_TO" || tf.operator === "LESS_THAN_EQUAL_TO") {
          dataToRemove.push(tf);
        }
      });
      targetingFiltering.map((filt, index) => {
        dataToRemove.map((d) => {
          if (filt.field_name === d.field_name) {
            targetingFiltering.splice(filt, index);
          }
        });
      });

      setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);
    }
    if (appliedFilterData.length) {
      appliedFilterData.map((numFiltering) => {
        if (
          numFiltering.maxRange !== "" &&
          numFiltering.maxRange !== 0 &&
          numFiltering.maxRange !== "-"
        ) {
          setMinMaxFilterActivated(true);
          setTargetingFiltering((targetingFiltering) => [
            ...targetingFiltering,
            {
              field_name: numFiltering.id,
              operator: "LESS_THAN_EQUAL_TO",
              value: numFiltering.maxRange,
            },
          ]);
        }
        if (
          numFiltering.minRange !== "" &&
          numFiltering.minRange !== 0 &&
          numFiltering.minRange !== "-"
        ) {
          setMinMaxFilterActivated(true);
          setTargetingFiltering((targetingFiltering) => [
            ...targetingFiltering,
            {
              field_name: numFiltering.id,
              operator: "GREATER_THAN_EQUAL_TO",
              value: numFiltering.minRange,
            },
          ]);
        }
      });
    }

  }, [appliedFilterData]);

  useEffect(() => {
    if (
      parentDetailToGetChildData?.checkedTargeting &&
      parentDetailToGetChildData.matchtyp
    ) {
      let dataToRemove = [];
      setChildDataFiltering([
        {
          field_name: "targeting",
          operator: "IN",
          values: [parentDetailToGetChildData.checkedTargeting],
        },
        {
          field_name: "adyogi_targeting_type",
          operator: "IN",
          values: [parentDetailToGetChildData.matchtyp],
        },
      ]);
      if (targetingFiltering.length) {
        targetingFiltering.map((fil) => {
          if (fil.field_name == "adyogi_targeting_type") {
          } else if (
            fil.field_name == "targeting" &&
            fil.operator == "EQUALS"
          ) {
          } else {
            setChildDataFiltering((childDataFiltering) => [
              ...childDataFiltering,
              fil,
            ]);
          }
        });
      }
    }
  }, [parentDetailToGetChildData]);

  useEffect(() => {
    if (childDataFiltering.length) {
      getMasterTargetingChildData();
    }
  }, [childDataFiltering]);

  useEffect(() => {
    setdataHeads(getUpdatedConfigHeadsForMasterTargetingListSection);
  }, [getUpdatedConfigHeadsForMasterTargetingListSection]);

  useEffect(() => {
    formatDateFn(calendarDate[0].startDate);
  }, []);

  useEffect(() => {
    setPaginationAction({
      ...paginationAction,
      actionnType: null,
      currentPageCount: 1,
    });
    // dateConverter(calendarDate[0].startDate, calendarDate[0].endDate);
  }, [applyCalendar]);//[calendarDate]

  useEffect(() => {
    if (
     ( actionEditCollectiveBidForMasterTargeting.targetIdsToChagedbid &&
      actionEditCollectiveBidForMasterTargeting.targetIdsToChagedbid.length) || (
        checkedAdGroupData.length && selectedViewType==="Child"
      )
    ) {
      editCurrentBidInBulk();
    } 
  }, [actionEditCollectiveBidForMasterTargeting]);

  //this will close colummn search box
  useEffect(() => {
    document.addEventListener("mousedown", closeColumnnSearch);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeColumnnSearch);
    };
  }, [findClickedColumnName]);

  //this will execute for edit bid
  const editCurrentBidInBulk = () => {
    let createRequestBodyForUpdateBidOnserver = [];
    let updatedBid;
    let tempTargetStat = JSON.stringify(targetingDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);
      if (
        actionEditCollectiveBidForMasterTargeting.targetIdsToChagedbid &&
        actionEditCollectiveBidForMasterTargeting.targetIdsToChagedbid.length && selectedViewType === "Parent"
      ) {
        for (
          let l = 0;
          l <
          actionEditCollectiveBidForMasterTargeting.targetIdsToChagedbid.length;
          l++
        ) {
          for (let i = 0; i < tempTargetStat.length; i++) {
            if (tempTargetStat[i].childRows) {
              for (let j = 0; j < tempTargetStat[i].childRows.length; j++) {
                if (
                 ( tempTargetStat[i].childRows[j].target_id ===
                  actionEditCollectiveBidForMasterTargeting
                    .targetIdsToChagedbid[l].selectedChildTargetingId) && isCurrentBidPresent(tempTargetStat[i].childRows[j]) && isCampaignNotSB(tempTargetStat[i].childRows[j])
                ) {
                  if (
                    actionEditCollectiveBidForMasterTargeting.selectedAction ==
                    "increase-by"
                  ) {
                    updatedBid =
                      Number(tempTargetStat[i].childRows[j].current_bid) +
                      (tempTargetStat[i].childRows[j].current_bid / 100) *
                      actionEditCollectiveBidForMasterTargeting.valuePercentage;
                    tempTargetStat[i].childRows[j].current_bid = updatedBid;

                    tempTargetStat[i].childRows[
                      j
                    ].targeting_expression.target[0].bid = updatedBid;
                    createRequestBodyForUpdateBidOnserver.push(
                      tempTargetStat[i].childRows[j].targeting_expression
                    );
                  } else if (
                    actionEditCollectiveBidForMasterTargeting.selectedAction ==
                    "set_bid_as"
                  ) {
                    updatedBid =
                      actionEditCollectiveBidForMasterTargeting.setAsValue;
                    tempTargetStat[i].childRows[j].current_bid = updatedBid;
                    tempTargetStat[i].childRows[
                      j
                    ].targeting_expression.target[0].bid = updatedBid;
                    createRequestBodyForUpdateBidOnserver.push(
                      tempTargetStat[i].childRows[j].targeting_expression
                    );
                  } else {
                    updatedBid =
                      Number(tempTargetStat[i].childRows[j].current_bid) -
                      (tempTargetStat[i].childRows[j].current_bid / 100) *
                      actionEditCollectiveBidForMasterTargeting.valuePercentage;
                    tempTargetStat[i].childRows[j].current_bid = updatedBid;
                    tempTargetStat[i].childRows[
                      j
                    ].targeting_expression.target[0].bid = updatedBid;
                    createRequestBodyForUpdateBidOnserver.push(
                      tempTargetStat[i].childRows[j].targeting_expression
                    );
                  }

                  tempTargetStat[i].childRows[j].isTargetChecked = false;
                  tempTargetStat[i].isTargetChecked = false;
                }
              }
            }
          }
        }

      
       if(createRequestBodyForUpdateBidOnserver.length) {
        setUpdateTargetDataForBulk([...tempTargetStat]);
        setTargetingDataForTable(tempTargetStat);
        setCheckedAdGroupData([]);
        updateTargetingDataOnServer(createRequestBodyForUpdateBidOnserver,"Bid Updated Successfully","Unknown Error occurred");
       } 

       if(!createRequestBodyForUpdateBidOnserver.length) {
        toast.warning('All the selected data contains either SB targetings or Bid is not present')
       }

      }
      
      // For Child View
      if(checkedAdGroupData.length && selectedViewType === "Child" && actionEditCollectiveBidForMasterTargeting?.targetIdsToChagedbid?.length) {
        const {selectedAction,valuePercentage,setAsValue} = actionEditCollectiveBidForMasterTargeting
        // Create Payload Data FOR API Request
        createRequestBodyForUpdateBidOnserver = [...checkedAdGroupData]?.filter((checkedData) => {
          return isCampaignNotSB(checkedData) && isCurrentBidPresent(checkedData)
        })?.map((data) => {
         const newData = structuredClone(data)
          newData.targeting_expression.target[0].bid = selectedAction!=="set_bid_as" ? modifyValueByPercentage(selectedAction,valuePercentage,newData?.current_bid) : Number(setAsValue)
          return newData?.targeting_expression
        })
        // GET All SELECTED data Id's
        const checkedAdgroupDataIds = checkedAdGroupData?.filter((mainData) => {
          return isCampaignNotSB(mainData) && isCurrentBidPresent(mainData)
        })?.map(data => data?.target_id)

        // Update select Ids in State Array
        tempTargetStat = tempTargetStat?.map((data) => {
          const newData = {...data}
          if(checkedAdgroupDataIds.includes(data?.target_id)) {
            newData.targeting_expression.target[0].bid = selectedAction!=="set_bid_as" ? modifyValueByPercentage(selectedAction,valuePercentage,newData?.current_bid) : Number(setAsValue)
            newData.current_bid = newData.targeting_expression.target[0].bid
            newData.current_bid_copy = newData.targeting_expression.target[0].bid
            newData.isTargetChecked = false
          }
          return newData
        })    
        if(createRequestBodyForUpdateBidOnserver.length) {
          setCheckedAdGroupData([])
          setTargetingDataForTable([...tempTargetStat])
          updateTargetingDataOnServer(createRequestBodyForUpdateBidOnserver,"Bid Updated Successfully","Some Unknown Error Occurred");
        }
        
       if(!createRequestBodyForUpdateBidOnserver.length) {
        toast.warning('All the selected data contains either SB targetings or Current Bid is not present')
       }
      
      }
    
  };

  //this will handle search in column heads
  useEffect(() => {
    let encodedDataForColumnSearchFilterig;
    if (tableColumnFindIconSearchInputText !== "") {
      switch (findClickedColumnName) {
        case "PORTFOLIO":
          encodedDataForColumnSearchFilterig = encodeData([
            {
              field_name: "name",
              operator: "CONTAINS",
              value: tableColumnFindIconSearchInputText,
            },
          ]);
          getDataForSearchListeinng(
            findClickedColumnName,
            encodedDataForColumnSearchFilterig
          );
          break;
        case "AD GROUP":
          encodedDataForColumnSearchFilterig = encodeData([
            {
              field_name: "name",
              operator: "CONTAINS",
              value: tableColumnFindIconSearchInputText,
            },
          ]);
          getDataForSearchListeinng(
            findClickedColumnName,
            encodedDataForColumnSearchFilterig
          );
          break;
        case "CAMPAIGNS":
          encodedDataForColumnSearchFilterig = encodeData([
            {
              field_name: "name",
              operator: "CONTAINS",
              value: tableColumnFindIconSearchInputText,
            },
          ]);
          getDataForSearchListeinng(
            findClickedColumnName,
            encodedDataForColumnSearchFilterig
          );
          break;

        default:
          break;
      }
    } else {
      getDataForSearchListeinng(findClickedColumnName);
    }
  }, [tableColumnFindIconSearchInputText]);

  useEffect(() => {
    if (paginationAction.actionnType === "forword") {
      if (paginationAction.currentPageCount > 1) {
        setoffesetDataAsPerCurretPage(
          (offesetDataAsPerCurretPage) => offesetDataAsPerCurretPage + limit
        );
      }
    } else if (paginationAction.actionnType === "backword") {
      if (paginationAction.currentPageCount > 1) {
        setoffesetDataAsPerCurretPage(
          (offesetDataAsPerCurretPage) => offesetDataAsPerCurretPage - limit
        );
      } else {
        setoffesetDataAsPerCurretPage(0);
      }
    } else if (paginationAction.actionnType === "onFirstPage") {
      if (paginationAction.currentPageCount == 1) {
        setoffesetDataAsPerCurretPage(0);
      }
    }
  }, [paginationAction]);

  //this will close datebox when click outside
  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);

  useEffect(() => {
    setPaginationAction({
      ...paginationAction,
      actionnType: null,
      currentPageCount: 1,
    });
    setoffesetDataAsPerCurretPage(0);
    // let targetingFilterBy = encodeData(targetingFiltering);
    if (targetingFiltering.length) {
      let dataHeadCopy = JSON.stringify(dataHeads);
      dataHeadCopy = JSON.parse(dataHeadCopy);

      targetingFiltering.map((tf) => {
        dataHeadCopy.map((col) => {
          if (tf.field_name === "adgroup_id" && col.id === "mt_adgroup_name") {
            // if (col.id === "mt_adgroup_name") {
            col.isFindTaskActive = true;
            // }
          } else if (tf.field_name === "campaign_id") {
            if (col.id === "mt_campaign_name") {
              col.isFindTaskActive = true;
            }
          } else if (tf.field_name === "portfolio_id") {
            if (col.id === "mt_portfolio") {
              col.isFindTaskActive = true;
            }
          }
        });
      });

      setdataHeads(dataHeadCopy);
    }
    serTargetingApiFilteringData(targetingFiltering);
  }, [targetingFiltering]);

  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  const onInputRangeChangeHandler = (data) => {
    setMinMaxFilterActivated(false);
    let tempCampaigns = JSON.stringify(data);

    tempCampaigns = JSON.parse(tempCampaigns);

    let filteredCampaigns = [];
    let filterWithDashAsZero = [];

    for (let i = 0; i < appliedFilterData.length; i++) {
      let temp = JSON.parse(JSON.stringify(appliedFilterData[i]));
      temp.minRange = isNaN(temp.minRange) ? 0 : Number(temp.minRange);
      temp.maxRange =
        temp.maxRange == "" || isNaN(temp.maxRange)
          ? Infinity
          : Number(temp.maxRange);

      if (
        (temp.minRange != 0 && temp.maxRange != Infinity) ||
        (temp.minRange == 0 && temp.maxRange != Infinity)
      ) {
        filterWithDashAsZero.push(temp);
      } else if (
        (temp.minRange != 0 && temp.maxRange == Infinity) ||
        (temp.minRange != 0 && temp.maxRange == "")
      ) {
        filterWithDashAsZero.push(temp);
      }
    }

    for (let r = 0; r < tempCampaigns.length; r++) {
      let shouldPass = true;

      for (let i = 0; i < filterWithDashAsZero.length; i++) {
        setMinMaxFilterActivated(true);
        let currentFilter = filterWithDashAsZero[i];

        if (
          (currentFilter.minRange != 0 && currentFilter.maxRange != Infinity) ||
          (currentFilter.minRange == 0 && currentFilter.maxRange != Infinity) ||
          (currentFilter.minRange != 0 && currentFilter.maxRange == Infinity)
        ) {
          let currentCampaign = tempCampaigns[r];
          if (
            currentCampaign[currentFilter.id] != undefined &&
            currentCampaign[currentFilter.id] !== null
          ) {
            if (
              currentCampaign[currentFilter.id] < currentFilter.minRange ||
              (currentFilter.maxRange !== Infinity &&
                currentCampaign[currentFilter.id] > currentFilter.maxRange)
            ) {
              shouldPass = false;
            }
          } else {
            shouldPass = false;
          }
        } else {
          shouldPass = false;
        }
      }
      if (shouldPass) {
        filteredCampaigns.push(tempCampaigns[r]);
      }
      setTargetingDataForTable(filteredCampaigns);
    }
  };

  // Get custom Data Heads

  // useEffect(() => {
  //   const isChildRowOpen = targetingDataForTable?.some((data) => !data?.isPlusIconDisabled)
  //   setIsCustomHeadsRequired(isChildRowOpen)
  // },[targetingDataForTable])

  // function getCustomDataHeads (arrayOfMainData,arrayOfSecondData) {
  //   const result = arrayOfMainData.filter((data) => {
  //     const mainData = data?.id
  //     return !arrayOfSecondData.includes(mainData)
  //   })
  //   return result
  // }

  //this will close column search box

  const closeColumnnSearch = (e) => {
    if (
      searchInColumnRef.current &&
      findClickedColumnName &&
      !searchInColumnRef.current.contains(e.target)
    ) {
      setFindClickedColumnName(null);
      setCheckedColumnSearchData([]);
      // setCheckedColumnSearchDataa([]);

      setListForSearchAnndFilterTargetingBy([]);
      if (findClickedColumnName === "CAMPAIGNS") {
        setCheckedCampaignIdForFilter([]);
        setCheckedPlatforIdForFilter([]);
      }
    }
  };

  //this funnction will consolidate main targeting with its child targeting by matching targeting key
  const consolidateDataWithDefaultStateKey = (masterTargetingData) => {
    for (let i = 0; i < masterTargetingData.length; i++) {
      masterTargetingData[i].isPlusIconDisabled = true;
      masterTargetingData[i].isTargetChecked = false;
      masterTargetingData[i].isEditCurrenntBidValueDisabled = true
      masterTargetingData[i].current_bid_copy = masterTargetingData[i]?.current_bid
      masterTargetingData[i].isEnabled = masterTargetingData[i]?.status === "PAUSED" ? false : true
    }
    setTargetingDataForTable(masterTargetingData);
    setIsLoaderVisible(false)
    setTotalEnabledCampaign([]);
    setTotalPausedCampaign([]);

    for (let i = 0; i < masterTargetingData.length; i++) {
      if (masterTargetingData[i].status === "ENABLED") {
        setTotalEnabledCampaign((totalEnabledCampaign) => [
          ...totalEnabledCampaign,
          masterTargetingData[i].status,
        ]);
      }
      if (masterTargetingData[i].status === "PAUSED") {
        setTotalPausedCampaign((totalPausedCampaign) => [
          ...totalPausedCampaign,
          masterTargetingData[i].status,
        ]);
      }
    }
  };

  //when clicked on plus icon this function will consolidate child data with its parent & add temporary connditional default keys
  const consolidateChilddataWithParent = (childData) => {
    let tempTargetStat = JSON.stringify(targetingDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);
    let createRequestBodyForUpdateBidOnserver = [];
    for (let i = 0; i < targetingDataForTable.length; i++) {
      if (
        tempTargetStat[i].targeting ===
        parentDetailToGetChildData.checkedTargeting &&
        tempTargetStat[i].adyogi_targeting_type ===
        parentDetailToGetChildData.matchtyp
      ) {
        setMainTargetingSelceted(false);
        tempTargetStat[i].isTargetChecked = false;
        tempTargetStat[i].childRows = [];
        if (childData.length) {
          for (let c = 0; c < childData.length; c++) {
            childData[c].isEditCurrenntBidValueDisabled = true;

            if (parentDetailToGetChildData.selectedTargetigPlusIconState) {
              if (parentDetailToGetChildData.actionType === "checkboxClicked") {
                if(isCurrentBidPresent(childData[c]) && isCampaignNotSB(childData[c])) {
                  childData[c].isTargetChecked = true;
                  setsTargetingBoxChecked(true);
                  tempTargetStat[i].isTargetChecked = true;
                  setCheckedAdGroupData((checkedAdGroupData) => [
                    ...checkedAdGroupData,
                    {
                      checkedStatus: true,
                      selectedChildTargetingId: childData[c].target_id,
                    },
                  ]);
  
                }
              }
              if (parentDetailToGetChildData.actionType === "targetingToggle") {
                if (
                  parentDetailToGetChildData.currentTargetinngState ===
                  "ENABLED"
                ) {
                  childData[c].status = "PAUSED";
                  tempTargetStat[i].status = "PAUSED";
                  if (
                    childData &&
                    childData.length &&
                    childData[c].targeting_expression
                  ) {
                    childData[c].targeting_expression.target[0].status =
                      "PAUSED";
                    createRequestBodyForUpdateBidOnserver.push(
                      childData[c].targeting_expression
                    );
                  }
                } else {
                  childData[c].status = "ENABLED";
                  tempTargetStat[i].status = "ENABLED";
                  if (
                    childData &&
                    childData.length &&
                    childData[c].targeting_expression
                  ) {
                    childData[c].targeting_expression.target[0].status =
                      "ENABLED";
                    createRequestBodyForUpdateBidOnserver.push(
                      childData[c].targeting_expression
                    );
                  }
                }
              }
            }
            tempTargetStat[i].childRows.push(childData[c]);
          }
        }
        tempTargetStat[i].isPlusIconDisabled = false;
      }
    }
    setTargetingDataForTable(tempTargetStat);
    if (createRequestBodyForUpdateBidOnserver.length) {
      updateTargetingDataOnServer(createRequestBodyForUpdateBidOnserver);
    }
  };


  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const closeCalender = (e) => {
    if (
      masterTargetinngCalenderIconRef.current &&
      showCustomDateBox &&
      !masterTargetinngCalenderIconRef.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };

  const onFilterIconhandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };

  const editBidHandler = () => {
    dispatch(campaignListActions.showBulkEditBidModal());
    const parsedData = JSON.parse(JSON.stringify(checkedAdGroupData))
    dispatch(
      campaignListActions.checkedTargetDataForBulEdit(parsedData)
    );
  };

  const onSortHandler = (id, sortingStateCurrent) => {
    // setIsloaderVisibiltyNeededForMainTargeting(true);
    let sortingTyoe = sortingStateCurrent === "DESC" ? "ASC" : "DESC";

    setSortingDetails({
      sortingDetails,
      sortByOrder: sortingTyoe,
      sortingColumnName: id,
    });

    setPaginationAction({
      ...paginationAction,
      actionnType: null,
      currentPageCount: 1,
    });
    setoffesetDataAsPerCurretPage(0);
  };

  const onFindIconHanndler = (columnName) => {
    setFindClickedColumnName(columnName);
    setShowSelectColumnSearchToggleBtnState(false);
    setAllCheckStateInColumnSearchBox(false);
    if (
      chckedCampaignDataForFilteringPortfolioAdgroup.selectedCampaignId
        .length ||
      chckedCampaignDataForFilteringPortfolioAdgroup.selectedPlatformId.length
    ) {
      let encodeDataForListOnBasisOfCampign;
      if (columnName === "PORTFOLIO") {
        encodeDataForListOnBasisOfCampign = encodeData([
          {
            field_name: "amz_portfolio_id",
            operator: "IN",
            values:
              chckedCampaignDataForFilteringPortfolioAdgroup.selectedPlatformId,
          },
        ]);
      } else if (columnName === "AD GROUP") {
        encodeDataForListOnBasisOfCampign = encodeData([
          {
            field_name: "campaign_id",
            operator: "IN",
            values:
              chckedCampaignDataForFilteringPortfolioAdgroup.selectedCampaignId,
          },
        ]);
      }
      getDataForSearchListeinng(columnName, encodeDataForListOnBasisOfCampign);
    } else {
      getDataForSearchListeinng(columnName);
    }
  };

  const checkedFiltering = (dataResponse, clickedColName) => {
    let currentList =
      dataResponse &&
      dataResponse.length &&
      dataResponse.map((a) => {
        return { ...a };
      });
    targetingApiFilteringData &&
      targetingApiFilteringData.map((filterinng) => {
        if (
          (clickedColName === "PORTFOLIO" &&
            filterinng.field_name === "portfolio_id") ||
          (clickedColName === "AD GROUP" &&
            filterinng.field_name === "adgroup_id") ||
          (clickedColName === "CAMPAIGNS" &&
            filterinng.field_name === "campaign_id")
        ) {
          filterinng?.values &&
            filterinng?.values?.length &&
            filterinng.values.map((id) => {
              currentList.map((listData) => {
                if (listData.platform_id === id) {
                  listData.isDataChecked = true;
                  setCheckedColumnSearchData((checkedColumnSearchData) => [
                    ...checkedColumnSearchData,
                    listData,
                  ]);
                  // checkedArray.push(listData);
                }
              });
            });
        }
      });
    setListForSearchAnndFilterTargetingBy(currentList);
    shiftCHeckedDataOnTop(currentList);
  };

  const shiftCHeckedDataOnTop = (listdata) => {
    let shiftCheckdDataonTop = [];
    let uncheckedArray = [];
    let checkedArray = [];

    listdata &&
      listdata.length &&
      listdata.map((el) => {
        if (el?.isDataChecked) {
          checkedArray.push(el);
        } else {
          uncheckedArray.push(el);
        }
      });
    shiftCheckdDataonTop = checkedArray.concat(uncheckedArray);
    setListForSearchAnndFilterTargetingBy(shiftCheckdDataonTop);
  };

  const dashIconInBulKOperationHandler = () => {
    uncheckedAllselectedTarget();
  };

  const uncheckedAllselectedTarget = () => {
    let tempTargetStat = JSON.stringify(targetingDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);

    for (let i = 0; i < tempTargetStat.length; i++) {
      tempTargetStat[i].isTargetChecked = false;
      if (tempTargetStat[i].childRows && tempTargetStat[i].childRows.length) {
        for (let j = 0; j < tempTargetStat[i].childRows.length; j++) {
          tempTargetStat[i].childRows[j].isTargetChecked = false;
        }
      }
    }
    setCheckedAdGroupData([]);
    setsTargetingBoxChecked(false);
    setMainTargetingSelceted(false);
    setTargetingDataForTable(tempTargetStat);
  };

  const parentRowCheckboxHandler = (
    e,
    checkedTargeting,
    matchtyp,
    selectedTargetigPlusIconState
  ) => {
    // if (!selectedTargetigPlusIconState) {
    let tempTargetingData = JSON.stringify(targetingDataForTable);
    tempTargetingData = JSON.parse(tempTargetingData);

    for (let i = 0; i < tempTargetingData.length; i++) {
      if (
        tempTargetingData[i].targeting === checkedTargeting &&
        tempTargetingData[i].adyogi_targeting_type === matchtyp
      ) {
        if (
          (!selectedTargetigPlusIconState && tempTargetingData[i].childRows) ||
          (selectedTargetigPlusIconState &&
            tempTargetingData[i].childRows &&
            tempTargetingData[i].childRows.length)
        ) {
          //this will check/uncheck all child rows & show & hide count for edit
          if (tempTargetingData[i].childRows) {
            for (let k = 0; k < tempTargetingData[i].childRows.length; k++) {
              tempTargetingData[i].isPlusIconDisabled = false;
              if (tempTargetingData[i].isTargetChecked) {
                tempTargetingData[i].childRows[k].isTargetChecked = false;
                setMainTargetingSelceted(false);

                for (
                  let index = 0;
                  index < checkedAdGroupData.length;
                  index++
                ) {
                  if (
                    checkedAdGroupData[index].selectedChildTargetingId &&
                    checkedAdGroupData[index].selectedChildTargetingId ===
                    tempTargetingData[i].childRows[k].target_id
                  ) {
                    checkedAdGroupData.splice(index, 1);
                  }
                }
                setCheckedAdGroupData((checkedAdGroupData) => [
                  ...checkedAdGroupData,
                ]);
                // setsTargetingBoxChecked(false);
              } else {
                setsTargetingBoxChecked(true);
                if (!tempTargetingData[i].childRows[k].isTargetChecked && isCurrentBidPresent(tempTargetingData[i].childRows[k]) && isCampaignNotSB(tempTargetingData[i].childRows[k])) {
                  setCheckedAdGroupData((checkedAdGroupData) => [
                    ...checkedAdGroupData,
                    {
                      selectedChildTargetingId:
                        tempTargetingData[i].childRows[k].target_id,
                      checkedStatus: true,
                    },
                  ]);
                  tempTargetingData[i].childRows[k].isTargetChecked = true;
                }
              }
            }
          }

          //This will checked/unchecked parent row
          if (tempTargetingData[i].isTargetChecked) {
            tempTargetingData[i].isTargetChecked = false;
          } else {
            tempTargetingData[i].isTargetChecked = true;
          }
        } else {
          setCurrentClickedTargeting({
            ...currentClickedTargeting,
            clikedTargeting: checkedTargeting,
            clickedTargetingMatch: matchtyp,
          });
          //   passFilterinngToApi(
          //     checkedTargeting,
          //     matchtyp,
          //     selectedTargetigPlusIconState,
          //     "checkboxClicked"
          //   );
          setparentDetailToGetChildData({
            checkedTargeting: checkedTargeting,
            matchtyp: matchtyp,
            selectedTargetigPlusIconState: selectedTargetigPlusIconState,
            actionType: "checkboxClicked",
          });
          return;
        }
      }

      // }
    }
    setTargetingDataForTable(tempTargetingData);
  };

  const childRowCheckboxHandler = (
    e,
    checkedTargetingDiffecrciateByTargeting,
    matchtyp,
    targeting
  ) => {
    let tempTargetingData = JSON.stringify(targetingDataForTable);
    tempTargetingData = JSON.parse(tempTargetingData);

    for (let i = 0; i < tempTargetingData.length; i++) {
      if (
        tempTargetingData[i].childRows &&
        tempTargetingData[i].childRows.length
      ) {
        for (let k = 0; k < tempTargetingData[i].childRows.length; k++) {
          if (
            tempTargetingData[i].childRows[k].target_id ===
            checkedTargetingDiffecrciateByTargeting &&
            tempTargetingData[i].adyogi_targeting_type === matchtyp
          ) {
            // for (let k = 0; k < tempTargetingData[i].childRows.length; k++) {
            if (tempTargetingData[i].childRows[k].isTargetChecked) {
              tempTargetingData[i].childRows[k].isTargetChecked = false;
              tempTargetingData[i].isTargetChecked = false;
              setMainTargetingSelceted(false);

              for (let index = 0; index < checkedAdGroupData.length; index++) {
                if (
                  checkedAdGroupData[index].selectedChildTargetingId &&
                  checkedAdGroupData[index].selectedChildTargetingId ===
                  checkedTargetingDiffecrciateByTargeting
                ) {
                  checkedAdGroupData.splice(index, 1);
                }
              }
              setCheckedAdGroupData((checkedAdGroupData) => [
                ...checkedAdGroupData,
              ]);
              setsTargetingBoxChecked(false);
            } else {
              tempTargetingData[i].childRows[k].isTargetChecked = true;
              setsTargetingBoxChecked(false);

              setCheckedAdGroupData((checkedAdGroupData) => [
                ...checkedAdGroupData,
                {
                  selectedChildTargetingId:
                    tempTargetingData[i].childRows[k].target_id,
                  checkedStatus: true,
                },
              ]);

              //   }
            }
          }
        }
      }
    }

    setTargetingDataForTable(tempTargetingData);
    checkedParent(
      tempTargetingData,
      checkedTargetingDiffecrciateByTargeting,
      matchtyp,
      targeting
    );
  };

  const checkedParent = (
    tempTargetingDataa,
    checkedTargetingDiffecrciateByTargeting,
    matchtyp,
    targeting
  ) => {
    let checkedChild = [];
    let tempTargetingData = JSON.stringify(tempTargetingDataa);
    tempTargetingData = JSON.parse(tempTargetingData);

    for (let i = 0; i < tempTargetingData.length; i++) {
      if (
        targeting === tempTargetingData[i].targeting &&
        matchtyp === tempTargetingData[i].adyogi_targeting_type
      ) {
        if (
          tempTargetingData[i].childRows &&
          tempTargetingData[i].childRows.length
        ) {
          for (let k = 0; k < tempTargetingData[i].childRows.length; k++) {
            if (tempTargetingData[i].childRows[k].isTargetChecked) {
              checkedChild.push(
                tempTargetingData[i].childRows[k].isTargetChecked
              );
            }
          }

          if (tempTargetingData[i].childRows.length == checkedChild.length) {
            tempTargetingData[i].isTargetChecked = true;
          }
        }
      }
    }
    setTargetingDataForTable(tempTargetingData);
  };

  //this fn will checked all open child data in table
  const onAllOpenTargetingCheckHandler = (e) => {
    setCheckedAdGroupData([]);
    let tempTargetingData = JSON.stringify(targetingDataForTable);
    tempTargetingData = JSON.parse(tempTargetingData);
    for (let i = 0; i < tempTargetingData.length; i++) {
      // if Parent View
      if(selectedViewType === "Parent") {
        if (!tempTargetingData[i].isPlusIconDisabled) {
          if (
            tempTargetingData[i].childRows &&
            tempTargetingData[i].childRows.length
          ) {
            for (let c = 0; c < tempTargetingData[i].childRows.length; c++) {
              if (e.target.checked) {
                setMainTargetingSelceted(true);
                tempTargetingData[i].isTargetChecked = true;
                tempTargetingData[i].childRows[c].isTargetChecked = true;
  
                setCheckedAdGroupData((checkedAdGroupData) => [
                  ...checkedAdGroupData,
                  {
                    selectedChildTargetingId:
                      tempTargetingData[i].childRows[c].target_id,
                    checkedStatus: true,
                  },
                ]);
              } else {
                setMainTargetingSelceted(false);
                tempTargetingData[i].isTargetChecked = false;
                tempTargetingData[i].childRows[c].isTargetChecked = false;
                for (let index = 0; index < checkedAdGroupData.length; index++) {
                  if (
                    checkedAdGroupData[index].selectedChildTargetingId &&
                    checkedAdGroupData[index].selectedChildTargetingId ===
                    tempTargetingData[i].childRows[c].target_id
                  ) {
                    checkedAdGroupData.splice(index, 1);
                  }
                }
              }
            }
          }
        }
      } else {
        setMainTargetingSelceted(!mainTargetingSelected)
        tempTargetingData = tempTargetingData?.map((data) => {
          if(isCurrentBidPresent(data) && isCampaignNotSB(data)) {
            data.isTargetChecked = !mainTargetingSelected
          }
          return data
        })
      }
      
    }
    setTargetingDataForTable(tempTargetingData);
  };

  //this function will ensure row hide & show & editing handlinng in table
  const plusIconHandler = (
    targetinng,
    matchtyp,
    plusIconhanndlerCurrentState,
    type
  ) => {
    let campaignStatusValue;
    let tempCampaigns = JSON.stringify(targetingDataForTable);
    tempCampaigns = JSON.parse(tempCampaigns);
    // setMainTargetingSelceted(false);
    if (!plusIconhanndlerCurrentState) {
      tempCampaigns.map((campaignInstance) => {
        if (
          campaignInstance.targeting == targetinng &&
          campaignInstance.adyogi_targeting_type === matchtyp
        ) {
          if (type === "PlusIconHandler") {
            if (!campaignInstance.isPlusIconDisabled) {
              campaignInstance.isPlusIconDisabled = true;
              //if plus icon closed by user then we will uncheck that data

              campaignInstance.isTargetChecked = false;
              campaignInstance.childRows.map((cr) => {
                cr.isTargetChecked = false;
                for (
                  let index = 0;
                  index < checkedAdGroupData.length;
                  index++
                ) {
                  if (
                    checkedAdGroupData[index].selectedChildTargetingId &&
                    checkedAdGroupData[index].selectedChildTargetingId ===
                    cr.target_id
                  ) {
                    checkedAdGroupData.splice(index, 1);
                  }
                }
                setCheckedAdGroupData((checkedAdGroupData) => [
                  ...checkedAdGroupData,
                ]);
              });
            }
          }
        }
      });

      setTargetingDataForTable(tempCampaigns);
      if (checkedAdGroupData && !checkedAdGroupData.length) {
        setMainTargetingSelceted(false);
      }
    } else {
      tempCampaigns.map((campaignInstance) => {
        if (
          campaignInstance.targeting == targetinng &&
          campaignInstance.adyogi_targeting_type === matchtyp
        ) {
          if (campaignInstance.childRows && campaignInstance.childRows.length) {
            campaignInstance.isPlusIconDisabled = false;
            campaignInstance.childRows.map((cr) => {
              cr.isTargetChecked = false;
            });
            setMainTargetingSelceted(false);
          } else if (campaignInstance.isPlusIconDisabled) {
            // setCurrentClickedTargeting(targetinng);

            setCurrentClickedTargeting({
              ...currentClickedTargeting,
              clikedTargeting: targetinng,
              clickedTargetingMatch: matchtyp,
            });

            setparentDetailToGetChildData({
              checkedTargeting: targetinng,
              matchtyp: matchtyp,
            });
          }
        }
      });
      setTargetingDataForTable(tempCampaigns);
    }

    // setsearchfilteredData(tempCampaigns)
  };

  const editBidofChildClickHandler = (targetingValue, type, updatedValue) => {
    let createRequestBodyForUpdateBidOnserver = [];
    let mainTargetings = JSON.stringify(targetingDataForTable);
    mainTargetings = JSON.parse(mainTargetings);
    for (let i = 0; i < mainTargetings.length; i++) {
      if (mainTargetings[i].childRows && mainTargetings[i].childRows.length) {
        for (let j = 0; j < mainTargetings[i].childRows.length; j++) {
          if (type === "CurrentBidPencil") {
            if (mainTargetings[i].childRows[j].target_id === targetingValue) {
              if (
                !mainTargetings[i].childRows[j].isEditCurrenntBidValueDisabled
              ) {
                mainTargetings[i].childRows[
                  j
                ].isEditCurrenntBidValueDisabled = true;
                if (
                  mainTargetings[i]?.childRows &&
                  mainTargetings[i]?.childRows.length &&
                  mainTargetings[i].childRows[j]?.targeting_expression
                ) {
                  mainTargetings[i].childRows[
                    j
                  ].targeting_expression.target[0].bid = updatedValue;
                  createRequestBodyForUpdateBidOnserver.push(
                    mainTargetings[i]?.childRows[j]?.targeting_expression
                  );
                }
              } else if (
                mainTargetings[i].childRows[j].target_id == targetingValue
              ) {
                mainTargetings[i].childRows[
                  j
                ].isEditCurrenntBidValueDisabled = false;
              }
            }
          }
        }
      }
    }
    setTargetingDataForTable(mainTargetings);
    if (createRequestBodyForUpdateBidOnserver.length) {
      updateTargetingDataOnServer(createRequestBodyForUpdateBidOnserver,"Bid Updated Successfully","Some unknown Error Occurred");
    }
  };

  const bidCopyIconnHandler = (
    clickedTargetingId,
    targetingCurrentBid,
    TargetingSugBid,
    recievedIndex
  ) => {
    if (targetingCurrentBid != TargetingSugBid) {
      dispatch(campaignListActions.showActionAlertModal());

      setAlertModalCauseAction({
        alertCauseActionType: "AlignBidAsSuggested",
        clickedTargeting: clickedTargetingId,
        currentIndex:recievedIndex
      });

      // setActionAlertModalShow(true);
      setShowAlertMsgOf(
        "Are you sure you want to align current bids of the selected targeting with their respective suggested bids (excluding SB campaign type Or if current bid is not present)"
      );
    }
  };

  const actioAlertYesClickhandler = () => {
    if (alertModalCauseAction.alertCauseActionType === "AlignBidAsSuggested") {
      if(selectedViewType==="Parent") {
        onCopySuggestedBidActionYesHandler(
          alertModalCauseAction.clickedTargeting,
          "AlignBidAsSuggested"
        );
      } else {
        childCopySuggestedBidHandler(alertModalCauseAction?.currentIndex)
      }
      
    } else if (
      alertModalCauseAction.alertCauseActionType ===
      "AlignBidAsSuggestedOfSelectedTargeting"
    ) {
      onCopySuggestedBidActionYesHandler(
        "",
        "AlignBidAsSuggestedOfSelectedTargeting"
      );
    } else if (alertModalCauseAction.alertCauseActionType === "allEnabled") {
      enableAndPausedAllChekedTargetingWithChild("ENABLED");
    } else if (alertModalCauseAction.alertCauseActionType === "allPaused") {
      enableAndPausedAllChekedTargetingWithChild("PAUSED");
    } else if (
      alertModalCauseAction.alertCauseActionType === "parentRowToogle"
    ) {
      onParentRowToggleHandler(
        alertModalCauseAction.checkedTargeting,
        alertModalCauseAction.matchtyp,
        alertModalCauseAction.selectedTargetigPlusIconState,
        alertModalCauseAction.currentTargetinngState
      );
    } 
  };

  const enableAndPausedAllChekedTargetingWithChild = (actionType) => {
    let tempTargetingData = JSON.stringify(targetingDataForTable);
    let createRequestBodyForUpdateBidOnserver = [];
    tempTargetingData = JSON.parse(tempTargetingData);

    for (let i = 0; i < tempTargetingData.length; i++) {
      // For Parent View
      if(selectedViewType === "Parent") {
        if (
          tempTargetingData[i].childRows &&
          tempTargetingData[i].childRows.length
        ) {
          if (checkedAdGroupData.length) {
            for (let index = 0; index < checkedAdGroupData.length; index++) {
              for (let k = 0; k < tempTargetingData[i].childRows.length; k++) {
                if (
                  checkedAdGroupData[index].selectedChildTargetingId ===
                  tempTargetingData[i].childRows[k].target_id
                ) {
                  if (
                    tempTargetingData[i].isTargetChecked ||
                    tempTargetingData[i].childRows[k].isTargetChecked
                  ) {
                    tempTargetingData[i].childRows[k].status = actionType;
  
                    if (
                      tempTargetingData[i]?.childRows &&
                      tempTargetingData[i]?.childRows.length &&
                      tempTargetingData[i].childRows[k]?.targeting_expression
                    ) {
                      tempTargetingData[i].childRows[
                        k
                      ].targeting_expression.target[0].status = actionType;
                      createRequestBodyForUpdateBidOnserver.push(
                        tempTargetingData[i]?.childRows[k]?.targeting_expression
                      );
                    }
                  }
                  tempTargetingData[i].childRows[k].isTargetChecked = false;
                }
              }
            }
            tempTargetingData[i].isTargetChecked = false;
          }
        }
      } 
    }

    if(selectedViewType === "Child") {
      createRequestBodyForUpdateBidOnserver = checkedAdGroupData?.map((data) => {
        data.targeting_expression.target[0].status = actionType
        return data?.targeting_expression
      })
    }

    setTargetingDataForTable(tempTargetingData);

    if (createRequestBodyForUpdateBidOnserver.length) {
      updateTargetingDataOnServer(createRequestBodyForUpdateBidOnserver,"Updated Successfully","Some unknown Error Occurred");
    }
    setCheckedAdGroupData([]);
  };

  //this function will handle ALL CHECKED CHILD paused/Enabled functionality
  const onEnnablePauseddClickhanndler = (type) => {
    dispatch(campaignListActions.showActionAlertModal());
    if (type === "ENABLED") {
      setAlertModalCauseAction("allCheckedEnabled");

      setShowAlertMsgOf(
        "Are you sure you want to Enable the selected targeting"
      );
      setAlertModalCauseAction({
        alertCauseActionType: "allEnabled",
        clickedTargeting: "",
      });
    } else if (type === "PAUSED") {
      setAlertModalCauseAction("allCheckedEnabled");

      setShowAlertMsgOf(
        "Are you sure you want to Paused the selected targeting"
      );
      setAlertModalCauseAction({
        alertCauseActionType: "allPaused",
        clickedTargeting: "",
      });
    }
  };

  function isCurrentBidPresent (obj) {
    return obj?.hasOwnProperty('current_bid') && obj?.current_bid!==undefined && obj?.current_bid!==null && obj?.current_bid!==""
  }

  function isCampaignNotSB (obj) {
    return obj?.campaign_type!=="SB"
  }

  //this will handle bid alignment with suggested bid when user click o copy icon in sug bid
  const onCopySuggestedBidActionYesHandler = (
    clickedTargetingChildId,
    type
  ) => {
    let createRequestBodyForUpdateBidOnserver = [];
    let tempTargetingData = JSON.stringify(targetingDataForTable);
    tempTargetingData = JSON.parse(tempTargetingData);

    for (let i = 0; i < tempTargetingData.length; i++) {
      if (tempTargetingData[i].childRows) {
        for (let k = 0; k < tempTargetingData[i]?.childRows.length; k++) {
          if(isCurrentBidPresent(tempTargetingData[i].childRows[k]) && isCampaignNotSB(tempTargetingData[i].childRows[k])) {
            if (type === "AlignBidAsSuggested") {
              if (
                tempTargetingData[i].childRows[k].target_id ===
                clickedTargetingChildId 
              ) {
                tempTargetingData[i].childRows[k].current_bid =
                  tempTargetingData[i].childRows[k].suggested_bid;
  
                if (
                  tempTargetingData[i]?.childRows &&
                  tempTargetingData[i]?.childRows.length &&
                  tempTargetingData[i].childRows[k]?.targeting_expression
                ) {
                  tempTargetingData[i].childRows[
                    k
                  ].targeting_expression.target[0].bid =
                    tempTargetingData[i].childRows[k].suggested_bid;
                  createRequestBodyForUpdateBidOnserver.push(
                    tempTargetingData[i]?.childRows[k]?.targeting_expression
                  );
                }
              }
            }
            if (type === "AlignBidAsSuggestedOfSelectedTargeting" && selectedViewType === "Parent") {
              if (tempTargetingData[i].childRows[k].isTargetChecked === true) {
                tempTargetingData[i].childRows[k].current_bid =
                  tempTargetingData[i].childRows[k].suggested_bid;
  
                if (
                  tempTargetingData[i]?.childRows &&
                  tempTargetingData[i]?.childRows.length &&
                  tempTargetingData[i].childRows[k]?.targeting_expression
                ) {
                  tempTargetingData[i].childRows[
                    k
                  ].targeting_expression.target[0].bid =
                    tempTargetingData[i].childRows[k].suggested_bid;
                  createRequestBodyForUpdateBidOnserver.push(
                    tempTargetingData[i]?.childRows[k]?.targeting_expression
                  );
                  tempTargetingData[i].childRows[k].isTargetChecked = false;
                  tempTargetingData[i].isTargetChecked = false;
                }
                setCheckedAdGroupData([]);
              }
            }
          }    
        }
      }
    }


    // For Child view edit this with care
    if(type === "AlignBidAsSuggestedOfSelectedTargeting" && selectedViewType === "Child") {
      createRequestBodyForUpdateBidOnserver = checkedAdGroupData?.filter((mainData) => {
        return isCurrentBidPresent(mainData) && isCampaignNotSB(mainData)
      })?.map((data) => {
        data.targeting_expression.target[0].bid = data?.suggested_bid
        return data?.targeting_expression
      })
      const checkedAdgroupIds = checkedAdGroupData?.map((data) => data?.target_id)
      tempTargetingData = tempTargetingData?.map((data) => {
        if(checkedAdgroupIds?.includes(data?.target_id)) {
          data.targeting_expression.target[0].bid = data?.suggested_bid
          data.current_bid =  data?.targeting_expression?.target[0]?.bid
          data.current_bid_copy = data?.targeting_expression?.target[0]?.bid
          data.isTargetChecked = false
        }
        return data
      }) 
      setCheckedAdGroupData([]);
    }

    setTargetingDataForTable(tempTargetingData);
    if (createRequestBodyForUpdateBidOnserver.length) {
      updateTargetingDataOnServer(createRequestBodyForUpdateBidOnserver,"Updated Successfully","Some unknown error occurred");
    }

       
    if(!createRequestBodyForUpdateBidOnserver.length) {
      toast.warning('All the selected data contains either SB targetings or Current Bid is not present')
     }
  };

  //this will handle bid alignment with suggested bid
  const alignWithSuggestedBidhandler = () => {
    dispatch(campaignListActions.showActionAlertModal());
    setShowAlertMsgOf(
      "Are you sure you want to align current bids of the selected targeting with their respective suggested bids (excluding SB campaign type Or if current bid is not present)"
    );

    setAlertModalCauseAction({
      alertCauseActionType: "AlignBidAsSuggestedOfSelectedTargeting",
      clickedTargeting: "",
    });
  };

  const onnParentRowTogleShowAlertHandler = (
    checkedTargeting,
    matchtyp,
    selectedTargetigPlusIconState,
    currentTargetinngState,
    data
  ) => {
    dispatch(campaignListActions.showActionAlertModal());
    if (currentTargetinngState === "ENABLED") {
      setShowAlertMsgOf(
        "Are you sure you want to Paused the selected targeting"
      );
    } else {
      setShowAlertMsgOf(
        "Are you sure you want to Enable the selected targeting"
      );
    }
    setAlertModalCauseAction({
      alertCauseActionType: "parentRowToogle",
      clickedTargeting: "",
      checkedTargeting: checkedTargeting,
      matchtyp: matchtyp,
      selectedTargetigPlusIconState: selectedTargetigPlusIconState,
      currentTargetinngState: currentTargetinngState,
    });
  };

  const onParentRowToggleHandler = (
    checkedTargeting,
    matchtyp,
    selectedTargetigPlusIconState,
    currentTargetinngState
  ) => {
    let createRequestBodyForUpdateBidOnserver = [];
    let tempTargetingData = JSON.stringify(targetingDataForTable);
    tempTargetingData = JSON.parse(tempTargetingData);
    for (let i = 0; i < tempTargetingData.length; i++) {
      if (
        checkedTargeting === tempTargetingData[i].targeting &&
        tempTargetingData[i].adyogi_targeting_type === matchtyp
      ) {
        if (
          (!selectedTargetigPlusIconState &&
            tempTargetingData[i].childRows &&
            tempTargetingData[i].childRows.length) ||
          (selectedTargetigPlusIconState &&
            tempTargetingData[i].childRows &&
            tempTargetingData[i].childRows.length)
        ) {
          if (tempTargetingData[i].status === "PAUSED") {
            for (let c = 0; c < tempTargetingData[i].childRows.length; c++) {
              tempTargetingData[i].childRows[c].status = "ENABLED";
              if (
                tempTargetingData[i]?.childRows &&
                tempTargetingData[i]?.childRows.length &&
                tempTargetingData[i].childRows[c]?.targeting_expression
              ) {
                tempTargetingData[i].childRows[
                  c
                ].targeting_expression.target[0].status = "ENABLED";
                createRequestBodyForUpdateBidOnserver.push(
                  tempTargetingData[i]?.childRows[c]?.targeting_expression
                );
              }
            }
            tempTargetingData[i].status = "ENABLED";
          } else {
            for (let c = 0; c < tempTargetingData[i].childRows.length; c++) {
              tempTargetingData[i].childRows[c].status = "PAUSED";
              if (
                tempTargetingData[i]?.childRows &&
                tempTargetingData[i]?.childRows.length &&
                tempTargetingData[i].childRows[c]?.targeting_expression
              ) {
                tempTargetingData[i].childRows[
                  c
                ].targeting_expression.target[0].status = "PAUSED";
                createRequestBodyForUpdateBidOnserver.push(
                  tempTargetingData[i]?.childRows[c]?.targeting_expression
                );
              }
            }
            tempTargetingData[i].status = "PAUSED";
          }
          tempTargetingData[i].isPlusIconDisabled = false;
        } else {
          if (
            checkedTargeting === tempTargetingData[i].targeting &&
            tempTargetingData[i].adyogi_targeting_type === matchtyp
          ) {
            setCurrentClickedTargeting({
              ...currentClickedTargeting,
              clikedTargeting: checkedTargeting,
              clickedTargetingMatch: matchtyp,
            });
            // passFilterinngToApi(
            //   checkedTargeting,
            //   matchtyp,
            //   selectedTargetigPlusIconState,
            //   "targetingToggle",
            //   currentTargetinngState
            // );
            setparentDetailToGetChildData({
              checkedTargeting: checkedTargeting,
              matchtyp: matchtyp,
              selectedTargetigPlusIconState: selectedTargetigPlusIconState,
              actionType: "targetingToggle",
              currentTargetinngState: currentTargetinngState,
            });
            return;
          }
        }
        //   }
      }
    }
    setTargetingDataForTable(tempTargetingData);
    if (createRequestBodyForUpdateBidOnserver.length) {
      updateTargetingDataOnServer(createRequestBodyForUpdateBidOnserver,"Updated Successfully","Some unknown Error Occurred");
    }
  };

  //this will handle child row action toggle
  const onChildRowToggleHandler = (selctedTargetingId, parentTargetinng) => {
    let tempTargetingData = JSON.stringify(targetingDataForTable);
    let createRequestBodyForUpdateBidOnserver = [];
    tempTargetingData = JSON.parse(tempTargetingData);

    for (let i = 0; i < tempTargetingData.length; i++) {
      if (
        tempTargetingData[i].childRows &&
        tempTargetingData[i].childRows.length
      ) {
        for (let c = 0; c < tempTargetingData[i].childRows.length; c++) {
          if (
            selctedTargetingId === tempTargetingData[i].childRows[c].target_id
          ) {
            if (tempTargetingData[i].childRows[c].status === "ENABLED") {
              tempTargetingData[i].childRows[c].status = "PAUSED";
              if (
                tempTargetingData[i]?.childRows &&
                tempTargetingData[i]?.childRows.length &&
                tempTargetingData[i].childRows[c]?.targeting_expression
              ) {
                tempTargetingData[i].childRows[
                  c
                ].targeting_expression.target[0].status = "PAUSED";
                createRequestBodyForUpdateBidOnserver.push(
                  tempTargetingData[i]?.childRows[c]?.targeting_expression
                );
              }
            } else if (tempTargetingData[i].childRows[c].status === "PAUSED") {
              tempTargetingData[i].childRows[c].status = "ENABLED";
              tempTargetingData[i].status = "ENABLED";
              if (
                tempTargetingData[i]?.childRows &&
                tempTargetingData[i]?.childRows.length &&
                tempTargetingData[i].childRows[c]?.targeting_expression
              ) {
                tempTargetingData[i].childRows[
                  c
                ].targeting_expression.target[0].status = "ENABLED";
                createRequestBodyForUpdateBidOnserver.push(
                  tempTargetingData[i]?.childRows[c]?.targeting_expression
                );
              }
            }
          }
        }
      }
    }
    if (createRequestBodyForUpdateBidOnserver.length) {
      updateTargetingDataOnServer(createRequestBodyForUpdateBidOnserver,"Updated Successfully","Some unknown Error Occurred");
    }
    setTargetingDataForTable(tempTargetingData);
  };
  //this will handle main targeting search
  const onTargetingSearchhandler = (searchValue) => {
    if (searchValue == "") {
      removePreviosSameFilterObject(targetingFiltering, "targeting");
      setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);
    }
  };

  const cancelIconInSearchInput = () => {
    onTargetingSearchhandler("");
    // document.getElementById("search-input-mt").value = "";
  };
  //this fn will enncode data
  const encodeData = (data) => {
    data = JSON.stringify(data);
    let encodedData = encodeURIComponent(data);
    return encodedData;
  };

  //this will calculate ofset data for pagination
  const getPaginationOnForwordArrowClickHandler = (type) => {
    // setIsloaderVisibiltyNeededForMainTargeting(true);
    if (type === "forword") {
      setPaginationAction({
        ...paginationAction,
        actionnType: type,
        currentPageCount: paginationAction.currentPageCount + 1,
      });
    } else if (type === "backword") {
      if (paginationAction.currentPageCount != 1) {
        setPaginationAction({
          ...paginationAction,
          actionnType: type,
          currentPageCount: paginationAction.currentPageCount - 1,
        });
      } else {
        setPaginationAction({
          ...paginationAction,
          actionnType: type,
          currentPageCount: 1,
        });
      }
    } else if (type === "onFirstPage") {
      setPaginationAction({
        ...paginationAction,
        actionnType: type,
        currentPageCount: 1,
      });
    }
  };

  //this will listen onchage of edit current bid of child and update its value in field
  const setInputValue = (currentTargetId, updatedValue, type, dataValue) => {
    let targetings = targetingDataForTable.map((a) => {
      return { ...a };
    });
    targetings.map((targetingInnstance) => {
      if (targetingInnstance.childRows && targetingInnstance.childRows.length) {
        targetingInnstance.childRows.map((childTargetig) => {
          switch (type) {
            case "curretBidInput":
              if (childTargetig.target_id === currentTargetId) {
                childTargetig.current_bid = updatedValue;
              }
              break;
          }
        });
      }
    });
    setTargetingDataForTable(targetings);
    // setCopyOfTargetingDataForTable(tempCampaigns);
  };

  //this function will handle search input onnchange for column head search
  const onSearchInputinColumnHandler = (e) => {
    setTableColumnFindIconSearchInputText(e.target.value);
  };
  //this function will selclect all list data in column search create array of checked item from column head search
  const onColumnSearchAllCheckHanndler = (e) => {
    let platformId = [];
    let campaignId = [];
    setCheckedColumnSearchData([]);
    let currentList = JSON.stringify(listForSearchAnndFilterTargetingBy);
    currentList = JSON.parse(currentList);
    currentList.map((el) => {
      if (!allCheckStateInColumnSearchBox) {
        setAllCheckStateInColumnSearchBox(true);
        el.isDataChecked = true;
        setCheckedColumnSearchData((checkedColumnSearchData) => [
          ...checkedColumnSearchData,
          el,
        ]);
        if (findClickedColumnName === "CAMPAIGNS") {
          platformId.push(el.platform_id);
          campaignId.push(el.id);

          setCheckedCampaignIdForFilter((checkedCampaignIdForFilter) => [
            ...checkedCampaignIdForFilter,
            el.id,
          ]);

          setCheckedPlatforIdForFilter((checkedPlatforIdForFilter) => [
            ...checkedPlatforIdForFilter,
            el.platform_id,
          ]);
        }
      } else {
        setAllCheckStateInColumnSearchBox(false);
        setShowSelectColumnSearchToggleBtnState(false);
        el.isDataChecked = false;
        for (let index = 0; index < checkedColumnSearchData.length; index++) {
          if (
            checkedColumnSearchData.length &&
            checkedColumnSearchData[index] == el.platform_id
          ) {
            checkedColumnSearchData.splice(index, 1);
          }
        }
        setCheckedColumnSearchData((checkedColumnSearchData) => [
          ...checkedColumnSearchData,
        ]);
      }
    });

    setListForSearchAnndFilterTargetingBy(currentList);
  };

  const onSearchBarEnterClicked = (e) => {
    if (e.key === "Enter") {
      if (e.target.value !== "") {
        // setIsloaderVisibiltyNeededForMainTargeting(false);
        removePreviosSameFilterObject(targetingFiltering, "targeting");
        setTargetingFiltering((targetingFiltering) => [
          ...targetingFiltering,
          {
            field_name: "targeting",
            operator: "EQUALS",
            value: e.target.value,
          },
        ]);
      } else {
        // setIsloaderVisibiltyNeededForMainTargeting(true);
        removePreviosSameFilterObject(targetingFiltering, "targeting");
        setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);
      }
    }
  };
  //this filter will deselcte all column serach filter

  //this will reset means unnncheck all data from currentlist in column search pop up
  const onCoumnnSearchRestHandler = () => {
    if (findClickedColumnName === "CAMPAIGNS") {
      setCheckedCampaignIdForFilter([]);
      setCheckedPlatforIdForFilter([]);
    }
    setAllCheckStateInColumnSearchBox(false);
    setCheckedColumnSearchData([]);
    columnnSearchCheckHandler("", "", "", "resetAllRemoveChecks");
  };

  const onPortfolioCampaignAdgroupOuterResetBtnHandler = () => {
    setoffesetDataAsPerCurretPage(0);

    setCheckedColumnSearchData([]);
    setChckedCampaignDataForFilteringPortfolioAdgroup({
      ...chckedCampaignDataForFilteringPortfolioAdgroup,
      selectedCampaignId: [],
      selectedPlatformId: [],
    });

    let dataHeadCopy = JSON.stringify(dataHeads);
    dataHeadCopy = JSON.parse(dataHeadCopy);
    for (let i = 0; i < dataHeadCopy.length; i++) {
      dataHeadCopy[i].isFindTaskActive = false;
    }
    setdataHeads(dataHeadCopy);
    setCancelSpecifiNumberFilter("RemoveAllRanges");
    setMinMaxFilterActivated(false);
    setTargetingFiltering([]);
    setFindClickedColumnName(null);

    let currentList = JSON.stringify(DropDownDataList);
    currentList = JSON.parse(currentList);
    currentList.map((dd) => {
      dd.isDataChecked = false;
    });
    setDropDownDataList(currentList);
  };

  //this function will create array of checked item from column head search
  const columnnSearchCheckHandler = (e, id, checkedStatus, type) => {
    let selectedItemsFromColumnSearch = [];
    let currentList = JSON.stringify(listForSearchAnndFilterTargetingBy);
    currentList = JSON.parse(currentList);
    currentList.map((el) => {
      if (type === "resetAllRemoveChecks") {
        el.isDataChecked = false;
      } else {
        if (el.id === id) {
          if (!checkedStatus) {
            setCheckedColumnSearchData((checkedColumnSearchData) => [
              ...checkedColumnSearchData,
              el,
            ]);
            el.isDataChecked = true;
            if (findClickedColumnName === "CAMPAIGNS") {
              setCheckedCampaignIdForFilter((checkedCampaignIdForFilter) => [
                ...checkedCampaignIdForFilter,
                el.id,
              ]);
              setCheckedPlatforIdForFilter((checkedPlatforIdForFilter) => [
                ...checkedPlatforIdForFilter,
                el.platform_id,
              ]);
            }
          } else {
            for (
              let index = 0;
              index < checkedColumnSearchData.length;
              index++
            ) {
              if (
                checkedColumnSearchData.length &&
                checkedColumnSearchData[index].platform_id == el.platform_id
              ) {
                checkedColumnSearchData.splice(index, 1);
              }
            }
            setCheckedColumnSearchData((checkedColumnSearchData) => [
              ...checkedColumnSearchData,
            ]);
            el.isDataChecked = false;

            if (findClickedColumnName === "CAMPAIGNS") {
              if (
                checkedPlatforIdForFilter &&
                checkedPlatforIdForFilter.length
              ) {
                for (
                  let index = 0;
                  index < checkedPlatforIdForFilter.length;
                  index++
                ) {
                  if (
                    checkedPlatforIdForFilter[index] &&
                    checkedPlatforIdForFilter[index] === el.platform_id
                  ) {
                    checkedPlatforIdForFilter.splice(index, 1);
                  }
                }
                setCheckedPlatforIdForFilter((checkedPlatforIdForFilter) => [
                  ...checkedPlatforIdForFilter,
                ]);
              }

              if (
                checkedCampaignIdForFilter &&
                checkedCampaignIdForFilter.length
              ) {
                for (
                  let index = 0;
                  index < checkedCampaignIdForFilter.length;
                  index++
                ) {
                  if (
                    checkedCampaignIdForFilter[index] &&
                    checkedCampaignIdForFilter[index] === el.platform_id
                  ) {
                    checkedCampaignIdForFilter.splice(index, 1);
                  }
                }
                setCheckedCampaignIdForFilter((checkedCampaignIdForFilter) => [
                  ...checkedCampaignIdForFilter,
                ]);
              }
            }
          }
        }
      }
    });

    setListForSearchAnndFilterTargetingBy(currentList);
  };

  const createArrayOfIdsFromObjectOfCheckedListDataColumnSearch = (
    arrayOfObj,
    filedToMakeArrayOf
  ) => {
    let idsArray = [];
    for (let i = 0; i < arrayOfObj.length; i++) {
      idsArray.push(arrayOfObj[i][filedToMakeArrayOf]);
    }
    return idsArray;
  };

  //this will hande=le colummn search btns action
  const onColumnSearchClickBtnHandler = (e, btnName) => {
    if (btnName === "cancel") {
      if (findClickedColumnName === "CAMPAIGNS") {
        setCheckedCampaignIdForFilter([]);
        setCheckedPlatforIdForFilter([]);
      }
      setFindClickedColumnName(null);
      setCheckedColumnSearchData([]);
      setListForSearchAnndFilterTargetingBy([]);
      setAllCheckStateInColumnSearchBox(false);
    } else if (btnName === "apply") {
      if (checkedColumnSearchData.length) {
        setAllCheckStateInColumnSearchBox(false);
        let encodedDataForadgroupcampaignPortfolioFiltering;
        let buttonName;
        switch (findClickedColumnName) {
          case "PORTFOLIO":
            removePreviosSameFilterObject(targetingFiltering, "portfolio_id");
            let checkedColSearchDataId =
              createArrayOfIdsFromObjectOfCheckedListDataColumnSearch(
                checkedColumnSearchData,
                "platform_id"
              );

            encodedDataForadgroupcampaignPortfolioFiltering = {
              field_name: "portfolio_id",
              operator: "IN",
              values: checkedColSearchDataId,
            };
            // removePreviosSameFilterObject(droDownFilterDataForApi, buttonName);
            let selectedPortfolioFilteringName =
              createArrayOfIdsFromObjectOfCheckedListDataColumnSearch(
                checkedColumnSearchData,
                "name"
              );
            break;
          case "CAMPAIGNS":
            removePreviosSameFilterObject(targetingFiltering, "campaign_id");
            let checkedColCampaignSearchDataId =
              createArrayOfIdsFromObjectOfCheckedListDataColumnSearch(
                checkedColumnSearchData,
                "platform_id"
              );

            encodedDataForadgroupcampaignPortfolioFiltering = {
              field_name: "campaign_id",
              operator: "IN",
              values: checkedColCampaignSearchDataId,
            };
            setChckedCampaignDataForFilteringPortfolioAdgroup({
              selectedCampaignId: checkedCampaignIdForFilter,
              selectedPlatformId: checkedPlatforIdForFilter,
            });
            let selectedCampaignFilteringName =
              createArrayOfIdsFromObjectOfCheckedListDataColumnSearch(
                checkedColumnSearchData,
                "name"
              );

            break;
          case "AD GROUP":
            removePreviosSameFilterObject(targetingFiltering, "adgroup_id");
            let checkedColAdgroupSearchDataId =
              createArrayOfIdsFromObjectOfCheckedListDataColumnSearch(
                checkedColumnSearchData,
                "platform_id"
              );

            encodedDataForadgroupcampaignPortfolioFiltering = {
              field_name: "adgroup_id",
              operator: "IN",
              values: checkedColAdgroupSearchDataId,
            };
            let selectedAdgroupFilteringName =
              createArrayOfIdsFromObjectOfCheckedListDataColumnSearch(
                checkedColumnSearchData,
                "name"
              );
            // removePreviosSameFilterObject(droDownFilterDataForApi, "adgroup_id");
            // setDroDownFilterDataForApi((droDownFilterDataForApi) => [
            //   ...droDownFilterDataForApi,
            //   {
            //     fieldNameOfSelectedBtn: "Adgroup",
            //     field_name: "adgroup_id",
            //     checkedValue: selectedAdgroupFilteringName,
            //   },
            // ]);

            break;

          default:
            break;
        }

        //   serTargetingApiFilteringData(
        //     encodedDataForadgroupcampaignPortfolioFiltering
        //   );

        setTargetingFiltering((targetingFiltering) => [
          ...targetingFiltering,
          encodedDataForadgroupcampaignPortfolioFiltering,
        ]);
        setFindClickedColumnName(null);
        setCheckedColumnSearchData([]);
        setListForSearchAnndFilterTargetingBy([]);
      } else {
        switch (findClickedColumnName) {
          case "PORTFOLIO":
            removePreviosSameFilterObject(targetingFiltering, "portfolio_id");
            setTargetingFiltering((targetingFiltering) => [
              ...targetingFiltering,
            ]);
            break;
          case "CAMPAIGNS":
            removePreviosSameFilterObject(targetingFiltering, "campaign_id");
            setTargetingFiltering((targetingFiltering) => [
              ...targetingFiltering,
            ]);
            setChckedCampaignDataForFilteringPortfolioAdgroup({
              ...chckedCampaignDataForFilteringPortfolioAdgroup,
              selectedCampaignId: [],
              selectedPlatformId: [],
            });
            setCheckedCampaignIdForFilter([]);
            setCheckedPlatforIdForFilter([]);
            break;
          case "AD GROUP":
            removePreviosSameFilterObject(targetingFiltering, "adgroup_id");
            setTargetingFiltering((targetingFiltering) => [
              ...targetingFiltering,
            ]);
          default:
            break;
        }
        // setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);

        let dataHeadCopy = JSON.stringify(dataHeads);
        dataHeadCopy = JSON.parse(dataHeadCopy);

        dataHeadCopy.map((col) => {
          if (findClickedColumnName == col.fieldName) {
            col.isFindTaskActive = false;
          }
        });

        setdataHeads(dataHeadCopy);

        setFindClickedColumnName(null);
        setCheckedColumnSearchData([]);
        setListForSearchAnndFilterTargetingBy([]);
        setAllCheckStateInColumnSearchBox(false);
      }
    }
    // setTargetingFiltering([])
  };

  //this will remove duplicate object from array
  const removePreviosSameFilterObject = (array, field_name) => {
    if (array.length) {
      for (let index = 0; index < array.length; index++) {
        if (array[index].field_name && array[index].field_name === field_name) {
          array.splice(index, 1);
        }
      }
    }
  };

  //this function will handle check & unncheck state of currently apllied filtering of dropdown btn
  const showCheckedAppliedFilteringWhenOPen = (
    btnNameHeader,
    btnFieldName,
    fieldNameAsSendInApiForAplication
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });

    let appliedTargetingFieldNameArray = [];

    targetingApiFilteringData.map((tf, i) => {
      appliedTargetingFieldNameArray.push(tf.field_name);
    });

    //this will empty check data list
    setCheckedDropDownFilterButtonData([]);

    //first this will uncheck all check data
    currentList.map((cl) => {
      cl.isDataChecked = false;
    });

    //this will loop through data list & applied filter list and if filter is having that value then we will check that value & add in CheckedDropDownFilterButtonData list
    targetingApiFilteringData.map((tf, i) => {
      if (targetingApiFilteringData.length) {
        currentList.map((list) => {
          if (
            list.buttonName === btnNameHeader &&
            list.fieldName === btnFieldName &&
            appliedTargetingFieldNameArray.includes(
              fieldNameAsSendInApiForAplication
            )
          ) {
            if (tf.field_name === "adyogi_targeting_type") {
              if (tf.values.includes(list.valueToSnedInApiCallForFilter)) {
                list.isDataChecked = true;

                setCheckedDropDownFilterButtonData(
                  (checkedDropDownFilterButtonData) => [
                    ...checkedDropDownFilterButtonData,
                    {
                      value: list.name,
                      fieldNameOfSelectedBtn: btnNameHeader,
                      fieldNameForAPIFilter: tf.field_name,
                      valueToSendForFilter: list?.valueToSnedInApiCallForFilter,
                    },
                  ]
                );
              }
            } else {
              if (tf.values.includes(list.name)) {
                list.isDataChecked = true;
                setCheckedDropDownFilterButtonData(
                  (checkedDropDownFilterButtonData) => [
                    ...checkedDropDownFilterButtonData,
                    {
                      value: list.name,
                      fieldNameOfSelectedBtn: btnNameHeader,
                      fieldNameForAPIFilter: tf.field_name,
                      valueToSendForFilter: list?.valueToSnedInApiCallForFilter,
                    },
                  ]
                );
              }
            }
          } else {
            list.isDataChecked = false;
          }
        });
      }
    });

    setDropDownDataList(currentList);
  };

  //this function will handle Multiselect Dropdown Select All option action
  const allSeletHandler = (
    e,
    displayedBtnName,
    BtnNameUniqueField,
    fieldNameForAPIFilter
  ) => {
    setCheckedDropDownFilterButtonData([]);
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === displayedBtnName &&
        list.fieldName === BtnNameUniqueField
      ) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: list.name,
                fieldNameOfSelectedBtn: displayedBtnName,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
                valueToSendForFilter: list?.valueToSnedInApiCallForFilter,
              },
            ]
          );
        }
      }
    });
    setDropDownDataList(currentList);
  };

  //this function will handle Multiselect Dropdown checked list array
  const onOptionCheckedHandlerInDropDownFilter = (
    e,
    checkedValue,
    fieldNameForAPIFilter,
    fieldNameOfSelectedBtn,
    fieldName
    // DropDownDataList
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (list.name === checkedValue && list.fieldName === fieldName) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: checkedValue,
                fieldNameOfSelectedBtn: fieldNameOfSelectedBtn,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
                valueToSendForFilter: list?.valueToSnedInApiCallForFilter,
              },
            ]
          );
        } else {
          list.isDataChecked = false;
          if (
            checkedDropDownFilterButtonData &&
            checkedDropDownFilterButtonData.length
          ) {
            for (
              let index = 0;
              index < checkedDropDownFilterButtonData.length;
              index++
            ) {
              if (
                checkedDropDownFilterButtonData[index] &&
                checkedDropDownFilterButtonData[index].value === checkedValue
              ) {
                checkedDropDownFilterButtonData.splice(index, 1);
              }
            }
            setCheckedDropDownFilterButtonData(
              (checkedDropDownFilterButtonData) => [
                ...checkedDropDownFilterButtonData,
              ]
            );
          }
        }
      }
    });

    setDropDownDataList(currentList);
  };

  //this Functionn will handle Multiselect DropDown Remove all dash icon actionn
  const onDashIconHandlerToremoveAllCheckedOption = (
    e,
    targettingValue,
    fieldName,
    uniqueFieldName,
    fieldNameAsSendInApi
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === targettingValue &&
        list.fieldName === uniqueFieldName
      ) {
        list.isDataChecked = false;
        checkedDropDownFilterButtonData.map((checkedData, index) => {
          if (
            checkedData.fieldNameOfSelectedBtn === targettingValue &&
            checkedData.fieldNameForAPIFilter === fieldNameAsSendInApi
          ) {
            if (index > 0) {
              checkedDropDownFilterButtonData.splice(index, 1);
            } else {
              checkedDropDownFilterButtonData.splice(index);
            }
          }
        });
      }
    });
    setDropDownDataList(currentList);

    setCheckedDropDownFilterButtonData((checkedDropDownFilterButtonData) => [
      ...checkedDropDownFilterButtonData,
    ]);

    // removePreviosSameFilterObject(targetingFiltering, fieldNameAsSendInApi);
  };

  //this function will handle Multiselect Dropdown apply btn action
  const onApplyBtnhandler = (fieldNameAsSendInApi) => {
    let selectedValue = [];
    let selectedDropDownName;
    let buttonName;
    setMultiSelectDropDownSelecteddDataCount({
      ...multiSelectDropDownSelectedDataCount,
      [fieldNameAsSendInApi]: 0,
    });
    // const [droDownFilterDataForApi, setDroDownFilterDataForApi] = useState([]);
    let fieldNameOfDropDownData = [
      "adyogi_targeting_type",
      "campaign_type",
      "campaign_objective",
    ];
    if (checkedDropDownFilterButtonData.length) {
      for (let i = 0; i < checkedDropDownFilterButtonData.length; i++) {
        fieldNameOfDropDownData.map((currentFieldName) => {
          if (
            checkedDropDownFilterButtonData[i].fieldNameForAPIFilter ===
            currentFieldName
          ) {
            removePreviosSameFilterObject(
              targetingFiltering,
              checkedDropDownFilterButtonData[i].fieldNameForAPIFilter
            );
            selectedDropDownName =
              checkedDropDownFilterButtonData[i].fieldNameForAPIFilter;
            buttonName =
              checkedDropDownFilterButtonData[i].fieldNameOfSelectedBtn;

            currentFieldName === "adyogi_targeting_type"
              ? selectedValue.push(
                checkedDropDownFilterButtonData[i].valueToSendForFilter
              )
              : selectedValue.push(checkedDropDownFilterButtonData[i].value);
          }

        });
      }
      fieldNameOfDropDownData.map((currentFieldName) => {
        if (selectedDropDownName === currentFieldName) {
          setTargetingFiltering((targetingFiltering) => [
            ...targetingFiltering,
            {
              field_name: currentFieldName,
              operator: "IN",
              values: selectedValue,
            },
          ]);
        }
      });
    } else {
      removePreviosSameFilterObject(targetingFiltering, fieldNameAsSendInApi);

      setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);
    }
    setCheckedDropDownFilterButtonData([]);
  };

  const onEnablePausedButtonhandler = (
    e,
    type,
    statusValueForGetFiltereddataByStatus
  ) => {
    removePreviosSameFilterObject(targetingFiltering, type);
    setselectedBtn(e.target.id);
    if (type === "target_status") {
      if (statusValueForGetFiltereddataByStatus === "ALL") {
        removePreviosSameFilterObject(targetingFiltering, type);
        setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);
        // getMasterTargetingData()
      } else {
        setTargetingFiltering((targetingFiltering) => [
          ...targetingFiltering,
          {
            field_name: "target_status",
            operator: "EQUALS",
            value: statusValueForGetFiltereddataByStatus,
          },
        ]);
      }
    }
  };

  //this function will trigger & give data needed to redirection onclick of campaignn name
  const onClickCampaignName = (campName) => {
    // navigate(
    //   `/edit-adset-targeting?campaignId=${clickedAdsetDetails.adset.campaign_id}&adsetId=${clickedAdsetDetails.adset.id}&numericCampaignId=${clickedAdsetDetails.campaign_id}&numericAdsetId=${clickedAdsetDetails.adset_id}&name=${clickedAdsetDetails?.adset?.name}&adsetPlatformId=${clickedAdsetDetails?.adset?.platform_id}`
    // );
    // navigate(`/campaign-list?campName=${campName}`);
    if (campName) {
      window.open(
        `/campaign-list?campName=${campName}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  const onClickAdgroupName = (childData, parentData) => {
    getCampaignIdWhenClickedOnAdgroupToRedirect(childData, parentData);
  };

  const onCancelClickRemoveFilteringHandlerr = (fieldNameForAPIFilter) => {
    setCancelSpecifiNumberFilter(fieldNameForAPIFilter);
    let duplicateKey = [];

    //this will check for duplicate key in an filter array (key likr sspends : Gratet Then ,spend : lessTHEN) and push it to duplicateKey array
    targetingApiFilteringData.map((tf) => {
      if (tf.field_name === fieldNameForAPIFilter) {
        duplicateKey.push(tf.field_name);
      }
    });
    //if duplicate array has length means clicked value has two object with same key so we will remove both else will remove single object
    if (duplicateKey.length > 1) {
      duplicateKey.map((dubKey) => {
        targetingApiFilteringData.map((tfKey) => {
          if (dubKey === tfKey.field_name) {
            removePreviosSameFilterObject(targetingFiltering, dubKey);
          }
        });
      });
      setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);
    } else {
      removePreviosSameFilterObject(targetingFiltering, fieldNameForAPIFilter);
      setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);

      //if campaign chip canncel icon click then we will empty this array which pass filtering for portfolio & adgroup listing
      if (fieldNameForAPIFilter === "campaign_id") {
        setChckedCampaignDataForFilteringPortfolioAdgroup({
          ...chckedCampaignDataForFilteringPortfolioAdgroup,
          selectedCampaignId: [],
          selectedPlatformId: [],
        });
        setCheckedCampaignIdForFilter([]);
        setCheckedPlatforIdForFilter([]);
      }

      if (
        fieldNameForAPIFilter === "adyogi_targeting_type" ||
        fieldNameForAPIFilter === "campaign_type" ||
        fieldNameForAPIFilter === "campaign_objective"
      ) {
        let currentList = DropDownDataList.map((a) => {
          return { ...a };
        });
        currentList.map((list) => {
          if (fieldNameForAPIFilter === list.apiFieldNameForFilteringRequest) {
            list.isDataChecked = false;
            setMultiSelectDropDownSelecteddDataCount({
              ...multiSelectDropDownSelectedDataCount,
              [fieldNameForAPIFilter]: 0,
            });
          }
        });
        setDropDownDataList(currentList);
      }

      //on cancel icon click on chip this will false its key and class will update so it will show inactive
      if (
        fieldNameForAPIFilter === "portfolio_id" ||
        fieldNameForAPIFilter === "campaign_id" ||
        fieldNameForAPIFilter == "adgroup_id"
      ) {
        let findClickedColumnInArrayByFieldName = [
          { displayname: "PORTFOLIO", fieldName: "portfolio_id" },
          { displayname: "CAMPAIGNS", fieldName: "campaign_id" },
          { displayname: "AD GROUP", fieldName: "adgroup_id" },
        ];

        let dataHeadCopy = JSON.stringify(dataHeads);
        dataHeadCopy = JSON.parse(dataHeadCopy);

        dataHeadCopy.map((col) => {
          findClickedColumnInArrayByFieldName.map((currentField) => {
            if (
              currentField.fieldName === fieldNameForAPIFilter &&
              col.fieldName === currentField.displayname
            ) {
              col.isFindTaskActive = false;
            }
          });
        });

        setdataHeads(dataHeadCopy);
      }
    }

    const hasNumericRange = appliedFilterData.some((obj) => {
      return (
        !isNaN(obj.minRange) ||
        (!isNaN(obj.maxRange) &&
          obj.maxRange !== 0 &&
          obj.maxRange !== "Infinity")
      );
    });
    hasNumericRange
      ? setMinMaxFilterActivated(true)
      : setMinMaxFilterActivated(false);
  };

  const getClassNameThead = (th, sortingDetails) => {
    let clsname = ""
    if (th.fieldName === "SUGG BID") {
      clsname = "targetinng-table-heads-suggbid-with-maginifine-icon captital-first-letter"
    } else if (th.fieldName === "CAMPAIGNS") {
      clsname = "targetinng-table-heads-campaigntype-with-maginifine-icon"
    } else if (th.fieldName === "AD GROUP") {
      clsname = "targetinng-table-heads-adgrouptype-with-maginifine-icon"
    } else if (th.isSortFunctionalityNeededHere) {
      clsname = "targetinng-table-heads stats-heads"
    } else {
      clsname = "targetinng-table-heads";
    }

    if (sortingDetails.sortingColumnName === th.id || th.isFindTaskActive) {
      clsname += " selected";
    }

    return clsname;
  }

  function getStatusWiseValue(status, val,currentDataHead) {
    let tmp= val === 0 || val === "" ? (status === 'ENABLED' ? 0 : '-') : val;
    return currentDataHead?.isRsSymbolNeeded && tmp !== '-' ? `${currencySymbol}${tmp}` : tmp
  }

  const bindParentValueColumns = (data, currentDataHead) => {
    const value = data[currentDataHead.id];

    if (value) {
      if (typeof value === "number") {
        if (currentDataHead.isTwoDecimalPlacesNeeded) {
          return (value === 0 || value === "") ? getStatusWiseValue(data?.status, value,currentDataHead) : roundFigureNum(value);
        } else if (currentDataHead.isRsSymbolNeeded) {
          return (value === 0 || value === "")  ? getStatusWiseValue(data?.status, value,currentDataHead) : `${currencySymbol}${Math.round(value)}`;
        } else {
          return (value === 0 || value === "") ? getStatusWiseValue(data?.status, value,currentDataHead) : Math.round(value);
        }
      } else {
        return data?.status === "ENABLED" ? "0" : "-";
      }
    } else {
      return data?.status === "ENABLED" ? "0" : "-";
    }
  }

  const bindChildValueColumns = (data, currentDataHead, childRowInstance) => {
    let val = 0
    if (data.childRows.length > 0) {
      if (typeof childRowInstance[currentDataHead.id] === "number") {
        if (currentDataHead.isTwoDecimalPlacesNeeded) {
          val = (childRowInstance[currentDataHead.id] === 0) ? getStatusWiseValue(childRowInstance?.status, childRowInstance[currentDataHead.id],currentDataHead) : roundFigureNum(childRowInstance[currentDataHead.id])
        } else {
          if (currentDataHead.isRsSymbolNeeded) {
            val = (childRowInstance[currentDataHead.id] === 0) ? getStatusWiseValue(childRowInstance?.status, childRowInstance[currentDataHead.id],currentDataHead) : `${currencySymbol}${Math.round(childRowInstance[currentDataHead.id])}`
          } else {
            val = (childRowInstance[currentDataHead.id] === 0) ? getStatusWiseValue(childRowInstance?.status, childRowInstance[currentDataHead.id],currentDataHead) : Math.round(childRowInstance[currentDataHead.id])
          }
        }
      } else {
        val = childRowInstance?.status === "ENABLED" ? "0" : "-"
      }
    } else {
      val = childRowInstance?.status === "ENABLED" ? "0" : "-"
    }

    return val;
  }

  const [selectedChildRows, setSelectedChildRows] = useState([]);
  const handleCellClick = (rowIndex) => {
    setSelectedChildRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowIndex)
        ? prevSelectedRows.filter((row) => row !== rowIndex)
        : [...prevSelectedRows, rowIndex]
    );
  };


  let tableBody = (
    <>
    <div className={lazyLoading ?"main-targeting-table-body-container overflow-x-hidden listinng-table" :"main-targeting-table-body-container listinng-table"  }ref={tableRef} onScroll={handleScroll}>
      <table className="mt-table main-targeting-center">
        <thead className="fixed-table">
          <tr className="main-table-row-fixed">
            {customDataHeads.map((th, mainIndex) => {
              return (
                <th
                  key={th.fieldName + "12"}
                  className={
                    getClassNameThead(th, sortingDetails)
                  }
                >
                  {th.isSortFunctionalityNeededHere ? (
                    <div className="d-flex flex-row align-items-center justify-content-end ">
                      <div className="d-flex align-items-center">
                        <span
                          className={
                            sortingDetails.sortingColumnName === th.id
                              ? "stat-heads-with-icon captital-first-letter active-filter-higlighted-text"
                              : "stat-heads-with-icon captital-first-letter"
                          }
                        >
                          {!th.isAbbrivation
                            ? th.fieldName.toLowerCase()
                            : th.fieldName}
                        </span>
                      </div>
                      <span className="sort-icon cursor-pointer-global">
                        <img
                          className={
                            sortingDetails.sortingColumnName === th.id
                              ? " d-flex align-items-end  mt-spends-icon-activated"
                              : " d-flex align-items-end"
                          }
                          src={
                            sortingDetails.sortByOrder === "DESC" &&
                              sortingDetails.sortingColumnName === th.id
                              ? descendingIconSvg
                              : sortingDetails.sortByOrder === "ASC" &&
                                sortingDetails.sortingColumnName === th.id
                                ? ascendingIconSvg
                                : ascendingIconSvg
                          }
                          onClick={() =>
                            onSortHandler(th.id, sortingDetails.sortByOrder)
                          }
                          alt="sort"
                        ></img>
                      </span>
                    </div>
                  ) : // th.fieldName === "TARGETING TYPE" ||  th.fieldName === "AD GROUP" ||  th.fieldName === "CAMPAIGN NAME" ?
                    // <div className="targetinng-typ">{th.fieldName}</div> :
                    th.fieldName === "TARGETING SELECTED" ? (
                      <div className="targeting-selected-head captital-first-letter">
                        <span className="">
                          <div className="parent-targeting-checkbox">
                            <CheckboxRb
                              inputLabelClass="check-colum-head"
                              label={th.fieldName.toLowerCase()}
                              checked={mainTargetingSelected}
                              onChange={onAllOpenTargetingCheckHandler}
                            ></CheckboxRb>
                          </div>
                        </span>
                        {/* <span className="stat-heads-with-icon">{th.fieldName}</span> */}
                      </div>
                    ) : th.fieldName === "PORTFOLIO" ||
                      th.fieldName === "CAMPAIGNS" ||
                      th.fieldName === "AD GROUP" ? (
                      <>
                        <div
                          className={
                            th.isFindTaskActive
                              ? " fieldnamme-with-search-iconn find-icon-head active-filter-higlighted-text"
                              : "fieldnamme-with-search-iconn find-icon-head"
                          }
                        >
                          <span className="d-flex align-items-center find-icon-container">
                            <img
                              className={
                                th.isFindTaskActive
                                  ? " cursor-pointer-global mt-find-icon-activated find-icon-height"
                                  : "cursor-pointer-global find-icon-height"
                              }
                              // className=" cursor-pointer-global mt-find-icon-activated"
                              src={findIconActive}
                              onClick={() => onFindIconHanndler(th.fieldName)}
                              alt="search"
                            ></img>
                          </span>
                          <div className="d-flex align-items-center ">
                            <span className="stat-heads-with-icon captital-first-letter">
                              {th.fieldName.toLowerCase()}
                            </span>
                          </div>
                        </div>
                        {th.fieldName === findClickedColumnName ? (
                          <div
                            ref={searchInColumnRef}
                            className={
                              findClickedColumnName &&
                                th.fieldName === findClickedColumnName
                                ? // findClickedColumnName
                                "find-box-container "
                                : "hide"
                            }
                          >
                            <div className="find-box-mainn-container">
                              <div className="search-box-infind-box">
                                <div>
                                  <img src={searchIcon} alt="search"></img>
                                </div>
                                <div className="finnd-input-text">
                                  <input
                                    type="text"
                                    placeholder={
                                      findClickedColumnName === "PORTFOLIO"
                                        ? "Portfolio name"
                                        : findClickedColumnName === "CAMPAIGNS"
                                          ? "Campaign name"
                                          : findClickedColumnName === "AD GROUP"
                                            ? "AD Group name"
                                            : ""
                                    }
                                    onChange={onSearchInputinColumnHandler}
                                  ></input>
                                </div>
                              </div>

                              <div className="mt-colum-serach-reset-row">
                                <div className="d-flex flex-column ">
                                  <div className="d-flex justify-content-end show-selected-text align-items-center">
                                  </div>

                                  <div className="d-flex  justify-content-between align-items-center">
                                    <div className="">
                                      {checkedColumnSearchData.length ? (
                                        <div className="d-flex colum-serach-top-row-show-selection">
                                          <img
                                            style={{filter:"none"}}
                                            className="dash-icon"
                                            src={dashIcon}
                                            onClick={onCoumnnSearchRestHandler}
                                            alt="dash"
                                          ></img>
                                          <div className="selcted-text-cont-text-for-col--search-mt cursor-pointer-global" onClick={onCoumnnSearchRestHandler}>{`${checkedColumnSearchData.length} Selected`}</div>
                                        </div>
                                      ) : (
                                        <div className="colum-serach-top-row-show-checkbox-select-all">
                                          <CheckboxRb
                                            inputLabelClass="select-all-text"
                                            // label="Select All "
                                            id={"select_all"}
                                            label={
                                              checkedColumnSearchData.length
                                                ? `${checkedColumnSearchData.length} Selected`
                                                : "Select All"
                                            }
                                            checked={
                                              allCheckStateInColumnSearchBox
                                            }
                                            onChange={
                                              onColumnSearchAllCheckHanndler
                                            }
                                          ></CheckboxRb>
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className="mt-column-search-rest-text cursor-pointer-global "
                                      onClick={onCoumnnSearchRestHandler}
                                    >
                                      Reset
                                    </div>
                                  </div>
                                </div>
                                {listForSearchAnndFilterTargetingBy &&
                                  findClickedColumnName &&
                                  listForSearchAnndFilterTargetingBy.map(
                                    (fdata, i) => {
                                      return (
                                        <div
                                          key={i + 23}
                                          className="search-result-row"
                                        >
                                          <div>
                                            <CheckboxRb
                                              inputLabelClass="search-result-text"
                                              label={fdata.name}
                                              id={fdata.name}
                                              checked={
                                                fdata?.isDataChecked
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                columnnSearchCheckHandler(
                                                  e,
                                                  fdata.id,
                                                  fdata.isDataChecked
                                                )
                                              }
                                            ></CheckboxRb>
                                          </div>
                                          {/* <div className="search-result-text"></div> */}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                              <div className="column-aerch-box-footer justify-content-center">
                                <SecondaryBtnRb
                                  secondaryBtnText="Cancel"
                                  className="primary-inactive-btn columnn-search-btn"
                                  id="canel-search"
                                  onClick={(e) =>
                                    onColumnSearchClickBtnHandler(e, "cancel")
                                  }
                                ></SecondaryBtnRb>
                                <SecondaryBtnRb
                                  secondaryBtnText="Apply"
                                  className="primary-active-btn columnn-search-btn "
                                  id="apply-search"
                                  onClick={(e) =>
                                    onColumnSearchClickBtnHandler(e, "apply")
                                  }
                                ></SecondaryBtnRb>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : th.fieldName === "ACTION" ? (
                      <div className="captital-first-letter">
                        {th.fieldName.toLowerCase()}
                      </div>
                    ) : th.fieldName == "TARGETING TYPE" ? (
                      <div className="targetinng-typ captital-first-letter">
                        {th.fieldName.toLowerCase()}
                      </div>
                    ) : (
                      <div className="other-col captital-first-letter">
                        {th.fieldName.toLowerCase()}
                      </div>
                    )}
                </th>
              );
            })}
          </tr>
        </thead>
        {targetingDataForTable.length ? (
          <tbody className="another-tbody">
            {targetingDataForTable.map((data, index1) => {
              data = JSON.stringify(data);
              data = JSON.parse(data);

              for (let i = 0; i < customDataHeads.length; i++) {
                if (!customDataHeads[i].isDefaultHead) {
                  data[customDataHeads[i].id] = data[customDataHeads[i].id]
                    ? data[customDataHeads[i].id]
                    : "-";
                }
              }

              return (
                <React.Fragment key={index1 + 10}>
                  <tr  
                  onClick={() => handleCellClick(index1 + "mainrow")}
                  className={
                    selectedChildRows.includes(index1 + "mainrow")
                      ? "main-row-font cursor-pointer highlight"
                      : "main-row-font cursor-pointer"
                  }
                  >
                    <td className="first-column-sw-btn">
                      <div className="classlist-switch-btn">
                        <div className="mt-status-dot-container">
                          <div
                            className={
                              data?.status == "ENABLED"
                                ? "enabled-status-dots-mt"
                                : data?.status == "PAUSED"
                                  ? "paused-status-dots-mt"
                                  : null
                            }
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td className="targetinng-second-column ">
                      <div className="targetinng-name-connntainer">
                        <div className="d-flex main-display-campaign">
                          <div className="d-flex justify-content-start align-items-start" onClick={() => handleCellClick(index1 + "mainrow")}>
                            <CheckboxRb
                              className="parent-targeting-checkbox"
                              onChange={(e) =>
                                parentRowCheckboxHandler(
                                  e,
                                  data?.targeting,
                                  data?.adyogi_targeting_type,
                                  data?.isPlusIconDisabled
                                )
                              }
                              inputLabelClass="label-extra-class"
                              checked={data.isTargetChecked}
                              label={data?.targeting}
                              disabled={
                                allFetchLoaders.targetingChildLoaderForPlusAndCheckIcon
                                  ? true
                                  : false
                              }
                            ></CheckboxRb>
                          </div>
                          <div
                            className={
                              data.isPlusIconDisabled
                                ? "plus-icon-sec-container"
                                : "plus-icon-sec-container-when-clicked"
                            }
                          >
                            <div className={"td-with-loader"} onClick={() => handleCellClick(index1 + "mainrow")}>
                              {allFetchLoaders.targetingChildLoaderForPlusAndCheckIcon &&
                                data.targeting ===
                                currentClickedTargeting.clikedTargeting &&
                                data.adyogi_targeting_type ===
                                currentClickedTargeting.clickedTargetingMatch ? (
                                <Spinner shimmer={true} shimmerShape={"box"} />
                              ) : (
                                <img
                                  onClick={() =>
                                    plusIconHandler(
                                      data?.targeting,
                                      data?.adyogi_targeting_type,
                                      data?.isPlusIconDisabled,
                                      "PlusIconHandler"
                                    )
                                  }
                                  className={
                                    allFetchLoaders.targetingChildLoaderForPlusAndCheckIcon
                                      ? "diable-click-event"
                                      : "hoverGreen"
                                  }
                                  src={
                                    data.isPlusIconDisabled ? addIcon : lessIcon
                                  }
                                  alt="add"
                                ></img>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* checkCircle */}
                      </div>
                    </td>

                    <td>{data?.adyogi_targeting_type}</td>
                    <td>{"-"}</td>
                    <td>
                      <div className="campaign-text-column">
                        <div className="campaign-count-circle">
                          {data?.campaign_count}
                        </div>
                        <span>{"Campaigns"}</span>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-row  ">
                        <div className="d-flex align-items-center">
                          <div className="campaign-count-circle">
                            {data?.adgroup_count}
                          </div>
                        </div>
                        <span>{"Ad Groups"}</span>
                      </div>
                    </td>
                    {
                      isCustomHeadsRequired && <><td>{data?.suggested_bid ? data?.suggested_bid : "-"}</td>
                      <td>{data?.current_bid ? data?.current_bid : "-"} </td></> 
                    }
                  

                    {customDataHeads.map((currentDataHead, i) => {
                      if (!currentDataHead.isDefaultHead) {
                        return (
                          <td key={i + 11} className="stats-data-col">
                            {bindParentValueColumns(data, currentDataHead)}
                          </td>
                        );
                      }
                    })}
                  </tr>

                  {allFetchLoaders.targetingChildLoaderForPlusAndCheckIcon &&
                    data.targeting === currentClickedTargeting.clikedTargeting &&
                    data.adyogi_targeting_type ===
                    currentClickedTargeting.clickedTargetingMatch
                    ? dummyCountForChildLoaderRow.map((dumyEl) => {
                      return (
                        <tr>
                          {customDataHeads.map((el) => {
                            return (
                              <td className="loader-td">
                                <div className="td-with-loader">
                                  <Spinner shimmer={true} />
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                    : data["childRows"] &&
                    data["childRows"].map((childRowInstance, indo) => {
                      if (childRowInstance.length) {
                        for (let i = 0; i < customDataHeads.length; i++) {
                          if (!customDataHeads[i].isDefaultHead) {
                            childRowInstance[customDataHeads[i].id] =
                              childRowInstance[customDataHeads[i].id]
                                ? childRowInstance[customDataHeads[i].id]
                                : "-";
                          }
                        }
                      }


                      return (
                        <tr
                          key={indo + 13}
                          // className={
                          //   data.isPlusIconDisabled
                          //     ? "hide"
                          //     : "mt-campaign-details"
                          // }
                          onClick={() => handleCellClick(indo + "subrow")}
                          className={data.isPlusIconDisabled ? "hide"
                             : selectedChildRows.includes(indo + "subrow")
                              ? "mt-campaign-details cursor-pointer highlight"
                              : "mt-campaign-details cursor-pointer"
                          }
                        >
                          <td>
                            <div className="child-switch-btn-container" onClick={() => handleCellClick(indo + "subrow")}>
                              {
                                <SwitchBtn
                                  checked={
                                    childRowInstance?.status === "ENABLED"
                                      ? true
                                      : false
                                  }
                                  disabled={!isCampaignNotSB(childRowInstance)}
                                  onChange={() =>
                                    onChildRowToggleHandler(
                                      childRowInstance?.target_id,
                                      data?.targeting
                                    )
                                  }
                                ></SwitchBtn>
                              }
                            </div>
                          </td>
                          <td className=" second-column-child">
                            <div className="targetinng-name-connntainer">
                              <div className="d-flex ">
                                <div className="d-flex justify-content-start align-items-start " onClick={() => handleCellClick(indo + "subrow")}>
                                  <CheckboxRb
                                    className="child-targeting-checkbox"
                                    label={childRowInstance?.targeting}
                                    disabled={!isCampaignNotSB(childRowInstance) || !isCurrentBidPresent(childRowInstance)}
                                    checked={
                                      childRowInstance?.isTargetChecked
                                    }
                                    onChange={(e) =>
                                      {
                                        childRowCheckboxHandler(
                                          e,
                                          childRowInstance?.target_id,
                                          childRowInstance?.adyogi_targeting_type,
                                          data.targeting
                                        )
                                      }
                                    
                                    }
                                  ></CheckboxRb>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>{childRowInstance?.adyogi_targeting_type}</td>
                          <td>{childRowInstance?.portfolio_name}</td>
                          <td>
                            <div
                              className="cursor-pointer-global clickable-text-color"
                              onClick={() =>
                                onClickCampaignName(
                                  childRowInstance?.campaign_name
                                )
                              }
                            >
                              {childRowInstance?.campaign_name}
                            </div>
                          </td>
                          <td>
                            <div
                              className="cursor-pointer-global clickable-text-color"
                              onClick={() =>
                                onClickAdgroupName(childRowInstance, data)
                              }
                            >
                              {childRowInstance?.adgroup_name}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row">
                              <span>
                                {childRowInstance?.suggested_bid
                                  ? childRowInstance?.suggested_bid
                                  : "-"}
                              </span>
                              <TooltipRb
                                tooltipText={
                                  childRowInstance?.current_bid ==
                                    childRowInstance?.suggested_bid
                                    ? "Current bid is same as suggested bid"
                                    : "Copy suggested as current bid by clicking on this icon"
                                }
                                placement="top"
                                id="test"
                              >
                                <div className="d-flex align-items-center mx-3 ">
                                  <img
                                    // className="cursor-pointer-global"
                                    className={
                                      childRowInstance?.suggested_bid
                                        ? "cursor-pointer-global"
                                        : "hide"
                                    }
                                    onClick={() =>
                                      bidCopyIconnHandler(
                                        childRowInstance?.target_id,
                                        childRowInstance?.current_bid,
                                        childRowInstance?.suggested_bid,
                                        indo
                                      )
                                    }
                                    src={
                                      childRowInstance?.current_bid ==
                                        childRowInstance?.suggested_bid
                                        ? valueCopiedIcon
                                        : copyIcon
                                    }
                                    alt="copy"
                                  ></img>
                                </div>
                              </TooltipRb>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <div className=" currennt-bid-td-container">
                                <input
                                  type="text"
                                  className={
                                    childRowInstance?.isEditCurrenntBidValueDisabled
                                      ? "currentbid-rightbox"
                                      : "curret-bid-value-edit"
                                  }
                                  disabled={
                                    childRowInstance?.isEditCurrenntBidValueDisabled
                                  }
                                  value={childRowInstance?.current_bid}
                                  onChange={(e) => {
                                    setInputValue(
                                      childRowInstance?.target_id,
                                      e.target.value,
                                      "curretBidInput"
                                    );
                                  }}
                                ></input>
                                <span
                                  onClick={() =>
                                    editBidofChildClickHandler(
                                      childRowInstance?.target_id,
                                      "CurrentBidPencil",
                                      childRowInstance?.current_bid
                                    )
                                  }
                                  className={`edit-budget-icon-container ${!isCurrentBidPresent(childRowInstance) || !isCampaignNotSB(childRowInstance) ? 'disable-pointer-events' : ''}`}
                                >
                                  <img
                                    src={
                                      childRowInstance?.isEditCurrenntBidValueDisabled
                                        ? editIcon
                                        : checkCircle
                                    }
                                    alt="edit"
                                  ></img>
                                </span>
                              </div>
                            </div>
                          </td>
                          {/* {dataHeads.map((currentDataHead, i) => {
								if (!currentDataHead.isDefaultHead) {
									return (
										<td key={i + 13}>
											{data.childRows.length
												? typeof childRowInstance[
														currentDataHead.id
													] === "number"
													? childRowInstance[currentDataHead.id]
													: childRowInstance[currentDataHead.id]
												: "-"}
										</td>
									);
								}
							})} */}
                          {customDataHeads.map((currentDataHead, i) => {
                            if (!currentDataHead.isDefaultHead) {
                              return (
                                <td key={i + 13} className="stats-data-col">
                                  {bindChildValueColumns(data, currentDataHead, childRowInstance)}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <div>
              <p className="no-data-found-text-container">NO DATA FOUND</p>
            </div>
          </tbody>
        )}
      </table>
      {lazyLoading && (
        <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
          <LazyLoadSpinner text={`Loading ${limit} More Targetings...`} />
        </div>
      )}
    </div>
    </>
  );


  // Methods Child Table Body Methods 

  const handleEditBidChange = (index,update) => {
    const data = structuredClone(targetingDataForTable)
    if(!update) {
      data[index].targeting_expression.target[0].bid = data[index]?.current_bid
      updateTargetingDataOnServer([data[index].targeting_expression],"Bid Updated Successfully","Some unknown Error Occurred")
    }
    data[index].isEditCurrenntBidValueDisabled = !data[index].isEditCurrenntBidValueDisabled
    setTargetingDataForTable([...data])
  }

  const onEditBidChange = (index,e) => {
    const data = structuredClone(targetingDataForTable)
    data[index].current_bid = e.target.value
    setTargetingDataForTable([...data])
  }

  const childCopySuggestedBidHandler = (index) => {
    const data = structuredClone(targetingDataForTable)
    data[index].targeting_expression.target[0].bid = data[index]?.suggested_bid
    data[index].current_bid = data[index]?.suggested_bid
    updateTargetingDataOnServer([data[index].targeting_expression],"Bid Updated Successfully","Some unknown Error Occurred")
    setTargetingDataForTable([...data])
  }

  const onChildViewCheckboxHandler = (index) => {
    const data = structuredClone(targetingDataForTable);
    data[index].isTargetChecked = !data[index].isTargetChecked
    setTargetingDataForTable([...data])
  }

  const childViewRowToggleHandler = (index) => {
    const data = structuredClone(targetingDataForTable)
     data[index].targeting_expression.target[0].status = data[index].targeting_expression.target[0].status === "ENABLED" ? "PAUSED" : "ENABLED"
     data[index].status = data[index].targeting_expression.target[0].status
     updateTargetingDataOnServer([data[index]?.targeting_expression],"Updated Successfully","Some unknown Error Occurred")
     setTargetingDataForTable([...data])
  }

  // Effects For Child Table Body

  useEffect(() => {
    if(selectedViewType==="Child") {
      const data = targetingDataForTable.filter(data => data?.isTargetChecked);
      setCheckedAdGroupData([...data])
    }
  },[targetingDataForTable])

  let tableBodyChild = (
    <>
    <div className={lazyLoading ?"main-targeting-table-body-container overflow-x-hidden listinng-table" :"main-targeting-table-body-container listinng-table"}  ref={tableRef} onScroll={handleScroll}>
      <table className="mt-table main-targeting-center">
        <thead className="fixed-table">
          <tr className="main-table-row-fixed">
            {dataHeads.map((th, mainIndex) => {
              return (
                <th
                  key={th.fieldName + "12"}
                  className={
                    getClassNameThead(th, sortingDetails)
                  }
                >
                  {th.isSortFunctionalityNeededHere ? (
                    <div className="d-flex flex-row align-items-center justify-content-end ">
                      <div className="d-flex align-items-center">
                        <span
                          className={
                            sortingDetails.sortingColumnName === th.id
                              ? "stat-heads-with-icon captital-first-letter active-filter-higlighted-text"
                              : "stat-heads-with-icon captital-first-letter"
                          }
                        >
                          {!th.isAbbrivation
                            ? th.fieldName.toLowerCase()
                            : th.fieldName}
                        </span>
                      </div>
                      <span className="sort-icon cursor-pointer-global">
                        <img
                          className={
                            sortingDetails.sortingColumnName === th.id
                              ? " d-flex align-items-end  mt-spends-icon-activated"
                              : " d-flex align-items-end"
                          }
                          src={
                            sortingDetails.sortByOrder === "DESC" &&
                              sortingDetails.sortingColumnName === th.id
                              ? descendingIconSvg
                              : sortingDetails.sortByOrder === "ASC" &&
                                sortingDetails.sortingColumnName === th.id
                                ? ascendingIconSvg
                                : ascendingIconSvg
                          }
                          onClick={() =>
                            onSortHandler(th.id, sortingDetails.sortByOrder)
                          }
                          alt="sort"
                        ></img>
                      </span>
                    </div>
                  ) : // th.fieldName === "TARGETING TYPE" ||  th.fieldName === "AD GROUP" ||  th.fieldName === "CAMPAIGN NAME" ?
                    // <div className="targetinng-typ">{th.fieldName}</div> :
                    th.fieldName === "TARGETING SELECTED" ? (
                      <div className="targeting-selected-head captital-first-letter">
                        <span className="">
                          <div className="parent-targeting-checkbox">
                            <CheckboxRb
                              inputLabelClass="check-colum-head"
                              label={th.fieldName.toLowerCase()}
                              checked={mainTargetingSelected}
                              onChange={onAllOpenTargetingCheckHandler}

                            ></CheckboxRb>
                          </div>
                        </span>
                        {/* <span className="stat-heads-with-icon">{th.fieldName}</span> */}
                      </div>
                    ) : th.fieldName === "PORTFOLIO" ||
                      th.fieldName === "CAMPAIGNS" ||
                      th.fieldName === "AD GROUP" ? (
                      <>
                        <div
                          className={
                            th.isFindTaskActive
                              ? " fieldnamme-with-search-iconn find-icon-head active-filter-higlighted-text"
                              : "fieldnamme-with-search-iconn find-icon-head"
                          }
                        >
                          <span className="d-flex align-items-center find-icon-container">
                            <img
                              className={
                                th.isFindTaskActive
                                  ? " cursor-pointer-global mt-find-icon-activated find-icon-height"
                                  : "cursor-pointer-global find-icon-height"
                              }
                              // className=" cursor-pointer-global mt-find-icon-activated"
                              src={findIconActive}
                              onClick={() => onFindIconHanndler(th.fieldName)}
                              alt="search"
                            ></img>
                          </span>
                          <div className="d-flex align-items-center ">
                            <span className="stat-heads-with-icon captital-first-letter">
                              {th.fieldName.toLowerCase()}
                            </span>
                          </div>
                        </div>
                        {th.fieldName === findClickedColumnName ? (
                          <div
                            ref={searchInColumnRef}
                            className={
                              findClickedColumnName &&
                                th.fieldName === findClickedColumnName
                                ? // findClickedColumnName
                                "find-box-container "
                                : "hide"
                            }
                          >
                            <div className="find-box-mainn-container">
                              <div className="search-box-infind-box">
                                <div>
                                  <img src={searchIcon} alt="search"></img>
                                </div>
                                <div className="finnd-input-text">
                                  <input
                                    type="text"
                                    placeholder={
                                      findClickedColumnName === "PORTFOLIO"
                                        ? "Portfolio name"
                                        : findClickedColumnName === "CAMPAIGNS"
                                          ? "Campaign name"
                                          : findClickedColumnName === "AD GROUP"
                                            ? "AD Group name"
                                            : ""
                                    }
                                    onChange={onSearchInputinColumnHandler}
                                  ></input>
                                </div>
                              </div>

                              <div className="mt-colum-serach-reset-row">
                                <div className="d-flex flex-column ">
                                  <div className="d-flex justify-content-end show-selected-text align-items-center">
                                  </div>

                                  <div className="d-flex  justify-content-between align-items-center">
                                    <div className="">
                                      {checkedColumnSearchData.length ? (
                                        <div className="d-flex colum-serach-top-row-show-selection">
                                          <img
                                            style={{filter:"none"}}
                                            className="dash-icon"
                                            src={dashIcon}
                                            onClick={onCoumnnSearchRestHandler}
                                            alt="dash"
                                          ></img>
                                          <div className="selcted-text-cont-text-for-col--search-mt cursor-pointer-global" onClick={onCoumnnSearchRestHandler}>{`${checkedColumnSearchData.length} Selected`}</div>
                                        </div>
                                      ) : (
                                        <div className="colum-serach-top-row-show-checkbox-select-all">
                                          <CheckboxRb
                                            inputLabelClass="select-all-text"
                                            // label="Select All "
                                            id={"select_all"}
                                            label={
                                              checkedColumnSearchData.length
                                                ? `${checkedColumnSearchData.length} Selected`
                                                : "Select All"
                                            }
                                            checked={
                                              allCheckStateInColumnSearchBox
                                            }
                                            onChange={
                                              onColumnSearchAllCheckHanndler
                                            }
                                          ></CheckboxRb>
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className="mt-column-search-rest-text cursor-pointer-global "
                                      onClick={onCoumnnSearchRestHandler}
                                    >
                                      Reset
                                    </div>
                                  </div>
                                </div>
                                {listForSearchAnndFilterTargetingBy &&
                                  findClickedColumnName &&
                                  listForSearchAnndFilterTargetingBy.map(
                                    (fdata, i) => {
                                      return (
                                        <div
                                          key={i + 23}
                                          className="search-result-row"
                                        >
                                          <div>
                                            <CheckboxRb
                                              inputLabelClass="search-result-text"
                                              label={fdata.name}
                                              id={fdata.name}
                                              checked={
                                                fdata?.isDataChecked
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                columnnSearchCheckHandler(
                                                  e,
                                                  fdata.id,
                                                  fdata.isDataChecked
                                                )
                                              }
                                            ></CheckboxRb>
                                          </div>
                                          {/* <div className="search-result-text"></div> */}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                              <div className="column-aerch-box-footer justify-content-center">
                                <SecondaryBtnRb
                                  secondaryBtnText="Cancel"
                                  className="primary-inactive-btn columnn-search-btn"
                                  id="canel-search"
                                  onClick={(e) =>
                                    onColumnSearchClickBtnHandler(e, "cancel")
                                  }
                                ></SecondaryBtnRb>
                                <SecondaryBtnRb
                                  secondaryBtnText="Apply"
                                  className="primary-active-btn columnn-search-btn "
                                  id="apply-search"
                                  onClick={(e) =>
                                    onColumnSearchClickBtnHandler(e, "apply")
                                  }
                                ></SecondaryBtnRb>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : th.fieldName === "ACTION" ? (
                      <div className="captital-first-letter">
                        {th.fieldName.toLowerCase()}
                      </div>
                    ) : th.fieldName == "TARGETING TYPE" ? (
                      <div className="targetinng-typ captital-first-letter">
                        {th.fieldName.toLowerCase()}
                      </div>
                    ) : (
                      <div className="other-col captital-first-letter">
                        {th.fieldName.toLowerCase()}
                      </div>
                    )}
                </th>
              );
            })}
          </tr>
        </thead>
        {targetingDataForTable.length ? (
          <tbody className="another-tbody">
            {targetingDataForTable.map((data, index1) => {
              data = JSON.stringify(data);
              data = JSON.parse(data);

              for (let i = 0; i < dataHeads.length; i++) {
                if (!dataHeads[i].isDefaultHead) {
                  data[dataHeads[i].id] = data[dataHeads[i].id]
                    ? data[dataHeads[i].id]
                    : "-";
                }
              }

              return (
                <React.Fragment key={index1 + 10}>
                  <tr className="main-row-font">
                    <td className="first-column-sw-btn">
                    <div className="child-switch-btn-container">
                              {
                                <SwitchBtn
                                  checked={
                                    data?.status === "ENABLED"
                                      ? true
                                      : false
                                  }
                                  disabled={!isCampaignNotSB(data)}
                                  onChange={() =>
                                    {
                                      childViewRowToggleHandler(
                                        index1
                                      )
                                    }
                                  
                                  }
                                ></SwitchBtn>
                              }
                            </div>
                    </td>
                    <td className=" second-column-child bg-white">
                            <div className="targetinng-name-connntainer">
                              <div className="d-flex ">
                                <div className="d-flex justify-content-start align-items-start ">
                                  <CheckboxRb
                                    className="child-targeting-checkbox"
                                    label={data?.targeting}
                                    checked={
                                      data?.isTargetChecked
                                    }
                                    disabled={!isCampaignNotSB(data)}
                                    onChange={(e) =>
                                     onChildViewCheckboxHandler(index1)
                                    }
                                  ></CheckboxRb>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td>{data?.adyogi_targeting_type}</td>
                          <td>{data?.portfolio_name}</td>
                          <td>
                            <div
                              className="cursor-pointer-global clickable-text-color"
                              onClick={() =>
                                onClickCampaignName(
                                  data?.campaign_name
                                )
                              }
                            >
                              {data?.campaign_name}
                            </div>
                          </td>

                    <td>
                            <div
                              className="cursor-pointer-global clickable-text-color"
                              onClick={() =>
                                onClickAdgroupName(data, data)
                              }
                            >
                              {data?.adgroup_name}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row">
                              <span>
                                {data?.suggested_bid
                                  ? data?.suggested_bid
                                  : "-"}
                              </span>
                              <TooltipRb
                                tooltipText={
                                  data?.current_bid ==
                                    data?.suggested_bid
                                    ? "Current bid is same as suggested bid"
                                    : "Copy suggested as current bid by clicking on this icon"
                                }
                                placement="top"
                                id="test"
                              >
                                <div className="d-flex align-items-center mx-3 ">
                                  <img
                                    // className="cursor-pointer-global"
                                    className={
                                      data?.suggested_bid
                                        ? "cursor-pointer-global"
                                        : "hide"
                                    }
                                    onClick={() =>
                                      bidCopyIconnHandler(
                                        data?.target_id,
                                        data?.current_bid,
                                        data?.suggested_bid,
                                        index1
                                      )
                                    }
                                    src={
                                      data?.current_bid ==
                                        data?.suggested_bid
                                        ? valueCopiedIcon
                                        : copyIcon
                                    }
                                    alt="copy"
                                  ></img>
                                </div>
                              </TooltipRb>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row justify-content-center">
                              <div className=" currennt-bid-td-container">
                                <input
                                  type="text"
                                  className={
                                    data?.isEditCurrenntBidValueDisabled
                                      ? "currentbid-rightbox"
                                      : "curret-bid-value-edit"
                                  }
                                  disabled={
                                    data?.isEditCurrenntBidValueDisabled
                                  }
                                  value={data?.current_bid}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    onEditBidChange(index1,e)
                                  }}
                                ></input>
                                <span
                                  onClick={(e) =>
                                  {  e.stopPropagation();
                                    handleEditBidChange(index1 , data?.isEditCurrenntBidValueDisabled)
                                  }
                                  }
                                  className={`edit-budget-icon-container ${!isCurrentBidPresent(data) || !isCampaignNotSB(data) ? "disable-pointer-events" : ''}`}
                                >
                                  <img
                                    src={
                                      data?.isEditCurrenntBidValueDisabled
                                        ? editIcon
                                        : checkCircle
                                    }
                                    alt="edit"
                                  ></img>
                                </span>
                              </div>
                            </div>
                          </td>
                    {dataHeads.map((currentDataHead, i) => {
                      if (!currentDataHead.isDefaultHead) {
                        return (
                          <td key={i + 11} className="stats-data-col">
                            {bindParentValueColumns(data, currentDataHead)}
                          </td>
                        );
                      }
                    })}
                  </tr>

                 
                </React.Fragment>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <div>
            {!isLoaderVisible &&  <p className="no-data-found-text-container">NO DATA FOUND</p>}
            </div>
          </tbody>
        )}
      </table>
    {lazyLoading ? <div className="lazy-loading-container p-3">
         <LazyLoadSpinner text={"Loading 200 More Targetings..."} />
      </div>:null}
    </div>
    </>
       );

  // change view 
  useEffect(() => {
    setPaginationAction({
      ...paginationAction,
      actionnType: null,
      currentPageCount: 1,
    });
    setoffesetDataAsPerCurretPage(0);
    setRunChildData(true)
    
  },[selectedViewType])

  useEffect(() => {
    if(runChildData) {
      getTargetingRowsCount()
      getMasterTargetingData();
      setRunChildData(false)
    }
  },[runChildData])

  const cancelHandler = () => {
     dispatch(campaignListActions.checkedTargetDataForBulEdit([]))
  }

  const tableBodyToRender = selectedViewType=== "Parent" ? tableBody : tableBodyChild

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Breadcrumb />
      <div className="campaign-list-container">
        <Loader visible={initialLoad}></Loader> 
        <div>
          {
            <FilterLogicModal
              filterFor="master-targeting-list"
              show={isFilterVisible}
              cancelSpecifiNumberFilter={cancelSpecifiNumberFilter}
            ></FilterLogicModal>
          }
        </div>
        <div>
          {
            <MasterTagetinngActionAlertMModal
              onYesClick={actioAlertYesClickhandler}
              alertText={showAlertMsgOf}
            ></MasterTagetinngActionAlertMModal>
          }
        </div>
        <div>
          <EditBidModalForTargeting
            show={bulkEditBidModalVisibilty}
            setBidAs={true}
            cancel={cancelHandler}
          ></EditBidModalForTargeting>
        </div>
        <div
          className={
            checkedAdGroupData.length
              ? "et-filter-options-container-formasterTargeting alignment-top-for-master-targeting"
              : "hide"
          }
        >
          <button className="selected-row-count mx-2">
            <img
              className="dash-icon"
              src={lessIcon}
              onClick={dashIconInBulKOperationHandler} alt="less"
            ></img>
            <span className="selected-text-with-count">
              {checkedAdGroupData && `${checkedAdGroupData?.length}  Selected`}
              {/* <div>{checkedAdGroupData.map((ad) => {})}</div> */}
            </span>
          </button>
          <button
            className="alignn-with-sug-bid-box-selective "
            onClick={alignWithSuggestedBidhandler}
          >
            Align with Suggested Bid
          </button>
          <button
            className="bid-manipulate-box-selective"
            onClick={editBidHandler}
            data-toggle="modal"
            data-target="#exampleModalCenter"
          >
            Adjust Bid
          </button>
          <button
            onClick={() => onEnnablePauseddClickhanndler("ENABLED")}
            className="bid-manipulate-box-selective"
          // onClick={bulkPausehandler}
          >
            <img className="mx-1" src={resumeIcon} alt="resume" />
            <span> Enable</span>
          </button>
          <button
            onClick={() => onEnnablePauseddClickhanndler("PAUSED")}
            className="bid-manipulate-box-selective border-radius"
          // onClick={bulkPausehandler}
          >
            <img className="mx-1" src={pauseIcon} alt="pause" />
            <span> Pause</span>
          </button>
        </div>
        <Stack>
          <div className="section-heading bottom-margin">
            <Stack direction="horizontal" className="d-flex align-item-center">
              <SectionTitle sectionTitleHeading="Targeting"></SectionTitle>
            </Stack>

           
          </div>
          <div className="line-column-graph-main">
              <div
                className={
                  dashboardSideBarVisibiltyState
                    ? "line-column-graph-container-on-sidebar-open"
                    : "line-column-graph-container"
                }
              >
                <div className="position-relative-container-master">
                  <div className="line-column-graph-legends">
                    <span className="line-column-graph-title">
                      Targeting Spends v/s ROAS
                    </span>
                    <div className="d-flex gap-3">
                      <LegendComponent
                        legendColor={"#1D216E"} //7078CC
                        legendText={"Keyword Targeting"}
                      ></LegendComponent>
                      <LegendComponent
                        legendColor={"#50A5B5"}//70BDCC
                        legendText={"Product Targeting"}
                      ></LegendComponent>
                      <LegendComponent
                        legendColor={"#DEC46A"}//E6DB7E
                        legendText={"Automatic Targeting"}
                      ></LegendComponent>
                      <LegendComponent
                        legendColor={"#81B550"}//9CCC70
                        legendText={"Audience Targeting"}
                      ></LegendComponent>
                      <span className="dotted-line">.......</span>
                      ROAS
                    </div>
                  </div>
                  {/* <div className="position-relative-container"> */}
                  <TrendGraphMaster {...graphData} />
                  {/* </div> */}
                </div>
                <div className="line-column-graph-foter">
                  <div className="dates-description-graph">
                    *Data shown of last {7} Weeks (
                    {moment(
                      moment().subtract(42, "days")._d,
                      "DD-MM-YYYY"
                    ).format("Do MMMM, YYYY")}{" "}
                    to{" "}
                    {moment(
                      moment().subtract(1, "days")._d,
                      "DD-MM-YYYY"
                    ).format("Do MMMM, YYYY")}
                    )
                  </div>

                  {/*Use Legend Component here  */}
                </div>
              </div>
            </div>
          <div className="mt-config-row bottom-margin">
            <Stack className="mt-config-btns-container flex-wrap">
              <Stack
                direction="horizontal"
                className=" list-pages-search-input-container mb-1"
              >
                <Stack
                  direction="vertical"
                  className="justify-content-end align-item-end"
                >
                  <SearchBarForCampaignListOnly
                    placeholder="Search for Targeting"
                    onClickCancel={cancelIconInSearchInput}
                    searchBarForListPage={true}
                    onChange={(e) => onTargetingSearchhandler(e.target.value)}
                    onKeyDown={onSearchBarEnterClicked}
                    id="mt-global-targeting-list"
                    className="mt-custom-search-bar"
                    dynamicWidth="search-input-width-for-listing-pages"
                  ></SearchBarForCampaignListOnly>
                </Stack>
              </Stack>
              <Stack
                direction="horizontal"
                className="d-flex justify-content-center flex-wrap"
              >
                <Stack className="mt-filter-gap mb-1" direction="horizontal">
                  <MultiSelectCustomDropDown
                    allSeletHandler={(e) =>
                      allSeletHandler(
                        e,
                        "Targeting Type",
                        "mt-targeting-type",
                        "adyogi_targeting_type"
                      )
                    }
                    checkedItemCount={
                      multiSelectDropDownSelectedDataCount.adyogi_targeting_type
                    }
                    onApplyClickHandler={() =>
                      onApplyBtnhandler("adyogi_targeting_type")
                    }
                    targettingValue={"Targeting Type"}
                    src={dropdownIcon}
                    onDashIconHandlerToremoveAllCheckedOption={(
                      e,
                      targettingValue,
                      fieldName
                    ) =>
                      onDashIconHandlerToremoveAllCheckedOption(
                        e,
                        targettingValue,
                        fieldName,
                        "mt-targeting-type",
                        "adyogi_targeting_type"
                      )
                    }
                    status={"adyogi_targeting_type"}
                    fieldName={"mt-targeting-type"}
                    selectedOption={checkedDropDownFilterButtonData}
                    onChange={(e) =>
                      onOptionCheckedHandlerInDropDownFilter(
                        e,
                        e.target.value,
                        "adyogi_targeting_type",
                        "Targeting Type",
                        "mt-targeting-type"
                      )
                    }
                    showCheckedAppliedFilteringWhenOPen={() =>
                      showCheckedAppliedFilteringWhenOPen(
                        "Targeting Type",
                        "mt-targeting-type",
                        "adyogi_targeting_type"
                      )
                    }
                    data={DropDownDataList}
                  />
                </Stack>
                <Stack className="mt-filter-gap mb-1" direction="horizontal">
                  <MultiSelectCustomDropDown
                    showCheckedAppliedFilteringWhenOPen={() =>
                      showCheckedAppliedFilteringWhenOPen(
                        "Campaign Type",
                        "mt-campaign_type",
                        "campaign_type"
                      )
                    }
                    checkedItemCount={
                      multiSelectDropDownSelectedDataCount.campaign_type
                    }
                    onDashIconHandlerToremoveAllCheckedOption={(
                      e,
                      targettingValue,
                      fieldName
                    ) =>
                      onDashIconHandlerToremoveAllCheckedOption(
                        e,
                        targettingValue,
                        fieldName,
                        "mt-campaign_type",
                        "campaign_type"
                      )
                    }
                    allSeletHandler={(e) =>
                      allSeletHandler(
                        e,
                        "Campaign Type",
                        "mt-campaign_type",
                        "campaign_type"
                      )
                    }
                    targettingValue={"Campaign Type"}
                    src={dropdownIcon}
                    status={"campaign_type"}
                    fieldName={"mt-campaign_type"}
                    onApplyClickHandler={() =>
                      onApplyBtnhandler("campaign_type")
                    }
                    selectedOption={checkedDropDownFilterButtonData}
                    // data={campaignTypeDropDownData}
                    data={DropDownDataList}
                    onChange={(e) =>
                      onOptionCheckedHandlerInDropDownFilter(
                        e,
                        e.target.value,
                        "campaign_type",
                        "Campaign Type",
                        "mt-campaign_type"
                      )
                    }
                  />
                </Stack>
                <Stack className="mt-filter-gap mb-1" direction="horizontal">
                  <MultiSelectCustomDropDown
                    showCheckedAppliedFilteringWhenOPen={() =>
                      showCheckedAppliedFilteringWhenOPen(
                        "Objective",
                        "mt-campaign_objective",
                        "campaign_objective"
                      )
                    }
                    checkedItemCount={
                      multiSelectDropDownSelectedDataCount.campaign_objective
                    }
                    onDashIconHandlerToremoveAllCheckedOption={(
                      e,
                      targettingValue,
                      fieldName
                    ) =>
                      onDashIconHandlerToremoveAllCheckedOption(
                        e,
                        targettingValue,
                        fieldName,
                        "mt-campaign_objective",
                        "campaign_objective"
                      )
                    }
                    allSeletHandler={(e) =>
                      allSeletHandler(
                        e,
                        "Objective",
                        "mt-campaign_objective",
                        "campaign_objective"
                      )
                    }
                    targettingValue={"Objective"}
                    src={dropdownIcon}
                    status={"campaign_objective"}
                    fieldName={"mt-campaign_objective"}
                    onApplyClickHandler={() =>
                      onApplyBtnhandler("campaign_objective")
                    }
                    selectedOption={checkedDropDownFilterButtonData}
                    // data={objectiveDropdownData}
                    data={DropDownDataList}
                    onChange={(e) =>
                      onOptionCheckedHandlerInDropDownFilter(
                        e,
                        e.target.value,
                        "campaign_objective",
                        "Objective",
                        "mt-campaign_objective"
                      )
                    }
                  />
                </Stack>
                <Stack gap={4} className="mt-filter-gap mb-1 position-relative d-none">
                  <Stack className="d-flex justify-content-end align-item-end">
                    <button
                      className="date-container date-btn-campaignlist"
                      onClick={dateInputClickHandler}
                    >
                      <div className="d-flex justify-conntent-center">
                        <span className="date-btn-text">{dateDifference}</span>
                      </div>
                      <div className="btn-calender-img-holder">
                        <span className="">
                          <img
                            className="dropdown-img-for-select"
                            src={selectSideIcon} alt="down"
                          ></img>

                          <img
                            className="calender-icon-for-campaignlist"
                            src={CalenderIconForCampaignList} alt="calender"
                          ></img>
                        </span>
                      </div>
                    </button>
                  </Stack>
                </Stack>
              </Stack>
              <div
                ref={masterTargetinngCalenderIconRef}
                className={
                  showCustomDateBox ? "calender-inclasslist-container " : "hide"
                }
              >
                <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
                <span className="calender-close-icon-campaignlist">
                  <img
                    onClick={() => setShowCustomDateBox(false)}
                    src={closeIcon} alt="close"
                  ></img>
                </span>
              </div>
            </Stack>
          </div>
          <div>
            <Stack direction="horizontal" gap={4}>
              <Stack className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row w-100 justify-content-between">
                  <div className="d-flex justify-content-between gap-2">
                  <div className="d-flex flex-row margin-top-cl">
                    <div>
                      <SecondaryBtnRb
                        secondaryBtnText={`All`}
                        className={
                          selectedBtn === "all-btn"
                            ? "primary-active-btn ennable-paused-btns-height"
                            : "primary-inactive-btn ennable-paused-btns-height"
                        }
                        id="all-btn"
                        onClick={(e) =>
                          onEnablePausedButtonhandler(e, "target_status", "ALL")
                        }
                      ></SecondaryBtnRb>
                    </div>
                    <div>
                      <SecondaryBtnRb
                        secondaryBtnText={`Enabled (${rowCount?.enabled_count
                          ? rowCount?.enabled_count
                          : 0
                          })`}
                        className={
                          selectedBtn === "enabled-btn"
                            ? "primary-active-btn ennable-paused-btns-height"
                            : "primary-inactive-btn ennable-paused-btns-height"
                        }
                        id="enabled-btn"
                        onClick={(e) =>
                          onEnablePausedButtonhandler(e, "target_status", "1")
                        }
                      ></SecondaryBtnRb>
                    </div>
                    <div>
                      <SecondaryBtnRb
                        secondaryBtnText={`Paused (${rowCount?.paused_count
                          ? rowCount?.paused_count
                          : 0
                          })`}
                        className={
                          selectedBtn === "paused-btn"
                            ? "primary-active-btn ennable-paused-btns-height"
                            : "primary-inactive-btn ennable-paused-btns-height"
                        }
                        id="paused-btn"
                        value="PAUSED"
                        onClick={(e) =>
                          onEnablePausedButtonhandler(e, "target_status", "0")
                        }
                      ></SecondaryBtnRb>
                    </div>
                    </div>
                    <div className="filter-icon-container-main margin-left-mt-filter-icon-container">
                    <div
                      className={
                        minMaxFilterActivated
                          ? "activated-min-max-filter"
                          : "hide"
                      }
                    ></div>
                    <img src={settingIcon} onClick={onFilterIconhandler}></img>
                  </div>
                  </div>
                  
                  <div className="d-flex flex-row margin-top-cl">
                    <div>
                      <SecondaryBtnRb
                        secondaryBtnText={`Global`}
                        className={
                          selectedViewType === "Parent"
                            ? "primary-active-btn ennable-paused-btns-height"
                            : "primary-inactive-btn ennable-paused-btns-height"
                        }
                        id="Parent"
                        onClick={(e) =>
                          setSelectedViewType("Parent")
                        }
                      ></SecondaryBtnRb>
                    </div>
                    <div>
                      <SecondaryBtnRb
                        secondaryBtnText={`Granular`}
                        className={
                          selectedViewType === "Child"
                            ? "primary-active-btn ennable-paused-btns-height m-0"
                            : "primary-inactive-btn ennable-paused-btns-height m-0"
                        }
                        id="Child"
                        onClick={(e) =>
                          setSelectedViewType("Child")
                        }
                      ></SecondaryBtnRb>
                    </div>
                    </div>
                </div>
                <div className="margin-top-cl">
                </div>
              </Stack>
            </Stack>
            <Stack
              className={
                droDownFilterDataForApi.length ? "pt-3 d-flex flex-row" : "hide"
              }
            >
              <FilterChips
                filterChipList={droDownFilterDataForApi}
                onCancelClickRemoveFilteringHandler={(fieldNameForAPIFilter) =>
                  onCancelClickRemoveFilteringHandlerr(fieldNameForAPIFilter)
                }
                onResetButtonClick={
                  onPortfolioCampaignAdgroupOuterResetBtnHandler
                }
                isResetButtonNeeded={true}

              ></FilterChips>
            </Stack>
          </div>
          <div className="d-flex justify-content-end w-100 mt-5">
                <Link to={"#"} className={"text-muted"} style={{fontSize:"12px"}}>* SB optimisation not supported</Link>
            </div>
          <div className="paginnation">
            <div className="targeting-list-table-data">
              <Stack className="targeting-list-main-table">
                <TableRb tableHeads={customDataHeads} noDataFound={!targetingDataForTable?.length && !isLoaderVisible && !initialLoad} shimmer={isLoaderVisible} table={tableBodyToRender}></TableRb>
                {/* <div className="paginnationn-sec-box">
                  <div className="d-flex flex-row">
                    <div className="paginnation-static-text">1-50 of more</div>

                    <div className="pagination-arrow-conntainer">
                      <span>
                        <img
                          src={
                            paginationAction.currentPageCount == 1
                              ? paginationnLeftParentArrowIcon
                              : paginationnRightParentArrowIcon
                          }
                          onClick={() =>
                            getPaginationOnForwordArrowClickHandler(
                              "onFirstPage"
                            )
                          }
                          className={
                            paginationAction.currentPageCount == 1
                              ? "cursor-pointer-global"
                              : "turn-arrow-black cursor-pointer-global"
                          }
                        ></img>
                      </span>
                      <span>
                        <img
                          src={paginationnLefttChildArrowIcon}
                          onClick={() =>
                            getPaginationOnForwordArrowClickHandler("backword")
                          }
                          className="cursor-pointer-global"
                        ></img>
                      </span>
                      <span>
                        <span className="paginnation-count-text">{`${paginationAction.currentPageCount} of more`}</span>
                      </span>
                      <span>
                        <img
                          src={paginationnRighttChildArrowIcon}
                          onClick={() =>
                            getPaginationOnForwordArrowClickHandler("forword")
                          }
                          className="cursor-pointer-global"
                        ></img>
                      </span>
                      <span>
                        <img
                          className="cursor-pointer-global"
                          src={paginationnRightParentArrowIcon}
                        ></img>
                      </span>
                    </div>
                  </div>
                </div> */}
              </Stack>
            </div>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default MasterTargetingList;
