import { useEffect, useRef, useState } from "react";
import RadioInputGroup from "../radioInputGroup/radioInputGroup";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  registerables
} from "chart.js";

import { Line } from "react-chartjs-2";
import LegendComponent from "../legend/legend";
import moment from "moment/moment";
import "./lineGraph.css";
import arrowdown from '../../assets/icons/down_arrow.svg'
import { masterApi } from "../../apis/masterApi";
import Loader from "../loader";
import ReactApexChart from "react-apexcharts";
import { useGlobalContext } from "../../context/global";
import { useNavigate } from "react-router-dom";
import redirect from "../../constant/Redirect";
import weekToDays from "../../helper/common/weekToDays";
import tooltiporange from "../../assets/icons/tooltiporange.png";
import tooltipblue from "../../assets/icons/tooltipblue.png";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getCurrencySymbol } from "../../constant/defaultCurrencyConfigurations";
import {encodeData} from '../../helper/common/encodeData';
// ChartJS.register(
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement
// );
ChartJS.register(...registerables,ChartDataLabels);

// Changing the default formatting of moment
moment.defaultFormat = "DD-MM-YYYY";

export default function LineGraph({ headerText, noHeader, noTopMargin , portfolios , portfoliosIds }) {
  const [tooltipData, setTooltipData] = useState(null);
  const chartRef = useRef(null);
  const [timeType, setTimeType] = useState("Day");
  const [lineGraphData, setLineGraphData] = useState();
  const [apiResult, setApiResult] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [series1Data, setSeries1Data] = useState([]);
  const [series2Data, setSeries2Data] = useState([]);
  const [datesOnXAxis, setDatesOnXAxis] = useState([]);
  const [tooltipDate, setTooltipDate] = useState([]);
  const navigate = useNavigate();

  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );

  const currencySymbol = getCurrencySymbol()

  let selectHeading = [
    "Spends",
    "Impressions",
    "Clicks",
    "Sale_Value",
    "CTR",
    "CVR",
    "CPM",
    "AOV",
    "CPC",
    "Orders",
    "ROAS",
    "ACOS",
  ];
  let selectHeadingFirst = [
    "Spends",
    "Clicks",
    "Sale_Value",
    "CTR",
    "CVR",
    "CPM",
    "AOV",
    "CPC",
    "ROAS",
    "ACOS",
  ];
  let selectHeadingSecond = [
    "Impressions",
    "Clicks",
    "Sale_Value",
    "CTR",
    "CVR",
    "CPM",
    "AOV",
    "CPC",
    "ROAS",
    "ACOS",
  ];
  const [firstSelectOptions, setFirstSelectOptions] = useState([
    ...selectHeading,
  ]);
  const [secondSelectOptions, setSecondSelectOptions] = useState([
    ...selectHeading,
  ]);
  const [firstSelectedOtion, setFirstSelectedOtion] = useState(
    String("Spends")
  );
  const [secondSelectedOption, setSecondSelectedOption] = useState(
    String("Impressions")
  );
  const { dateDifference, setDateDifference, calendarDate, setCalendarDate,applyCalendar } =
    useGlobalContext();
  const [firstDropDown, setFirstDropDown] = useState(false);
  const closeRef = useRef();
  const [Brandoption, setBrandoption] = useState(false);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);

  const [secondDropDown, setSecondDropDown] = useState(false);

  const [loaderVisible, setLoaderVisible] = useState(false);
  const [lineGraphOption, setLineGraphOption] = useState({
    //   interaction: {
    //     mode: 'nearest'
    // },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            //  return `₹${tooltipItem?.raw}`;
          },
          title: function (tooltipItem) {
            return `${currencySymbol}${tooltipItem[0].raw}`;
          },
          style: {
            color: "red !impotanet",
          },
        },
        enabled: true,
        xAlign: "center",
        yAlign: "bottom",
        titleAlign: "center",
        backgroundColor: "#403D51",
        titleColor: "#FFFFFF",
        padding: 12,
        // external: customTooltip,
      },
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          // display: false
        },
        // callback:(value, index, ticks)=>{return ""+value},
        ticks: {
          stepSize: 40,
          padding: 10,
          callback: (value, index, ticks) => {
            return currencySymbol + parseInt(value);
          },

          // align:"center",
          color: "#132A30",
        },
        display: true,
        title: {
          display: true,
          text: "Spends",
        },
        min: 0,
        max: 200,
        // position:"center",
      },
      y1: {
        min: 0,
        max: 200,
        grid: {
          drawBorder: false,
          // display: false
        },
        // callback:(value, index, ticks)=>{return ""+value},
        ticks: {
          // reverse:true,
          stepSize: 40,
          padding: 10,
          callback: (value, index, ticks) => {
            return currencySymbol + parseInt(value);
          },

          color: "#132A30",
        },
        display: true,
        title: {
          align: "center",
          display: true,
          text: "Clicks",
          // callback:(value, index, ticks)=>{return "$"+value},
        },
        position: "right",
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            w.globals.labels[dataPointIndex] +
            ": " +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "</div>"
          );
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
        },
        // position:"right",
        display: false,
        // position:{y: 30},
        title: {
          display: true,
          text: "Revenue",
        },
        labels: {
          show: false,
        },
      },
    },
    maintainAspectRatio: true,
  });

  const options = {
    responsive:true,
    maintainAspectRatio:false,
    layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 10
      }
    },
    animation:{
      duration:0
    },
    hover:{
      intersect:false,
      mode:"index"
    },
    plugins :{
      datalabels: { // This code is used to display data values
        display: false
    },
      legend:{
        display:false,
      },
      tooltip:{
        mode:"index",
        intersect:false,
        displayColors:true,
        usePointStyle:true,
        callbacks: {
          labelColor: function(context) {
            const colorOfBackground = context.datasetIndex === 1 ? "rgb(112, 189, 204)":"#1D216E"
              return {
                  borderColor: '#fff',
                  backgroundColor: colorOfBackground,
                  borderWidth: 2,
                  borderRadius: 2,
              };
          }
        }
      }, 
    },
    scales:{
     y:{
      type:"linear",
      display:true,
      position:"left",
      // pointStyle: "circle", 
      label:{
        display:true,
        align:"left"
      },
      title:{
        text:firstSelectedOtion,
        display:true,
        font: {
          weight: "bold" // Adding bold font weight to the Y-axis title
        }
      },
      fill:false,
      pointRadius:1,
      borderWidth:3,
      tension:0,
     },
     y1:{
      type:"linear",
      display:true,
      title:{
        text:secondSelectedOption,
        display:true,
        font: {
          weight: "bold" // Adding bold font weight to the Y-axis title
        }
      },
      position:"right",
      // pointStyle: "circle",
      fill:false,
      pointRadius:1,
      borderWidth:3,
      tension:0,
      grid: {
        display:false
      },  
     },
  x:{
    grid:{
      display:false
    }
  }

    }
  }
  const data = {
    labels:datesOnXAxis,
    datasets:[
     { 
      label:firstSelectedOtion,
      data:series1Data,
      yAxisID: "y",
      borderColor: "#1D216E",//7078CC
      backgroundColor: "#1D216E",//7078CC
      title:{
        text:firstSelectedOtion,
        display:true
      },  
      // pointStyle: "circle",
      lineTension:0,
      fill:false,
      // pointRadius:3,
      borderWidth:2,
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      pointHoverBackgroundColor: '#1D216E',
      pointHoverBorderColor: '#1D216E',
    },
    { 
      label:secondSelectedOption,
      data:series2Data,
      yAxisID: "y1",
      borderColor: "rgb(112, 189, 204)",
      // pointStyle: "circle",
      backgroundColor:"rgb(112, 189, 204)",
      lineTension:0,
      fill:false,
      // pointRadius:3,
      borderWidth:2,
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      pointHoverBackgroundColor: 'rgb(112, 189, 204)',
      pointHoverBorderColor: 'rgb(112, 189, 204)'
    }
    ]
  }

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  async function TrendGraphApi() {
    setLoaderVisible(true);
    const portfolioFiltering = [{field_name:'portfolio_name',operator:"IN",values:portfolios}]
    try {
      const data = {
        level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        fields: `${secondSelectedOption.toLowerCase()},${firstSelectedOtion.toLowerCase()},date`,
        time_increment: timeType,
        order_by: "date",
        comparison_data: "data",
        ...(portfolios?.length && {filtering:encodeData(portfolioFiltering)})
      };
      // Changing the Time format using Moment JS.
      // On everytime the selected options from each of the array will get change the function will get trigger with current timeType and selected options and  vice versa for timeType.

      switch (timeType.toLowerCase()) {
        case "day":
          // data["date_preset"] = "last_7d";
          data["time_increment"] = "day";
          data["order_by"] = "date";
          data["sorting_order"] = "asc";
          data[
            "fields"
          ] = `${secondSelectedOption.toLowerCase()},${firstSelectedOtion.toLowerCase()},date`;
          // setStartDate(moment().subtract(1,"day").format("DD-MM-YYYY"));
          // setEndDate(moment().format("DD-MM-YYYY"));
          data["startDate"] = `${formatDateFn(calendarDate[0].startDate)}`;
          data["endDate"] = `${formatDateFn(calendarDate[0].endDate)}`;
          break;
        case "week":
          // data["date_preset"] = "last_30d";
          data["time_increment"] = "week";
          data["order_by"] = "week";
          data["sorting_order"] = "asc";
          data[
            "fields"
          ] = `${secondSelectedOption.toLowerCase()},${firstSelectedOtion.toLowerCase()},week`;
          // setStartDate(moment().subtract(7,"days").format("DD-MM-YYYY"));
          // setEndDate(moment().format("DD-MM-YYYY"));
          data["startDate"] = `${formatDateFn(calendarDate[0].startDate)}`;
          data["endDate"] = `${formatDateFn(calendarDate[0].endDate)}`;
          break;
        case "month":
          data["time_increment"] = "month";
          data["order_by"] = "month";
          data[
            "fields"
          ] = `${secondSelectedOption.toLowerCase()},${firstSelectedOtion.toLowerCase()},month`;
          data["sorting_order"] = "desc";
          // setStartDate(moment().subtract(1,"month").format("DD-MM-YYYY"));
          // setEndDate(moment().format("DD-MM-YYYY"));
          data["startDate"] = `${formatDateFn(calendarDate[0].startDate)}`;
          data["endDate"] = `${formatDateFn(calendarDate[0].endDate)}`;
          break;
        default:
          // data["date_preset"] = "last_7d";
          data["time_increment"] = "day";
          data["order_by"] = "date";
          data["sorting_order"] = "asc";
          data[
            "fields"
          ] = `${secondSelectedOption.toLowerCase()},${firstSelectedOtion.toLowerCase()},month`;
          // setStartDate("01-07-2022");
          // setEndDate("17-08-2022");

          data["startDate"] = `${formatDateFn(calendarDate[0].startDate)}`;
          data["endDate"] = `${formatDateFn(calendarDate[0].endDate)}`;
          break;
      }

      const result = await masterApi(data);
      if (redirect(result)) return navigate("/login");
      setApiResult(result.data);
      setLoaderVisible(false);
      return result;
    } catch (e) {
      // console.warn("Error in Trend Api :-", e);
      setLoaderVisible(false);
    }
  }

  useEffect(() => {
    // Load the Graph on every state changes
    TrendGraphApi();
  }, [timeType, firstSelectedOtion, secondSelectedOption, applyCalendar , portfolios]);//[timeType, firstSelectedOtion, secondSelectedOption, calendarDate]

  // Function To convert Date for X-axis according to Timetype selected.

  const convertDates = (datesArray, timeType) => {
    switch (timeType) {
      case "Day":
        setDatesOnXAxis(datesArray);
        break;
      case "Week":
        const convertedArray = weekToDays(datesArray)
        setDatesOnXAxis(convertedArray);
        break;
      case "Month":
        setDatesOnXAxis(datesArray);
        break;
      default:
        break;
    }
  };

  const compareDates = (a, b) => {
    const [monthA, yearA] = a.split(', ');
    const [monthB, yearB] = b.split(', ');
  
    // Compare years first
    if (yearA !== yearB) {
      return yearA.localeCompare(yearB);
    }
  
    // If years are the same, compare months
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const monthIndexA = months.indexOf(monthA);
    const monthIndexB = months.indexOf(monthB);
  
    return monthIndexA - monthIndexB;
  };

  function convertMonthToDate(month) {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames.indexOf(month.split(", ")[0]);
}

  

  // Defining arrays for series in apex charts

  let array1;
  let array2;

  useEffect(() => {
    // Checking if the API result has property own data if yes then this will get trigger
    const titles = ["CTR", "CVR", "CPM", "CPC", "ROAS", "ACOS"];
    if (apiResult?.hasOwnProperty("data")) {
      const structuredData = structuredClone(apiResult) ?? JSON.stringify(JSON.parse(apiResult))
      const newData = timeType === "Month" ? [...structuredData?.data]?.sort((a,b) => {
        const dateA = convertMonthToDate(a.month);
        const dateB = convertMonthToDate(b.month);
        return dateA - dateB;
      }):apiResult?.data;
      const spends = newData.map((item) => {
        return titles.includes(firstSelectedOtion.toUpperCase()) ? (item[firstSelectedOtion.toLowerCase()]).toFixed(2) : Math.round(item[firstSelectedOtion.toLowerCase()]);
      });
      const clicks = newData.map((item) => {
        return titles.includes(secondSelectedOption.toUpperCase()) ? (item[secondSelectedOption.toLowerCase()]).toFixed(2) : Math.round(item[secondSelectedOption.toLowerCase()]);
      });
      const dates = newData.map((item) => {
        if (timeType === "Day") {
          return item.date;
        } else if (timeType === "Week") {
          return item.week;
        } else if (timeType === "Month") {
          return item.month;
        }
      });
      
      let final_dates = timeType === "Month" ? dates.sort(compareDates) : dates
      setTooltipDate(final_dates);

      convertDates(final_dates, timeType);
      const min = Math.min(...[...spends, ...clicks]);
      const max = Math.max(...[...spends, ...clicks]);
      const options = { ...lineGraphOption };
      options["scales"]["y"]["min"] = min;
      options["scales"]["y"]["max"] = max;
      options["scales"]["y1"]["min"] = min;
      options["scales"]["y1"]["max"] = max;
      options["scales"]["y1"]["ticks"]["stepSize"] = max / 6;
      options["scales"]["y"]["ticks"]["stepSize"] = max / 6;
      options["scales"]["y"]["title"]["text"] = firstSelectedOtion;
      options["scales"]["y1"]["title"]["text"] = secondSelectedOption;
      setLineGraphOption(options);
      array1 = [
        ...spends.map((item) => {
          if (isNaN(item)) {
            return (item = 0);
          }
          return item;
        }),
      ];
      array2 = [
        ...clicks.map((item) => {
          if (isNaN(item)) {
            return (item = 0);
          }
          return item;
        }),
      ];
      // Validation for checking if all the items in the array are 1 if yes then make zero for apex chart render
      let checker1 = array1.every((item) => item === 1);
      let checker2 = array2.every((item) => item === 1);
      // Taking two more variables for storing values i.e array3 or array4.
      let array3;
      let array4;
      array3 = checker1 ? array1.map((item) => (item = 0)) : array1;
      array4 = checker2 ? array2.map((item) => (item = 0)) : array2;
      setSeries1Data(array3);
      setSeries2Data(array4);
      let data = {
        labels: [...spends],
        datasets: [
          {
            label: firstSelectedOtion,
            data: [...spends],
            fill: true,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.5,
            responsive: true,
            yAxisID: "y",
          },
          {
            label: secondSelectedOption,
            data: [...clicks],
            fill: true,
            borderColor: "#FF8566",
            tension: 0.5,
            responsive: true,
            yAxisID: "y1",
          },
        ],
      };
      setLineGraphData(data);
    }
  }, [apiResult]);
  const handleFirstSelectChange = (value) => {
    setFirstSelectedOtion(value);
    setSecondSelectOptions((current) =>
      current.filter((item) => item !== value)
    );
    setFirstSelectOptions([...selectHeading]);
  };

  const handleSecondSelectChange = (value) => {
    setSecondSelectedOption(value);
    setFirstSelectOptions((current) =>
      current.filter((item) => item !== value)
    );
    setSecondSelectOptions([...selectHeading]);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        firstDropDown &&
        closeRef.current &&
        !closeRef.current.contains(e.target)
      ) {
        setFirstDropDown(false);
      }
      if (
        secondDropDown &&
        closeRef.current &&
        !closeRef.current.contains(e.target)
      ) {
        setSecondDropDown(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [firstDropDown, secondDropDown]);
  return (
    <>
      <Loader visible={loaderVisible}></Loader>
      <div className="line-graph-main"
        style={{ marginTop: noTopMargin ? '0px' : '' }}
      >
        {!(noHeader) && <div class={"line-graph-heading"}>
          {headerText ? headerText : "Trend Graph"}
        </div>}
        <div className="line-graph-container">

        <div className="position-relative-container">
          <div className="line-graph-heading text-sizes mb-4 align-right">
            {
              <>
                <div
                  className={"select-line-container pt-1 d-flex flex-grow-1"}
                >
                  <div className="dash-drodown-btn-container">
                    <div className="dropdown" id="dropdown" ref={closeRef}>
                      <div
                        className="dropdown-btn spent-color"
                        id="dropdown-btn"
                        onClick={(e) => {
                          setFirstDropDown(!firstDropDown);
                          setBrandoption(false);
                          setShowCustomDateBox(false);
                        }}
                      >
                        {firstSelectedOtion === "Sale_Value"
                          ? "Revenue"
                          : firstSelectedOtion}
                        <span style={{ paddingLeft: "20px" }}>
                          {" "}
                          <img src={arrowdown}></img>
                        </span>
                      </div>
                      {firstDropDown && (
                        <div className="dropdown-content">
                          {firstSelectOptions.map((ele) => (
                            <>
                              <div
                                className="dropdown-item"
                                onMouseDown={(e) => {
                                  handleFirstSelectChange(ele);
                                  setFirstDropDown(false);
                                }}
                                // onMouseDown={(e) => {console.log(e)}}
                                key={Math.random() * 10000}
                              >
                                {ele === "Sale_Value" ? "Revenue" : ele}
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="dash-drodown-btn-container">
                    <div className="dropdown" id="dropdown" ref={closeRef}>
                      <div
                        className="dropdown-btn impression-color"
                        id="dropdown-btn"
                        onClick={(e) => {
                          setSecondDropDown(!secondDropDown);
                          setBrandoption(false);
                          setShowCustomDateBox(false);
                          setFirstDropDown(false);
                        }}
                      >
                        {secondSelectedOption === "Sale_Value"
                          ? "Revenue"
                          : secondSelectedOption}
                        <span style={{ paddingLeft: "20px" }}>
                          {" "}
                          <img src={arrowdown}></img>
                        </span>
                      </div>
                      {secondDropDown && (
                        <div className="dropdown-content">
                          {secondSelectOptions.map((ele) => (
                            <>
                              <div
                                className="dropdown-item"
                                onClick={(e) => {
                                  handleSecondSelectChange(ele);
                                  setSecondDropDown(false);
                                }}
                                key={Math.random() * 10000}
                              >
                                {ele === "Sale_Value" ? "Revenue" : ele}
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
            <div>
              <RadioInputGroup
                state={timeType}
                setState={setTimeType}
                name="timeType"
                radios={["Day", "Week", "Month"]}
                required
              ></RadioInputGroup>
            </div>
          </div>
          <div className="position-relative chart-wrapper-div"> 
            {/* <center> */}
              {lineGraphData ? (
                <>
                  {/* <ReactApexChart
                    id="chart"
                    height={280}
                    width={dashboardSideBarVisibiltyState ? "90%" : "100%"}
                    series={[
                      {
                        name: firstSelectedOtion,
                        type: "line",
                        data: series1Data,
                      },
                      {
                        name: secondSelectedOption,
                        type: "line",
                        data: series2Data,
                      },
                    ]}
                    options={{
                      chart: {
                        type: "line",
                        stacked: false,
                        toolbar: {
                          show: false,
                        },
                      },
                      xaxis: {
                        type: "category",
                        categories: datesOnXAxis,
                        labels: {
                          show: true,
                        },
                      },
                      tooltip: {
                        enabled: true,
                        followCursor: false,
                        shared: false,
                        intersect: true,
                        onDatasetHover: {
                          highlightDataSeries: false,
                        },
                        x: {
                          show: true,
                        },
                        custom: function ({
                          series,
                          seriesIndex,
                          dataPointIndex,
                          w,
                        }) {
                          let firstName = w.globals.initialSeries[0].name;
                          let secondName = w.globals.initialSeries[1].name;
                          let num =
                            w.globals.initialSeries[0].data[dataPointIndex];
                          let seconNum =
                            w.globals.initialSeries[1].data[dataPointIndex];
                            console.log(num,seconNum,"Graph Data")
                          let firtValue =
                            Math.abs(num) > 999
                              ? Math.sign(num) *
                                  (Math.abs(num) / 1000).toFixed(1) +
                                "k"
                              : Number(num)
                          let secondValue =
                            Math.abs(seconNum) > 999
                              ? Math.sign(seconNum) *
                                  (Math.abs(seconNum) / 1000).toFixed(1) +
                                "k"
                              : Number(seconNum)
                          let month = tooltipDate[dataPointIndex].split("-")[1];
                          let date = tooltipDate[dataPointIndex].split("-")[2];
                          let year = tooltipDate[dataPointIndex].split("-")[0];
                          let currentmonth;
                          switch (month) {
                            case "01":
                              currentmonth = "Jan";
                              break;
                            case "02":
                              currentmonth = "Feb";
                              break;
                            case "03":
                              currentmonth = "Mar";
                              break;
                            case "04":
                              currentmonth = "Apr";
                              break;
                            case "05":
                              currentmonth = "May";
                              break;
                            case "06":
                              currentmonth = "Jun";
                              break;
                            case "07":
                              currentmonth = "Jul";
                              break;
                            case "08":
                              currentmonth = "Aug";
                              break;
                            case "09":
                              currentmonth = "Sep";
                              break;
                            case "10":
                              currentmonth = "Oct";
                              break;
                            case "11":
                              currentmonth = "Nav";
                              break;
                            case "12":
                              currentmonth = "Dec";
                              break;
                            default:
                              break
                          }

                          return (
                            '<div class="arrow_box">' +
                            "<span class=arrow_box_date>" +
                            // "₹" +
                            currentmonth +
                            " " +
                            date +
                            "," +
                            year +
                            "</span>" +
                            "<span>" +
                            // "₹" +
                            "<img src='' />" +
                            firstName +
                            ":" +
                            firtValue +
                            "</span>" +
                            "<span>" +
                            // "₹" +
                            secondName +
                            ":" +
                            secondValue +
                            "</span>" +
                            "</div>"
                          );
                        },
                      },
                      legend: {
                        show: false,
                      },
                      colors: ["#A397DB", "#70BDCC"],
                      markers: {
                        size: 5,
                        colors: undefined,
                        strokeColors: "#fff",
                        strokeWidth: 2,
                        strokeOpacity: 0.9,
                        strokeDashArray: 0,
                        fillOpacity: 1,
                        discrete: [],
                        shape: "circle",
                        radius: 2,
                        offsetX: 0,
                        offsetY: 0,
                        onClick: undefined,
                        onDblClick: undefined,
                        showNullDataPoints: true,
                        hover: {
                          size: undefined,
                          sizeOffset: 3,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        width: [4, 4],
                      },
                      yaxis: [
                        {
                          axisTicks: {
                            show: false,
                          },
                          axisBorder: {
                            show: false,
                            color: "#008FFB",
                          },
                          labels: {
                            style: {
                              colors: "#132A30",
                              fontSize: "14px",
                              fontWeight: 500,
                            },
                            // formatter:(value) => {return "₹" + value}
                          },
                          title: {
                            text:
                              firstSelectedOtion === "Sale_Value"
                                ? "Revenue"
                                : firstSelectedOtion,
                            offsetX: 2,
                            style: {
                              color: "#132A30",
                              fontSize: "16px",
                              lineHeight: "20px",
                              fontWeight: 500,
                              cssClass: "apexcharts-yaxis-title",
                            },
                          },
                          tooltip: {
                            enabled: false,
                          },
                        },
                        {
                          seriesName: "Income",
                          opposite: true,
                          axisTicks: {
                            show: false,
                          },
                          axisBorder: {
                            show: false,
                            color: "#00E396",
                          },
                          tooltip: {
                            enabled: false,
                          },
                          labels: {
                            style: {
                              colors: "#132A30",
                              fontSize: "14px",
                              fontWeight: 500,
                            },
                            // formatter:(value) => {return "₹" + value  }
                          },
                          title: {
                            text:
                              secondSelectedOption === "Sale_Value"
                                ? "Revenue"
                                : secondSelectedOption,
                            style: {
                              color: "#132A30",
                              fontSize: "16px",
                              lineHeight: "20px",
                              fontWeight: 500,
                            },
                          },
                        },
                      ],
                    }}
                  /> */}
                  <Line data={data}  options={options}/>
                  {/* {tooltipData ? <GraphTooltip data={tooltipData} ></GraphTooltip> : <div></div >} */}
                </>
              ) : (
                "Loading"
              )}
            {/* </center> */}
          </div>
          <div className="line-graph-foter">
            <div>
              *Data shown from{" "}
              {moment(calendarDate[0].startDate, "DD-MM-YYYY").format(
                "Do MMMM, YYYY"
              )}{" "}
              to{" "}
              {moment(calendarDate[0].endDate, "DD-MM-YYYY").format(
                "Do MMMM, YYYY"
              )}
            </div>
            {/* <div className="d-flex gap-3">
              <LegendComponent
                legendColor={"#6FC8FB"}
                legendText={
                  firstSelectedOtion === "Sale_Value"
                    ? "Revenue"
                    : firstSelectedOtion
                }
              ></LegendComponent>
              <LegendComponent
                legendColor={"#FF8566"}
                legendText={
                  secondSelectedOption === "Sale_Value"
                    ? "Revenue"
                    : secondSelectedOption
                }
              ></LegendComponent>
            </div> */}
            {/*Use Legend Component here  */}
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
