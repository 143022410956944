export default async function updateFileUrl (url) {
    const accoundIdNumeric = JSON.parse(localStorage.getItem('accountIdNumeric'));
    if(url) {
        try {
            const apiUrl = `${process.env.REACT_APP_ADS_API}/accounts/${accoundIdNumeric}?type=amazon_ad_account`;
            const options = {
                method:"PATCH",
                headers:{
                    'Content-Type':"application/merge-patch+json",
                    Authorization: getCookieValue('_auth'),
                },
                body:JSON.stringify({"vendor_catalog_csv_url":`${url}`})
            }
            const response = await fetch(apiUrl,options);       
            const result = await response.json();
            return result
        } catch (error) {
            return error
        }
    }   
}

function getCookieValue(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
    return '';
  }