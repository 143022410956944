import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import ProductImage1 from "../../../assets/images/SP_ProductImage/Product1.png";
import AddProductButton from "../../../assets/svgs/plus_box.svg";
import DeleteProductButton from "../../../assets/images/SP_ProductImage/DeleteProductButton.png";
import AsinsTable from "../CampaignCreationPages/SP_Product_Targeting/AsinsTable";
import TableRb from "../../Tables/tableRb";
import { useGlobalContext } from "../../../context/global";
import { useAuthHeader } from "react-auth-kit";
import { getCurrencySymbol } from "../../../constant/defaultCurrencyConfigurations";



const SDProductProspectAdGroupTable = () => {
  const [isVisible, setIsVisible] = useState(false);
  const SPProductTargetingProductData = useSelector(
    (state) => state.CampaignCreationData.SPProductTargetingProductData
  );
  const {
    manuallyAddedAsins,
    adsetAsins,
    page,
    sdSuggestedCategories,
    setSdSuggestedCategories,
    sdSelectedSuggestedCategories,
    setSdSelectedSuggestedCategories,
    sdAsins,
    setSdAsins,
    setSdWholeCategories,
    sdWholeCategories,
    clientId,
    selectedCategory,
    clientData,
    setAdGroupData,
    adGroupData,
    createCampaignErrors,
    userToken
  } = useGlobalContext();

  // Refs
  const default_bid = JSON.parse(localStorage.getItem("defaultBid"));
  const authHeader  = useAuthHeader();
  const token = authHeader();

  const currencySymbol = getCurrencySymbol()

  // Methods

  const radioChange = (e) => {
    const mainBtn = e.target.closest(".redioButton-box");
    const allBtn = mainBtn.querySelectorAll(".redioButton");
    allBtn.forEach((item) => {
      item.classList.remove("active");
    });
    const add = e.target.closest(".redioButton");
    add.classList.add("active");
    add.children[0].children[0].children[0].checked = true;
  };

  const getSuggestedCategories = async (dataPassed) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken
      },
      body: JSON.stringify(dataPassed),
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADS_API}/client/${clientId}:fetch-suggested-product-categories?source=amazon`,
        options
      );
      const result = await response.json();
      return result?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getBidRecommendation = async (dataPassed) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userToken}`,
      },
      body: JSON.stringify(dataPassed),
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADS_API}/client/${clientId}:fetch-bid-recommendation?source=amazon`,
        options
      );
      const result = await response.json();
      return result?.data;
    } catch (error) {
      console.log(error);
    }
  };

  function getModifiedData(array1, array2) {
    let suggestedCategoriesDataToBeShown = [];
    array1.forEach((category) => {
      array2.forEach((categorywithbid) => {
        if (
          category?.category_id ===
          categorywithbid.targeting_clauses.expressions[0].value
        ) {
          const {
            category_id,
            category_name,
            category_product_lower_range,
            category_product_upper_range,
          } = category;
          const {
            recommended_suggested_bid,
            upper_suggested_bid,
            lower_suggested_bid,
          } = categorywithbid;
          suggestedCategoriesDataToBeShown.push({
            category_id,
            category_name,
            category_product_lower_range,
            category_product_upper_range,
            recommended_suggested_bid,
            upper_suggested_bid,
            lower_suggested_bid,
          });
        }
      });
    });
    return suggestedCategoriesDataToBeShown;
  }

  const fetchData = async () => {
    // Data for API
    const data = {
      type: "SD",
      categories:Array.isArray(selectedCategory)?selectedCategory:[selectedCategory]
    };

    // API Call to be made Here
    // Result 1 is suggested Categories
    const result = await getSuggestedCategories(data);
    const allIds = result.map((resultId) => resultId?.category_id);
    const mainDataToBePosted = allIds.map((id) => {
      return {
        expressions: [
          {
            type: "asinCategorySameAs",
            value: id,
          },
        ],
      };
    });
    const dataToBePosted = {
      type: "SD_CATEGORY",
      categories: Array.isArray(selectedCategory)?selectedCategory:[selectedCategory],
      targeting_clauses: mainDataToBePosted,
    };
    const result2 = await getBidRecommendation(dataToBePosted);

    const suggestedCategoriesDataToBeShown = getModifiedData(result, result2);

    // API Result will be here

    setSdSuggestedCategories([...suggestedCategoriesDataToBeShown]);
  };

  const handleAddProduct = async (id) => {
    // Get the clicked product from the ID
    const findedCategory = sdSuggestedCategories.filter((category) => {
      return category.category_id === id;
    });

    // Check If the product is alreay added in the another Table
    // const isCategoryAdded = sdSelectedSuggestedCategories.filter((category) => {
    //   return findedCategory[0].category_name === category.name
    // })

    const isCategoryAdded = sdSelectedSuggestedCategories.filter((category) => {
      return category.category_id === findedCategory[0].category_id;
    });

    // If Product is not added in the Table Then Add It.
    if (isCategoryAdded.length === 0) {
      const {
        category_name,
        category_product_upper_range,
        category_id,
        recommended_suggested_bid,
        category_product_lower_range,
        upper_suggested_bid,
        lower_suggested_bid,
      } = findedCategory[0];
      // New Object.
      const modifiedProduct = {
        status: "ENABLED",
        category_id: category_id,
        recommended_suggested_bid: recommended_suggested_bid ?? default_bid,
        category_product_upper_range,
        category_product_lower_range,
        upper_suggested_bid,
        lower_suggested_bid,
        category_name,
      };
      // setAdGroupData({...campaignData,"amz_product_targeting":[...spSelectedSuggestedCategories,modifiedProduct]})
      setSdSelectedSuggestedCategories([
        ...sdSelectedSuggestedCategories,
        modifiedProduct,
      ]);
      const anotherModified = {
        status: "ENABLED",
        expression: [
          {
            type: "category",
            value: category_id,
          },
        ],
        bid: recommended_suggested_bid ?? default_bid,
      };
      setSdWholeCategories([...sdWholeCategories, anotherModified]);
      setAdGroupData({
        ...adGroupData,
        amz_product_targeting: [...sdWholeCategories, anotherModified],
      });
    } else {
      alert("The product is already added");
    }
  };

  // Function to remove all the selected Categories.
  const deleteAll = () => {
    setSdSelectedSuggestedCategories([]);

    setSdWholeCategories((current) => {
      return current.filter((category) => {
        return category.expression[0].type !== "category";
      });
    });
    setAdGroupData((current) => {
      const copy = { ...current };
      let updatedArray = copy["amz_product_targeting"].filter((product) => {
        return product.expression[0].type !== "category";
      });
      copy["amz_product_targeting"] = updatedArray;
      return copy;
    });
  };

  // Function to remove selected category
  const handleDeleteProduct = (id) => {
    setSdSelectedSuggestedCategories((current) => {
      return current.filter((category) => {
        return category.category_id !== id;
      });
    });
    setSdWholeCategories((current) => {
      return current.filter((product) => {
        return product.expression[0]?.value !== id;
      });
    });
    setAdGroupData((current) => {
      const copy = { ...current };
      let updatedArray = copy["amz_product_targeting"].filter((product) => {
        return product.expression[0]?.value !== id;
      });
      copy["amz_product_targeting"] = updatedArray;
      return copy;
    });
  };

  useEffect(() => {
    if(selectedCategory!=="" && selectedCategory!==null && selectedCategory!==undefined) {
      fetchData();
    }
  }, [selectedCategory]);

  const handleAddAll = () => {
    const filteredProducts = sdSuggestedCategories.filter((product) => {
      return !sdSelectedSuggestedCategories
        ?.map((category) => category?.category_id)
        ?.includes(product?.category_id);
    });
    const localData = typeArrayMapper(filteredProducts,"local")
    const globalData = typeArrayMapper(filteredProducts,"global")
    setSdSelectedSuggestedCategories([...sdSelectedSuggestedCategories,...localData])
    setSdWholeCategories([...sdWholeCategories,...globalData])
    setAdGroupData({...adGroupData,amz_product_targeting:[...sdWholeCategories,...globalData]})
  };

  function typeArrayMapper(data, type) {
    return data?.map((product) => {
      const {
        category_product_upper_range,
        category_product_lower_range,
        upper_suggested_bid,
        lower_suggested_bid,
        category_name,
        recommended_suggested_bid
      } = product;
      return {
        status: "ENABLED",
        expression: [
          {
            type: "category",
            value: product.category_id,
          },
        ],
        bid: recommended_suggested_bid ?? default_bid,
        ...(type === "local" && {
          category_id: product?.category_id,
          category_product_upper_range,
          category_product_lower_range,
          upper_suggested_bid,
          lower_suggested_bid,
          category_name,
          recommended_suggested_bid:recommended_suggested_bid ?? default_bid
        }),
      };
    });
  }

  let tableBody = (
    <>
      <div className="generic-main-table-body-container">
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="float-left p-3">0 Added</div>
                <div className="float-right">
                <button
                    type="button"
                    disabled={!sdSuggestedCategories.length}
                    onClick={handleAddAll}
                    className={
                      sdSuggestedCategories.length > 0
                        ? "add-all-btn-dark"
                        : "add-all-btn"
                    }
                  >
                    Add All
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-right-white product-table-first">
                <span className="table-header-text mx-3">All Categories</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14756"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14756)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="black"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </th>
              <th id="product-table-header">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="table-header-text mx-3">
                      Suggested Bid
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1178_14756"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1178_14756)">
                        <path
                          d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                          fill="black"
                        />
                        <path
                          d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </span>
                  <svg
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="menu-icon-table"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <path
                      d="M1.36328 11.625C1.14661 11.625 0.967615 11.5543 0.826281 11.413C0.684281 11.271 0.613281 11.0917 0.613281 10.875C0.613281 10.675 0.684281 10.5 0.826281 10.35C0.967615 10.2 1.14661 10.125 1.36328 10.125H17.3633C17.5799 10.125 17.7593 10.2 17.9013 10.35C18.0426 10.5 18.1133 10.675 18.1133 10.875C18.1133 11.0917 18.0426 11.271 17.9013 11.413C17.7593 11.5543 17.5799 11.625 17.3633 11.625H1.36328ZM1.36328 6.75C1.14661 6.75 0.967615 6.679 0.826281 6.537C0.684281 6.39567 0.613281 6.21667 0.613281 6C0.613281 5.78333 0.684281 5.604 0.826281 5.462C0.967615 5.32067 1.14661 5.25 1.36328 5.25H17.3633C17.5799 5.25 17.7593 5.32067 17.9013 5.462C18.0426 5.604 18.1133 5.78333 18.1133 6C18.1133 6.21667 18.0426 6.39567 17.9013 6.537C17.7593 6.679 17.5799 6.75 17.3633 6.75H1.36328ZM1.36328 1.875C1.14661 1.875 0.967615 1.8 0.826281 1.65C0.684281 1.5 0.613281 1.325 0.613281 1.125C0.613281 0.908333 0.684281 0.729333 0.826281 0.588C0.967615 0.446 1.14661 0.375 1.36328 0.375H17.3633C17.5799 0.375 17.7593 0.446 17.9013 0.588C18.0426 0.729333 18.1133 0.908333 18.1133 1.125C18.1133 1.325 18.0426 1.5 17.9013 1.65C17.7593 1.8 17.5799 1.875 17.3633 1.875H1.36328Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {sdSuggestedCategories &&
              sdSuggestedCategories.map((category) => {
                let bidRange = `${category.upper_suggested_bid}-${category.lower_suggested_bid}`;
                let category_range = `${category.category_product_lower_range}-${category.category_product_upper_range}`;
                return (
                  <>
                    <tr key={Math.random() * 10000} className="row-height">
                      <td className="tabledata-tb">
                        <Product
                          image={AddProductButton}
                          Name={category.category_name}
                          Desc={category_range}
                        />
                      </td>
                      <td className="tabledata-tb bids-parent-container">
                        <div className="bids-container">
                          <span>{currencySymbol}{category.recommended_suggested_bid}</span>
                          <span>{currencySymbol}{bidRange}</span>
                        </div>
                        <span className="cursor-pointer-global selected-icon-hover">
                          <img
                            src={AddProductButton}
                            alt="productaddButton"
                            onClick={() =>
                              handleAddProduct(category.category_id)
                            }
                          />
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
          {/* })} */}
        </table>
      </div>
    </>
  );

  let tableBodyAdded = (
    <>
      <div className="generic-main-table-body-container">
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="float-left p-3">
                  {" "}
                  {`${sdSelectedSuggestedCategories.length} Added`}{" "}
                </div>
                <div className="float-right">
                  <button
                    type="button"
                    onClick={deleteAll}
                    className={
                      sdSelectedSuggestedCategories.length > 0
                        ? "remove-all-btn-dark"
                        : "remove-all-btn"
                    }
                  >
                    Remove All
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-right-white product-table-first">
                <span className="table-header-text mx-3">All Categories</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14756"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14756)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="black"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </th>
              <th id="product-table-header">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="table-header-text mx-3">
                      Suggested Bid
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1178_14756"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1178_14756)">
                        <path
                          d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                          fill="black"
                        />
                        <path
                          d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </span>
                  <svg
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="menu-icon-table"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <path
                      d="M1.36328 11.625C1.14661 11.625 0.967615 11.5543 0.826281 11.413C0.684281 11.271 0.613281 11.0917 0.613281 10.875C0.613281 10.675 0.684281 10.5 0.826281 10.35C0.967615 10.2 1.14661 10.125 1.36328 10.125H17.3633C17.5799 10.125 17.7593 10.2 17.9013 10.35C18.0426 10.5 18.1133 10.675 18.1133 10.875C18.1133 11.0917 18.0426 11.271 17.9013 11.413C17.7593 11.5543 17.5799 11.625 17.3633 11.625H1.36328ZM1.36328 6.75C1.14661 6.75 0.967615 6.679 0.826281 6.537C0.684281 6.39567 0.613281 6.21667 0.613281 6C0.613281 5.78333 0.684281 5.604 0.826281 5.462C0.967615 5.32067 1.14661 5.25 1.36328 5.25H17.3633C17.5799 5.25 17.7593 5.32067 17.9013 5.462C18.0426 5.604 18.1133 5.78333 18.1133 6C18.1133 6.21667 18.0426 6.39567 17.9013 6.537C17.7593 6.679 17.5799 6.75 17.3633 6.75H1.36328ZM1.36328 1.875C1.14661 1.875 0.967615 1.8 0.826281 1.65C0.684281 1.5 0.613281 1.325 0.613281 1.125C0.613281 0.908333 0.684281 0.729333 0.826281 0.588C0.967615 0.446 1.14661 0.375 1.36328 0.375H17.3633C17.5799 0.375 17.7593 0.446 17.9013 0.588C18.0426 0.729333 18.1133 0.908333 18.1133 1.125C18.1133 1.325 18.0426 1.5 17.9013 1.65C17.7593 1.8 17.5799 1.875 17.3633 1.875H1.36328Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {sdSelectedSuggestedCategories &&
              sdSelectedSuggestedCategories.map((category) => {
                let bidRange = `${category.upper_suggested_bid}-${category.lower_suggested_bid}`;
                let category_range = `${category.category_product_upper_range}-${category.category_product_lower_range}`;
                return (
                  <>
                    <tr key={Math.random() * 10000} className="tesheight">
                      <td className="tabledata-tb">
                        <Product
                          image={AddProductButton}
                          Name={category.category_name}
                          Desc={category_range}
                        />
                      </td>
                      <td className="tabledata-tb bids-parent-container">
                        <div className="bids-container">
                          <span>{currencySymbol}{category.recommended_suggested_bid}</span>
                          <span>{currencySymbol}{bidRange}</span>
                        </div>
                        <span className="cursor-pointer-global selected-icon-hover">
                          <img
                            src={DeleteProductButton}
                            alt="productaddButton"
                            onClick={() =>
                              handleDeleteProduct(category.category_id)
                            }
                          />
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );

  const deleteAllAsins = () => {
    setSdAsins([]);
    setSdWholeCategories((current) => {
      return current.filter((category) => {
        return category.expression[0].type !== "product";
      });
    });
    setAdGroupData((current) => {
      const copy = { ...current };
      let updatedArray = copy["amz_product_targeting"].filter((asin) => {
        return asin.expression[0].type !== "product";
      });
      copy["amz_product_targeting"] = updatedArray;
      return copy;
    });
  };

  const tableBodyOfAsins = (
    <>
      <div className={`${createCampaignErrors?.targeting ? "border border-danger" : ""} generic-main-table-body-container`}>
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="float-left p-3">0 Added</div>
                <div className="float-right">
                  <button
                    type="button"
                    onClick={deleteAllAsins}
                    className={
                      sdAsins.length > 0
                        ? "remove-all-btn-dark"
                        : "remove-all-btn"
                    }
                  >
                    Remove All
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-right-white product-table-first">
                <span className="table-header-text mx-3">All Categories</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14756"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14756)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="black"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </th>
              <th id="product-table-header">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="table-header-text mx-3">
                      Suggested Bid
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1178_14756"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1178_14756)">
                        <path
                          d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                          fill="black"
                        />
                        <path
                          d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </span>
                  <svg
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="menu-icon-table"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <path
                      d="M1.36328 11.625C1.14661 11.625 0.967615 11.5543 0.826281 11.413C0.684281 11.271 0.613281 11.0917 0.613281 10.875C0.613281 10.675 0.684281 10.5 0.826281 10.35C0.967615 10.2 1.14661 10.125 1.36328 10.125H17.3633C17.5799 10.125 17.7593 10.2 17.9013 10.35C18.0426 10.5 18.1133 10.675 18.1133 10.875C18.1133 11.0917 18.0426 11.271 17.9013 11.413C17.7593 11.5543 17.5799 11.625 17.3633 11.625H1.36328ZM1.36328 6.75C1.14661 6.75 0.967615 6.679 0.826281 6.537C0.684281 6.39567 0.613281 6.21667 0.613281 6C0.613281 5.78333 0.684281 5.604 0.826281 5.462C0.967615 5.32067 1.14661 5.25 1.36328 5.25H17.3633C17.5799 5.25 17.7593 5.32067 17.9013 5.462C18.0426 5.604 18.1133 5.78333 18.1133 6C18.1133 6.21667 18.0426 6.39567 17.9013 6.537C17.7593 6.679 17.5799 6.75 17.3633 6.75H1.36328ZM1.36328 1.875C1.14661 1.875 0.967615 1.8 0.826281 1.65C0.684281 1.5 0.613281 1.325 0.613281 1.125C0.613281 0.908333 0.684281 0.729333 0.826281 0.588C0.967615 0.446 1.14661 0.375 1.36328 0.375H17.3633C17.5799 0.375 17.7593 0.446 17.9013 0.588C18.0426 0.729333 18.1133 0.908333 18.1133 1.125C18.1133 1.325 18.0426 1.5 17.9013 1.65C17.7593 1.8 17.5799 1.875 17.3633 1.875H1.36328Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {sdAsins &&
              sdAsins.map((category, index) => {
                let category_range = category.bid;
                return (
                  <>
                    <tr key={Math.random() * 10000} className="row-height">
                      <td className="tabledata-tb">
                        <Product
                          image={ProductImage1}
                          Name={category.expression[0].value}
                          Desc={category_range}
                        />
                      </td>
                      <td className="tabledata-tb bids-parent-container">
                        <div className="bids-container">
                          {/* <span>{product.name}</span>
                    <span>{product.bidRange}</span>
                    <span>{product.name}</span> */}
                        </div>
                        <span className="cursor-pointer-global selected-icon-hover">
                          <img
                            src={DeleteProductButton}
                            onClick={() =>
                              onDelete(category.expression[0].value)
                            }
                            alt="productaddButton"
                          />
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
          {/* })} */}
        </table>
      </div>
    </>
  );

  const onDelete = (id) => {
    setSdAsins((current) => {
      return current.filter((asin) => {
        return asin.expression[0].value !== id;
      });
    });
    setSdWholeCategories((current) => {
      return current.filter((asin) => {
        return asin.expression[0].value !== id;
      });
    });
    setAdGroupData((current) => {
      const copy = { ...current };
      let updatedArray = copy["amz_product_targeting"].filter((asin) => {
        return asin.expression[0].value !== id;
      });
      copy["amz_product_targeting"] = updatedArray;
      return copy;
    });
  };

  return (
    <>
      <div className="key-wrap">
        <span className="common-head">
          Product Targeting
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1178_14743"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="21"
              height="20"
            >
              <rect
                x="0.585693"
                y="0.0175781"
                width="20"
                height="19.9643"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_1178_14743)">
              <path
                d="M10.5856 17.9022C9.48839 17.9022 8.45728 17.6943 7.49228 17.2783C6.52672 16.8624 5.68978 16.3009 4.98145 15.5939C4.27311 14.8868 3.71061 14.0513 3.29395 13.0875C2.87728 12.1242 2.66895 11.095 2.66895 9.9997C2.66895 8.90443 2.87728 7.87489 3.29395 6.91106C3.71061 5.94778 4.27311 5.11261 4.98145 4.40554C5.68978 3.69847 6.52672 3.13697 7.49228 2.72105C8.45728 2.30513 9.48839 2.09717 10.5856 2.09717C11.6828 2.09717 12.7142 2.30513 13.6798 2.72105C14.6448 3.13697 15.4814 3.69847 16.1898 4.40554C16.8981 5.11261 17.4606 5.94778 17.8773 6.91106C18.2939 7.87489 18.5023 8.90443 18.5023 9.9997C18.5023 11.095 18.2939 12.1242 17.8773 13.0875C17.4606 14.0513 16.8981 14.8868 16.1898 15.5939C15.4814 16.3009 14.6448 16.8624 13.6798 17.2783C12.7142 17.6943 11.6828 17.9022 10.5856 17.9022ZM10.5856 16.6545C12.4328 16.6545 14.0059 16.0065 15.3048 14.7104C16.6031 13.4139 17.2523 11.8436 17.2523 9.9997C17.2523 8.15577 16.6031 6.58553 15.3048 5.28896C14.0059 3.99294 12.4328 3.34494 10.5856 3.34494C8.73839 3.34494 7.16561 3.99294 5.86728 5.28896C4.56839 6.58553 3.91895 8.15577 3.91895 9.9997C3.91895 11.8436 4.56839 13.4139 5.86728 14.7104C7.16561 16.0065 8.73839 16.6545 10.5856 16.6545Z"
                fill="#4D4184"
              />
              <path
                d="M10.559 13.9509C10.7396 13.9509 10.889 13.8921 11.0073 13.7745C11.1251 13.6564 11.184 13.5072 11.184 13.327V9.77083C11.184 9.60446 11.1251 9.46221 11.0073 9.34409C10.889 9.22652 10.7396 9.16774 10.559 9.16774C10.3784 9.16774 10.2293 9.22652 10.1115 9.34409C9.99317 9.46221 9.934 9.61139 9.934 9.79162V13.3478C9.934 13.5141 9.99317 13.6564 10.1115 13.7745C10.2293 13.8921 10.3784 13.9509 10.559 13.9509ZM10.559 7.7536C10.7534 7.7536 10.9132 7.68761 11.0382 7.55562C11.1632 7.42419 11.2257 7.26143 11.2257 7.06733C11.2257 6.8871 11.1632 6.73099 11.0382 6.599C10.9132 6.46757 10.7534 6.40186 10.559 6.40186C10.3646 6.40186 10.2048 6.46757 10.0798 6.599C9.95483 6.73099 9.89233 6.8871 9.89233 7.06733C9.89233 7.26143 9.95483 7.42419 10.0798 7.55562C10.2048 7.68761 10.3646 7.7536 10.559 7.7536Z"
                fill="#4D4184"
              />
            </g>
          </svg>
        </span>
        <div className="tab-wrap">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className="primary-btn d-flex"
                  style={{ width: "180px" }}
                >
                  <span className="product-search-text">Search Product</span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_1178_14756"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                    >
                      <rect width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1178_14756)">
                      <path
                        d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                        fill="white"
                      />
                      <path
                        d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </Nav.Link>
                {/* <button >Tab 1</button> */}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="primary-btn ms-1 d-flex"
                  eventKey="second"
                  style={{ width: "180px" }}
                >
                  <span className="product-search-text">Enter ASIN's</span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_1178_14752"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                    >
                      <rect width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1178_14752)">
                      <path
                        d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                        fill="#4D4184"
                      />
                      <path
                        d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                        fill="#4D4184"
                      />
                    </g>
                  </svg>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="row">
                  <div className="col-lg-6 sp-product-targettig-outer-container">
                    <TableRb table={tableBody}></TableRb>
                  </div>
                  <div className="col-lg-6 sp-product-targettig-outer-container">
                    <TableRb table={tableBodyAdded} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="row">
                  <div className="col-lg-6 ">
                    <AsinsTable SDProduct={"SDProduct"} />
                  </div>
                  <div className="col-lg-6">
                    <div className="sp-product-targettig-outer-container">
                      <TableRb className="" table={tableBodyOfAsins}></TableRb>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};


const Product = ({ image, Name, Desc }) => {
  return (
    <>
      <div className="product-container">
        <div className="product-img-container">
          <img src={image} alt="image" />
        </div>
        <div className="product-description-container">
          <span className="category-heading">Category</span>
          <span className="product-name">{Name && Name}</span>
          <span className="product-desc">Product:{Desc}</span>
        </div>
      </div>
    </>
  );
};

export default SDProductProspectAdGroupTable;



