import React, { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';
import PrimaryBtnRB from '../Buttons/primaryBtnRB.js/primaryBtnRB';
import SecondaryBtnRb from '../Buttons/secondaryBtnRb/secondaryBtnRb';
import SectionTitle from '../sectionTitle';
import DataTable from '../Tables/table';
import TableRb from "../../components/Tables/tableRb"
import "./lowperformingSerchTerm.css"
import { masterApi } from '../../apis/masterApi';
import Loader from '../loader';
import { useGlobalContext } from '../../context/global';
import { useNavigate } from 'react-router-dom';
import redirect from '../../constant/Redirect';
import { getCurrencySymbol } from '../../constant/defaultCurrencyConfigurations';
import { calculateDateDifference , getSpecificDataFormat } from '../../helper/Reports/common';

const LowperformingSerchTerm = ({portfolios,portfoliosIds}) => {
    // let lowPerformanceSearchTermtableData;
    const {calendarDate,setCalendarDate,applyCalendar} = useGlobalContext();
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [lowPerformingSearchTermData, setlowPerformingSearchTermData] = useState([]);
    const [lpstData, setData] = useState([]);
    const navigate = useNavigate();
    const previousDaysDates = calculateDateDifference(calendarDate[0]);

    const currencySymbol = getCurrencySymbol()
      

    const formatDateFn = (date) => {
        const selectedDate = new Date(date)
        const formattedDate = selectedDate.getDate() + "-"+ parseInt(selectedDate.getMonth()+1) +"-"+ selectedDate.getFullYear();
        return selectedDate.getDate() + "-"+ parseInt(selectedDate.getMonth()+1) +"-"+ selectedDate.getFullYear();
      }

    // useEffect(() => {
    //     formatDateFn(calendarDate[0].startDate);
    // },[])

    async function lowPerformingSearchTermDataAPI() {
        setIsLoaderVisible(true);
        try {
            const data = {
                report_name: "low_performing_search_term",
                level: "account",
                platform: "amazon",
                start_date: `${formatDateFn(calendarDate[0].startDate)}`,
                end_date: `${formatDateFn(calendarDate[0].endDate)}`,
                // date_preset: "last_7d",
                limit: 20,
                ...(portfolios.length && {portfolio_ids:portfoliosIds.join(",")})


               
            }
            const result = await masterApi(data, "analytics");
            if(redirect(result)) return navigate('/login')
            setlowPerformingSearchTermData(result.data.data);
            setIsLoaderVisible(false);
            return result;
        }
        catch (e) {
            // console.warn("Error in  Targetting Default Top 10 Api :-", e);
            setIsLoaderVisible(false);
        }
    }
    useEffect(() => {
        lowPerformingSearchTermDataAPI();
    }, [applyCalendar,portfolios])//[calendarDate]



    const lowPerformaceTermHeads = ["Search Terms", `Current Period (${getSpecificDataFormat(calendarDate[0]?.startDate)} - ${getSpecificDataFormat(calendarDate[0]?.endDate)})`, `Previous Period (${getSpecificDataFormat(previousDaysDates.newStartDate)}-${getSpecificDataFormat(previousDaysDates.newEndDate)})`, "Comparison"]



    let lowPerformanceSearchTermTable = <table className="w-100 low-performig-term-table">
        <thead>
            <tr className="colspan-head">
                {
                    lowPerformaceTermHeads.map((th) => {
                        return th.toLowerCase()?.includes("current period" )  || th.toLowerCase()?.includes("previous period")  ?
                            <th className='text-center' colSpan={2} >{th}</th>

                            : <th>{th} </th>

                    })
                }
            </tr>
        </thead>
        {/* this is subhead of main head  */}
        <tr className="colspan-haed-row">
            <td></td>
            <td className="colspan-subhead">Spends</td>
            <td className="colspan-subhead">Orders</td>
            <td className="colspan-subhead">Spends</td>
            <td className="colspan-subhead">Orders</td>

        </tr>
        <tbody>

            {
                lowPerformingSearchTermData?.map((data,index) => {
                    return <tr key={data?.search_term + index}>
                        <td >{data.search_term}</td>
                        <td >{currencySymbol}{Math.round(data.current_spends * 100) / 100}</td>
                        <td >{data.current_orders}</td>
                        <td  >{currencySymbol}{Math.round(data.previous_spends * 100) / 100}</td>
                        <td >{data.previous_orders}</td>
                        <td className={Math.sign(data.comparison) === -1 ? "negative-data-comparison" : Math.sign(data.comparison) > 0 ? "positive-data-comparison" : "default-data"}>{data.comparison}</td>

                    </tr>

                })
            }
        </tbody>
    </table>
    return <div className='section-main-body'>
        <Loader visible={isLoaderVisible}></Loader>
        <Stack>
            <Stack direction="horizontal" gap={3} >
                <SectionTitle sectionTitleHeading="Low Performing Search Terms"></SectionTitle>
            </Stack>
            <Stack direction="vertical" className="lp-table">
                <TableRb table={lowPerformanceSearchTermTable}></TableRb>
            </Stack>
        </Stack>
    </div>;
}



export default LowperformingSerchTerm;