// import { useGlobalContext } from "../context/global";
// import { useSelector } from "react-redux";
import {
  runDeleteApi,
  runGetApi,
  runPatchApi,
  runPostApi,
  runPutApi,
} from "./api";
// import {useAuthentication} from "../hooks/authToken";

// const {userToken} = useGlobalContext()
// const tenant_id = "S1ldPTXS32";
// const tenant_id = "aiekYz4hQy";

const categoryClientId = "Ru0qfZxbjO";
const campaign_id_adset_testing = "/637783826acfed6c03765dd6";

/**
 * Master Api Call To reports/ads this url
 * @Note if you are making get api call don't worry directly pass as json data and the program automatically handle it .
 * @Note pass apiType= false if wanted to run Ad API
 * @param {ReqData} ReqData
 * @returns
 */

function getCookieValue(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return '';
}

export const masterApi = async (
  ReqData,
  apiName = "masterApi",
  apiType = true,
  methodType,
  specialElement,
  userToken
) => {
  const client_id =
    JSON.parse(localStorage.getItem("clientId")) === null
      ? "aiekYz4hQy"
      : JSON.parse(localStorage.getItem("clientId"));
  const tenant_id =
    JSON.parse(localStorage.getItem("clientId")) === null
      ? "aiekYz4hQy"
      : JSON.parse(localStorage.getItem("clientId"));
  let reportAdUrl = tenant_id + "/reports/ads";
  const header = {
    // "Content-type":"application/json",
   ...( apiType === false &&{"Authorization" : getCookieValue('_auth') === "" ?  userToken : getCookieValue('_auth')}) // API Type false = Ads API. send user token after login.
  };


  switch (apiName) {
    case "masterApi":
      reportAdUrl = tenant_id + "/reports/ads";
      break;
    case "analytics":
      reportAdUrl = tenant_id + "/analytics";
      break;
    case "campaignList":
      reportAdUrl = "client/" + client_id + "/campaigns";
      break;
    case "adsetList":
      reportAdUrl = "client/" + client_id + "/adsets";
      break;
    case "adsetStats":
      reportAdUrl = tenant_id + "/reports/ads";
      break;
    case "createPortfolio":
      reportAdUrl = "client/" + client_id + "/portfolios";
      break;
    case "getPortfolios":
      reportAdUrl = "client/" + client_id + "/portfolios";
      break;
    // API For gettting portfolios
    case "SPAutomaticcampaign":
      reportAdUrl = "client/" + client_id + "/campaigns";
      break;
    // API For Creating campaign
    case "Adgroup":
      reportAdUrl = `client/${client_id}/campaigns/${specialElement}/adsets`;
      break;
    // API For creating adgroup
    case "Adset":
      reportAdUrl = `client/${client_id}/creatives`;
      break;
    // API For creating adset
    case "keywords":
      reportAdUrl = `client/${client_id}/keywords`;
      break;
    // API For getting keywords
    case "categories":
      reportAdUrl = `client/${categoryClientId}/categories`;
      // API For getting categories
      break;
    case "targetStat":
      reportAdUrl = client_id + "/reports/ads";
      // API For getting categories
      break;
    case "configDashboard":
      reportAdUrl = `client/${client_id}/custom-config`;
      break;
    case "searchTermStat":
      reportAdUrl = client_id + "/reports/ads";
      break;
    case "getAdsForEditAdgroupTable":
      reportAdUrl = "client/" + client_id + "/ads";
      break;
    case "customCreativesByFiltering":
      reportAdUrl = "client/" + client_id + "/creatives";
      break;
    case "clientData":
      reportAdUrl =
        "client/" +
        specialElement +
        "/accounts?type=amazon_ad_account&fields=account_id,client_id,type,default_bid,region,brand_id,brand_keywords,suggested_bid,currency_code,name,asins,id,account_type,vendor_catalog_csv_url,country_code,id";
      break;
    case "masterTargeting":
      reportAdUrl = "client/" + client_id + ":master-targeting";
      break;
    case "masterAds":
      reportAdUrl = "client/" + client_id + ":get-amazon-master-ads";
      break;
    case "masterSearchTerm":
      reportAdUrl = "client/" + client_id + ":amazon-master-search-terms";
      break;
    case "updateadsinbulk":
      reportAdUrl = "client/" + client_id + ":update-bulk-ads-status";
      break;
    case "uploadasinsstatus":
      reportAdUrl = "client/" + client_id + ":update-bulk-asins-status?source=amazon";
      break;
    case "updatetargetingsinbulk":
      reportAdUrl = "client/" + client_id + ":update-amazon-bulk-target?source=amazon";
      break
    case "getSmartAdsProductsByRules":
      reportAdUrl = "client/" + client_id + "/product-set-products"
      break
    case "getOptimisedBids":
      reportAdUrl = "client/" + client_id + ":amazon-master-bids-optimiser";
      break;
    case "getSmartAds":
      reportAdUrl = "client/" + client_id + "/product-set?source=amazon&categories";
      break
    case "getProductSet":
      reportAdUrl = "client/" + client_id + "/product-set";
      break;
    case "getProducts":
      reportAdUrl = "client/" + client_id + "/products";
      break;
    case "historyLogs":
      reportAdUrl = "client/" + client_id + "/history-logs";
      break;
    case "cursor":
        reportAdUrl = "client/" + client_id + "/cursor";
        break;
    case "updateClientConfig":
      reportAdUrl = "client/" + client_id + "/custom-config"
      if(methodType==="put") {
        reportAdUrl = reportAdUrl+`/${specialElement}`
      }
      break
    case "automationsRule":
      reportAdUrl = "client/" + client_id + (methodType === "patch" ? "/automation-rule-sets?source=amazon" : "/automation-rule-set")
      break;
    default:
      break;
  }
  const Result =
    methodType === "post"
      ? await runPostApi(reportAdUrl, ReqData, header, apiType)
      : methodType === "put"
        ? await runPutApi(reportAdUrl, ReqData, header, apiType)
        : methodType === "patch"
          ? await runPatchApi(reportAdUrl, ReqData, header, apiType)
          : methodType === "delete"
            ? await runDeleteApi(reportAdUrl, ReqData, header, apiType)
            : await runGetApi(reportAdUrl, ReqData, header, apiType);

  return Result;
};
