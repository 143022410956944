// Import React hooks
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../context/global";


// Define a helper function to get the expiration date of a token
const getExpirationDate = (token) => {
  // Decode the token payload
  const payload = JSON.parse(window.atob(token.split(".")[1]));
  // Return the expiration date in milliseconds
  return payload.exp * 1000;
};

// Define a helper function to check if a token is expired
const isExpired = (date) => {
  // Get the current date in milliseconds
  const now = Date.now();
  // Return true if the date is past or within 10 seconds of now
  return date <= now + 10000;
};

// Define a custom hook that takes an API endpoint as an argument
const useToken = (endpoint) => {
  const { cubeToken, setCubeToken } = useGlobalContext();
  // Use useState to store the token
  const [token, setToken] = useState(null);
  
  // Define a function to refresh the token
  const refresh = async () => {
    
    try {
      // Make an API call to get a new token

      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_CUBE_JS_SECRET,
        },
      };
      if(endpoint) {
        var url = process.env.REACT_APP_LOGIN_API+"/api/login/generateCubeToken?type="+endpoint
      } else {
        var url = process.env.REACT_APP_LOGIN_API+"/api/login/generateCubeToken?type=reports";
      }
      const response = await fetch(url, options)
      const data = await response.json();
    //   const response = await fetch(endpoint);
    //   const data = await response.json();
      // Update the state with the new token
      localStorage.setItem("cubeToken", JSON.stringify(data.token));
      setToken(data.token);
      setCubeToken(data.token)
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  // Use useEffect to get the initial token when the component mounts
  useEffect(() => {
    if(cubeToken) {
      let currentTime = Date.now();
      let differenceInHour = ((getExpirationDate(cubeToken) - currentTime) / (60 * 60000)).toFixed(0);
      if(differenceInHour < 2 || differenceInHour > 48) {
        refresh();
      }
    } else {
      refresh();
    }
    
  }, []);

  // Use useEffect to set up a timer that checks if the token is expired and refreshes it if needed
  useEffect(() => {
    // If there is no token, do nothing
    if (!token) return;
    // Get the expiration date of the token
    const expirationDate = getExpirationDate(token);
    // Set up a timer that runs every second and checks if the token is expired
    const timer = setInterval(() => {
      if (isExpired(expirationDate)) {
        // If the token is expired, refresh it
        refresh();
        // Clear the timer
        clearInterval(timer);
      }
    }, 1000);
    // Clean up the timer when the component unmounts or the token changes
    return () => clearInterval(timer);
  }, [token]);

  // Return the token and the refresh function
  return [token, refresh];
};

// Export the custom hook
export default useToken;