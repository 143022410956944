import React from "react";
import movingUp from "../../../assets/svgs/arrow_upward_green.svg";
import movingDown from "../../../assets/svgs/arrow_downward_red.svg";
import movingUpRed from "../../../assets/svgs/arrow_upward_red.svg";
import movingDownGreen from "../../../assets/svgs/arrow_downward_green.svg";
import numeral from 'numeral';
import defaultDateRangesWithLables from "../../../constant/calendarDefaultConfigurations";


export function humanize(str) {
  var i,
    frags = str?.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
  }
  return frags?.join(" ");
}

export const COLORS_SERIES = [
  '#1D216E', '#50A5B5', '#DEC46A', '#81B550','#703AC7',
  "#7078CC",
  "#70BDCC",
  "#E5DB7E",
  "#9CCC70",
  "#0099c6",
  "#00A0FA",
  "#BEB1F9",
  "#FF9800",
  "#7078CC",
  "#70BDCC",
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#3366cc",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411",
];
export const PALE_COLORS_SERIES = [
  "#2C91FF",
  "#93c7d2",
  "#eddeab",
  "#b1d293",
  "#BEB1F9",
  "#86aaf9",
  "#a1d3dd",
  "#d6dbe4",
  "#fdeecd",
  "#dad8fe",
];
export const commonOptions = {
  responsive: !0,
  interaction: { mode: "index", intersect: !0 },
  plugins: { legend: { position: "top" } },
  scales: {
    x: { ticks: { autoSkip: !0, maxRotation: 0, padding: 12, minRotation: 0 } },
    y0: { type: "linear", display: !0, position: "left" },
    y1: {
      type: "linear",
      display: !0,
      position: "right",
      grid: { drawOnChartArea: !1 },
    },
  },
};
export const useDrilldownCallback = ({
  datasets,
  labels,
  onDrilldownRequested,
  pivotConfig,
}) => {
  return React.useCallback(
    (elements) => {
      if (elements.length <= 0) return;
      const { datasetIndex, index } = elements[0];
      const { yValues } = datasets[datasetIndex];
      const xValues = [labels[index]];
      if (typeof onDrilldownRequested === "function") {
        onDrilldownRequested({ xValues, yValues }, pivotConfig);
      }
    },
    [datasets, labels, onDrilldownRequested]
  );
};

export const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }
      return [...memo, column];
    }, []);
  }
  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value == undefined) {
      return value;
    }
    if (type === "boolean") {
      if (typeof value === "boolean") {
        return value.toString();
      } else if (typeof value === "number") {
        return Boolean(value).toString();
      }
      return value;
    }
    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }
    return value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }
  return data.map(format);
};
function getCookieValue(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return '';
}

export const createQueryURL = (allFilters) => {
  let url = {}
  for(const filter of allFilters) {
   const filterName = filter.member.split('.')[1]
   if(filterName!=='profileId' && filterName!=="profile_id" && !allMetrics?.includes(filterName) && filterName!=="parentSku") {
     url[filterName] = filter.values.join(",")
   }
  }
 return url
}

export const dataQueryParser = (currentData,e,type,tableName) => {
  let data = [...currentData];
  data.forEach((x, i) => {
    if (x.member === `${tableName}.` + type) {
      data.splice(i, 1);
    }
  });
  if (e.length > 0) {
    data.push({
      member: `${tableName}.` + type,
      operator: "equals",
      values: e,
    });
  }
  return data
}

export async function saveCustomView(headings, clientId, datapresent,key) {
  let type = "POST";
  let apiUrl =
    process.env.REACT_APP_ADS_API + "/client/" + clientId + "/custom-config";
  const result = await getCustomView(clientId, key);
  let output = result.value;
  if (output !== undefined && output !== null && output !== "") {
    type = "PUT";
    apiUrl =
      process.env.REACT_APP_ADS_API +
      "/client/" +
      clientId +
      "/custom-config/" +
      result.id;
  }

  const data = { key: key, value: headings };
  fetch(apiUrl, {
    method: type,
    headers: { "Content-Type": "application/json","Authorization": getCookieValue('_auth')  },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {});
}

export async function getCustomView(clientId,key) {
  const apiUrl =
    process.env.REACT_APP_ADS_API +
    "/client/" +
    clientId +
    "/custom-config?key="+key;
  let output = false;
  await fetch(apiUrl, {
    method: "GET",
    headers: { "Content-Type": "application/json","Authorization": getCookieValue('_auth') },
  })
    .then((response) => response.json())
    .then((data) => {
      output = data;
    });
  return output;
}

export const allMetrics = [
  "cost",
  "costDRR",
  "impressions",
  "impressionsDRR",
  "clicks",
  "clicksDRR",
  "conversions14d",
  "conversions14dDRR",
  "sales14d",
  "sales14dDRR",
  "cpm",
  "cpmDRR",
  "ctr",
  "ctrDRR",
  "cpc",
  "cpcDRR",
  "conversion_rate",
  "conversion_rateDRR",
  "roas",
  "roasDRR",
  "acos",
  "acosDRR",
  "average_order_value",
  "average_order_valueDRR",
];

export const measuresFunnal = [
    "dateDiff",
    "costDRR",
    "impressions",
    "impressionsDRR",
    "clicks",
    "clicksDRR",
    "ctrDRR",
    "cpcDRR",
    "cpm",
    "cpmDRR",
    "conversions14dDRR",
    "conversion_rate",
    "conversion_rateDRR",
    "sales14dDRR",
    "average_order_valueDRR",
    "acosDRR",
    "roasDRR",
];
export const measuresFunnalDRR = [
  "dateDiff",
    "cost",
    "impressions",
    "impressionsDRR",
    "clicks",
    "clicksDRR",
    "ctr",
    "cpc",
    "cpm",
    "cpmDRR",
    "conversions14d",
    "conversion_rate",
    "conversion_rateDRR",
    "sales14d",
    "average_order_value",
    "acos",
    "roas",
];
export const measuresPerformance = [
  "dateDiff",
  "cost",
  "costDRR",
  "impressionsDRR",
  "clicksDRR",
  "ctrDRR",
  "cpc",
  "cpcDRR",
  "cpm",
  "cpmDRR",
  "conversions14dDRR",
  "conversion_rateDRR",
  "sales14d",
  "sales14dDRR",
  "average_order_value",
  "average_order_valueDRR",
  "acos",
  "acosDRR",
  "roas",
  "roasDRR",
];
export const measuresPerformanceDRR = [
  "dateDiff",
    "cost",
    "costDRR",
    "impressions",
    "clicks",
    "ctr",
    "cpc",
    "cpcDRR",
    "cpm",
    "cpmDRR",
    "conversions14d",
    "conversion_rate",
    "sales14d",
    "sales14dDRR",
    "average_order_value",
    "average_order_valueDRR",
    "acos",
    "acosDRR",
    "roas",
    "roasDRR",
];
export const ComparisonViewFunel = [
  "dateDiff",
    "costDRR",
    "impressions",
    "impressionsDRR",
    "clicks",
    "clicksDRR",
    "ctrDRR",
    "cpcDRR",
    "cpm",
    "cpmDRR",
    "conversions14dDRR",
    "conversion_rateDRR",
    "sales14dDRR",
    "average_order_value",
    "average_order_valueDRR",
    "acosDRR",
    "roasDRR"
];
export const ComparisonViewFunelDRR = [
  "dateDiff",
    "cost",
    "impressions",
    "impressionsDRR",
    "clicks",
    "clicksDRR",
    "ctr",
    "cpc",
    "cpm",
    "cpmDRR",
    "conversions14d",
    "conversion_rate",
    "sales14d",
    "average_order_value",
    "average_order_valueDRR",
    "acos",
    "roas"
];

export const linkedStrings = [
  "week",
  "month",
  "Category",
  "Date",
  "Portfolio Name",
  "Campaign Format",
  "Targeting Type",
  "Campaign Name",
  "Child ASIN",
];

export const formulatedStrings = [
  "cpm",
  "ctr",
  "cpc",
  "conversion_rate",
  "average_order_value",
  "roas",
  "acos",
  "cpmDRR",
  "ctrDRR",
  "cpcDRR",
  "conversion_rateDRR",
  "average_order_valueDRR",
  "roasDRR",
  "acosDRR",
];

export const metricStrings = [
    "cost",
    "costDRR",
    "impressions",
    "impressionsDRR",
    "clicks",
    "clicksDRR",
    "conversions14d",
    "conversions14dDRR",
    "sales14d",
    "sales14dDRR",
    "TotalOrderItemCount"
];

export const expendableStrings = [
  "Parent ASIN",
  "Campaign Format",
  "Placement",
  "Targeting",
  "Query",
  "Portfolio Name",
  "Campaign Format",
  "Broad Category"
];

export const defaultRadioValues = ["Performance View", "Funnel View", "Custom View"]

export const summaryMapping = {
  "cpm": { numeratorKey: "cost", denominatorKey: "impressions", multiplier: 1000 },
  "ctr": { numeratorKey: "clicks", denominatorKey: "impressions", multiplier: 100,symbol:"%" },
  "cpc": { numeratorKey: "cost", denominatorKey: "clicks" },
  "acos": { numeratorKey: "cost", denominatorKey: "sales14d", multiplier: 100 },
  "roas": { numeratorKey: "sales14d", denominatorKey: "cost" },
  "average_order_value": { numeratorKey: "sales14d", denominatorKey: "conversions14d",decimal:0 },
  "conversion_rate": { numeratorKey: "conversions14d", denominatorKey: "clicks", multiplier: 100,symbol:"%" },
  "cpmDRR": { numeratorKey: "cost", denominatorKey: "impressions", multiplier: 1000 },
  "ctrDRR": { numeratorKey: "clicks", denominatorKey: "impressions", multiplier: 100,symbol:"%" },
  "cpcDRR": { numeratorKey: "cost", denominatorKey: "clicks" },
  "acosDRR": { numeratorKey: "cost", denominatorKey: "sales14d", multiplier: 100 },
  "roasDRR": { numeratorKey: "sales14d", denominatorKey: "cost" },
  "average_order_valueDRR": { numeratorKey: "sales14d", denominatorKey: "conversions14d",decimal:0 },
  "conversion_rateDRR": { numeratorKey: "conversions14d", denominatorKey: "clicks", multiplier: 100,symbol:"%" },
  "costDRR": { numeratorKey: "cost", denominatorKey: "dateDiff",decimal:0 },
  "impressionsDRR": { numeratorKey: "impressions", denominatorKey: "dateDiff",decimal:0 },
  "clicksDRR": { numeratorKey: "clicks", denominatorKey: "dateDiff",decimal:0 },
  "conversions14dDRR": { numeratorKey: "conversions14d", denominatorKey: "dateDiff",decimal:0 },
  "sales14dDRR": { numeratorKey: "sales14d", denominatorKey: "dateDiff",decimal:0 },

};


export function sortArrayOfObjectsByLabel(data,label) {
  return data.sort((a, b) => {
    let labelA = a[label].toLowerCase();
    let labelB = b[label].toLowerCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
}


export const formatDate = (string) => {
  let date = new Date(string);
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset*60*1000))
  return date.toISOString().split('T')[0].toString()
};

export const calenderDate = (formattedDateString) => {
  const dateParts = formattedDateString.split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months are zero-based in JavaScript Date object
  const day = parseInt(dateParts[2]);

  const date = new Date(year, month, day);
  return date;
};


export const getSpecificDataFormat = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};

export const getSelectedPresetLabel = (range) => {
  for (let i = 0; i < defaultDateRangesWithLables.length; i++) {
    const staticRange = defaultDateRangesWithLables[i].range();
    if (
      range.startDate.toString() === staticRange.startDate.toString() &&
      range.endDate.toString() === staticRange.endDate.toString()
    ) {
      return { date: defaultDateRangesWithLables[i].label, isCustom: false };
    }
  }

  const formattedStartDate = getSpecificDataFormat(range.startDate);
  const formattedEndDate = getSpecificDataFormat(range.endDate);
  return {
    date: formattedStartDate !== formattedEndDate
      ? formattedStartDate + " - " + formattedEndDate
      : formattedStartDate,
    isCustom: true,
  };
};

export function displayDate(data, type) {
  if (type === "date") {
    return new Date(data).toLocaleDateString(
      "en-US",
      {
        day: "numeric",
        year: "numeric",
        month: "long",
      }
    )
  } else if (type === "month") {
    return new Date(data).toLocaleDateString(
      "en-US",
      {
        month: "long",
        year:"numeric"
      }
    )
  }
}

export const objectoFiltering  = (queryObject, tableName) => {
  const mainFiltering = [];
  for (let key in queryObject) {
    if (key !== "viewtype" && key!=="start" && key!=="end" && key!=="metricViewType" && key!=="performanceGroupType" && key!=="reportType" && key!=="parentSku") {
      const operator = tableName === 'AmazonTargetTable' && key === 'adyogiTargetingType' ? 'contains' : 'equals'
      if(queryObject[key]!=="" && queryObject[key]!==undefined) {
        mainFiltering.push({
          member: `${tableName}.${key}`,
          operator: operator,
          values: queryObject[key]?.split(","),
        });
      }
    }
  }
  return mainFiltering;
}

export const  getSearchParamsObject = (object) => {
  const paramsObject = {};

    for (const [key, value] of object.entries()) {
        // Convert values to appropriate types if needed
        paramsObject[key] = value;
    }

    return paramsObject;
 }

export const format = '0,0'; // 10,000

export const formatDecimal = '0,0.00'; //10,000.00
export const format1Decimal = '0,0.0'; //10,000.0
export const formatK = '0 a'; // 10k

export function formatPieChartData(result,type,table,dimension) {
  if(Object.keys(result).length > 0) {
  let cubeResult = JSON.parse(JSON.stringify(result))
  let returnResultLabel = [];
  let returnResultSeries = [];
  let data = cubeResult?.loadResponse?.results[0]?.data
  let tempArray = data.map((value, index) => {
      if(index < 5) {
          if( typeof value[`${table}.${dimension}`] === 'undefined' || value[`${table}.${dimension}`] === null ) {
              returnResultLabel.push("NULL")
          } else {
              returnResultLabel.push(value[`${table}.${dimension}`])
          }
          returnResultSeries.push(Math.round(parseFloat(value[`${table}.${type}`])));
      } else if(index === 5) {
          returnResultLabel.push('Other')
          returnResultSeries.push(
            data.reduce((acc, obj, index) => {
              if (index > 4) {
                if (typeof acc == "undefined") {
                  acc = 0;
                }
                return acc + Math.round(parseFloat(obj[`${table}.${type}`]));
              }
            }, 0)
          );
      } 
  });
  return {"label":returnResultLabel,"series":returnResultSeries};
  } 
}

export function formatPieChartDataComplete(result,type,table,dimension) {
  if(Object.keys(result).length > 0) {
  let cubeResult = JSON.parse(JSON.stringify(result))
  let returnResultLabel = [];
  let returnResultSeries = [];
  let data = cubeResult?.loadResponse?.results[0]?.data
  let tempArray = data.map((value, index) => {
          if( typeof value[`${table}.${dimension}`] === 'undefined' || value[`${table}.${dimension}`] === null ) {
              returnResultLabel.push("NULL")
          } else {
              returnResultLabel.push(value[`${table}.${dimension}`])
          }
          returnResultSeries.push(Math.round(parseFloat(value[`${table}.${type}`])));

  });
  return {"label":returnResultLabel,"series":returnResultSeries};
  } 
}

export const getDivideData = (val1, val2) => {
  return parseFloat(val2) !== 0 ? val1 / val2 : 0;
};

export const getComparisonHeader = (data) => {
  const convertArray = (arr) => {
    return arr.map((obj) => {
      if (obj.children) {
        const children = obj.children.map((child) => ({
          key: child.key,
          type: child.type,
          format: child.format,
          meta: child.meta,
          dataIndex: child.dataIndex,
          title: child.title,
          shortTitle: child.shortTitle,
          fieldName:child?.shortTitle
        }));
        return children;
      } else {
        return obj;
      }
    });
  };
  const outputArray = convertArray(data);
  const mergedArray = outputArray.reduce((acc, curr) => acc.concat(curr), []);
  const uniqueKeys = new Set();
  const filteredData = mergedArray.filter((obj) => {
    if (!uniqueKeys.has(obj.key)) {
      uniqueKeys.add(obj.key);
      return true;
    }
    return false;
  });
  return filteredData;
};

export function getPercentageChange(oldValue, newValue) {
  const percentageChange = (((newValue - oldValue) / oldValue) * 100).toFixed(1);
  return percentageChange;
}
const getCurrentData = (resultSet, objKey, objValue, dataIndex) => {
  let outputObject = {};
  resultSet.loadResponses.forEach((obj, index) => {
    if (index === 0) {
      obj.data.forEach((key, index1) => {
        Object.entries(key).forEach(([key1, value], innerIndex) => {
          if (dataIndex) {
            if (innerIndex === dataIndex) {
              if (key1 === objKey && value === objValue) {
                outputObject = obj.data[index1];
              }
            }
          } else {
            if (innerIndex === 0) {
              if (key1 === objKey && value === objValue) {
                outputObject = obj.data[index1];
              }
            }
          }
        });
      });
    }
  });
  return outputObject;
};

const getPreviousData = (resultSet, objKey, objValue, dataIndex) => {
  let outputObject = {};
  resultSet.loadResponses.forEach((obj, index) => {
    if (index === 1) {
      obj.data.forEach((key, index1) => {
        Object.entries(key).forEach(([key1, value], innerIndex) => {
          if (dataIndex) {
            if (innerIndex === dataIndex) {
              if (key1 === objKey && value === objValue) {
                outputObject = obj.data[index1];
              }
            }
          } else {
            if (innerIndex === 0) {
              if (key1 === objKey && value === objValue) {
                outputObject = obj.data[index1];
              }
            }
          }
        });
      });
    }
  });
  return outputObject;
};

export const getComparisonData = (resultSet, page, type) => {
  const mergedData = [];
  var currentDimensionKeys = [];
  // Iterate over the array of objects
  resultSet.loadResponses.forEach((obj, index) => {
    if (index === 0) {
      obj.data.forEach((key, index1) => {
        let dataObj = {};
        let previousObjec = {};
        Object.entries(key).forEach(([key1, value], innerIndex) => {
          if (innerIndex === 0) {
            previousObjec = getPreviousData(resultSet, key1, value, 0);
            dataObj[key1] = value;
            currentDimensionKeys.push(value)
          } else if (
            page === "product-child" &&
            key1 === "VariantAnalysisTable.productName"
          ) {
            dataObj[key1] = value;
          } else if (
            page === "expendable" &&
            innerIndex === 1 &&
            type === "child"
          ) {
            previousObjec = getPreviousData(resultSet, key1, value, innerIndex);
            dataObj[key1] = value;
          } else {
            if (Object.keys(previousObjec).length) {
              if (key1 !== "compareDateRange") {
                dataObj[key1] = {
                  current: {
                    value: value,
                    change: getPercentageChange(previousObjec[key1], value),
                  },
                  previous: previousObjec[key1] ?? 0,
                };
              }
              if (key1.includes("conversion_rate")) {
                dataObj[key1] = {
                  current: {
                    value: value?.toFixed(2),
                    change: getPercentageChange(previousObjec[key1], value),
                  },
                  previous: previousObjec[key1].toFixed(2) ?? 0,
                };
              }
              if (key1.includes("ctr")) {
                dataObj[key1] = {
                  current: {
                    value: value?.toFixed(2) + "%",
                    change: getPercentageChange(previousObjec[key1], value),
                  },
                  previous: previousObjec[key1].toFixed(2) + "%" ?? 0,
                };
              }
            } else {
              if (key1 !== "compareDateRange") {
                dataObj[key1] = {
                  current: {
                    value: value,
                    change: getPercentageChange(previousObjec[key1], value),
                  },
                  previous: previousObjec[key1] ?? 0,
                };
              }
              if (key1.includes("conversion_rate")) {
                dataObj[key1] = {
                  current: { value: value?.toFixed(3), change: NaN },
                  previous: 0,
                };
              }
            }
          }
        });
        mergedData.push(dataObj);
      });
    }
    // if (index === 1) {
    //   obj.data.forEach((key, index1) => {
    //     let dataObj = {};
    //     let previousObjec = {};
    //     previousDimensionKeys.push(Object.entries(key)[0][1])
    //     if(!currentDimensionKeys.includes(Object.entries(key)[0][1])) {
    //     Object.entries(key).forEach(([key1, value], innerIndex) => {
    //       if (innerIndex === 0) {
    //         previousObjec = getCurrentData(resultSet, key1, value, 0);
    //         dataObj[key1] = value;
    //       } else if (
    //         page === "product-child" &&
    //         key1 === "VariantAnalysisTable.productName"
    //       ) {
    //         dataObj[key1] = value;
    //       } else if (
    //         page === "expendable" &&
    //         innerIndex === 1 &&
    //         type === "child"
    //       ) {
    //         previousObjec = getCurrentData(resultSet, key1, value, innerIndex);
    //         dataObj[key1] = value;
    //       } else {
    //           if (key1 !== "compareDateRange") {
    //             dataObj[key1] = {
    //               current: {
    //                 value: previousObjec[key1] ?? 0,
    //                 change: NaN,
    //               },
    //               previous: value,
    //             };
    //           }
    //           if (key1.includes("conversion_rate")) {
    //             dataObj[key1] = {
    //               current: {value: 0 , change: NaN },
    //               previous: value?.toFixed(3),
    //             };
    //           }
    //       }
      
    //     });
    //     mergedData.push(dataObj);
    //   }
    //   });
    // }
  });
  return mergedData;
};

export const getKeyWiseTotal = (key, totals, daysDifference,page) => {
  if (summaryMapping[key]) {
    const { numeratorKey, denominatorKey, multiplier, symbol, decimal } = summaryMapping[key];
    let numerator;
    if(denominatorKey === "dateDiff") {
       numerator = parseFloat(getDivideData(totals[numeratorKey], daysDifference));
    } else {
       numerator = parseFloat(getDivideData(totals[numeratorKey], totals[denominatorKey]));
    }
    const result = isNaN(numerator) ? "-" : (numerator * (multiplier || 1));
    let resultValue = parseFloat(result);
    if(decimal === 0) {
      resultValue = parseFloat(resultValue).toFixed(0)
    } else {
      resultValue = parseFloat(resultValue).toFixed(2)
    }
    return resultValue + (symbol ? "%" : "")
} else {
  return isNaN(totals[key]) ? "-" : (page!=="marketing-stream" ?parseFloat(totals[key]).toFixed(0):numeral(totals[key]).format(formatDecimal));
}
}

export const getClassNameThead = (hiddenKeys,sortKey,mainIndex) => {
  if (mainIndex === 0) {
    if(sortKey === mainIndex){
      return "targeting-second-column targeting-table-heads selected";
    }else{
      return "targeting-second-column targeting-table-heads";
    }
  } else if (hiddenKeys.includes(mainIndex)) {
    return "d-none";
  } else if (sortKey === mainIndex) {
    return "targeting-table-heads selected";
  } else {
    return "targeting-table-heads";
  }
}

export const openPopup = (imageUrl) => {
  const popup = document.createElement('div');
  popup.classList.add('popupProductImage');
  
  const image = document.createElement('img');
  image.src = imageUrl;
  
  popup.appendChild(image);
  document.body.appendChild(popup);
  
  const closePopup = () => {
    document.body.removeChild(popup);
  };
  
  popup.addEventListener('click', closePopup);
};

export function getProductWithImage (td,hideImages) {
  if(!td) {
    return <></>
  }
  if(!hideImages) {
    return <><div className="d-flex gap-2 align-items-center">
              {td?.split('||')?.[0].trim() !== '' && td?.split('||')?.[0].trim() != '0' && 
              <div onClick={(e)=> openPopup(td?.split('||')?.[0].trim())} >
                <img src={td?.split('||')?.[0].trim()} alt="Product" className="productIcon img-responsive" />
              </div>
              }
              <div>{td?.split('||')?.[1]?.trim()}</div>
              </div>
          </>
  } else {
    return <><div className="d-flex gap-2 align-items-center">
                <div>{td?.split('||')?.[1]?.trim()}</div>
              </div>
          </>
  }
  
}

export const getPercentageChangeDiv = (value, type, images) => {
  if (isNaN(value) && !images) {
    return "";
  } else if (!isFinite(value) && !images) {
    return "";
  } else if(images && (!isFinite(value) || isNaN(value))) {
    return <td></td>
  }
  let chartType = type.split(".")[1];
  var different = ["cpm", "cpc", "acos"];
  let color = "";
  let img = "";
  if (value >= 0) {
    color = different.includes(chartType) ? "#B91C1C" : "#047857";
    img = different.includes(chartType) ? movingUpRed : movingUp;
  } else {
    color = different.includes(chartType) ? "#047857" : "#B91C1C";
    img = different.includes(chartType) ? movingDownGreen : movingDown;
  }
  if (images) {
    return (
      <td>
        <span
          className="percentage-up-down-container reports"
          style={{ display: "inline" }}
        >
          <span className="percentage-desc" style={{ color: color }}>
            {numeral(Math.abs(value)).format(format)}%
          </span>
          {
            <span className="percentage-desc" style={{ color: color }}>
              {value > 0 ? "↑" : "↓"}
            </span>
          }
        </span>
      </td>
    );
  } else {
    return (
      <>
        <div className="percentage-up-down-container reports">
          <span className="percentage-desc" style={{ color: color }}>
          {numeral(Math.abs(value)).format(format)}%
          </span>
          <span className="percentage-icon">
            <img src={img} alt="movingdown" />
          </span>
        </div>
      </>
    );
  }
};

export const checkObject = (td, type) => {
  if (type === "previous") {
    return typeof td === "object" && td !== null
      ? td?.previous
      : td === null
      ? ""
      : td?.previous;
  } else if (type === "change") {
    return typeof td === "object" && td !== null
      ? td.current.change
      : td === null
      ? ""
      : 0;
  } else {
    return typeof td === "object" && td !== null
      ? td.current.value
      : td === null
      ? null
      : td;
  }
};