import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { React, useState, useRef, useEffect } from "react";
import TableRb from "../../../Tables/tableRb.js";
import { Tab, Nav,Form } from "react-bootstrap";
import SPkeywordTable1 from "../../CampaignCreationPages/SPkeywordTable1.jsx";
import AddSpKeyword from "../../CampaignCreationPages/AddSpKeyword.jsx";
import "./addTargetingModal.css";
// import SwitchBtn from "../../../Buttons/switchBtn.js";
import CheckboxRb from "../../../Buttons/checkbox/checkbox.js";
import { Trash } from "react-bootstrap-icons";
import PrimaryBtnRB from "../../../Buttons/primaryBtnRB.js/primaryBtnRB";
import { masterApi } from "../../../../apis/masterApi.js";
import closeIcon from "../../../../assets/svgs/close.svg";
import { useSelector } from "react-redux";
import AsinsTable from "../../CampaignCreationPages/SP_Product_Targeting/AsinsTable.jsx";
import FilterkeywordModal from "../../campaignCreation/SpKeywordTargeting/FilterkeywordModal.jsx";
import { useGlobalContext } from "../../../../context/global.js";
import SearchbarForEdittargeting from "../../../../pages/campaignList/SearchbarForEdittargeting.jsx";
import AddProductButton from "../../../../assets/svgs/plus_box.svg";
import ProductImage1 from "../../../../assets/images/SP_ProductImage/Product1.png"
import MasterTargetingSearchSelectModal from "../../masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal.jsx";
import SearchbarForEditCampaign from "../../../searchbarForEditCampaign.js";
import DeleteProductButton from "../../../../assets/icons/delete.png";
import downArrow from "../../../../assets/icons/downArrowForSplitButton.svg";
import infoIcon from '../../../../assets/svgs/info.svg';
import { useAuthHeader } from "react-auth-kit";
import { TOAST_DANGER, TOAST_POSITION_TOP_RIGHT } from "../../../../constant/HardTypes.js";
import { getCurrencySymbol } from "../../../../constant/defaultCurrencyConfigurations.js";
import {toast} from 'react-toastify';

function AddTargetingModal(props) {
  // States to show and close the modal
  const handleClose = () => props.setIsVisible(false);
  const handleShow = () => props.setIsVisible(true);
  const authHeader = useAuthHeader();
  const token = authHeader();

  // States
  const [keywords, setKeywords] = useState(
    JSON.parse(localStorage.getItem("keywords"))
  );
  const [newIsVisible, setNewIsVisible] = useState(false);
  const [page, setPage] = useState("");
  const { modalBody } = props;
  const editBtnclickedCampaignDetailsForEditTargetingTable = useSelector(
    (state) =>
      state.campaignListData.editBtnclickedCampaignDetailsForEditTargetingTable
  );
  const { adset, campaignType, currentCampaign } =
    editBtnclickedCampaignDetailsForEditTargetingTable;
  const { objective, campaignType: type } = props.currentCampaign;
  const {
    amz_category,
    campaign_id,
    amz_keyword_targeting,
    id,
    amz_product_targeting,
  } = props.adset;
  const [word, setWord] = useState("");
  const [dropDownVisible, setdropDownVisible] = useState({
    searchSortModal: false,
  });
  const {
    editFormValues,
    setEditFormValues,
    editMatchTypes,
    setEditKeywords,
    editKeywords,
    showAlert,
    editSuggestedCategories,
    setEditSuggestedCategories,
    editKeywordsToBeAdded,
    setEditKeywordsToBeAdded,
    matchTypes,
    setMatchTypes,
    editWholeKeywords,
    setEditWholeKeywords,
    setEditKeywordsToBeShow,
    editAsins,
    setEditAsins,
    editWholeCategories,
    setEditWholeCategories,
    selectedEditSuggestedCategories,
    setSelectedEditSuggestedCategories,
    categories,
    setCategories,
    setManualMatchTypes,
    userToken
  } = useGlobalContext();

  // Get the client ID From LocalStorage

  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const default_bid = JSON.parse(localStorage.getItem("defaultBid"));
  const currencySymbol = getCurrencySymbol()


  const updateKeywords = async () => {
    const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/campaigns/${campaign_id}/adsets/${id}?source=amazon`;

   const editedKeywords = editKeywordsToBeAdded.map(function(keyword) {
      keyword["bid"] = keyword["userBid"]
      delete(keyword.lowest)
      delete(keyword.heighest)
      delete(keyword.userBid)
        return keyword;
    });

    let updatedArraywithDuplicates = [
      ...editedKeywords,
      ...amz_keyword_targeting,
    ].filter(
      (thing, index, self) =>
        index ===
        self.findIndex((t) => t.text === thing.text && t.match === thing.match)
    );
    const data = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: userToken,
      },
      body: JSON.stringify({
        amz_keyword_targeting: updatedArraywithDuplicates,
      }),
    };
    try {
      const response = await fetch(url, data);
      const result = await response.json();
      if (result?.success === true) {
        setEditWholeKeywords([]);
        setEditKeywordsToBeAdded([]);
        setEditKeywords([]);
        setEditKeywordsToBeShow([]);
        alert("Updated Successfully");
        handleClose();
      } else {
        alert("Error while updating");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateKeywordsWithType = (currentWords, currentTypes) => {
    let oneMoreArray = [];
    let newArray = currentWords.map((word) => {
      return currentTypes.map((type) => {
        oneMoreArray.push({ text: word.keyword, match: type });
      });
    });
    setEditKeywords(oneMoreArray);
  };

  const updateCategories = async () => {
    const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}/campaigns/${campaign_id}/adsets/${id}?source=amazon`;
    const updatedArraywithDuplicates = [
      ...editWholeCategories,
      ...amz_product_targeting,
    ].filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) => t.expression[0].value === thing.expression[0].value
        )
    );
    const data = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/merge-patch+json",
        Authorization: userToken,
      },
      body: JSON.stringify({
        amz_product_targeting: updatedArraywithDuplicates,
      }),
    };

    try {
      const response = await fetch(url, data);
      const result = await response.json();
      if (result?.success === true) {
        setEditAsins([]);
        setEditWholeCategories([]);
        setSelectedEditSuggestedCategories([]);
        alert("Updated Successfully");
        handleClose();
      } else {
        alert("Error while updating");
      }
    } catch (error) {
      console.log(error);
    }
  };


const applyHandler = () => {

}

  useEffect(() => {
    fetchData();
    if(categories.length === 0) {
      fetchCategories();
    }
    setMatchTypes(["Exact"]);
    setManualMatchTypes(["Exact"])
  }, []);

  // Methods

  const getSuggestedCategories = async (dataPassed) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      body: JSON.stringify(dataPassed),
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADS_API}/client/${clientId}:fetch-suggested-product-categories?source=amazon`,
        options
      );
      const result = await response.json();
      if(result?.message) {
        // showAlert(TOAST_POSITION_TOP_RIGHT,3000,{backgroundColor:TOAST_DANGER,title:"error",description:"No Products Present in Ad Group"})
        toast.error('No Products Present in Ad Group')
      } else {
        return result?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBidRecommendation = async (dataPassed) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
      body: JSON.stringify(dataPassed),
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADS_API}/client/${clientId}:fetch-bid-recommendation?source=amazon`,
        options
      );
      const result = await response.json();
      return result?.data;
    } catch (error) {
      console.log(error);
    }
  };

  function getModifiedData(array1, array2) {
    let suggestedCategoriesDataToBeShown = [];
    array1.forEach((category) => {
      array2.forEach((categorywithbid) => {
        if (
          category?.category_id ===
          categorywithbid.targeting_clauses.expressions[0].value
        ) {
          const {
            category_id,
            category_name,
            category_product_lower_range,
            category_product_upper_range,
          } = category;
          const {
            recommended_suggested_bid,
            upper_suggested_bid,
            lower_suggested_bid,
          } = categorywithbid;
          suggestedCategoriesDataToBeShown.push({
            category_id,
            category_name,
            category_product_lower_range,
            category_product_upper_range,
            recommended_suggested_bid,
            upper_suggested_bid,
            lower_suggested_bid,
          });
        }
      });
    });
    return suggestedCategoriesDataToBeShown;
  }

  const fetchData = async () => {
    const data = {
      type: `${objective}`,
      adset_id: `${id}`,
    };
    let result;
    if (campaignType === "SP-Product") {
      // API Call to be made Here
      result = await getSuggestedCategories(data);
      const modifiedResult = result.map((result) => {
        return { ...result, bid: default_bid };
      });
      setEditSuggestedCategories([...modifiedResult]);
    } else {
      result = await getSuggestedCategories(data);
      const modifiedResult = result.map((result) => {
        return { ...result, bid: default_bid };
      });
      const allIds = result.map((resultId) => resultId?.category_id);
      const mainDataToBePosted = allIds.map((id) => {
        return {
          expressions: [
            {
              type: "asinCategorySameAs",
              value: id,
            },
          ],
        };
      });
      const dataToBePosted = {
        type: "SD_CATEGORY",
        adset_id: `${id}`,
        targeting_clauses: mainDataToBePosted,
      };
      const result2 = await getBidRecommendation(dataToBePosted);
      const suggestedCategoriesDataToBeShown = getModifiedData(result, result2);
      setEditSuggestedCategories(suggestedCategoriesDataToBeShown);
    }
  };

  const fetchCategories = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: userToken,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/categories?source=amazon&fields=name&limit=1000`, options
      );
      const result = await response.json();
      setCategories(result?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleDropDownModal = (e) => {
    e.preventDefault();
    setdropDownVisible({
      ...dropDownVisible,
      searchSortModal: !dropDownVisible.searchSortModal,
    });
  }

  // Methods For Table Body

  const handleAddProduct = (id) => {
    // Get the clicked product from the ID
    const findedProduct = editSuggestedCategories.filter((category) => {
      return category.category_id === id;
    });

    // Check If the product is alreay added in the another Table
    const isProductThere = selectedEditSuggestedCategories.filter(
      (category) => {
        return category.expression[0].value === findedProduct[0].category_id;
      }
    );

    if (isProductThere.length === 0) {
      const {
        bid,
        recommended_suggested_bid,
        lower_suggested_bid,
        upper_suggested_bid,
        category_id,
        category_name,
        category_product_lower_range,
        category_product_upper_range,
      } = findedProduct[0];
      if (campaignType === "SP-Product") {
        const modifiedProduct = {
          status: "ENABLED",
          expression: [
            {
              type: "category",
              value: `${category_id}`,
            },
          ],
          bid: recommended_suggested_bid,
          category_name,
          category_id,
          Desc: `${category_product_lower_range}-${category_product_upper_range}`,
        };
        const anotherModifiedProduct = {
          status: "ENABLED",
          expression: [
            {
              type: "category",
              value: category_id,
            },
          ],
        };
        setSelectedEditSuggestedCategories([
          ...selectedEditSuggestedCategories,
          modifiedProduct,
        ]);
        setEditWholeCategories([
          ...editWholeCategories,
          anotherModifiedProduct,
        ]);
      } else {
        const modifiedProduct = {
          status: "ENABLED",
          expression: [
            {
              type: "category",
              value: `${category_id}`,
            },
          ],
          bid: recommended_suggested_bid,
          category_name,
          category_id,
          Desc: `${category_product_lower_range}-${category_product_upper_range}`,
          bidRange:`${upper_suggested_bid}-${lower_suggested_bid}`
        };
        const anotherModifiedProduct = {
          status: "ENABLED",
          expression: [
            {
              type: "category",
              value: `${category_id}`,
            },
          ],
          bid: recommended_suggested_bid ?? default_bid,
        };
        setSelectedEditSuggestedCategories([
          ...selectedEditSuggestedCategories,
          modifiedProduct,
        ]);
        setEditWholeCategories([
          ...editWholeCategories,
          anotherModifiedProduct, 
        ]);
      }
    }
  };

  const checkAndRevert = (currentTypes, requestedTypes) => {
    // This function checks the current value and sets the new Value
    if (currentTypes.includes(requestedTypes)) {
      let newTypes = currentTypes?.filter((type) => type !== requestedTypes);
      if (typeof newTypes === "number") {
        newTypes = [];
      }
      setMatchTypes(newTypes);
    } else {
      setMatchTypes([...currentTypes, requestedTypes]);
    }
  };

  const handleChange = (e) => {
    const value = e?.target?.value
    setMatchTypes((prev) => {
      return prev.includes(value)
          ? prev.filter((row) => value !== row)
          : [...prev, value]
      })
  };

  // ALL Table Bodies are here

  let tableBody = (
    <>
      <div className="generic-main-table-body-container">
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="float-left p-3">0 Added</div>
                <div className="float-right">
                  <button
                    type="button"
                    // onClick={handelClick}
                    className="remove-all-btn"
                  >
                    Remove All
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-right-white product-table-first">
                <span className="table-header-text mx-3">All Categories</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14756"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14756)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="black"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </th>
              <th id="product-table-header">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="table-header-text mx-3">
                      Suggested Bid
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1178_14756"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1178_14756)">
                        <path
                          d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                          fill="black"
                        />
                        <path
                          d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </span>
                  <svg
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="menu-icon-table"
                  >
                    <path
                      d="M1.36328 11.625C1.14661 11.625 0.967615 11.5543 0.826281 11.413C0.684281 11.271 0.613281 11.0917 0.613281 10.875C0.613281 10.675 0.684281 10.5 0.826281 10.35C0.967615 10.2 1.14661 10.125 1.36328 10.125H17.3633C17.5799 10.125 17.7593 10.2 17.9013 10.35C18.0426 10.5 18.1133 10.675 18.1133 10.875C18.1133 11.0917 18.0426 11.271 17.9013 11.413C17.7593 11.5543 17.5799 11.625 17.3633 11.625H1.36328ZM1.36328 6.75C1.14661 6.75 0.967615 6.679 0.826281 6.537C0.684281 6.39567 0.613281 6.21667 0.613281 6C0.613281 5.78333 0.684281 5.604 0.826281 5.462C0.967615 5.32067 1.14661 5.25 1.36328 5.25H17.3633C17.5799 5.25 17.7593 5.32067 17.9013 5.462C18.0426 5.604 18.1133 5.78333 18.1133 6C18.1133 6.21667 18.0426 6.39567 17.9013 6.537C17.7593 6.679 17.5799 6.75 17.3633 6.75H1.36328ZM1.36328 1.875C1.14661 1.875 0.967615 1.8 0.826281 1.65C0.684281 1.5 0.613281 1.325 0.613281 1.125C0.613281 0.908333 0.684281 0.729333 0.826281 0.588C0.967615 0.446 1.14661 0.375 1.36328 0.375H17.3633C17.5799 0.375 17.7593 0.446 17.9013 0.588C18.0426 0.729333 18.1133 0.908333 18.1133 1.125C18.1133 1.325 18.0426 1.5 17.9013 1.65C17.7593 1.8 17.5799 1.875 17.3633 1.875H1.36328Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>

          <tbody className="table-body main-table-body" >
            {editSuggestedCategories &&
              editSuggestedCategories.map((category) => {
                let bidRange = `${category.upper_suggested_bid}-${category.lower_suggested_bid}`;
                let category_range = `${category.category_product_lower_range}-${category.category_product_upper_range}`;
                return (
                  <>
                    <tr key={Math.random() * 10000} className="row-height" >
                      <td className="tabledata-tb">
                        <Product
                          image={ProductImage1}
                          Name={category.category_name}
                          Desc={category_range}
                        />
                      </td>
                      <td className="tabledata-tb bids-parent-container">
                        <div className="bids-container">
                          <span
                            className={
                              campaignType === "SP-Product" ? "d-none" : ""
                            }
                          >
                            {currencySymbol}{category.recommended_suggested_bid}
                          </span>
                          <span
                            className={
                              campaignType === "SP-Product" ? "d-none" : ""
                            }
                          >
                            {currencySymbol}{bidRange}
                          </span>
                        </div>
                        <span className="cursor-pointer-global selected-icon-hover">
                          <img
                            src={AddProductButton} 
                            alt="productaddButton"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleAddProduct(category?.category_id)
                             }}
                          />
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );

  const handleDeleteProduct = (id) => {
    setSelectedEditSuggestedCategories((current) => {
      return current.filter((keyword) => {
        return keyword.expression[0].value !== id;
      });
    });
    setEditWholeCategories((current) => {
      return current.filter((keyword) => {
        return keyword.expression[0].value !== id;
      });
    });
  };

  const removeAllCategories = () => {
    setSelectedEditSuggestedCategories([]);
    setEditWholeCategories((current) => {
      return current.filter((keyword) => {
        return keyword.expression[0].type !== "product";
      });
    });
  };

  let tableBodyAdded = (
    <>
      <div className="generic-main-table-body-container">
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="float-left p-3">0 Added</div>
                <div className="float-right">
                  <button
                    type="button"
                    onClick={removeAllCategories}
                    className={
                      selectedEditSuggestedCategories &&
                      selectedEditSuggestedCategories.length !== 0
                        ? "remove-all-btn-dark"
                        : "remove-all-btn"
                    }
                  >
                    Remove All
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-right-white product-table-first">
                <span className="table-header-text mx-3">All Categories</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14756"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14756)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="black"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </th>
              <th id="product-table-header">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="table-header-text mx-3">
                      Suggested Bid
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1178_14756"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1178_14756)">
                        <path
                          d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                          fill="black"
                        />
                        <path
                          d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </span>
                  <svg
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="menu-icon-table"
                  >
                    <path
                      d="M1.36328 11.625C1.14661 11.625 0.967615 11.5543 0.826281 11.413C0.684281 11.271 0.613281 11.0917 0.613281 10.875C0.613281 10.675 0.684281 10.5 0.826281 10.35C0.967615 10.2 1.14661 10.125 1.36328 10.125H17.3633C17.5799 10.125 17.7593 10.2 17.9013 10.35C18.0426 10.5 18.1133 10.675 18.1133 10.875C18.1133 11.0917 18.0426 11.271 17.9013 11.413C17.7593 11.5543 17.5799 11.625 17.3633 11.625H1.36328ZM1.36328 6.75C1.14661 6.75 0.967615 6.679 0.826281 6.537C0.684281 6.39567 0.613281 6.21667 0.613281 6C0.613281 5.78333 0.684281 5.604 0.826281 5.462C0.967615 5.32067 1.14661 5.25 1.36328 5.25H17.3633C17.5799 5.25 17.7593 5.32067 17.9013 5.462C18.0426 5.604 18.1133 5.78333 18.1133 6C18.1133 6.21667 18.0426 6.39567 17.9013 6.537C17.7593 6.679 17.5799 6.75 17.3633 6.75H1.36328ZM1.36328 1.875C1.14661 1.875 0.967615 1.8 0.826281 1.65C0.684281 1.5 0.613281 1.325 0.613281 1.125C0.613281 0.908333 0.684281 0.729333 0.826281 0.588C0.967615 0.446 1.14661 0.375 1.36328 0.375H17.3633C17.5799 0.375 17.7593 0.446 17.9013 0.588C18.0426 0.729333 18.1133 0.908333 18.1133 1.125C18.1133 1.325 18.0426 1.5 17.9013 1.65C17.7593 1.8 17.5799 1.875 17.3633 1.875H1.36328Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>

          <tbody className="table-body main-table-body">
            {selectedEditSuggestedCategories &&
              selectedEditSuggestedCategories.map((category) => {
                let bidRange = `${category.upper_suggested_bid}-${category.lower_suggested_bid}`;
                return (
                  <>
                    <tr key={Math.random() * 10000} className="row-height">
                      <td className="tabledata-tb">
                        <Product
                          image={ProductImage1}
                          Name={category.category_name}
                          Desc={category.Desc}
                        />
                      </td>
                      <td className="tabledata-tb bids-parent-container">
                        <div className="bids-container">
                          <span
                            className={
                              campaignType === "SP-Product" ? "d-none" : ""
                            }
                          >
                            {currencySymbol}{category?.bid}
                          </span>
                          <span
                            className={
                              campaignType === "SP-Product" ? "d-none" : ""
                            }
                          >
                            {currencySymbol}{category?.bidRange}
                          </span>
                        </div>
                        {/* <span>
                          <img
                            src={DeleteProductButton}
                            alt="productaddButton"
                            className="cursor-pointer-global"
                            onClick={() => {
                              handleDeleteProduct(category.expression[0].value)
                            }
                            }
                          />
                        </span> */}
                           <span
                            className="text-left cursor-pointer-global selected-icon-hover" 
                            // onClick={() => onDelete(product)}
                          >
                            <Trash
                              className="text-danger remove-item-btn"
                              height="12px"
                              width="12px" 
                              onClick={() =>
                               handleDeleteProduct(category.expression[0].value)
                              }

                            />
                          </span>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );

  const removeAllAsins = () => {
    const allAsinsIds = editAsins.map((asin) => asin.expression[0]?.value);
    setEditWholeCategories((current) => {
      return current.filter((asin) => {
        return !allAsinsIds.includes(asin.expression[0]?.value);
      });
    });
    setEditAsins([]);
  };

  const onDeleteAsin = (id) => {
    setEditAsins((current) => {
      return current.filter((asin) => {
        return asin.expression[0].value !== id;
      });
    });
    setEditWholeCategories((current) => {
      return current.filter((asin) => {
        return asin.expression[0].value !== id;
      });
    });
  };

  let tableBodyOfAsins = (
    <>
      <div className="generic-main-table-body-container">
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={3} className="table-upper">
                <div className="float-left p-3">0 Added</div>
                <div className="float-right">
                  <button
                    type="button"
                    onClick={removeAllAsins}
                    className={
                      editAsins && editAsins.length !== 0
                        ? "remove-all-btn-dark"
                        : "remove-all-btn"
                    }
                  >
                    Remove All
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <th className="border border-right-white product-table-first">
                <span className="table-header-text mx-3">All Categories</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_1178_14756"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                  >
                    <rect width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_1178_14756)">
                    <path
                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                      fill="black"
                    />
                    <path
                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </th>
              <th id="product-table-header">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="table-header-text mx-3">
                      Suggested Bid
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1178_14756"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1178_14756)">
                        <path
                          d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                          fill="black"
                        />
                        <path
                          d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </span>
                  <svg
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="menu-icon-table"
                  >
                    <path
                      d="M1.36328 11.625C1.14661 11.625 0.967615 11.5543 0.826281 11.413C0.684281 11.271 0.613281 11.0917 0.613281 10.875C0.613281 10.675 0.684281 10.5 0.826281 10.35C0.967615 10.2 1.14661 10.125 1.36328 10.125H17.3633C17.5799 10.125 17.7593 10.2 17.9013 10.35C18.0426 10.5 18.1133 10.675 18.1133 10.875C18.1133 11.0917 18.0426 11.271 17.9013 11.413C17.7593 11.5543 17.5799 11.625 17.3633 11.625H1.36328ZM1.36328 6.75C1.14661 6.75 0.967615 6.679 0.826281 6.537C0.684281 6.39567 0.613281 6.21667 0.613281 6C0.613281 5.78333 0.684281 5.604 0.826281 5.462C0.967615 5.32067 1.14661 5.25 1.36328 5.25H17.3633C17.5799 5.25 17.7593 5.32067 17.9013 5.462C18.0426 5.604 18.1133 5.78333 18.1133 6C18.1133 6.21667 18.0426 6.39567 17.9013 6.537C17.7593 6.679 17.5799 6.75 17.3633 6.75H1.36328ZM1.36328 1.875C1.14661 1.875 0.967615 1.8 0.826281 1.65C0.684281 1.5 0.613281 1.325 0.613281 1.125C0.613281 0.908333 0.684281 0.729333 0.826281 0.588C0.967615 0.446 1.14661 0.375 1.36328 0.375H17.3633C17.5799 0.375 17.7593 0.446 17.9013 0.588C18.0426 0.729333 18.1133 0.908333 18.1133 1.125C18.1133 1.325 18.0426 1.5 17.9013 1.65C17.7593 1.8 17.5799 1.875 17.3633 1.875H1.36328Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </th>
            </tr>
          </thead>

          <tbody className="table-body main-table-body">
            {editAsins &&
              editAsins.map((category, index) => {
                let category_range = category.bid;
                return (
                  <>
                    <tr key={Math.random() * 10000} className="row-height">
                      <td className="tabledata-tb">
                        <Product
                          image={ProductImage1}
                          Name={category.expression[0].value}
                          Desc={category_range}
                        />
                      </td>
                      <td className="tabledata-tb bids-parent-container">
                        <div className="bids-container">
                          {/* <span>{category.name}</span> */}
                          <span
                            className={
                              campaignType === "SP-Product" ? "d-none" : ""
                            }
                          >
                            {category.bid}
                          </span>
                          {/* <span>{category.name}</span> */}
                        </div>
                        {/* <span>
                          <img
                            src={DeleteProductButton}
                            onClick={() =>
                              onDeleteAsin(category.expression[0].value)
                            }
                            alt="productaddButton"
                          />
                        </span> */}
                           <span
                            className="text-left cursor-pointer-global selected-icon-hover" 
                            // onClick={() => onDelete(product)}
                          >
                            <Trash
                              className="text-danger remove-item-btn"
                              height="12px"
                              width="12px" 
                              onClick={() =>
                               onDeleteAsin(category.expression[0].value)
                              }

                            />
                          </span>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );

  let dataHead = ["KEYWORD", "Match Type", "SUGG.BID"];
  const [dynamicHeaders, setDynamicHeaders] = useState([
    "Keywords",
    "Match Type",
    "Sugg. Bid",
    "Edit Bid"
  ]);

  const removeAllKeywords = () => {
    // const existingKeywordsId = editKeywordsToBeAdded.map(
    //   (keyword) => `${keyword.text}-${keyword.match}`
    // );
    // setEditWholeKeywords((current) => {
    //   return current.filter((keyword) => {
    //     return !existingKeywordsId.includes(`${keyword.text}-${keyword.match}`);
    //   });
    // });
    setEditKeywordsToBeAdded([]);
  };

  const handleDeleteKeyword = (id) => {
    const { text, match } = id;
    const matchingKey = `${text}-${match}`;
    let filteredKeywords = editKeywordsToBeAdded.filter((keyword) => {
      let matchingKey2 = `${keyword.text}-${keyword.match}`;
      return matchingKey !== matchingKey2;
    });
    setEditKeywordsToBeAdded(filteredKeywords);
    // setEditWholeKeywords((current) => {
    //   return current.filter((keyword) => {
    //     let matchingKey2 = `${keyword.text}-${keyword.match}`;
    //     return matchingKey !== matchingKey2;
    //   });
    // });
  };

  const bidChange = (input,word) => {
    setEditKeywordsToBeAdded((prev)=>{
      let data = [...prev]
      data = data.map((keyword) => {
        if(keyword.text === word) {
          keyword.userBid = parseFloat(input.target.value)
        }
        return keyword
      })
      return data 
    })
    setEditWholeKeywords((prev)=>{
      let data = [...prev]
      data = data.map((keyword) => {
        if(keyword.text === word) {
          keyword.userBid = parseFloat(input.target.value)
        }
        return keyword
      })
      return data 
    })
  }

  const tableBodyAddedKeywords = (
    <>
      <div className="generic-main-table-body-container keyword-targeting-modal">
        <table className="sp-product-targetting-table">
          <thead className="main-table-head">
            <tr className="table-heading-style">
              <td colspan={4} className="table-upper">
                <div className="float-left text-dark p-3">
                  {editKeywordsToBeAdded && editKeywordsToBeAdded.length} Added
                </div>
                <div className="float-right">
                  <button
                    type="button"
                    onClick={removeAllKeywords}
                    className={
                      editKeywordsToBeAdded &&
                      editKeywordsToBeAdded.length !== 0
                        ? "remove-all-btn-dark"
                        : "remove-all-btn"
                    }
                  >
                    Remove All
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              {dynamicHeaders.map((th, index) => {
                if (index === dynamicHeaders.length - 1) {
                  return (
                    <th
                      className="border border-right-white"
                      onClick={() => {
                        // setIsVisible(true)
                      }}
                    >
                      {th}
                    </th>
                  );
                }
                return <th className="border border-right-white">{th}</th>;
              })}
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {props.editkeywords
              ? editKeywordsToBeAdded &&
                editKeywordsToBeAdded.map((word, index) => {
                  const keywordKey = `${word.text}-${word.match}`;
                  return (
                    <tr key={keywordKey} className="row-height">
                      <td className="tabledata-td">{word.text}</td>
                      <td className="tabledata-td">
                        <div>
                          <span>{word.match}</span>
                          <span onClick={() => handleDeleteKeyword(word)}>
                            <Trash />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : editKeywordsToBeAdded &&
                editKeywordsToBeAdded.map((word, index) => {
                  const keywordKey = `${word.text}-${word.match}`;
                  return (
                    <tr key={keywordKey} className="row-height">
                      <td className="tabledata-td">{word.text}</td>
                      <td className="tabledata-td">
                        <div>
                          <span>{word.match} </span>
                        </div>
                      </td>
                      <td className="tabledata-td">
                      <div className="d-flex flex-column w-max align-items-center">
                        <div>{currencySymbol}{word.bid}</div>
                        <div className="text-muted">
                        <div className="fs-10"><small className="">
                            ({currencySymbol}{word.lowest} - {currencySymbol}{word.heighest})
                          </small>
                        </div>
                        </div>
                      </div>
                    </td>
                    <td className="tabledata-td text-center">
                      <div className="d-flex gap-1 justify-content-between align-items-center" style={{width:'75px'}}>
                        <div className="w-100">
                            <Form.Control
                              size="sm"
                              className="w-100"
                              type="number"
                              step="0.1"
                              value={word.userBid}
                              onChange={(e) => bidChange(e,word.text)}
                              placeholder="Enter your bid"
                            />
                        </div>
                        <div>
                          <span
                            className="text-left d-block selected-icon-hover"
                            onClick={() => handleDeleteKeyword(word)}
                          >
                            <Trash
                              className="text-danger remove-item-btn"
                              height="12px"
                              width="12px"
                            />
                          </span>
                        </div>
                      </div>
                      </td>
                      {/* <td
                        className="tabledata-td"
                        onClick={() => handleDeleteKeyword(word)}
                      >
                        <span>{word.bid}</span>
                        <Trash />
                      </td> */}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <>
      <div className="modal-wrapper add-targeting-modal">
        <Modal
          className="modal-wrapper-targeting modal-wrapper"
          show={props.isVisible}
          onHide={handleClose}
        >
          <Modal.Body>
            <>
              {props.modalBody === "keyword" ? (
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav
                    variant="pills"
                    className="flex-row"
                    style={{ marginTop: "25px", paddingLeft: "10px" }}
                  >
                    <div className="position-relative d-flex justify-content-between align-items-center gap-2 w-100">
                      <div style={{ display: "flex", gap: "20px" }}>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                            className="primary-btn d-flex"
                            style={{ width: "177px" }}
                          >
                            <span className="search-keyword-text">
                              Search Keyword
                            </span>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="svg"
                            >
                              <mask
                                id="mask0_1178_14756"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                              >
                                <rect width="20" height="20" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_1178_14756)">
                                <path
                                  d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                                  fill="white"
                                />
                                <path
                                  d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                                  fill="white"
                                />
                              </g>
                            </svg>
                          </Nav.Link>
                          {/* <button >Tab 1</button> */}
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="primary-btn ms-1 d-flex"
                            eventKey="second"
                          >
                            Enter list
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_1178_14752"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                              >
                                <rect width="20" height="20" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_1178_14752)">
                                <path
                                  d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                                  fill="#4D4184"
                                />
                                <path
                                  d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                                  fill="#4D4184"
                                />
                              </g>
                            </svg>
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div >
                        <img
                          src={closeIcon}
                          onClick={handleClose}
                          style={{ width: "16px" }}
                        ></img>
                      </div>
                    </div>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="keyword-targeting-tab-box">
                            <div className="search-product add-targeting">
                              <SearchbarForEditCampaign
                                keywords={"keywords"}
                                editkeywords={"editkeywords"}
                                placeholder="Search Keywords"
                              ></SearchbarForEditCampaign>
                              <div className="set-filter-modal">
                                <FilterkeywordModal
                                  page={page}
                                  isVisible={newIsVisible}
                                  setIsVisible={setNewIsVisible}
                                />
                              </div>
                              <span
                                className={
                                  newIsVisible
                                    ? "filter-active right-icon"
                                    : "right-icon"
                                }
                                onClick={() => setNewIsVisible(!newIsVisible)}
                                style={{ cursor: "pointer" }}
                              >
                                <svg
                                  width="22"
                                  height="16"
                                  viewBox="0 0 22 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={
                                    newIsVisible ? "white-color-active" : ""
                                  }
                                >
                                  <path
                                    d="M12.8541 12.9987L12.8532 12.9979C12.6483 12.7875 12.387 12.6843 12.0886 12.6843H9.91169C9.61326 12.6843 9.35202 12.7875 9.1477 12.9983C8.94341 13.209 8.84537 13.4749 8.84537 13.7764C8.84537 14.0778 8.94341 14.3437 9.1477 14.5545C9.35202 14.7652 9.61326 14.8685 9.91169 14.8685H12.0886C12.387 14.8685 12.6483 14.7653 12.8532 14.5549L12.8541 14.554C13.0574 14.3432 13.1549 14.0775 13.1549 13.7764C13.1549 13.4753 13.0574 13.2095 12.8541 12.9987ZM20.4774 2.88577L20.4778 2.88534C20.673 2.67165 20.7739 2.4153 20.7739 2.12726C20.7739 1.82618 20.6765 1.56053 20.4727 1.35031C20.2679 1.13903 20.0064 1.03516 19.7076 1.03516H2.29264C1.99384 1.03516 1.73247 1.13906 1.52824 1.35073L1.70816 1.52431L1.52906 1.34988C1.32438 1.56004 1.22632 1.82583 1.22632 2.12726C1.22632 2.41555 1.32785 2.67197 1.52376 2.88555C1.72672 3.10765 1.98938 3.21937 2.29264 3.21937H19.7076C20.011 3.21937 20.2738 3.10761 20.4774 2.88577ZM17.2125 7.17971L17.2121 7.17928C17.0085 6.95744 16.7456 6.84568 16.4423 6.84568H5.55795C5.25471 6.84568 4.99207 6.95738 4.78912 7.17944C4.59318 7.39303 4.49162 7.64947 4.49162 7.93779C4.49162 8.23902 4.58955 8.50465 4.79396 8.71474C4.99813 8.92613 5.25934 9.02989 5.55795 9.02989H16.4423C16.7411 9.02989 17.0026 8.92602 17.2074 8.71474C17.4112 8.50452 17.5086 8.23887 17.5086 7.93779C17.5086 7.64975 17.4077 7.3934 17.2125 7.17971Z"
                                    fill={newIsVisible ? "#fff" : "#07A9D2"}
                                    stroke={newIsVisible ? "#fff" : "#07A9D2"}
                                    stroke-width="0.5"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div>
                              <span className="match-head">
                                Match Type
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <mask
                                    id="mask0_1178_14809"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="20"
                                    height="20"
                                  >
                                    <rect
                                      width="20"
                                      height="20"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_1178_14809)">
                                    <path
                                      d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                                      fill="#4D4184"
                                    />
                                    <path
                                      d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                                      fill="#4D4184"
                                    />
                                  </g>
                                </svg>
                              </span>
                              <div className="checkbox-box">
                                <CheckboxRb
                                  label={"Broad"}
                                  value={"Broad"}
                                  SPKeyword={"SPKeyword"}
                                  checked={matchTypes?.includes("Broad")}
                                  handleChange={handleChange}
                                ></CheckboxRb>
                                <CheckboxRb
                                  label={"Phrase"}
                                  value={"Phrase"}
                                  SPKeyword={"SPKeyword"}
                                  checked={matchTypes?.includes("Phrase")}
                                  handleChange={handleChange}
                                ></CheckboxRb>
                                <CheckboxRb
                                  label={"Exact"}
                                  value={"Exact"}
                                  SPKeyword={"SPKeyword"}
                                  checked={matchTypes?.includes("Exact")}
                                  handleChange={handleChange}
                                ></CheckboxRb>
                              </div>
                              <div className="sp-product-targettig-outer-container">
                                <div className="table-body1">
                                  <SPkeywordTable1
                                    adsetId={id}
                                    amz_category={amz_category}
                                    editkeywords={"editkeywords"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 keyword-targeting-tab-box sp-product-targettig-outer-container">
                          <div className="table-body2">
                            <TableRb table={tableBodyAddedKeywords}></TableRb>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <AddSpKeyword adsetId={id} editkeywords={"editkeywords"} />
                    </Tab.Pane>
                  </Tab.Content>
                  <div
                    className="d-flex justify-content-end gap-10 keyword-targeting"
                    style={{
                      marginTop: "30px",
                      marginRight: "30px",
                      paddingBottom: "30px",
                    }}
                  >
                    <button
                      type="button"
                      className="primary-outline-btn btn btn-outline-primary px-5"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <div onClick={updateKeywords}>
                      <PrimaryBtnRB
                        insideBtnText="Update"
                        id={"create-campaign-btn"}
                        className="width-100"
                      ></PrimaryBtnRB>
                    </div>
                  </div>
                </Tab.Container>
              ) : (
                <>
                  <div
                    className="key-wrap"
                    style={{ paddingLeft: "20px", position: "relative" }}
                  >
                    <div className="d-flex justify-content-between">
                      <span className="common-head">
                        Product Targeting
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_1178_14743"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="21"
                            height="20"
                          >
                            <rect
                              x="0.585693"
                              y="0.0175781"
                              width="20"
                              height="19.9643"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_1178_14743)">
                            <path
                              d="M10.5856 17.9022C9.48839 17.9022 8.45728 17.6943 7.49228 17.2783C6.52672 16.8624 5.68978 16.3009 4.98145 15.5939C4.27311 14.8868 3.71061 14.0513 3.29395 13.0875C2.87728 12.1242 2.66895 11.095 2.66895 9.9997C2.66895 8.90443 2.87728 7.87489 3.29395 6.91106C3.71061 5.94778 4.27311 5.11261 4.98145 4.40554C5.68978 3.69847 6.52672 3.13697 7.49228 2.72105C8.45728 2.30513 9.48839 2.09717 10.5856 2.09717C11.6828 2.09717 12.7142 2.30513 13.6798 2.72105C14.6448 3.13697 15.4814 3.69847 16.1898 4.40554C16.8981 5.11261 17.4606 5.94778 17.8773 6.91106C18.2939 7.87489 18.5023 8.90443 18.5023 9.9997C18.5023 11.095 18.2939 12.1242 17.8773 13.0875C17.4606 14.0513 16.8981 14.8868 16.1898 15.5939C15.4814 16.3009 14.6448 16.8624 13.6798 17.2783C12.7142 17.6943 11.6828 17.9022 10.5856 17.9022ZM10.5856 16.6545C12.4328 16.6545 14.0059 16.0065 15.3048 14.7104C16.6031 13.4139 17.2523 11.8436 17.2523 9.9997C17.2523 8.15577 16.6031 6.58553 15.3048 5.28896C14.0059 3.99294 12.4328 3.34494 10.5856 3.34494C8.73839 3.34494 7.16561 3.99294 5.86728 5.28896C4.56839 6.58553 3.91895 8.15577 3.91895 9.9997C3.91895 11.8436 4.56839 13.4139 5.86728 14.7104C7.16561 16.0065 8.73839 16.6545 10.5856 16.6545Z"
                              fill="#4D4184"
                            />
                            <path
                              d="M10.559 13.9509C10.7396 13.9509 10.889 13.8921 11.0073 13.7745C11.1251 13.6564 11.184 13.5072 11.184 13.327V9.77083C11.184 9.60446 11.1251 9.46221 11.0073 9.34409C10.889 9.22652 10.7396 9.16774 10.559 9.16774C10.3784 9.16774 10.2293 9.22652 10.1115 9.34409C9.99317 9.46221 9.934 9.61139 9.934 9.79162V13.3478C9.934 13.5141 9.99317 13.6564 10.1115 13.7745C10.2293 13.8921 10.3784 13.9509 10.559 13.9509ZM10.559 7.7536C10.7534 7.7536 10.9132 7.68761 11.0382 7.55562C11.1632 7.42419 11.2257 7.26143 11.2257 7.06733C11.2257 6.8871 11.1632 6.73099 11.0382 6.599C10.9132 6.46757 10.7534 6.40186 10.559 6.40186C10.3646 6.40186 10.2048 6.46757 10.0798 6.599C9.95483 6.73099 9.89233 6.8871 9.89233 7.06733C9.89233 7.26143 9.95483 7.42419 10.0798 7.55562C10.2048 7.68761 10.3646 7.7536 10.559 7.7536Z"
                              fill="#4D4184"
                            />
                          </g>
                        </svg>
                      </span>
                      <span className="cursor-pointer"
                        style={{ paddingRight: "30px", paddingTop: "20px" }}
                      >
                        <img
                          src={closeIcon}
                          onClick={handleClose}
                          style={{ width: "15px" }}
                          alt={"Close Icon"}
                        ></img>
                      </span>
                    </div>
                    <div className="tab-wrap">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <Nav variant="pills" className="flex-row">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              className="primary-btn d-flex"
                              style={{ width: "180px" }}
                            >
                              <span className="product-search-text">
                                Search Product
                              </span>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <mask
                                  id="mask0_1178_14756"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="20"
                                  height="20"
                                >
                                  <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1178_14756)">
                                  <path
                                    d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                                    fill="white"
                                  />
                                </g>
                              </svg>
                            </Nav.Link>
                            {/* <button >Tab 1</button> */}
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              className="primary-btn ms-1 d-flex"
                              eventKey="second"
                              style={{ width: "180px" }}
                            >
                              <span className="product-search-text">
                                Enter ASIN's
                              </span>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <mask
                                  id="mask0_1178_14752"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="20"
                                  height="20"
                                >
                                  <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1178_14752)">
                                  <path
                                    d="M9.99992 17.9168C8.9027 17.9168 7.87159 17.7085 6.90659 17.2918C5.94103 16.8752 5.10409 16.3127 4.39575 15.6043C3.68742 14.896 3.12492 14.0591 2.70825 13.0935C2.29159 12.1285 2.08325 11.0974 2.08325 10.0002C2.08325 8.90294 2.29159 7.87155 2.70825 6.906C3.12492 5.941 3.68742 5.10433 4.39575 4.396C5.10409 3.68766 5.94103 3.12516 6.90659 2.7085C7.87159 2.29183 8.9027 2.0835 9.99992 2.0835C11.0971 2.0835 12.1285 2.29183 13.0941 2.7085C14.0591 3.12516 14.8958 3.68766 15.6041 4.396C16.3124 5.10433 16.8749 5.941 17.2916 6.906C17.7083 7.87155 17.9166 8.90294 17.9166 10.0002C17.9166 11.0974 17.7083 12.1285 17.2916 13.0935C16.8749 14.0591 16.3124 14.896 15.6041 15.6043C14.8958 16.3127 14.0591 16.8752 13.0941 17.2918C12.1285 17.7085 11.0971 17.9168 9.99992 17.9168ZM9.99992 16.6668C11.8471 16.6668 13.4202 16.0177 14.7191 14.7193C16.0174 13.4204 16.6666 11.8474 16.6666 10.0002C16.6666 8.15294 16.0174 6.57989 14.7191 5.281C13.4202 3.98266 11.8471 3.3335 9.99992 3.3335C8.1527 3.3335 6.57992 3.98266 5.28158 5.281C3.9827 6.57989 3.33325 8.15294 3.33325 10.0002C3.33325 11.8474 3.9827 13.4204 5.28158 14.7193C6.57992 16.0177 8.1527 16.6668 9.99992 16.6668Z"
                                    fill="#4D4184"
                                  />
                                  <path
                                    d="M9.97331 13.9585C10.1539 13.9585 10.3033 13.8996 10.4216 13.7818C10.5394 13.6635 10.5983 13.5141 10.5983 13.3335V9.771C10.5983 9.60433 10.5394 9.46183 10.4216 9.3435C10.3033 9.22572 10.1539 9.16683 9.97331 9.16683C9.79275 9.16683 9.64359 9.22572 9.52581 9.3435C9.40747 9.46183 9.34831 9.61127 9.34831 9.79183V13.3543C9.34831 13.521 9.40747 13.6635 9.52581 13.7818C9.64359 13.8996 9.79275 13.9585 9.97331 13.9585ZM9.97331 7.75016C10.1678 7.75016 10.3275 7.68405 10.4525 7.55183C10.5775 7.42016 10.64 7.25711 10.64 7.06266C10.64 6.88211 10.5775 6.72572 10.4525 6.5935C10.3275 6.46183 10.1678 6.396 9.97331 6.396C9.77886 6.396 9.61914 6.46183 9.49414 6.5935C9.36914 6.72572 9.30664 6.88211 9.30664 7.06266C9.30664 7.25711 9.36914 7.42016 9.49414 7.55183C9.61914 7.68405 9.77886 7.75016 9.97331 7.75016Z"
                                    fill="#4D4184"
                                  />
                                </g>
                              </svg>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="row">
                              <div className="col-lg-6 sp-product-targettig-outer-container">
                                <TableRb table={tableBody}></TableRb>
                              </div>
                              <div className="col-lg-6 sp-product-targettig-outer-container">
                                <TableRb table={tableBodyAdded} />
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div className="row">
                              <div className="col-lg-6 ">
                                <AsinsTable
                                  update={"update"}
                                  campaignType={campaignType}
                                  amz_category={id}
                                />
                              </div>
                              <div className="col-lg-6">
                                <div className="sp-product-targettig-outer-container">
                                  <TableRb
                                    className=""
                                    table={tableBodyOfAsins}
                                  ></TableRb>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                    <div></div>
                    <div
                      className="d-flex justify-content-end gap-10 keyword-targeting"
                      style={{
                        marginTop: "30px",
                        marginRight: "30px",
                        paddingBottom: "30px",
                      }}
                    >
                      <button
                        type="button"
                        className="primary-outline-btn btn btn-outline-primary px-5"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <div onClick={updateCategories}>
                        <PrimaryBtnRB
                          insideBtnText="Update"
                          id={"create-campaign-btn"}
                          className="width-100"
                          page={page}
                        ></PrimaryBtnRB>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default AddTargetingModal;

const Product = ({ image, Name, Desc }) => {
  return (
    <>
      <div className="product-container">
        <div className="product-img-container">
          <img src={image} alt="product" /> 
        </div>
        <div className="product-description-container">
          <span className="category-heading">Category</span>
          <span className="product-name">{Name && Name}</span>
          <span className="product-desc">Product:{Desc}</span>
        </div>
      </div>
    </>
  );
};
