import React from "react";
import { useState , useMemo } from "react";
import { Modal } from "react-bootstrap";
import RadioButtonRb from "../../../Buttons/radioButtonRb/radioButtonRb";
import "./addNegativeTargeting.css";
import SecondaryBtnRb from "../../../Buttons/secondaryBtnRb/secondaryBtnRb";
import MultiSelect from "../../../DropDown/Multiselect";
import { IoMdClose } from "react-icons/io";
import { FaCross } from "react-icons/fa";
import srcIcon from '../../../../assets/icons/downArrowForSplitButton.svg'
import CustomDropDown from "../../../DropDown/CustomDropDown";

const AddToNegativeTargetingModal = ({ show, onNoClick, onYesClick,defaultSelectedCampaigns , confirmationId,optionsForCampaigns,optionsForAdgroups,showMatchType }) => {
  const [activeTab, setActiveTab] = useState("");
  const [keywordActiveTab,setKeywordActiveTab] = useState("Exact");
  const [options, setOptions] = useState([...optionsForCampaigns]);
  const [selectedCampaignsForAdgroups,setSelectedCampaignsForAdgroups] = useState([...defaultSelectedCampaigns])
  const [adgroupOptions,setAdgroupOptions] = useState([...optionsForAdgroups]);
  const [selectedCategory, setSelectedCategory] = useState([...defaultSelectedCampaigns]);
  const [selectedAdgroups,setSelectedAdgroups] = useState([]);
  const dataForDropDown = options?.map((data) => data?.name)
  const mergedData = useMemo(() => {
    return mergeCampaignsAndAdgroups(options,adgroupOptions)
  },[options,adgroupOptions]) 
  const mergedDataOptionsForDropDown =  useMemo(() => {
    return getMergedDataOptionsForDropDown(selectedCampaignsForAdgroups,mergedData)
  },[mergedData,selectedCampaignsForAdgroups])

 
 

  // Effects

  function mergeCampaignsAndAdgroups(campaignData,adgroupData) {
    const mergedData = adgroupData?.reduce((acc,curr) => {
      const foundedCampaign = campaignData?.find((data) => data?.id === curr?.campaign_id)
      if(foundedCampaign) {
        acc.push({name:`${curr?.name} (Camp. Name:- ${foundedCampaign?.name})`,id:curr?.id,campaign_name:foundedCampaign?.name})
      }
      return acc
    },[])
    return mergedData
  }

  function getMergedDataOptionsForDropDown(selectedCampaigns,overAllMergedData) {
   return overAllMergedData?.reduce((acc,curr) => {
      if(selectedCampaigns?.length) {
        const foundedAdgroup = selectedCampaigns?.find((campaign) => campaign === curr?.campaign_name)
        if(foundedAdgroup) {
          acc.push(curr.name)
        }
      } else {
        acc.push(curr.name)
      } 
      return acc
    },[]) 
  }

  const renderItem = (item) => {
    const [itemName, campaignName] = item.split(' (Camp. Name:- ');
    const campaignNameModified = `(Camp. Name:- ${campaignName}`;
    const isSlicingRequired = campaignNameModified?.length > 70
    const slicedString = isSlicingRequired ? campaignNameModified?.slice(0,70) + "..." : campaignNameModified
    return <>{itemName} <br className="m-0"/><p className="m-0" title={campaignNameModified}>{slicedString}</p> </>
  }

  const renderCampaignLevelItem = (item) => {
    const isSlicingRequired = item?.length > 70
    const slicedString = isSlicingRequired ? item?.slice(0,70) + "..." : item
    return <><p className="m-0" title={item}>{slicedString}</p> </>
  }




  // Methods
  const cancelHandler = () => {
    onNoClick();
  };

  const onupdateClickHandler = () => {
    const campaignIds = options.filter((data) => {
      let result = false
      const selectedCampaigns = selectedCategory
      selectedCampaigns.forEach((name) => {
        if(data.name?.toLowerCase() === name?.toLowerCase()) {
          result = true
        }
      })
      return result
    }).map((campaign) => campaign?.id)
    const adgroupIds = selectedAdgroups?.reduce((acc,curr) => {
        const foundedId = mergedData?.find((data) => data?.name === curr)
        if(foundedId) {
          acc.push(foundedId?.id)
        }
        return acc
    },[])
    const details = {
      level:activeTab,
      campaignIds:campaignIds,
      adgroupIds:adgroupIds,
      match_type:keywordActiveTab
    }
    onYesClick(details)
  };

  const changeTab = (recievedTab,type) => {
    if(type==="Campaign") {
      setActiveTab(recievedTab);
    } else {
      setKeywordActiveTab(recievedTab)
    }
  };

  const handleApply = (e,type) => {
    if(type==="Campaign" && activeTab==="campaign") {
      setSelectedCategory(e);
    } else if(activeTab==="adgroup" && type==="Campaign") {
      setSelectedCampaignsForAdgroups(e)
    } else {
      setSelectedAdgroups(e)
    }
  };


  const searchLogic = (option,value) => {
    const [itemName, campaignName] = option.split(' (Camp. Name:- ');
    return itemName?.toLowerCase()?.includes(value);
  }



  const modalBody = (
    <>
      <div className="bid-config-modal-container">
        <div className="negative-targeting-modal-header px-4 py-3 align-items-center">
          <h5 className="m-0 fw-bold negative-targeting-modal-heading">Add To Negative Targeting</h5>
          <IoMdClose onClick={cancelHandler} size={20} className="cursor-pointer" />
        </div>
        <hr className="m-0" />
        <div className="px-4 py-3">
          <h6 className="mx-1 fw-bold">Mark as Negative targeting on</h6>
          <div className="d-flex gap-2 justify-content-between mt-2">
                <RadioButtonRb
                name="select-Campaign-Details"
                checked={activeTab === "account"}
                label={"Account Level"}
                id={"Account Level"}
                onChange={() => {
                  changeTab("account","Campaign");
                }}
              ></RadioButtonRb>
                <RadioButtonRb
                name="select-Campaign-Details"
                checked={activeTab === "campaign"}
                label={"Campaign Level"}
                id={"Campaign Level"}
                onChange={() => {
                  changeTab("campaign","Campaign");
                }}
              ></RadioButtonRb>
                <RadioButtonRb
                name="select-Campaign-Details"
                checked={activeTab === "adgroup"}
                label={"Adgroup Level"}
                id={"Adgroup Level"}
                onChange={() => {
                  changeTab("adgroup","Campaign");
                }}
              ></RadioButtonRb>
          </div>
          <div className={`mt-2 d-flex flex-column ${activeTab==="account"?"mst-negative-modal-drop-down-margin": activeTab==="campaign" ? "mb-1" : ""}`}>
            <div className="px-1">
              {showMatchType && <CustomDropDown classes={"w-100"} src={srcIcon} title={"Choose Match Type"} data={["Phrase","Exact"]} filterCampaigns={(e,type) => {
                        changeTab(e?.target.value)
              }} targettingValue={keywordActiveTab}/>}
            </div>
         
            </div> 

        </div>
        {activeTab === "campaign" && (
          <>
            <div className="px-4">
              <div className="px-1">
                <MultiSelect
                  title={"Choose Campaign"}
                  renderItem={renderCampaignLevelItem}
                  defaultSelected={selectedCategory}
                  options={dataForDropDown}
                  onApply={(e) => handleApply(e, "Campaign")}
                  isSearch={true}
                  placeHolder={"Search For Campaigns"}
                  classes={"position-static mst-negative-modal-drop-down-height"}
                />
              </div>
         
            </div>
          </>
        )}
        {activeTab === "adgroup" && (
          <>
            {
              <>
                <div className="px-4 ">
                  <div className="px-1 d-flex flex-column gap-3">
                    <MultiSelect
                      title={"Choose Campaigns"}
                      defaultSelected={selectedCampaignsForAdgroups}
                      options={dataForDropDown}
                      renderItem={renderCampaignLevelItem}
                      onApply={(e) => handleApply(e, "Campaign")}
                      isSearch={true}
                      placeHolder={"Search For Campaigns"}
                      classes={
                        "position-static mst-negative-modal-drop-down-height"
                      }
                    />
                    <MultiSelect
                      title={"Choose Adgroup"}
                      defaultSelected={selectedAdgroups}
                      renderItem={renderItem}
                      searchLogic={searchLogic}
                      customSearch={true}
                      options={mergedDataOptionsForDropDown}
                      onApply={(e) => handleApply(e, "adgroup")}
                      isSearch={true}
                      placeHolder={"Search For Adgroup"}
                      classes={
                        "position-static mst-negative-modal-drop-down-height"
                      }
                    />
                  </div>
                </div>
              </>
            }
          </>
        )}
      </div>
    </>
  );

  return (
    <div className="filetr-modal-for-targeting-main-container">
      <Modal
        show={show}
        size={"lg"}
        scrollable="true"
        className="add-negative-targeting-modal"
        backdropClassName="edit-bid-for-targetinng-backdrop"
        contentClassName="edit-bid-for-targetinng-conntennt"
        dialogClassName="negative-targeting-modal-dialog"
        
      >
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer className="add-negative-targeting-modal-footer">  <div className="targeting-btn-container-for-bid mt-2 justify-content-end">
          <SecondaryBtnRb
            secondaryBtnText="Cancel"
            onClick={cancelHandler}
            className="primary-inactive-btn px-5 mx-3"
          ></SecondaryBtnRb>
          <SecondaryBtnRb
            secondaryBtnText="Save"
            id={confirmationId ?? "save"}
            disabled={activeTab === "" || (activeTab === "campaign" && selectedCategory.length === 0) || (activeTab === "adgroup" && selectedAdgroups?.length===0)}
            onClick={onupdateClickHandler}
            className="primary-active-btn px-5"
          ></SecondaryBtnRb>
        </div></Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddToNegativeTargetingModal;
