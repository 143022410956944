import React, { useRef, useState } from "react";
import "./automationslogs.css";
import { Stack } from "react-bootstrap";
import SectionTitle from "../../components/sectionTitle";
// Components
import TableRb from "../../components/Tables/tableRb";
import { formatDate } from "../../components/Reports/Components/utils";
import { useGlobalContext } from "../../context/global";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
// Constants
import {dataHeadsForAutomationLogs} from '../../constant/dataHeadsForAutomationsLogs'
import { useEffect } from "react";
import useHighlightList from "../../hooks/useHighlightList";
import { masterApi } from "../../apis/masterApi";
import useInitialLoad from "../../hooks/useInitialLoad";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../constant/HardTypes";
import moment from "moment/moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import LazyLoadSpinner from "../../components/spinners/LazyLoadSpinner";
import ModalRb from "../../components/modal/ModalRb";
const AutomationsLogs = () => {
  const { showAlert, applyCalendar, calendarDate, cubeToken, setCubeToken, clientId } = useGlobalContext();
  const [token, refresh] = useToken("");
  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });
  const limit = 100;
  const tableRefScroll = useRef(null);
  const [offset,setOffset] = useState(0);
  const [lazyLoading,setLazyLoading] = useState(false);
  const [automationLogs, setAutomationLogs] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [initialLoad, setInitialLoad] = useInitialLoad();
  const [allLoaders, setAllLoaders] = useState({ mainLoader: false });
  const [handleHighlightList] = useHighlightList(automationLogs,setAutomationLogs);
  const tableName = "rule_entity_tracking";
  const [isVisible,setIsVisible] = useState(false);
  const [campaignsToShow,setCampaignsToShow] = useState([]);
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "rule_entity_tracking.rule_name",
    "rule_entity_tracking.rule_task_name",
    "rule_entity_tracking.rule_config",
    "rule_tracking.parent_rule_config",
    "rule_entity_tracking.created_at",
    "rule_entity_tracking.entity_id",
    "rule_entity_tracking.message",
    "rule_entity_tracking.decision",
    "rule_entity_tracking.parent_entity_name",
    "rule_entity_tracking.entity_name"
  ]);
  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["rule_entity_tracking.created_at", "desc"],
  ]);
  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState([
    {
      dimension: "rule_entity_tracking.time",
      dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ],
    },
  ]);
  const [cubeQueryFilters, setCubeQueryFilters] = useState([
    {
      member: "rule_entity_tracking.client_id",
      operator: "equals",
      values: [clientId],
    },
    {
      "member": "rule_entity_tracking.type",
      "operator": "equals",
      "values": [
        "AMAZON_Bid_Optimisation_Rule"
      ]
    },
    {
      "member": "rule_entity_tracking.change_recommended",
      "operator": "equals",
      "values": [
        "1"
      ]
    }
  ]);
  useEffect(() => {
        const startDate = formatDate(calendarDate[0].startDate);
        const endDate = formatDate(calendarDate[0].endDate);
        if (startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] || endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]) {
          setAllLoaders({ ...allLoaders, mainLoader: true });
            setCubeQueryTimeDimensions((prev) => {
                let data = [...prev];
                data[0]["dateRange"] = [startDate, endDate];
                return data;
            });
            setOffset(0)
        }
}, [applyCalendar]);
  useEffect(() => {
    //table data
    if (cubeToken && campaignData && campaignData.length > 0) {
      setAllLoaders({ allLoaders, mainLoader: true });
      cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            filters: cubeQueryFilters,
            offset:0,
            timeDimensions: cubeQueryTimeDimensions,
            limit: limit,
          },{
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          let resultData = resultSet.loadResponse.results[0].data.map(
            (data, index) => {
              if(data['rule_tracking.parent_rule_config'] !== null) {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: getCampaignNames(getCampaignIds(JSON.parse(data['rule_tracking.parent_rule_config']))),
                };
              } else {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: "",
                };
              }
              
            }
          );
          setAutomationLogs(resultData)
          setAllLoaders({ allLoaders, mainLoader: false });
          setInitialLoad(false);
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    }
  }, [cubeQueryFilters, cubeQueryTimeDimensions, cubeToken, cubeQueryOrder, campaignData]);

  function getCampaignIds(data) {
    let campaignIds = [];
    if (data.entityFilter && data.entityFilter.entityFilterRule && data.entityFilter.entityFilterRule.filters) {
      const filters = data.entityFilter.entityFilterRule.filters;
      for (const filter of filters) {
        if (filter.field === "Campaign_Id") {
          campaignIds = filter.value || [];
          break; // Assuming there is only one filter with "Campaign_Id"
        }
      }
    }
    return campaignIds
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setAllLoaders({ ...allLoaders, mainLoader: true });
        const dataBidValues = {
          limit: 5000,
          source: "amazon",
          fields:'id,platform_id,name'
        };
        const campData = await masterApi(dataBidValues, "campaignList", false);
        setCampaignData(campData?.data?.data);
        if (campData?.statusCode !== 200) {
          showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
            backgroundColor: TOAST_DANGER,
            title: "Error",
            description: `Some Unknown Error Occurred While Fetching Campaigns`,
          });
        }
        setAllLoaders({ ...allLoaders, mainLoader: false });
      } catch (error) {
        setAllLoaders({ ...allLoaders, mainLoader: false });
      }
    };
    // Call the async function
    fetchData();
  }, []);
  const getCampaignNames = (campaignIds) => {
    const names = campaignIds.map((campaignId) => {
      const campaign = campaignData.find((item) => item.id == campaignId);
      return campaign ? campaign.name : null;
    });
    return names.filter(Boolean)
  };
  function getCommaSepratedValue (data) {
    
    let formattedValues = JSON.parse(data)?.scheduleConfig?.frequencyValues.map(day => day.charAt(0).toUpperCase() + day.slice(1))
    if(formattedValues) {
      return formattedValues.join(', ')
    } else {
      // console.log(JSON.parse(data))
      return JSON.parse(data)?.scheduleConfig?.frequencyValues
    }
    
  }
  const targetingsMAPPER = {
    QUERY_HIGH_REL_MATCHES: "close match",
    QUERY_BROAD_REL_MATCHES: "loose match",
    ASIN_ACCESSORY_RELATED: "complements",
    ASIN_SUBSTITUTE_RELATED: "substitutes",
  };

  function replaceText(text) {
    let updatedText = text;
  
    Object.keys(targetingsMAPPER).forEach(key => {
      if (text?.includes(key)) {
        const mappedValue = targetingsMAPPER[key];
        updatedText = updatedText.replace(key, mappedValue);
      }
    });
  
    return updatedText;
  }


const handleCampaignShow = (data) => {
  setIsVisible(true);
  setCampaignsToShow(data)
}

  const showOnlyRestrictedText = (trimTill, data) => {
    const slicedData = data?.slice(0, trimTill);
    return (
      <>
        <span title={"Click to see all campaigns"} onClick={() => {
          handleCampaignShow(data)
        }}  data-tooltip-id={"campaign-names"}>{slicedData?.join(",")}</span>
      </>
    );
  };

  
  const loadMoreData = async (offset) => {
    return new Promise((resolve,reject) => {
        try {
          cubejsApi.load({
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            filters: cubeQueryFilters,
            offset:offset,
            timeDimensions: cubeQueryTimeDimensions,
            limit: limit,
          },{
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          let resultData = resultSet.loadResponse.results[0].data.map(
            (data, index) => {
              if(data['rule_tracking.parent_rule_config'] !== null) {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: getCampaignNames(getCampaignIds(JSON.parse(data['rule_tracking.parent_rule_config']))),
                };
              } else {
                return {
                  ...data,
                  [`${tableName}.campaign_name`]: "",
                };
              }
              
            }
          );
          resolve(resultData)
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
        } catch (error) {
          console.log(error)
          reject(error)
        }
    }) 
  };

  const handleScroll = async () => {
    if (tableRefScroll.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRefScroll.current;
      const lastScrollTop = tableRefScroll.current.getAttribute("data-scroll-top") || 0;
      // Determine scroll direction
      const scrollDirection = tableRefScroll.current.scrollTop > lastScrollTop ? "down" : "up";
      tableRefScroll.current.setAttribute("data-scroll-top", scrollTop);
      if (scrollTop === 0) {
      } else if ((scrollTop + clientHeight >= scrollHeight - 50) && lazyLoading===false && scrollDirection==="down") {
          const newOffset = offset+limit
          setLazyLoading(true)
          const data = await loadMoreData(newOffset);
          if(data?.length) {
            setAutomationLogs([...automationLogs,...data]);
            setLazyLoading(false)
            setOffset(newOffset)
          } else {
            setLazyLoading(false)
          }

      }
    }
  };

  const renderTableCell = (data, dataHead) => {
    if (!dataHead?.isRestColumn) {
      const columnId = dataHead.id;
        if (columnId === 'rule_config') {
          if(JSON.parse(data['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.percentageChange === undefined) {
            return JSON.parse(data['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.actionType 
          } else {
            return JSON.parse(data['rule_entity_tracking.rule_config'])?.ruleActionConfig?.entityActionConfig?.actionType + " by "+ JSON.parse(data[`${tableName}.${dataHead.id}`])?.ruleActionConfig?.entityActionConfig?.percentageChange + "%"
          }
        } else if (columnId === 'parent_rule_config') {
          return getCommaSepratedValue((data['rule_tracking.parent_rule_config']))
        } else if (columnId === 'lookback_window') {
          return JSON.parse(data[`${tableName}.rule_config`])?.entityMetricFilter?.timePeriod 
        } else if (columnId === 'created_at') {
          return moment.utc(data[`${tableName}.${dataHead.id}`]).local().format("HH:mm:ss DD-MM-YYYY")
        } else if (columnId === 'decision_old' && JSON.parse(data[`${tableName}.decision`]).extraInfo && JSON.parse(data[`${tableName}.decision`]).extraInfo.OldBid !== undefined) {
          return JSON.parse(data[`${tableName}.decision`]).extraInfo.OldBid
        } else if (columnId === 'decision_new' && JSON.parse(data[`${tableName}.decision`]).extraInfo && JSON.parse(data[`${tableName}.decision`]).extraInfo.NewBid !== undefined) {
          return JSON.parse(data[`${tableName}.decision`]).extraInfo.NewBid
        } else if (columnId === 'decision_status') {
          if(JSON.parse(data[`${tableName}.decision`]).decisionApplied === true) {
            return "True"
          } else {
            return "False"
          }
        } else if (columnId === 'entity_name') {
           return replaceText(data[`${tableName}.${columnId}`]) ?? "-"
        } else if (columnId === "campaign_name") {
          return showOnlyRestrictedText(4,data[`${tableName}.${columnId}`]) ?? "-"
        } else {
          // Default case for other columns
          return data[`${tableName}.${columnId}`] || '-'
        }
    }}
  const renderTableFormattedCell = (data, index) => {
    return <td>{renderTableCell(data, index)}</td>
  }
  const renderTableRow = (data, index) => {
      return (<tr key={index} className={data?.isHighlighted ? 'cursor-pointer highlight' : 'cursor-pointer'} onClick={() => handleHighlightList(index)}>
      {dataHeadsForAutomationLogs?.map((dataHead) =>  renderTableFormattedCell(data, dataHead))}
    </tr>)
  };
  const tableBody = (
    <>
      <div className="main-campaignlist-table-body-container automations-logs-main-table-wrapper" ref={tableRefScroll} onScroll={handleScroll}>
        {automationLogs?.length ? (
          <>
            <table  className="automations-table">
              <thead className="fixed-table">
                <tr className="main-table-row-fixed">
                  {dataHeadsForAutomationLogs?.map((dataHead) => {
                    return (
                      <th
                        key={dataHead?.fieldName}
                        className={"targeting-table-heads"}
                      >
                        {dataHead?.fieldName}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="another-body">{automationLogs?.map(renderTableRow)}</tbody>
            </table>
            {lazyLoading && (
                      <div className="lazy-loading-container d-flex p-2 justify-content-center align-items-center w-100">
                        <LazyLoadSpinner
                          text={`Loading ${limit} More Logs...`}
                        />
                      </div>
                    )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  const filterHeads = [
    {
      fieldName:'Campaign Name'
    }
  ]
  const renderMainTableBody =(data) => {
    return (
      <>
          <div className="main-campaignlist-table-body-container automations-main-table-wrappe automation-filter-table-body">
    {data?.length ? (
      <>
        <table className="automations-list-table">
          <thead className="fixed-table">
            <tr >
              {filterHeads?.map((th) => {
                return <th style={{position:'sticky',top:0}} key={th.fieldName}>{th.fieldName}</th>;
              })}
            </tr>
          </thead>
          <tbody className="another-body">
            {data?.map((name) => {
              return (
                <tr key={name}>
                  <td>{name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : null}
  </div>
      </>
    )
  
  }
  const mainModalBodyToShow = (
    <div className="d-flex gap-5">
    {campaignsToShow.length ? (
      <>
         <div className="automations-table-wrapper">
         <small className="text-center">{`Total Campaigns:- (${campaignsToShow?.length})`}</small>  
                  <div className="automations-main-table">
                    <TableRb table={renderMainTableBody(campaignsToShow)} />
                  </div>
          </div>
      </>
    ) : (
      <h2>No Data To Show</h2>
    )}
  </div>
  )
  return (
    <div className={"main-content-container-oncolapse-mode"}>
      <ModalRb
        dialogClassName={"automation-filter-modal"}
        Modalheading={"Campaigns"}
        show={isVisible}
        ModalMainBody={mainModalBodyToShow}
        onHide={() => {
          setIsVisible(false)
        }}
      />
      <div className="campaign-list-container overflow-hidden">
        <Stack>
          <SectionTitle sectionTitleHeading={"Automation Logs"} />
          <div className="automation-logs-table-wrapper">
            <Stack className="automation-logs-main-table">
              <TableRb table={tableBody} tableHeads={dataHeadsForAutomationLogs} shimmer={allLoaders?.mainLoader} noDataFound={
                  !allLoaders?.mainLoader && !initialLoad && !automationLogs?.length
                } />
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};
export default AutomationsLogs;