import { useAuthUser, useSignIn, useSignOut } from "react-auth-kit";
import { useEffect } from "react";

const useUserDetails = () => {
  const auth = useAuthUser();
  let userDetails = auth();

  const signOut = useSignOut();

  //  This Async function will validate the token whenever we retrieve the information about the user.
  const validateToken = async () => {
    const url = process.env.REACT_APP_LOGIN_API+"/api/login/validateToken";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "AMZN-D1457662DFDC43CD8CB7D856C341D",
      },
      body: JSON.stringify({ token: userDetails?.token })
    };
    try {
      if(userDetails !== null) {
        const response = await fetch(url, options);
        const data = await response.json();
        if (!data.success) {
          signOut();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    validateToken();
  }, [])

  return [userDetails];
};

export default useUserDetails;
