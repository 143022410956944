import React from "react";
import SecondaryBtnRb from "../../../Buttons/secondaryBtnRb/secondaryBtnRb";
import CheckboxRb from "../../../Buttons/checkbox/checkbox";
import searchIcon from "../../../../assets/icons/search_icon.svg";
import "./masterTargetingSearch&selectModal.css";
import { useState, useRef, useEffect } from "react";

const MasterTargetingSearchSelectModal = (props) => {
  // State
  const [initialData, setInitialData] = useState(props.initialData);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState(props.defaultSelected?props.defaultSelectedValue:[]);
  const { currentFilters } = props;
  // Refs
  const modalCloseRef = useRef(null);

  // Effects

  useEffect(() => {
    document.addEventListener("mousedown", closeModal);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeModal);
    };
  }, [currentFilters]);


  useEffect(() => {
    setInitialData(props.initialData)
  },[props.initialData])

  useEffect(() => {
    if(props.defaultSelected) {
      setIsCheck(props.defaultSelectedValue?.flat(1))
    }
  },[props.defaultSelectedValue])

  // Methods

  const closeModal = (e) => {
    if (
      modalCloseRef.current &&
      currentFilters &&
      !modalCloseRef.current.contains(e.target)
    ) {
      props.cancelFilter({ ...props.currentFilters, searchSortModal: false });
    }
  };

  const onSearch = (e) => {
    // This will help in searching it will take the raw data and apply filter on that and will set the UI Layer
    const filteredData = props.initialData.filter((dataName) => {
      return dataName?.name?.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setInitialData(
      filteredData.length === 0 ? props.initialData : filteredData
    );
  };

  const onSingleColumnHandler = (e, dataName) => {
    // This method will set Everything set the checkbox flag and also append/delete it from the filters list.
    const { checked } = e.target;
    setIsCheck([...isCheck, dataName]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== dataName));
    }
  };

  const onCancelHandler = (e,data) => {
    props.cancelFilter({ ...props.currentFilters, searchSortModal: false });
  };

  const onApplyHandler = () => {
    const dataToBeSet = props.initialData.filter((mainData) => { 
     return isCheck.includes(mainData.name);
    })
    setInitialData([...new Set(dataToBeSet.concat(initialData))]);
    props.cancelFilter({ ...props.currentFilters, searchSortModal: false });
    props.getUpdatedDrowDownFilters(dataToBeSet,props.keyToBeSet)
  };

  const onAllColumnHandler = () => {
    // This method will put whole data into filters List and or will whole data from isCheck
    setIsSelectAll(!isSelectAll);
    setIsCheck(initialData.map((li) => li.name));
    if (isSelectAll) {
      setIsCheck([]);
    }
  };

  const resetAll = () => {
    // This method will reset the component to it's initial Stage.
    setInitialData(props.initialData);
    setIsSelectAll(false);
    setIsCheck([]);
    props.getUpdatedDrowDownFilters("",props.keyToBeSet)
  };

  return (
    <div
      // ref={searchInColumnRef}
      className={
        props.findClickedColumnName && props.isVisible
          ? "find-box-container"
          : "hide"
      }
      ref={modalCloseRef}
    >
      <div className={props.dynamicWidth?"find-box-mainn-container dynamic-width":"find-box-mainn-container"}>
        <div className={props.dynamicWidth?"search-box-infind-box dynamic-width-search-bar":"search-box-infind-box"}>
          <div>
            <img src={searchIcon} alt="searchIcon"></img>
          </div>
          <div className="finnd-input-text">
            <input
              type="text"
              placeholder={
                props.findClickedColumnName.includes("Portfolio") 
                  ? "Portfolio name"
                  : props.findClickedColumnName.includes("Campaign")  
                  ? "Campaign name"
                  : props.findClickedColumnName === "AD Group"
                  ? "AD Group name"
                  :props.findClickedColumnName === "Category"
                  ? "Search Category Name"
                  : props.searchTextPlaceholder
              }
              id={"mainInput"}
              onChange={onSearch}
            ></input>
          </div>
        </div>
        <div className="search-list-of-data">
          <div className="search-result-row">
            <div className="select-reset-dropdown d-flex justify-content-between align-items-center w-100">
              <CheckboxRb
                inputLabelClass="selectAllStyles"
                label={"Select All"}
                checked={isSelectAll}
                onChange={(e) => onAllColumnHandler()}
              ></CheckboxRb>
              <span className="resetButtonStyles" onClick={resetAll}>
                Reset
              </span>
            </div>
          </div>
          {(initialData)?.map((fdata, i) => {
            return (
              <div key={i + 23} className="search-result-row">
                <div>
                  <CheckboxRb
                    inputLabelClass="search-result-text"
                    label={fdata.name}
                    onChange={(e) => {
                      e?.stopPropagation();
                      onSingleColumnHandler(e, fdata.name)
                    }}
                    checked={isCheck.includes(fdata?.name)}
                  ></CheckboxRb>
                </div>
              </div>
            );
          })}
        </div>
        <div className={props.dynamicWidth? "column-aerch-box-footer w-100 justify-content-center" :"column-aerch-box-footer justify-content-center"}>
          <SecondaryBtnRb
            secondaryBtnText="Cancel"
            className="primary-inactive-btn columnn-search-btn"
            id="canel-search"
            onClick={(e) => onCancelHandler(e, "cancel")}
          ></SecondaryBtnRb>
          <SecondaryBtnRb
            secondaryBtnText={"Apply"}
            className={isCheck.length===0?"primary-active-btn columnn-search-btn bg-secondary":"primary-active-btn columnn-search-btn"}
            id={props?.id ? props?.id :"apply-search"}
            disabled={isCheck.length===0}
            disabledText={"Please Select at least one option from above"}
            onClick={(e) => onApplyHandler(e, "apply")}       
          ></SecondaryBtnRb>
        </div>
      </div>
    </div>
  );
};

export default MasterTargetingSearchSelectModal;