import React, { useEffect, useState } from "react";
import { Modal, Stack } from "react-bootstrap";
import PrimaryBtnRB from "../Buttons/primaryBtnRB.js/primaryBtnRB";
import SecondaryBtnRb from "../Buttons/secondaryBtnRb/secondaryBtnRb";
import layoutSettingOptionsIcon from "../../assets/icons/layout-setting-option-icon.png";
import SwitchBtn from "../Buttons/switchBtn";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import "./dashboardLayoutSettingOpt.css";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../redux/slices/dashboardSlice";
import "./dashboardLayoutSettingOpt.css";
import { masterApi } from "../../apis/masterApi";
import { useGlobalContext } from "../../context/global";
const DashboardLayoutSettingOpt = (props) => {
  const {
    dashboardLayoutSequence,
    setDashboardLayoutSequence,
    itemList,
    setItemList,
    overlayoutput,
    setoverlayoutput,
    clientId,
    axios,
    userToken
  } = useGlobalContext();
  const [layoutid, setlayoutid] = useState("");
  const [layoutseq, setlayoutseq] = useState([]);

  const dispatch = useDispatch();
  // let clientId = JSON.parse(localStorage.getItem("clientId"))
  let layoutId = JSON.parse(localStorage.getItem("layoutId"));
  const getoverviewdata = () => {
    axios
      .get(
        `${process.env.REACT_APP_ADS_API}/client/${JSON.parse(
          localStorage.getItem("clientId")
        )}/custom-config?key=amz_dashboard_config`
      )
      .then((res) => {
        if(res.data) {
        // let overdata = res.data.value.layout_order;
        localStorage.setItem("layoutId", JSON.stringify(res.data.id));
        localStorage.setItem("layout_order", JSON.stringify(res.data?.value?.layout_order));
        // setlayoutid(res.data.id);
        // let sp = overdata.filter(function (item) {
        //   return item !== "overview";
        // });
        // setlayoutseq(sp);
        }

      });
  };
  
  useEffect(() => {
    getoverviewdata();
  }, [clientId]);

  const dashboardLayoutSequenceReduxdata = useSelector(
    (state) => state.dashboardData.dashboardLayoutSequencedata
  );

  const resetlayoutis = [
    "Trend Graph",
    "Campaign Summary",
    "Performance Summary",
    "Targeting",
    "Low Performing Search Term",
    "Product Summary",
  ];
  const layoutOptiosAsDefaultSwquence = [...itemList];

  const layoutOptios = dashboardLayoutSequenceReduxdata;
  const [activeBtn, setActiveBtn] = useState("update");
  // const [itemList, setItemList] = useState([]);

  if (itemList.length === 0) {
    setItemList(layoutOptios);
  }
  async function Resetlayout() {
    const response = await fetch(
      `${process.env.REACT_APP_ADS_API}/client/${clientId}/custom-config/${layoutId}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          "Authorization": userToken
        },
        body: JSON.stringify({
          key: "amz_dashboard_config",
          id: layoutId,
          value: {
            overview_metrices: [
              "impressions",
              "clicks",
              "orders",
              "sale_value",
            ],
            layout_order: [
              "overview",
              "trend_graph",
              "campaign_summary",
              "category_product_performance_summary",
              "targeting",
              "low_performing_search_terms",
              "product_summary",
            ],
          },
        }),
      }
    );

    // Awaiting response.json()
    const resData = await response.json();
    setDashboardLayoutSequence([
      "trend_graph",
      "campaign_summary",
      "category_product_performance_summary",
      "targeting",
      "low_performing_search_terms",
      "product_summary",
    ]);
    setItemList([
      "trend_graph",
      "campaign_summary",
      "category_product_performance_summary",
      "targeting",
      "low_performing_search_terms",
      "product_summary",
    ]);
    if (resData?.success === true) {
      setoverlayoutput(!overlayoutput);
    }

    // Return response data
    return resData;
  }

  const onRestHandler = (e) => {
    setActiveBtn(e.target.id);
    dispatch(dashboardActions.dashboardLayoutSequencedata(itemList));
    dispatch(dashboardActions?.dasboardLayoutSettingModalHide());
    // dispatch(dashboardActions.dashboardLayoutSequencedata(layoutOptiosAsDefaultSwquence))
    Resetlayout();
  };
  async function updatelayout(itemList) {
    if (layoutId) {
      const response = await fetch(
        `${process.env.REACT_APP_ADS_API}/client/${clientId}/custom-config/${layoutId}`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            "Authorization": userToken
          },
          body: JSON.stringify({
            id: layoutId,
            key: "amz_dashboard_config",
            value: {
              overview_metrices: [
                "impressions",
                "clicks",
                "orders",
                "sale_value",
              ],
              layout_order: [...itemList],
            },
          }),
        }
      );

      // Awaiting response.json()
      const resData = await response.json();
      if (resData?.success === true) {
        setoverlayoutput(!overlayoutput);
      }

      // Return response data
      return resData;
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_ADS_API}/client/${clientId}/custom-config`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Authorization": userToken
          },
          body: JSON.stringify({
            key: "amz_dashboard_config",
            value: {
              overview_metrices: [
                "impressions",
                "clicks",
                "orders",
                "sale_value",
              ],
              layout_order: [
                "overview",
                "trend_graph",
                "campaign_summary",
                "category_product_performance_summary",
                "targeting",
                "low_performing_search_terms",
                "product_summary",
              ],
            },
          }),
        }
      );

      // Awaiting response.json()
      const resData = await response.json();
      if (resData?.success === true) {
        setoverlayoutput(!overlayoutput);
      }

      // Return response data
      return resData;
    }
  }
  const updateBtnHandler = async (e) => {
    dispatch(dashboardActions?.dasboardLayoutSettingModalHide());
    updatelayout(itemList);
  };
  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    localStorage.setItem("layout_order", JSON.stringify(updatedList));
    setItemList(updatedList);
  };

  return (
    <div className="setting-modal">
      <div className="setting-modal-inside-main-content-body">
        <div className="border-bottom setting-opt-row">
          <p className="d-flex flex-grow-1 option-name">Overview</p>
          <div className="d-flex align-item-center">
            <img src={layoutSettingOptionsIcon} alt={"settings"}></img>
          </div>
        </div>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {itemList.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided) => (
                      <div
                        className="item-container"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        <div
                          className="border-bottom setting-opt-row"
                          draggable={true}
                        >
                          <p className="d-flex flex-grow-1 option-name">
                            {item === "trend_graph"
                              ? "Trend Graph"
                              : "" || item === "campaign_summary"
                              ? "Campaign Summary"
                              : "" ||
                                item === "category_product_performance_summary"
                              ? "Performance Summary"
                              : "" || item === "targeting"
                              ? "Targeting"
                              : "" || item === "low_performing_search_terms"
                              ? "Low Performing Search Term"
                              : "" || item === "product_summary"
                              ? "Product Summary"
                              : ""}
                          </p>
                          <div className="d-flex align-item-center">
                            <img src={layoutSettingOptionsIcon} alt={"settings"}></img>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Stack direction="horizontal" gap={3} className=" dashboard-footer-btn-container ">
        <button
          className={
            activeBtn === "reset"
              ? "setting-dahs-primary-active-btn"
              : "setting-dash-primary-inactive-btn"
          }
          id="reset"
          onClick={onRestHandler}
        >
          Reset
        </button>
        <button
          className={
            activeBtn === "update"
              ? "setting-dahs-primary-active-btn"
              : "setting-dash-primary-inactive-btn"
          }
          id="update"
          onClick={() => updateBtnHandler()}
        >
          Update
        </button>
      </Stack>
    </div>
  );
  // </div>;
};

export default DashboardLayoutSettingOpt;
