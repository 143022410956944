import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { useGlobalContext } from "../../context/global";
import MultiSelect from "../../components/DropDown/Multiselect";
import originalAxios from "axios";

const ReportFilters = (props) => {
  const [campaignType, setCampaignType] = useState([]);
  const [campaignFormat, setCampaignFormat] = useState([]);
  const [targetingType, setTargetingType] = useState([]);
  const [productSet, setProductSet] = useState([]);
  const [campaignStatus, setCampaignStatus] = useState([]);
  const [portfolioName, setPortfolioName] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [campaignName, setCampaignName] = useState([]);
  const [adStatus, setAdStatus] = useState([]);
  const [targetStatus, setTargetStatus] = useState([]);
  const [adgroupStatus, setAdgroupStatus] = useState([]);
  const [placement, setPlacement] = useState([]);
  const [portfolioNameFilter, setPortfolioNameFilter] = useState([]);
  const [campaignNameFilter, setCampaignNameFilter] = useState([]);
  const [categoryNameFilter, setCategoryNameFilter] = useState([]);
  const [productSetFilter, setProductSetFilter] = useState([]);
  const [defaultDropdown, setDefaultDropdown] = useState(props.selected ?? []);
  const [adyogiMatchtype, setAdyogiMatchtype] = useState([]);
  const { clientId, axios } = useGlobalContext();
  

  const handleApply = (e, type) => {
    props.handleDropdown(e, type);
    if (type === "campaignType") {
      setCampaignType(e);
    } else if (type === "campaignFormat") {
      setCampaignFormat(e);
    } else if (type === "targetingType") {
      setTargetingType(e);
    } else if (type === "productSet") {
      setProductSet(e);
    } else if (type === "campaignStatus") {
      setCampaignStatus(e);
    } else if (type === "portfolioName") {
      setPortfolioName(e);
    } else if (type === "adStatus") {
      setAdStatus(e);
    } else if (type === "category") {
      setCategoryName(e);
    } else if (type === "campaignName") {
      setCampaignName(e);
    } else if (type === "targetStatus") {
      setTargetStatus(e);
    } else if (type === "placement") {
      setPlacement(e);
    } else if (type === "defaultDropdown") {
      setDefaultDropdown(e);
    } else if (type === "adgroupStatus") {
      setAdgroupStatus(e)
    } else if (type === "adyogiTargetingType") {
      setAdyogiMatchtype(e)
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
// Log the values to the console
    const stateSetters = convertArray(props.enabledfilters);
    stateSetters.forEach((value) => {
      if(value === "categoryNames"){
        value = "category";
      }
      if(value === "campaignNames") {
        value = "campaignName"
      }
      if (queryParams.get(value)) {
        handleApply(queryParams.get(value).split(","), value);
      }
    });
  //  props.handleDropdown({},queryParamsObject,true)
  }, []);

  function convertArray(arr) {
    return arr.map((str) => {
      let words = str.split(" ");
      words = words.map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word[0].toUpperCase() + word.slice(1).toLowerCase()
      );
      return words.join("");
    });
  }

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "",
      headers: {},
    };
    if (props.enabledfilters.includes("Portfolio Name")) {
      config.url = process.env.REACT_APP_ADS_API+"/client/" +
        clientId +
        "/portfolios?source=amazon&fields=name&limit=10000";
      axios.request(config).then((response) => {
        let result = response.data.data.map((item) => item.name);
        setPortfolioNameFilter(result);
      });
    }
    if (props.enabledfilters.includes("Campaign Names")) {
      config.url = process.env.REACT_APP_ADS_API+"/client/" +
        clientId +
        "/campaigns?source=amazon&fields=name&limit=100000";
      axios.request(config).then((response) => {
        let result = response.data.data.map((item) => item.name);
        setCampaignNameFilter(result);
      });
    }
    if (props.enabledfilters.includes("Category Names")) {
      config.url = process.env.REACT_APP_CATALOG_API+"/client/" + clientId +
        "/categories?source=amazon&fields=name"; // Using this instance cause we don't need to sent authorization token in catalog api.
      originalAxios.request(config).then((response) => {
        let result = response.data.data.map((item) => item.name);
        setCategoryNameFilter(result);
      });
    }

    if (props.enabledfilters.includes("Product Set")) {
      config.url = process.env.REACT_APP_ADS_API+"/client/" +
        clientId +
        ":product-set?source=amazon";
      axios.request(config).then((response) => {
        let result = response.data.data.map((item) => {
          if (item?.product_set) {
            return item.product_set;
          }
        });
        setProductSetFilter(result);
      });
    }
  }, []);

  return (
    <>
      <div className="dropdownContentWrapper" style={{ marginBottom: "0px" }}>
        <div className={props.enabledfilters.length > 6 ? "d-flex gap-3 w-100 max-w-100 largeFilters1" : "d-flex gap-3 w-100 max-w-100"}>
          {props.enabledfilters.includes("Category Names") && categoryNameFilter.length > 0 ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Category Names"}
                  options={categoryNameFilter}
                  defaultSelected={categoryName}
                  onApply={(e) => handleApply(e, "category")}
                  label={"Category Names"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Campaign Names")  && campaignNameFilter.length >0 ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Name"}
                  options={campaignNameFilter}
                  defaultSelected={campaignName}
                  onApply={(e) => handleApply(e, "campaignName")}
                  label={"Campaign Name"}
                  isSearch={true}
                  placeHolder={"Search Here..."}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}

          {props.enabledfilters.includes("Campaign Type") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Type"}
                  options={["Retarget", "Prospect"]}
                  defaultSelected={campaignType}
                  onApply={(e) => handleApply(e, "campaignType")}
                  label={"Campaign Type"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Campaign Format") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Format"}
                  options={["SD", "SP", "SB(V)", "SB"]}
                  defaultSelected={campaignFormat}
                  onApply={(e) => handleApply(e, "campaignFormat")}
                  label={"Campaign Format"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Targeting Type") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Targeting Type"}
                  options={[
                    "G.Keyword",
                    "C.Keyword",
                    "NB.Keyword",
                    "Audience",
                    "Automatic",
                    "NB.Product",
                    "B.Keyword",
                    "B.Audience",
                    "B.Product",
                  ]}
                  defaultSelected={targetingType}
                  onApply={(e) => handleApply(e, "targetingType")}
                  label={"Targeting Type"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Adyogi Targeting Type") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Match type"}
                  options={[
                    "Automatic",
                    "Exact",
                    "Broad",
                    "Phrase",
                    "Product",
                    "Audience"
                  ]}
                  defaultSelected={adyogiMatchtype}
                  onApply={(e) => handleApply(e, "adyogiTargetingType")}
                  label={"Match type"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Product Set") && productSetFilter.length > 0 ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Product Set"}
                  options={productSetFilter}
                  defaultSelected={productSet}
                  onApply={(e) => handleApply(e, "productSet")}
                  label={"Product Set"}
                  isSearch={true}
                  placeHolder={"Search Product Set..."}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Campaign Status") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Status"}
                  options={["enabled", "paused"]}
                  defaultSelected={campaignStatus}
                  onApply={(e) => handleApply(e, "campaignStatus")}
                  label={"Campaign Status"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Ad Status") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Ad Status"}
                  options={["enabled", "paused"]}
                  defaultSelected={adStatus}
                  onApply={(e) => handleApply(e, "adStatus")}
                  label={"Ad Status"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Target Status") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Target Status"}
                  options={["enabled", "paused"]}
                  defaultSelected={targetStatus}
                  onApply={(e) => handleApply(e, "targetStatus")}
                  label={"Target Status"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Adgroup Status") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Ad Group Status"}
                  options={["enabled", "paused","archived"]}
                  defaultSelected={adgroupStatus}
                  onApply={(e) => handleApply(e, "adgroupStatus")}
                  label={"Ad Group Status"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Placement") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Placement"}
                  options={[
                    "Top of Search",
                    "Detail Page on-Amazon",
                    "Other Placements",
                  ]}
                  defaultSelected={placement}
                  onApply={(e) => handleApply(e, "placement")}
                  label={"Placement"}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          {props.enabledfilters.includes("Portfolio Name") && portfolioNameFilter.length > 0 ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={"Portfolio Name"}
                  options={portfolioNameFilter}
                  defaultSelected={portfolioName}
                  onApply={(e) => handleApply(e, "portfolioName")}
                  label={"Portfolio Name"}
                  isSearch={true}
                  placeHolder={"Search Portfolio..."}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
          
          {props.enabledfilters.includes("default") ? (
            <div className="filterItem">
              <Stack gap={4}>
                <MultiSelect
                  title={props.name}
                  options={props.data}
                  defaultSelected={props.defaultDropdown}
                  onApply={(e) => handleApply(e, "defaultDropdown")}
                  isSearch={true}
                  placeHolder={"Search Here..."}
                  classes={" mst-negative-modal-drop-down-height"}
                  label={props.label}
                />
              </Stack>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default ReportFilters;
