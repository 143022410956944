import "../campaignList.css";
import "./masterAdsMain.css";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGlobalContext } from "../../../context/global";
import Loader from "../../../components/loader";
import FilterLogicModal from "../../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import CSVUploadModal from "../../../components/modal/campaignListModals/csvUploadModal/csvUploadModal";
import MasterTagetinngActionAlertMModal from "../../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
  TOAST_DANGER,
} from "../../../constant/HardTypes";
import { roundFigureNum } from "../../../constant/roundFigureNum";
import { Link,useNavigate,useSearchParams } from "react-router-dom";
import dashIcon from "../../../assets/icons/dash-icon.png";
import { Stack } from "react-bootstrap";
import SectionTitle from "../../../components/sectionTitle";
import settingIcon from "../../../assets/icons/overviewSeiingIcon.svg";
import dropdownIcon from "../../../assets/icons/downArrowForSplitButton.svg";
import CalenderIconForCampaignList from "../../../assets/icons/calender-icon-for-campaignlist.svg";
import selectSideIcon from "../../../assets/icons/select-btn-side-grey-icon.png";
import closeIcon from "../../../assets/icons/close_icon.png";
import pauseIcon from "../../../assets/icons/pause_circle.svg";
import resumeIcon from "../../../assets/icons/play_circle.png";
import CalenderComponent from "../../../components/calender/Calender";
import TableRb from "../../../components/Tables/tableRb";
import SwitchBtn from "../../../components/Buttons/switchBtn";
import CheckboxRb from "../../../components/Buttons/checkbox/checkbox";
import Spinner from "../../../components/spinners/spinner";
import SecondaryBtnRb from "../../../components/Buttons/secondaryBtnRb/secondaryBtnRb";
import { Search } from "react-bootstrap-icons";
import CustomDropDown from "../../../components/DropDown/CustomDropDown";
import CampaignListTab from "../../../components/Tabs/CampaignListTab";
import MasterTargetingSearchSelectModal from "../../../components/modal/masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal";
import dateConverter from "../../../helper/common/dateConverter";
import sortData from "../../../helper/CampaignList/sortData";
import ascendingIconSvg from "../../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../../assets/svgs/descendingIcon.svg";
import whiteFindIcon from "../../../assets/icons/white_magnnifineIcon.png";
import FilterChips from "../../../components/FilterChips/FilterChips";
import AddIcon from "../../../assets/svgs/plus_box.svg";
import MinusIcon from "../../../assets/svgs/minus_box.svg";
import { campaignListActions } from "../../../redux/slices/campaignList";
import ProductNameTd from "../../../components/Tables/TableComponents/productNameTd";
import { onRowExpandHandler } from "../../../helper/CampaignList/masterAds/rowExpandHandler";
import { masterApi } from "../../../apis/masterApi";
import { parentRowCheckBoxHandler } from "../../../helper/CampaignList/masterAds/parentRowCheckBoxHandler";
import { selectAllcheckBoxes } from "../../../helper/CampaignList/masterAds/selectAllcheckBoxes";
import { generateFiltering } from "../../../helper/CampaignList/masterAds/generateFiltering";
import { encodeData } from "../../../helper/common/encodeData";
import { enablePauseSingleChildAd } from "../../../helper/CampaignList/masterAds/enablePauseSingle";
import { enablePauseBulkChildAd } from "../../../helper/CampaignList/masterAds/enablePauseBulk";
import MultiSelectCustomDropDown from "../../../components/DropDown/MultiSelectCustomDropDown";
import LazyLoadSpinner from "../../../components/spinners/LazyLoadSpinner";
import SearchBarForCampaignListOnly from "../../../components/searchBarForCampaignListOnly";
import { useAuthUser } from "react-auth-kit";
import csvIcon from "../../../assets/icons/csv-upload.svg";
import Papa from "papaparse";
import { AiOutlineSearch, AiOutlinePause, AiOutlinePlay } from 'react-icons/ai';
import { FaEllipsisV } from 'react-icons/fa';
import useInitialLoad from "../../../hooks/useInitialLoad";
import {defaultMetrices} from '../../../constant/marketingConstant'
import Breadcrumb from '../../../components/Breadcrumb/ReportsBreadCrumb';
import {toast} from 'react-toastify';
import { allConfirmationButtonsIds } from "../../../constant/confirmActions";

const MasterAdsMain = () => {
  const [isBulkUploadOptionOpen, setIsBulkUploadOptionOpen] = useState(false);
  const kababRef = useRef(null);
  // States || Global Contexts || Redux Imports || Global Variables
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [cancelSpecifiNumberFilter, setCancelSpecifiNumberFilter] =
    useState(null);

  const getUpdatedConfigHeadsForMasterAdsSection = useSelector(
    (state) => state.campaignListData.getUpdatedConfigHeadsForMasterAdsSection
  );

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );

  const csvUploadModalInGlobalAd = useSelector(
    (state) => state.campaignListData.csvUploadModalInGlobalAd
  );

  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );

  const [initialLoad,setInitialLoad] = useInitialLoad()
  const auth = useAuthUser()
  const userDetails = auth().user;
  const isMultiuser = userDetails?.multiUser === "yes";

  const dispatch = useDispatch();

  const { calendarDate, showAlert, dateDifference,applyCalendar, userToken } = useGlobalContext();
  const [allLoaders, setAllLoaders] = useState({
    mainLoader: true,
    childRowLoader: false,
  });
  const [allFiltersVisible, setAllFilterVisible] = useState({
    mainFilterLogicModal: false,
    ConfirmAlertModal: false,
    searchSortModal: false,
    csvUploadModal: false,
  });

  const [filters, setFilters] = useState({
    product_name: "",
    campaign_type: "All",
    campaign_objective: "Both",
    category: "",
    portfolio_id: "",
    campaign_id: "",
    adgroup_id: "",
    status: "",
    asin: "",
  });
  const [statFilters, setStatFilters] = useState([]);
  const [searchFiltersState, setSearchFiltersState] = useState({
    portfolios: [],
    campaigns: [],
    adGroups: [],
    categories: [],
  });
  const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
  const [categories, setCategories] = useState([]);
  // const [dateDifference, setDateDifference] = useState("");
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState("all-btn");
  const [dataHeads, setDataHeads] = useState([]);
  const [mainIndex, setMainIndex] = useState(0);
  const [actionAlertModalText, setActionAlertModalText] = useState("");
  const confirmationId = allConfirmationButtonsIds['product-ads'][actionAlertModalText] ?? 'product-ads-confirmation'
  const [parentRowsData, setParentRowsData] = useState([]);
  const [childRowsData, setChildRowsData] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [paginationAction, setPaginationAction] = useState({
    currentPage: 1,
    currentOffSet: 0,
  });
  const [sortBy, setSortBy] = useState({ sort_head: "spends", order: "desc" });
  const [selectedAds, setSelectedAds] = useState([]);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [overAllAdsCount, setOverAllAdsCount] = useState({});
  const [campaignsIds, setCampaignsIds] = useState("");
  const [currentAsin, setCurrentAsin] = useState("");
  const [searchValues, setSearchValues] = useState("");
  const dataForMultiSelectDropDown = [
    {
      name: "SP",
      isDataChecked: false,
      buttonName: "Campaign Type",
      apiFieldNameForFilteringRequest: "campaign_type",
      fieldName: "mt-campaign_type",
    },
    {
      name: "SD",
      isDataChecked: false,
      buttonName: "Campaign Type",
      apiFieldNameForFilteringRequest: "campaign_type",
      fieldName: "mt-campaign_type",
    },
  ];
  const [DropDownDataList, setDropDownDataList] = useState(
    dataForMultiSelectDropDown
  );
  const [checkedDropDownFilterButtonData, setCheckedDropDownFilterButtonData] =
    useState([]);
  const tableRef = useRef(null);

  // Main UI Layer
  const [tableDataToShow, setTableDataToShow] = useState([1]);

  // Custom Variables
  const clientId = JSON.parse(localStorage.getItem("clientId"));
  const limit = 50;
  const metricesWithNoDecimal = ["spends", "sale_value", "aov"];
  const metricesWithTwoDecimal = ["cpm", "ctr", "cpc", "cvr", "roas", "acos"];
  const upperCaseValues = ["cpm", "ctr", "cpc", "cvr", "roas", "acos", "aov"]
  const dataForChips = generateChipFilters(filters, statFilters);

  // Derived Variables From State
  const isCampaignFilterActive = filters?.campaign_id?.length > 0;
  const isPortfolioFilterActive = filters?.portfolio_id?.length > 0;
  const adGroupFilterActive = filters?.adgroup_id?.length > 0;
  const categoryFilterActive = filters?.category?.length > 0;

  // Refs
  const masterAdsCalendarIconRef = useRef(null);
  const hiddenFileInput = useRef(null);

  //redirect data
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { asinId } = params;
  const [asinIdLinkFromProductReport, setAsinIdLinkFromProductReport] =
  useState(asinId ? asinId : null);
  const navigate = useNavigate();
  const inputHandler = (searchValue) => {
    if (asinId) {
      searchParams.delete("asinId");
      navigate("/master-ads");
      setAsinIdLinkFromProductReport(null);
    }
    setSearchValues(searchValue);
  };

  // Effects

  const getAllCategories = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: userToken,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/categories?source=amazon&fields=name,id&limit=1000`, options
      );
      const result = await response.json();
      if (result?.data) {
        const { data } = result;
        // const modifiedData = data.map((dataName) => dataName.name);
        setCategories(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCampaigns = async () => {
    const campaignFilteringBasedOnPortfolio = generateFiltering({
      amz_portfolio_id: mapValues(filters, "platform_id")?.portfolio_id,
    });
    const adGroupFilteringBasedonCampaign = generateFiltering({
      campaign_id: campaignsIds,
    });
    const dataForCampaign = {
      source: "amazon",
      fields: "id,name,platform_id",
      filtering: encodeData(campaignFilteringBasedOnPortfolio),
    };
    const dataForAdgroup = {
      source: "amazon",
      fields: "id,name,platform_id",
      filtering: encodeData(adGroupFilteringBasedonCampaign),
    };
    const dataForPortfolio = {
      source: "amazon",
      fields: "id,name,platform_id",
    };
    try {
      const campaignsResponse = await masterApi(
        dataForCampaign,
        "campaignList",
        false
      );
      const adgroupResponse = await masterApi(
        dataForAdgroup,
        "adsetList",
        false
      );
      const portfolioResponse = await masterApi(
        dataForPortfolio,
        "getPortfolios",
        false
      );
      const campaigns = campaignsResponse?.data?.data;
      const adgroups = adgroupResponse?.data?.data;
      const portfolios = portfolioResponse?.data?.data;
      setSearchFiltersState({
        ...searchFiltersState,
        campaigns: campaigns,
        adGroups: adgroups,
        portfolios,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const loadMoreData = async (currentOffset) => {
    try {
      setLazyLoading(true);
      const mappedData = mapValues(filters, "platform_id");
      const filtering = generateFiltering(mappedData);
      let mainFiltering = filtering.concat(statFilters);
      mainFiltering.forEach(function (value, i) {
        if (value.field_name === 'asin') {
          if (mainFiltering[i].value.length > 0) {
            let object = {}
            object.field_name = "asin"
            object.operator = "IN"
            object.values = mainFiltering[i].value
            mainFiltering = []
            mainFiltering.push(object)
          } else {
            mainFiltering = []
          }
        }
      });
      const parentRowData = {
        source: "amazon",
        master_row: "true",
        order_by: sortBy?.sort_head,
        sorting_order: sortBy.order,
        limit: limit,
        offset: currentOffset,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(mainFiltering),
      };
      const result = await masterApi(parentRowData, "masterAds", false);
      setLazyLoading(false);
      return result?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  function modifyData(parentData = [], childData) {
    const modifiedData = parentData?.map((eachObject) => {
      return {
        ...eachObject,
        isPlusIconDisabled: true,
        isTargetChecked: false,
        childRows: [],
      };
    });
    return modifiedData;
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        masterAdsCalendarIconRef &&
        masterAdsCalendarIconRef.current &&
        !masterAdsCalendarIconRef.current.contains(e.target)
      ) {
        setShowCustomDateBox(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showCustomDateBox]);

  useEffect(() => {
    getParentsRowsData();
    setPaginationAction({
      ...paginationAction,
      currentOffSet: 0,
      currentPage: 1,
    });
  }, [filters, sortBy, statFilters, applyCalendar]);//[filters, sortBy, statFilters, calendarDate]

  useEffect(() => {
    setAllFilterVisible({
      ...allFiltersVisible,
      mainFilterLogicModal: filterModalInCampaignListVisibilty,
    });
  }, [filterModalInCampaignListVisibilty]);

  useEffect(() => {
    setAllFilterVisible({
      ...allFiltersVisible,
      csvUploadModal: csvUploadModalInGlobalAd,
    });
  }, [csvUploadModalInGlobalAd]);

  const handleClickOutside = (event) => {
    if (isBulkUploadOptionOpen && kababRef.current && !kababRef.current.contains(event.target)) {
      setIsBulkUploadOptionOpen(false);
    }
  };

  useEffect(() => {
    // dispatch(campaignListActions.appliedFilterData([]));

    // const handleClickOutside = (event) => {
    //   if (isBulkUploadOptionOpen && kababRef.current && !kababRef.current.contains(event.target)) {
    //     setIsBulkUploadOptionOpen(false);
    //   }
    // };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isBulkUploadOptionOpen]);


  // Effects


  useEffect(() => {
    if (appliedFilterData?.length) {
      const statFiltering = transformData(appliedFilterData);
      if (
        statFiltering.length > 0 ||
        (appliedFilterData?.length === 4 && statFilters.length > 0) ||
        statFilters.length > 0
      ) {
        setStatFilters(statFiltering);
      }
    } else {
      setStatFilters([]);
      setMinMaxFilterActivated(false);
    }
    setCancelSpecifiNumberFilter("")
  }, [appliedFilterData]);

  function transformData(input) {
    const output = [];
    setMinMaxFilterActivated(false);
    for (let i = 0; i < input?.length; i++) {
      const obj = input[i];

      // Check if the object has non-empty and non-zero values for minRange or maxRange
      if (
        (obj.minRange !== "" && obj.minRange !== 0 && obj.minRange !== "-") ||
        (obj.maxRange !== "" && obj.maxRange !== 0 && obj.maxRange !== "-")
      ) {
        // Add a new object to the output array with "GREATER_THAN" operator and the "minRange" value, if minRange is not empty or zero
        if (obj.minRange !== "" && obj.minRange !== 0 && obj.minRange !== "-") {
          setMinMaxFilterActivated(true);
          output.push({
            field_name: obj?.id.toLowerCase(),
            operator: "GREATER_THAN",
            value: obj.minRange.toString(),
          });
        }

        // Add a new object to the output array with "LESSER_THAN" operator and the "maxRange" value, if maxRange is not empty or zero
        if (obj.maxRange !== "" && obj.maxRange !== 0 && obj.maxRange !== "-") {
          setMinMaxFilterActivated(true);
          output.push({
            field_name: obj?.id.toLowerCase(),
            operator: "LESS_THAN",
            value: obj.maxRange.toString(),
          });
        }
      }
    }
    return output;
  }

  useEffect(() => {
    setDataHeads(getUpdatedConfigHeadsForMasterAdsSection);
  }, [getUpdatedConfigHeadsForMasterAdsSection]);

  useEffect(() => {
    // Getting All the categories to display in the dropdown
    getAllCategories();
    getAllCampaigns();
  }, [clientId, filters]);

  useEffect(() => {
    const count = getUpdatedCount(tableDataToShow);
    setSelectedAds([...count]);
  }, [tableDataToShow]);

  useEffect(() => {
    getParentRowsCount();
  }, []);
  // API Calls Methods

  async function getParentsRowsData() {
    setAllLoaders({ ...allLoaders, mainLoader: true });

    try {
      const filteredData = mapValues(filters, "platform_id");
      const filtering = generateFiltering(filteredData);
      let mainFiltering = filtering.concat(statFilters);
      //hack now for filtering will fix this in tech debt task
      mainFiltering.forEach(function (value, i) {
        if (value.field_name === 'asin') {
          if (mainFiltering[i].value.length > 0) {
            let object = {}
            object.field_name = "asin"
            object.operator = "IN"
            object.values = mainFiltering[i].value
            mainFiltering = []
            mainFiltering.push(object)
          } else {
            mainFiltering = []
          }
        }
      });
      let filteringAfterDuplicateRemoval = mainFiltering
      if (mainFiltering?.length === 0 || mainFiltering.length === 1) {
        // Removing Filtering Which has key as value and array // Removing array of asins in with EQUALS OPERATOR
        filteringAfterDuplicateRemoval = [...mainFiltering.concat(filtering).concat(statFilters)].filter((filters) => {
          return !Array.isArray(filters?.value)
        })
      }

      const parentRowData = {
        source: "amazon",
        master_row: "true",
        order_by: sortBy?.sort_head,
        sorting_order: sortBy.order,
        limit: limit,
        offset: 0,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(filteringAfterDuplicateRemoval),
      };
      const result = await masterApi(parentRowData, "masterAds", false);
      if (result && result?.data?.data) {
        const returnedData = [...modifyData(result?.data?.data, childRowsData)];
        setTableDataToShow(returnedData);
      } else {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Unknown Error Occurred with statusCode${result?.statusCode}`,
        // });
        toast.error(`Unknown Error Occurred with statusCode${result?.statusCode}`)
      }
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setInitialLoad(false)
    } catch (error) {
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setInitialLoad(false)
      console.log(error);
    }
  }

  async function getParentRowsCount() {
    setAllLoaders({ ...allLoaders, mainLoader: true });

    try {
      const mainFiltering = [
        { field_name: "status_count", operator: "EQUALS", value: "count" },
      ];
      const parentRowData = {
        source: "amazon",
        master_row: "true",
        order_by: "spends",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(mainFiltering),
      };
      
      const result = await masterApi(parentRowData, "masterAds", false);
      if (result && result?.data?.data) {
        setOverAllAdsCount(result?.data?.data[0]);
      }
      setAllLoaders({ ...allLoaders, mainLoader: false });
    } catch (error) {
      setAllLoaders({ ...allLoaders, mainLoader: false });
      console.log(error);
    }
  }

  // Methods

  // For Chip Filters

  const onCancelClickRemoveFilteringHandlerr = (fieldName) => {
    const isPresent = defaultMetrices?.includes(fieldName?.toLowerCase());
    if(isPresent) {
      setCancelSpecifiNumberFilter(fieldName);
    } else {
      setFilters({ ...filters, [fieldName]: "" });
      if (fieldName === "campaign_id") {
        setCampaignsIds("");
      }
    }
  };

  function generateChipFilters(data, dataForStatFilters) {
    const filteredData = mapValues(data, "name");
    const keys = Object.keys(filteredData);
    const transformedData = keys
      .filter(
        (key) =>
          filteredData[key] !== undefined &&
          filteredData[key] !== null &&
          filteredData[key] !== "" &&
          filteredData[key] !== "All" &&
          filteredData[key] !== "Both" &&
          key !== "product_name" &&
          key !== "asin" &&
          key !== "status"
      )
      .map((key) => {
        const fieldName = key.includes("portfolio")
          ? "Portfolio"
          : key.includes("campaign")
            ? "Campaign"
            : key.includes("category")
              ? "Category"
              : "Adgroup";
        return {
          fieldNameOfSelectedBtn: fieldName,
          field_name: key,
          checkedValue:
            typeof filteredData[key] !== "object" &&
              !Array.isArray(filteredData[key])
              ? [filteredData[key]]
              : filteredData[key],
        };
      });

    //this will create array witch merged duplicate key in single onject
    const newArray = [];
    dataForStatFilters.forEach((obj) => {
      let newObj = {
        fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        checkedValue: obj.value,
        operator: obj?.operator,
      };
      let foundIndex = newArray.findIndex(
        (x) => x.field_name === obj.field_name
      );
      if (foundIndex === -1) {
        newArray.push({
          field_name: obj.field_name,
          values: [newObj],
          fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        });
      } else {
        newArray[foundIndex].values.push(newObj);
      }
    });

    //thiw will loop through new array and create data for min max filter chip
    let listForChip = [];
    let nonnAbbrivaationFieldName = [
      "spends",
      "impressions",
      "clicks",
      "orders",
      "sale_value",
    ];
    newArray.map((currObj) => {
      let minValueForCurrentObj = 0;
      let maxValueForCurrentObj = "Infinity";

      currObj.values.map((currentDaata) => {
        if (currentDaata?.operator === "LESS_THAN") {
          maxValueForCurrentObj = currentDaata.checkedValue;
        }
        if (currentDaata?.operator === "GREATER_THAN") {
          minValueForCurrentObj = currentDaata.checkedValue;
        }
      });

      listForChip.push({
        field_name: currObj.field_name.toLowerCase(),
        fieldNameOfSelectedBtn:
          currObj.field_name === "sale_value"
            ? "Revenue "
            : nonnAbbrivaationFieldName.includes(currObj.field_name)
              ? currObj.field_name.replace(/^\w/, (c) => c.toUpperCase())
              : currObj.field_name.toUpperCase(),
        minValue: minValueForCurrentObj,
        maxValue: maxValueForCurrentObj,
      });
    });

    const allChipFiltersData = transformedData.concat(listForChip);
    return allChipFiltersData;
  }

  // Function to convert Filters Values into Single Array

  function mapValues(obj, filterKey) {
    const keysArray = ["campaign_id", "portfolio_id", "adgroup_id", "category"];
    const newObj = { ...obj };
    for (const key in newObj) {
      if (typeof newObj[key] === "object" || Array.isArray(newObj[key])) {
        if (keysArray.includes(key) && newObj[key].length > 0) {
          newObj[key] = newObj[key].map((item) =>
            key === "category" ? item["name"] : item[filterKey]
          );
        }
      }
    }
    return newObj;
  }

  // Custom SelectDrop Down Methods

  const onOptionCheckedHandlerInDropDownFilter = (
    e,
    checkedValue,
    fieldNameForAPIFilter,
    fieldNameOfSelectedBtn,
    fieldName
    // DropDownDataList
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (list.name === checkedValue && list.fieldName === fieldName) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: checkedValue,
                fieldNameOfSelectedBtn: fieldNameOfSelectedBtn,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
              },
            ]
          );
        } else {
          list.isDataChecked = false;
          if (
            checkedDropDownFilterButtonData &&
            checkedDropDownFilterButtonData.length
          ) {
            for (
              let index = 0;
              index < checkedDropDownFilterButtonData.length;
              index++
            ) {
              if (
                checkedDropDownFilterButtonData[index] &&
                checkedDropDownFilterButtonData[index].value === checkedValue
              ) {
                checkedDropDownFilterButtonData.splice(index, 1);
              }
            }
            setCheckedDropDownFilterButtonData(
              (checkedDropDownFilterButtonData) => [
                ...checkedDropDownFilterButtonData,
              ]
            );
          }
        }
      }
    });
    setDropDownDataList(currentList);
  };

  const onApplyBtnhandler = () => {
    setFilters({
      ...filters,
      campaign_type: checkedDropDownFilterButtonData?.map(
        (value) => value?.value
      ),
    });
  };

  const onDropdownImghandler = () => { };

  const allSeletHandler = (
    e,
    displayedBtnName,
    BtnNameUniqueField,
    fieldNameForAPIFilter
  ) => {
    setCheckedDropDownFilterButtonData([]);
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === displayedBtnName &&
        list.fieldName === BtnNameUniqueField
      ) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: list.name,
                fieldNameOfSelectedBtn: displayedBtnName,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
              },
            ]
          );
        }
      }
    });
    setDropDownDataList(currentList);
  };

  const onDashIconHandlerToremoveAllCheckedOption = (
    e,
    targettingValue,
    fieldName,
    uniqueFieldName,
    fieldNameAsSendInApi
  ) => {
    checkedDropDownFilterButtonData.map((checkedData, index) => {
      if (checkedData.fieldNameOfSelectedBtn === targettingValue) {
        // if (index > -1) {
        if (index > 0) {
          checkedDropDownFilterButtonData.splice(index, 1);
        } else {
          checkedDropDownFilterButtonData.splice(index);
        }

        // }
      }
    });
    setCheckedDropDownFilterButtonData((checkedDropDownFilterButtonData) => [
      ...checkedDropDownFilterButtonData,
    ]);
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === targettingValue &&
        list.fieldName === uniqueFieldName
      ) {
        // if (e.target.checked) {
        list.isDataChecked = false;
        // fieldNameForAPIFilter: "adyogi_targeting_type";
        // fieldNameOfSelectedBtn: "Targeting Type";
        // value: "Exact";

        // removePreviosSameFilterObject(
        //   targetingFiltering,
        //   list.apiFieldNameForFilteringRequest
        // );
      }
    });

    setDropDownDataList(currentList);
  };

  const onCloseOutSideDropDownList = () => { };

  const showCheckedAppliedFilteringWhenOPen = () => { };

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  const onFilterIconhandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };

  const onCSVIconClick = () => {
    setIsBulkUploadOptionOpen(!isBulkUploadOptionOpen)
    dispatch(campaignListActions.showUploadInGlobalAd());
  };

  const getUpdatedDrowDownFilters = (value, key) => {
    const dataToBeSet = value;
    // (value !=="") ? value?.map((data) => {
    //   return key === "category" ? data[filterKey] : parseInt(data[filterKey]);
    // }):""
    if (key === "campaign_id") {
      const campaignIdsData =
        value !== ""
          ? value?.map((data) => {
            return data?.id;
          })
          : "";
      setCampaignsIds(campaignIdsData);
    }
    setFilters({ ...filters, [key]: dataToBeSet });
  };

  const onAdsSearchHandler = (event) => {
    if (event.key === "Enter" || event.target.value === "") {
      if ('product_name' in filters) {
        delete filters.product_name
      }
      if ('asin' in filters) {
        delete filters.asin
      }
      if (!(/,/.test(event.target.value)) && !(/^[A-Z0-9]+$/.test(event.target.value))) {
        setFilters({ ...filters, product_name: event.target.value });
      } else {
        setFilters({ ...filters, asin: event.target.value.split(','), });
      }
    }
  };

  useEffect(()=>{
    if(asinId){
      if ('product_name' in filters) {
        delete filters.product_name
      }
      if ('asin' in filters) {
        delete filters.asin
      }
      setFilters({ ...filters, asin: [asinId], });
      setSearchValues(asinId)
    }
  },[asinId])

  const dateInputClickHandler = () => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const filterCampaigns = (e, type) => {
    setFilters({ ...filters, [type]: e.target.value });
  };

  const enablePauseHandler = (status) => {
    dispatch(campaignListActions.showActionAlertModal());
    setAllFilterVisible({ ...allFiltersVisible, ConfirmAlertModal: true });
    setActionAlertModalText(
      `Are you sure you want to ${status} the selected Ads?`
    );
  };

  const onSelectedBtnChangeHandler = (event, status, type) => {
    setSelectedBtn(event.target.id);
    setFilters({ ...filters, status: type.toLowerCase() });
  };

  const onAllAdSelection = (e) => {
    const updatedState = selectAllcheckBoxes(tableDataToShow, e.target.checked);
    setTableDataToShow([...updatedState]);
  };

  const onSearchModalHandler = (input) => {
    setAllFilterVisible({ ...allFiltersVisible, searchSortModal: true });
    setColumnName(input);
  };

  const onSortHandler = (
    initialData,
    condition,
    status,
    type,
    currentIndex
  ) => {
    // // FilterData Sorting.
    // const filteredData = sortData(initialData, condition, status, type);
    // // Set the Heads Flag to true or false
    let convertedData = JSON.parse(JSON.stringify(dataHeads));
    convertedData = convertedData.map((head) => {
      if (head.id === status) {
        head.isSortingClicked = !head.isSortingClicked;
      } else {
        head.isSortingClicked = false;
      }
      return head;
    });
    setSortBy({
      ...sortBy,
      sort_head: status,
      order: condition ? "desc" : "asc",
    });
    // // Update Necessary States
    setDataHeads(convertedData);
    // setMainCurrentIndex(currentIndex);
    // setTableDataToShow([...filteredData]);
  };

  const onParentRowCheckBoxHandler = async (sentAsin) => {
    setCurrentAsin(sentAsin);
    setAllLoaders({ ...allLoaders, childRowLoader: true });
    const asinForFiltering = generateFiltering({ asin: sentAsin });
    const filtersMappedValue = mapValues(filters, "platform_id");
    const mainFilters = generateFiltering(filtersMappedValue);
    const mainFiltering = asinForFiltering
      .concat(statFilters)
      .concat(mainFilters);
    const filteringAfterDuplicateRemoval = mainFiltering.filter((filters) => {
      return !Array.isArray(filters?.value)
    })
    // let tempFilters = []
    // let object = {}
    // object.field_name = "asin"
    // object.operator = "EQUALS"  
    // object.value = sentAsin
    // tempFilters.push(object)

    const childRowData = {
      source: "amazon",
      master_row: "false",
      order_by: "spends",
      // limit: "50",
      // offset: 0,
      start_date: `${formatDateFn(calendarDate[0].startDate)}`,
      end_date: `${formatDateFn(calendarDate[0].endDate)}`,
      filtering: encodeData(filteringAfterDuplicateRemoval),
    };
    const checkedData = await parentRowCheckBoxHandler(
      tableDataToShow,
      sentAsin,
      childRowData
    );
    if (checkedData?.error) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `${checkedData?.error}`,
      // });
      toast.error(`${checkedData?.error}`)
    } else {
      setTableDataToShow([...checkedData]);
    }
    setAllLoaders({ ...allLoaders, childRowLoader: false });
  };

  const onChildRowBoxHandler = (asin, childIndex) => {
    const updatedState = onRowExpandHandler(asin, childIndex, tableDataToShow);
    setTableDataToShow([...updatedState]);
  };

  const expandRow = async (recievedAsin) => {
    setCurrentAsin(recievedAsin);
    setAllLoaders({ ...allLoaders, childRowLoader: true });

    const asinForFiltering = generateFiltering({ asin: recievedAsin });
    const mappedFilters = mapValues(filters, "platform_id");
    const mainFilters = generateFiltering(mappedFilters);
    const mainFiltering = [...asinForFiltering.concat(statFilters)].concat(
      mainFilters
    );
    // Remove the Filtering which has array but key is value // Removal of asin Array.
    const filteringAfterDuplicateRemoval = mainFiltering.filter((filters) => {
      return !Array.isArray(filters?.value)
    })
    const childRowData = {
      source: "amazon",
      master_row: "false",
      order_by: "spends",
      // limit: limit,
      // offset: 0,
      start_date: `${formatDateFn(calendarDate[0].startDate)}`,
      end_date: `${formatDateFn(calendarDate[0].endDate)}`,
      filtering: encodeData(filteringAfterDuplicateRemoval),
    };
    // const recieveData = await onRowExpandHandler(sentIndex,tableDataToShow,childRowData);
    // setTableDataToShow([...recieveData]);
    let currentTableStateCopy = JSON.parse(JSON.stringify(tableDataToShow));
    const selectedAd = currentTableStateCopy.filter(
      (ad) => ad?.asin === recievedAsin
    )[0];
    if (selectedAd?.childRows.length > 0) {
      currentTableStateCopy = currentTableStateCopy.map((ad) => {
        if (ad?.asin === recievedAsin) {
          ad.isPlusIconDisabled = !ad?.isPlusIconDisabled;
        }
        return ad;
      });
    } else {
      const childRowsProducts = await masterApi(
        childRowData,
        "masterAds",
        false
      );
      if (childRowsProducts.statusCode !== 200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: `Unknown Error Occured with statusCode ${childRowsProducts?.statusCode}`,
        // });
        toast.error(`Unknown Error Occured with statusCode ${childRowsProducts?.statusCode}`)
      }
      const updatedChildRowsData = childRowsProducts?.data?.data?.map(
        (childAd) => {
          childAd.isTargetChecked = false;
          return childAd;
        }
      );
      currentTableStateCopy = currentTableStateCopy =
        currentTableStateCopy?.map((ad) => {
          if (ad?.asin === recievedAsin) {
            ad.isPlusIconDisabled = false;
            ad.childRows = updatedChildRowsData;
          }
          return ad;
        });
    }
    setAllLoaders({ ...allLoaders, childRowLoader: false });
    setTableDataToShow(currentTableStateCopy);
  };

  const getUpdatedCount = (currentState) => {
    let updatedCount = [];
    currentState?.forEach((Ad) => {
      Ad?.childRows?.forEach((childAd) => {
        if (childAd.isTargetChecked) {
          updatedCount.push(childAd);
        }
      });
    });
    return updatedCount;
  };

  const removeUpdatedCount = () => {
    const currentState = JSON.parse(JSON.stringify(tableDataToShow));
    let updatedCount = currentState.map((ad) => {
      ad.childRows?.map((childAd) => {
        if (childAd.isTargetChecked) {
          childAd.isTargetChecked = false;
        }
        return childAd;
      });
      return ad;
    });
    setTableDataToShow([...updatedCount]);
    setSelectedAds([]);
  };

  const noclickhandler = () => {
    setAllFilterVisible({ ...allFiltersVisible, ConfirmAlertModal: false });
  };
  const yesclickhandler = () => {
    setAllFilterVisible({ ...allFiltersVisible, ConfirmAlertModal: false });
  };

  const handleScroll = async () => {
    const container = tableRef.current;
    let { currentPage, currentOffSet } = paginationAction;
    // Keep track of last scroll position
    const lastScrollTop = container.getAttribute("data-scroll-top") || 0;

    // Determine scroll direction
    const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";

    // Update last scroll position
    container.setAttribute("data-scroll-top", container.scrollTop);
    if (
      scrollDirection === "down" &&
      Math.ceil(container.scrollTop + container.clientHeight) >=
      container.scrollHeight
      && tableDataToShow?.length >= 50
    ) {
      currentOffSet = currentOffSet + limit;
      currentPage = currentOffSet / limit + 1;
      if (!lazyLoading) {
        const moreData = await loadMoreData(currentOffSet);
        const returnedData = [...modifyData(moreData, childRowsData)];
        setTableDataToShow((prev) => [...prev,...returnedData]);
        setPaginationAction({
          ...paginationAction,
          currentPage,
          currentOffSet,
        });
      }
    }
  };

  const onYesClick = async () => {
    const status = actionAlertModalText.includes("Pause")
      ? "paused"
      : "enabled";
    const statusToSent = status.toUpperCase();
    const adsToBeUpdated = selectedAds.map((data) => {
      return { id: data?.id, status };
    });
    const mainResult = await updateBulkAds(adsToBeUpdated);
    if (mainResult?.statusCode !== 200) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `Unknown Error Occurred with statusCode${mainResult?.statusCode}`,
      // });
      toast.error(`Unknown Error Occurred with statusCode${mainResult?.statusCode}`)
    } else {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_SUCCESS,
      //   title: "Success",
      //   description: `Selected Ads has been ${status} Successfully `,
      // });
      toast.success(`Selected Ads has been ${status} Successfully `)
      const updatedState = enablePauseBulkChildAd(
        tableDataToShow,
        statusToSent
      );
      setTableDataToShow([...updatedState]);
    }
  };

  const updateBulkAds = async (dataForUpdate) => {
    try {
      setAllLoaders({ ...allLoaders, mainLoader: true });
      const url = `${process.env.REACT_APP_ADS_API}/client/${clientId}:update-bulk-ads-status?source=amazon`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: userToken,
        },
        body: JSON.stringify(dataForUpdate),
      };
      const response = await fetch(url, options);
      setAllLoaders({ ...allLoaders, mainLoader: false });
      return { statusCode: response.status };
    } catch (error) {
      console.log(error);
    }
  };

  const enablePauseSingleAd = async (recievedAd) => {
    const { status, id } = recievedAd;
    const statusToBeUpdate = status === "ENABLED" ? "paused" : "enabled";
    const dataForUpdate = [{ status: statusToBeUpdate, id }];
    const mainResult = await updateBulkAds(dataForUpdate);
    if (mainResult?.statusCode !== 200) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `Unknown Error Occurred with statusCode${mainResult?.statusCode}`,
      // });
      toast.error(`Unknown Error Occurred with statusCode${mainResult?.statusCode}`)
    } else {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_SUCCESS,
      //   title: "Success",
      //   description: `Ad has been ${statusToBeUpdate} Successfully `,
      // });
      toast.success(`Ad has been ${statusToBeUpdate} Successfully `)
      const updatedState = enablePauseSingleChildAd(
        tableDataToShow,
        id,
        statusToBeUpdate.toUpperCase()
      );
      setTableDataToShow([...updatedState]);
    }
  };

  const redirectToCampaignList = (campName) => {
    if (campName) {
      window.open(
        `/campaign-list?campName=${campName}`,
        "_blank",
        "noreferrer"
      );
    }
  }

  const handleClick = event => {
    setIsBulkUploadOptionOpen(!isBulkUploadOptionOpen)
    hiddenFileInput.current.click();
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let asinValues = results.data.map((data, index) => {
          return Object.values(data)[0]
        });
        setSearchValues(asinValues.toString())
      },
    });
  };

  const redirectToAdgroupName = (childData) => {
    const { campaign_id, ad_group_id, ad_group_name, campaign_name, asin } = childData
    const campaignIdAlphanumeric = searchFiltersState.campaigns.find((campaign) => parseInt(campaign.platform_id) === campaign_id);
    const adgroupIdAlphanumeric = searchFiltersState.adGroups.find((adgroup) => parseInt(adgroup?.platform_id) === ad_group_id)
    if (campaignIdAlphanumeric && adgroupIdAlphanumeric) {
      window.open(
        `/edit-adset-targeting?campaignId=${campaignIdAlphanumeric?.id}&adsetId=${adgroupIdAlphanumeric?.id}&numericCampaignId=${campaign_id}&numericAdsetId=${ad_group_id}&name=${ad_group_name}&adsetPlatformId=${ad_group_id}&campName=${campaign_name}&editadspage=${true}&asin=${asin}`,
        "_blank",
        "noreferrer"
      );
    }
  }
  const redirectToAmazon = (asin) => {
    window.open(` https://www.amazon.in/dp/${asin.toUpperCase()}`);
  };

  function getStatusWiseValue(status, val) {
    return val === 0 || val === "" ? (status?.toUpperCase() === 'ENABLED' ? 0 : '-') : val;
  }

  const bindParentValueColumns = (parentAd, currentDataHead) => {
    const value = parentAd[currentDataHead?.id];

    if (value) {
      if (typeof value === "number" && metricesWithNoDecimal.includes(currentDataHead?.id)) {
        return (value === 0 || value === "") ? getStatusWiseValue(parentAd?.status, value) : Math.round(value);
      } else if (typeof value === "number" && metricesWithTwoDecimal.includes(currentDataHead?.id)) {
        return (value === 0 || value === "") ? getStatusWiseValue(parentAd?.status, value) : value.toFixed(2);
      } else {
        return (value === 0 || value === "") ? getStatusWiseValue(parentAd?.status, value) : value;
      }
    } else {
      return getStatusWiseValue(parentAd?.status, 0);
    }
  }

  const bindChildValueColumns = (parentAd, childAd, currentDataHead) => {
    const childRowsLength = parentAd?.childRows?.length;
    const childAdValue = childAd[currentDataHead?.id];

    if (childRowsLength && childAdValue) {
      if (typeof childAdValue === "number" && metricesWithNoDecimal.includes(currentDataHead?.id)) {
        return (childAdValue === 0 || childAdValue === "") ? getStatusWiseValue(childAd.status, childAdValue) : Math.round(childAdValue);
      } else if (typeof childAdValue === "number" && metricesWithTwoDecimal.includes(currentDataHead?.id)) {
        return (childAdValue === 0 || childAdValue === "") ? getStatusWiseValue(childAd.status, childAdValue) : childAdValue.toFixed(2);
      } else {
        return (childAdValue === 0 || childAdValue === "") ? getStatusWiseValue(childAd.status, childAdValue) : childAdValue;
      }
    } else {
      return getStatusWiseValue(childAd.status, 0)
    }
  }

  const [selectedChildRows, setSelectedChildRows] = useState([]);
  const handleCellClick = (rowIndex) => {
    setSelectedChildRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowIndex)
        ? prevSelectedRows.filter((row) => row !== rowIndex)
        : [...prevSelectedRows, rowIndex]
    );
  };

  const onNullHandler = () => {
    const fileUploader = document.querySelector("#hiddenFileUploader")
    fileUploader.value = null
  }

  let tableBody = (
    <>
      <div
        className={ lazyLoading ? "main-campaignlist-table-body-container overflow-x-hidden master-ads-main-table-wrapper" :"main-campaignlist-table-body-container master-ads-main-table-wrapper"}
        ref={tableRef}
        onScroll={handleScroll}
      >
        {tableDataToShow?.length ? (
          <>
            <table className="master-ads-table reports">
              <thead className="fixed-table">
                <tr className="main-table-row-fixed">
                  {dataHeads?.map((th, thIndex) => {
                    // th means tableHead
                    return (
                      <th
                        key={th.fieldName + thIndex + "12"}
                        className={
                          (th.id === sortBy.sort_head) ||
                            (isCampaignFilterActive && th.fieldName === "Campaign") ||
                            (isPortfolioFilterActive && th.fieldName === "Portfolio") ||
                            (adGroupFilterActive && th.fieldName === "Ad Group") ||
                            (categoryFilterActive && th.fieldName === "Category")
                            ? "targeting-table-heads selected"
                            : "targeting-table-heads"
                          // th.isSortFunctionalityNeededHere ? "stats-heads targeting-table-heads"
                          // : "targeting-table-heads"
                          // stats-heads
                        }
                      >
                        {th.isSortFunctionalityNeededHere ? (
                          <div className="d-flex flex-row align-items-center justify-content-end ">
                            <div className="d-flex align-items-center">
                              <span
                                className={
                                  th.id === sortBy.sort_head
                                    ? "stat-heads-with-icon active-filter-higlighted-text"
                                    : "stat-heads-with-icon"
                                }
                              >
                                <span
                                  className={
                                    upperCaseValues?.includes(
                                      th.fieldName.toLowerCase()
                                    )
                                      ? "text-uppercase"
                                      : "text-capitalize"
                                  }
                                >
                                  {th.fieldName === "Campaign"
                                    ? "Campaigns"
                                    : !upperCaseValues?.includes(
                                      th.fieldName.toLowerCase()
                                    )
                                      ? th.fieldName.toLowerCase()
                                      : th.fieldName === "aov"
                                        ? "AOV"
                                        : th.fieldName}
                                </span>
                              </span>
                            </div>
                            <span className="sort-icon cursor-pointer-global">
                              <img
                                className={
                                  th.id === sortBy.sort_head
                                    ? " d-flex align-items-end mt-spends-icon-activated "
                                    : " d-flex align-items-end"
                                }
                                src={
                                  !th.isSortingClicked
                                    ? descendingIconSvg
                                    : ascendingIconSvg
                                }
                                onClick={() =>
                                  onSortHandler(
                                    tableDataToShow,
                                    th.isSortingClicked,
                                    th.id,
                                    "masterAds",
                                    thIndex
                                  )
                                }
                                alt={"HeadsWithSorting"}
                              ></img>
                            </span>
                          </div>
                        ) : th.fieldName === "Product" ? (
                          <>
                            <div className="targeting-selected-head">
                              <span className="">
                                <div className="targeting-checkbox d-flex justify-content-start">
                                  <CheckboxRb
                                    className="check-colum-head"
                                    onChange={(e) => onAllAdSelection(e)}
                                    label={th.fieldName}
                                  // checked={allRowsSelected}
                                  ></CheckboxRb>
                                </div>
                              </span>
                              {/* <span className="stat-heads-with-icon">{th.fieldName}</span> */}
                            </div>
                          </>
                        ) : th.fieldName === "Portfolio" ||
                          th.fieldName === "Campaign" ||
                          th.fieldName === "Category" ||
                          th.fieldName === "Ad Group" ? (
                          <>
                            <div
                              className={`fieldnamme-with-search-iconn justify-content-start`}
                            >
                              <span className="d-flex align-items-center me-2">
                                <img
                                  // className="find-icon cursor-pointer-global"
                                  className={
                                    (isCampaignFilterActive &&
                                      th.fieldName === "Campaign") ||
                                      (isPortfolioFilterActive &&
                                        th.fieldName === "Portfolio") ||
                                      (adGroupFilterActive &&
                                        th.fieldName === "Ad Group") ||
                                      (categoryFilterActive &&
                                        th.fieldName === "Category")
                                      ? "find-icon cursor-pointer-global mt-find-icon-activated"
                                      : "find-icon cursor-pointer-global"
                                  }
                                  src={whiteFindIcon}
                                  onClick={() =>
                                    onSearchModalHandler(th.fieldName)
                                  }
                                  alt={"whiteFindIcon"}
                                ></img>
                              </span>
                              <div className="d-flex align-items-center test">
                                <span
                                  className={
                                    (isCampaignFilterActive &&
                                      th.fieldName === "Campaign") ||
                                      (isPortfolioFilterActive &&
                                        th.fieldName === "Portfolio") ||
                                      (adGroupFilterActive &&
                                        th.fieldName === "Ad Group") ||
                                      (categoryFilterActive &&
                                        th.fieldName === "Category")
                                      ? "active-filter-higlighted-text"
                                      : "stat-heads-with-icon "
                                  }
                                >
                                  {th.fieldName === "Campaign"
                                    ? "Campaigns"
                                    : th.fieldName}
                                </span>
                              </div>
                              {columnName === th.fieldName &&
                                allFiltersVisible.searchSortModal && (
                                  <MasterTargetingSearchSelectModal
                                    findClickedColumnName={columnName}
                                    keyToBeSet={
                                      th.fieldName === "Campaign"
                                        ? "campaign_id"
                                        : th.fieldName === "Ad Group"
                                          ? "adgroup_id"
                                          : th.fieldName === "Portfolio"
                                            ? "portfolio_id"
                                            : "category"
                                    }
                                    getUpdatedDrowDownFilters={
                                      getUpdatedDrowDownFilters
                                    }
                                    isVisible={
                                      allFiltersVisible.searchSortModal
                                    }
                                    initialData={
                                      th.fieldName === "Campaign"
                                        ? searchFiltersState.campaigns
                                        : th.fieldName === "Ad Group"
                                          ? searchFiltersState.adGroups
                                          : th.fieldName === "Portfolio"
                                            ? searchFiltersState.portfolios
                                            : categories
                                    }
                                    cancelFilter={setAllFilterVisible}
                                    currentFilters={allFiltersVisible}
                                  />
                                )}
                            </div>
                          </>
                        ) : (
                          <>
                            <span>
                              {th.fieldName === "ACTION"
                                ? "Action"
                                : th.fieldName}
                            </span>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {tableDataToShow.length ? (
                <tbody className="another-body">
                  {tableDataToShow?.map((parentAd, paIndex) => {
                    return (
                      <React.Fragment key={parentAd?.asin}>
                        <tr   onClick={() => handleCellClick(paIndex + "mainrow")}
                          className={
                            selectedChildRows.includes(paIndex + "mainrow")
                              ? "cursor-pointer highlight"
                              : "cursor-pointer"
                          }>
                          <td className="first-column-sw-btn ads-td-first">
                            <div className="classlist-switch-btn">
                              {
                                // <SwitchBtn
                                //   checked={
                                //     parentAd?.status === "ENABLED" ? true : false
                                //   }
                                //   onChange={() => console.log("Yes")}
                                // ></SwitchBtn>
                                <div className="mt-status-dot-container">
                                  <div
                                    className={
                                      parentAd?.status === "enabled"
                                        ? "enabled-status-dots-mt"
                                        : "paused-status-dots-mt"
                                    }
                                  ></div>
                                </div>
                              }
                            </div>
                          </td>
                          <td className="ads-td-second">
                            <div className="main-parent-table-product-container d-flex  justify-content-between align-item-center" >
                              <div className="d-flex">
                                <div className="checkbox-container" onClick={() => handleCellClick(paIndex + "mainrow")}>
                                  <CheckboxRb
                                    className="targeting-checkbox"
                                    onChange={() =>
                                      onParentRowCheckBoxHandler(parentAd?.asin)
                                    }
                                    checked={parentAd.isTargetChecked}
                                  ></CheckboxRb>
                                </div>
                                <ProductNameTd
                                  product_name={parentAd?.product_name}
                                  asin={parentAd?.asin}
                                  image_url={parentAd?.imageurl}
                                  dynamicWidth="product-name-dyanamic-width-foe-master-ad"
                                />
                              </div>
                              <div
                                className={
                                  parentAd.isPlusIconDisabled
                                    ? "plus-icon-sec-container d-flex align-items-center"
                                    : "plus-icon-sec-container-when-clicked d-flex align-items-center"
                                }
                              >
                                <div className="product-child-row-button cursor-pointer-global d-flex justify-content-end">
                                  <div className="td-with-loader d-flex align-items-center" onClick={() => handleCellClick(paIndex + "mainrow")}>
                                    {allLoaders.childRowLoader &&
                                      currentAsin === parentAd?.asin ? (
                                      <Spinner
                                        shimmer={true}
                                        shimmerShape={"box"}
                                      />
                                    ) : (
                                      <img
                                        src={
                                          parentAd.isPlusIconDisabled
                                            ? AddIcon
                                            : MinusIcon
                                        }
                                        alt=""
                                        onClick={() => {
                                          expandRow(parentAd?.asin);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span
                              className="cursor-pointer-global adgroup-clickable-text-color"
                              onClick={() => redirectToAmazon(parentAd?.asin)}
                            >
                              {parentAd?.asin}
                            </span>
                          </td>
                          <td className="portfolioContainer">
                            {parentAd?.portfolio ?? "-"}
                          </td>
                          <td>
                            <div className="d-flex flex-row  ">
                              <div className="campaign-count-circle ">
                                {parentAd?.campaign_count ?? "-"}
                              </div>
                              <span>{"Campaigns"}</span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row  ">
                              <div className="campaign-count-circle">
                                {parentAd?.adgroup_count ?? "-"}
                              </div>
                              <span>{"Ad Groups"}</span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row  ">
                              {/* <div className="campaign-count-circle me-3">
                              {parentAd?.category ?? "-"}
                            </div> */}
                              <span className="text-start">
                                {parentAd?.category ?? "-"}
                              </span>
                            </div>
                          </td>
                          {dataHeads?.map((currentDataHead, i) => {
                            if (!currentDataHead.isDefaultHead) {
                              return (
                                <td key={i + 11} className="stats-data-col">
                                  {bindParentValueColumns(parentAd, currentDataHead)}
                                </td>
                              );
                            }
                          })}
                        </tr>

                        {allLoaders.childRowLoader &&
                          currentAsin === parentAd.asin ? (
                          <>
                            {Array.from(Array(3)).map((dumyEl) => {
                              return (
                                <tr>
                                  {dataHeads.map((el) => {
                                    return (
                                      <td className="ma-loader">
                                        {/* <div className="ma-loader"> */}
                                        <div className="td-with-loader">
                                          <Spinner shimmer={true} />
                                          {/* </div> */}
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          parentAd["childRows"] &&
                          parentAd["childRows"].map((childAd, mainIndex) => {
                            return (
                              <tr
                              key={mainIndex + Math.random() * 213}
                              // className={
                              //   parentAd.isPlusIconDisabled
                              //     ? "hide"
                              //     : "ad-campaign-details"
                              // }
                              onClick={() => handleCellClick(mainIndex + "subrow")}
                              className={parentAd.isPlusIconDisabled ? "hide"
                                  : selectedChildRows.includes(mainIndex + "subrow")
                                  ? "ad-campaign-details cursor-pointer highlight"
                                  : "ad-campaign-details cursor-pointer"
                              }
                              >
                              <td className="ads-td-first">
                                <div className="classlist-switch-btn child-switch-btn-container"  onClick={() => handleCellClick(mainIndex + "subrow")}>
                                  {
                                    <SwitchBtn
                                      checked={
                                        childAd.status === "ENABLED"
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        enablePauseSingleAd(childAd);
                                      }}
                                    />
                                  }
                                </div>
                              </td>
                              <td className="ads-td-second">
                                <div className="main-child-table-product-containerr d-flex">
                                  <div className="checkbox-container child-checkbox-containet-master-ad"  onClick={() => handleCellClick(mainIndex + "subrow")}>
                                    <CheckboxRb
                                      className="targeting-checkbox"
                                      onChange={() =>
                                        onChildRowBoxHandler(
                                          parentAd?.asin,
                                          mainIndex
                                        )
                                      }
                                      checked={
                                        childAd.isTargetChecked ? true : false
                                      }
                                    ></CheckboxRb>
                                  </div>
                                  <ProductNameTd
                                    asin={childAd?.asin ?? "-"}
                                    product_name={childAd?.product_name}
                                    image_url={childAd?.imageurl}
                                    dynamicWidth="product-name-dyanamic-width-foe-master-ad"
                                  />
                                  </div>
                                </td>
                                <td>
                                  <span
                                    className="cursor-pointer-global adgroup-clickable-text-color"
                                    onClick={() => redirectToAmazon(childAd?.asin)}
                                  >
                                    {childAd?.asin ?? "-"}
                                  </span>
                                </td>
                                <td>{childAd?.portfolio_name ?? "-"}</td>
                                <td>
                                  <div className="chid-row-campaign cursor-pointer-global adgroup-clickable-text-color" onClick={() => redirectToCampaignList(childAd?.campaign_name)}>
                                    {childAd?.campaign_name ?? "-"}
                                  </div>
                                </td>
                                <td>
                                  <div className="chid-row-campaign cursor-pointer-global adgroup-clickable-text-color" onClick={() => redirectToAdgroupName(childAd)}>
                                    {childAd?.ad_group_name ?? "-"}
                                  </div>{" "}
                                </td>
                                <td>{childAd?.category ?? "-"}</td>
                                {dataHeads?.map((currentDataHead, i) => {
                                  if (!currentDataHead.isDefaultHead) {
                                    return (
                                      <td
                                        key={i + 13}
                                        className="stats-data-col"
                                      >
                                        {bindChildValueColumns(parentAd, childAd, currentDataHead)}
                                        {/* {parentAd?.childRows?.length
                                          ? typeof childAd[
                                            currentDataHead.id
                                          ] === "number" &&
                                            metricesWithTwoDecimal.includes(
                                              currentDataHead.id
                                            )
                                            ? childAd[
                                              currentDataHead.id
                                            ] === "number" &&
                                              metricesWithTwoDecimal.includes(
                                                currentDataHead.id
                                              )
                                              ? childAd[
                                                currentDataHead.id
                                              ].toFixed(2)
                                              : childAd[currentDataHead.id]
                                          : "-"} */}
                                      </td>
                                    );
                                  }
                                })}
                              </tr>
                            );
                          })
                        )}
                        {/* 
                        {paIndex === tableDataToShow.length -1 && lazyLoading && Array.from(new Array(3)).map((data) => {
                          return   <tr> 
                           {Array.from(new Array(12)).map((data)  => {
                            return (
                              <td className="loader-td"> 
                              <div className="td-with-loader"> <Spinner shimmer={true}/> </div>
                              </td>
                            )
                           })}
                          </tr>
                        }) } */}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              ) : 
                
                  !allLoaders.mainLoader  &&  <p>No Data Found</p>
                
               
              }
            </table>
            {lazyLoading && (
              <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
                <LazyLoadSpinner text={"Loading 50 More Ads..."} />
              </div>
            )}
          </>
        ) : (
          <>
         { (!allLoaders?.mainLoader && !tableDataToShow?.length) &&  <p>NO Data Found</p>}
          </>
        )}
      </div>
    </>
  );
  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box-master-ads" onClick={handleClickOutside}
    >
      <Breadcrumb />
      <div className="campaign-list-container">
        <Loader visible={initialLoad} />
        <div>
          <FilterLogicModal
            show={allFiltersVisible?.mainFilterLogicModal}
            filterFor={"master-ads-list"}
            cancelSpecifiNumberFilter={cancelSpecifiNumberFilter}
          />
        </div>
        <div>
          <CSVUploadModal
            show={allFiltersVisible?.csvUploadModal}
            filterFor={"master-ads-list"}
            confirmationId={'bulk-enable-pause-csv-confirm'}
            cancelSpecifiNumberFilter={cancelSpecifiNumberFilter}
          />
        </div>

        <div>
          {actionAlertModalText !== "" &&
            <MasterTagetinngActionAlertMModal
              alertText={actionAlertModalText}
              noclickhandler={noclickhandler}
              yesclickhandler={yesclickhandler}
              onYesClick={onYesClick}
              confirmationId={confirmationId}
            />
          }
        </div>
        <div
          className={
            selectedAds.length ? "et-filter-options-for-master-ads alignment-top-master-ads" : "hide"
          }
        >
          <button className="selected-row-count mx-2">
            <img
              className="dash-icon"
              src={MinusIcon}
              alt={"selectedIcon"}
              onClick={removeUpdatedCount}
            ></img>
            <span className="selected-text-with-count">
              {selectedAds && `${selectedAds?.length}  Selected`}
            </span>
          </button>
          <CampaignListTab
            onClickHandler={() => enablePauseHandler("Enable")}
            imageSrc={pauseIcon}
            imageText={"Enable"}
          />
          <CampaignListTab
            onClickHandler={() => enablePauseHandler("Pause")}
            imageSrc={resumeIcon}
            classes={"mad-tab-border"}
            imageText={"Pause"}
          />
        </div>
        <Stack>
          <Stack direction="horizontal" className="d-flex align-item-center">
            <SectionTitle sectionTitleHeading={"Product Ads"} />
          </Stack>
          <div className="mt-config-row bottom-margin">
            <Stack className="mt-configs-btn-container d-flex flex-row">
              <Stack
                direction="horizontal"
                className="list-pages-search-input-container mb-1"
              >
                <Stack
                  direction="vertical"
                  className="justify-content-end align-item-end"
                >
                  <SearchBarForCampaignListOnly
                    placeholder="Search for Ads"
                    value={searchValues}
                    // onClickCancel={cancelIconInSearchInput}
                    searchBarForListPage={true}
                    // onChange={(e) => setSearchValues(e.target.value)}
                    onChange={(e) => inputHandler(e.target.value)}
                    onKeyUp={onAdsSearchHandler}
                    isCancelIconNotVisible={true}
                    // onCancelIconClick={cancelIconInSearchInput}
                    // onKeyDown={onSearchBarEnterClicked}
                    id="ma-global-targeting-list"
                    className="mt-custom-search-bar"
                    dynamicWidth="search-input-width-for-listing-pages"
                    defaultValue={
                      asinIdLinkFromProductReport
                        ? asinIdLinkFromProductReport
                        : ""}
                  ></SearchBarForCampaignListOnly>
                </Stack>
              </Stack>
              <Stack
                direction="horizontal"
                className="d-flex justify-content-center flex-wrap"
              >


                <Stack
                  className="mt-filter-gap mb-1 mt-filter-gap mb-1"
                  direction="horizontal"
                >
                  <CustomDropDown
                    title={"CampaignType"}
                    data={["All", "SP", "SD"]}
                    filterCampaigns={filterCampaigns}
                    targettingValue={filters.campaign_type}
                    type={"campaign_type"}
                    src={dropdownIcon}
                  // data
                  />

                </Stack>
                <Stack
                  className="master-ads-calendar-container mb-1"
                  direction="horizontal"
                >
                  <Stack className="d-flex justify-content-end align-item-end d-none">
                    <button
                      className="date-container date-btn-campaignlist"
                      onClick={dateInputClickHandler}
                    >
                      <div className="d-flex justify-conntent-center">
                        <span className="date-btn-text">{dateDifference}</span>
                      </div>
                      <div className="btn-calender-img-holder">
                        <span className="">
                          <img
                            className="dropdown-img-for-select"
                            src={selectSideIcon}
                            alt={"SelecteSideIcon"}
                          ></img>

                          <img
                            className="calender-icon-for-campaignlist"
                            src={CalenderIconForCampaignList}
                            alt={"CalendarIconForCampaignList"}
                          ></img>
                        </span>
                      </div>
                    </button>
                  </Stack>
                  <div
                    ref={masterAdsCalendarIconRef}
                    className={
                      showCustomDateBox
                        ? "calender-masterads-container"
                        : "hide"
                    }
                  >
                    {showCustomDateBox && (
                      <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
                    )}
                    <span className="calender-close-icon-campaignlist">
                      <img
                        onClick={() => setShowCustomDateBox(false)}
                        src={closeIcon}
                        alt={"closeIcon"}
                      ></img>
                    </span>
                  </div>
                </Stack>
                <Stack>
                  <div className="d-flex align-items-end h-100 px-2 py-1">
                    <div className="">
                      <div className="bulk-upload-option">
                        <div className="toggle-button" ref={kababRef} onClick={() => { setIsBulkUploadOptionOpen(!isBulkUploadOptionOpen) }}>
                          <FaEllipsisV className="toggle-icon" />
                        </div>
                        {isBulkUploadOptionOpen && (
                          <div className="options">
                            <div className="option">
                              <img onClick={handleClick} src={csvIcon} alt={"upload"} style={{ height: "16px", width: "16px", marginRight: "10px" }} />
                              <span className="option-label" onClick={handleClick}>Bulk Search</span>
                            </div>
                            <div className="option">
                              <img onClick={onCSVIconClick} src={csvIcon} alt={"upload"} style={{ height: "16px", width: "16px", marginRight: "10px" }} />
                              <span className="option-label" onClick={onCSVIconClick}>Bulk Enable/Pause</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <button type="button" class="btn btn-outline-light" style={{ borderColor: "rgba(77, 65, 132,0.3)" }}>
                        <img  onClick={handleClick} src={csvIcon} alt={"upload"} style={{height:"26px",width:"26px"}} />
                        <img onClick={onCSVIconClick} src={csvIcon} alt={"upload"} style={{ height: "26px", width: "26px" }} />
                      </button> */}
                    </div>
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      ref={hiddenFileInput}
                      style={{ display: "none" }}
                      onInput={changeHandler}
                      id={"hiddenFileUploader"}
                      onClick={onNullHandler}
                    />
                  </div>

                </Stack>
              </Stack>
              {/* </Stack> */}
            </Stack>
          </div>
          <Stack direction="horizontal">
            <Stack className="d-flex flex-row align-items-center">
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`All (${overAllAdsCount?.total_count})`}
                  className={
                    selectedBtn === "all-btn"
                      ? "primary-active-btn  ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="all-btn"
                  onClick={(e, type, value) =>
                    onSelectedBtnChangeHandler(e, "status", "All")
                  }
                ></SecondaryBtnRb>
              </div>
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`Enabled (${overAllAdsCount?.enabled_count})`}
                  className={
                    selectedBtn === "enabled-btn"
                      ? "primary-active-btn ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="enabled-btn"
                  onClick={(e, type, value) =>
                    onSelectedBtnChangeHandler(e, "status", "ENABLED")
                  }
                ></SecondaryBtnRb>
              </div>
              <div>
                <SecondaryBtnRb
                  secondaryBtnText={`Paused (${overAllAdsCount?.paused_count})`}
                  className={
                    selectedBtn === "PAUSED"
                      ? "primary-active-btn ennable-paused-btns-height "
                      : "primary-inactive-btn ennable-paused-btns-height "
                  }
                  id="PAUSED"
                  value="PAUSED"
                  onClick={(e, type, value) =>
                    onSelectedBtnChangeHandler(e, "status", "PAUSED")
                  }
                ></SecondaryBtnRb>
              </div>
              <div className="filter-icon-container-mainmt-filter-gap filter-icon-container-main margin-left-mt-filter-icon-container">
                <div
                  className={
                    minMaxFilterActivated ? "activated-min-max-filter" : "hide"
                  }
                ></div>
                <img
                  src={settingIcon}
                  onClick={onFilterIconhandler}
                  className="cursor-pointer-global"
                  alt="FilterIcon"
                ></img>

                {/* <div className="filter-icon-container-mainmt-filter-gap filter-icon-container-main ">
                <div
                  className={
                    minMaxFilterActivated ? "activated-min-max-filter" : "hide"
                  }
                ></div>
                <img src={settingIcon} onClick={onFilterIconhandler}></img>
              </div> */}
              </div>
            </Stack>
          </Stack>
          <Stack className={dataForChips.length ? "pt-3 " : "hide"}>
            <FilterChips
              filterChipList={dataForChips}
              onCancelClickRemoveFilteringHandler={(fieldNameForAPIFilter) =>
                onCancelClickRemoveFilteringHandlerr(fieldNameForAPIFilter)
              }
            ></FilterChips>
          </Stack>
          <div className="d-flex justify-content-end w-100 mt-2">
                <Link to={"#"} className={"text-muted"} style={{fontSize:"12px"}}>* SB Ads Data Not Present</Link>
          </div>
          <div className="master-ads-table-wrapper">
            <Stack className="master-ads-main-table">
              <TableRb tableHeads={dataHeads} shimmer={allLoaders?.mainLoader} noDataFound={(!allLoaders?.mainLoader && !tableDataToShow?.length && !initialLoad)}  table={tableBody}></TableRb>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default MasterAdsMain;
