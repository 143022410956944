import { data } from "browserslist";
import { masterApi } from "../../apis/masterApi";
import modifyValueByPercentage from "../../helper/common/modifyValueByPercentage";
import numeral from "numeral";
import { formatDecimal } from "../../components/Reports/Components/utils";
import ExcelJS from 'exceljs';
import { encodeData } from "../../helper/common/encodeData";
import movingUp from "../../assets/svgs/arrow_upward_green.svg";
import movingDown from "../../assets/svgs/arrow_downward_red.svg";
import editIcon from "../../assets/icons/edit.svg";
import checkCircle from "../../assets/icons/check_circle.png";
import moment from "moment";
import { formatDateFn } from "../../helper/common/formatDateForAPI";
import defaultDateRangesWithLables from "../../constant/calendarDefaultConfigurations";
import cancelIcon from '../../assets/icons/cancelIcon.png';
export const formatDate = (string) => {
  let date = new Date(string);
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0].toString();
};

export const OPTIMIZER_TABLE_ACTIONS = {
  EXPAND_ROW: "expand_row",
  EXPAND_ROW_AND_SELECT_CHILDREN: "expand_and_select",
  SELECT_ALL: "select_all",
  SET_INITIAL_STATE: "set_initial_state",
  ADD_CHILD_ROWS: "add_child_rows",
  EDIT_TABLE_CELL: "edit_table_cell",
  CANCEL_EDIT_TABLE_CELL:"cancel_edit_table_cell",
  SELECT_SINGLE_ROW: "select_single",
  SELECT_ALL_ROWS: "select_all_rows",
  TOGGLE_ENABLE_PAUSE: "toggle_enable_pause",
  SELECT_ALL_ROWS_FROM_HEADER: "select_all_from_header",
  KEY_VALUE_CHANGE: "key_value_change",
  ADD_MORE_DATA: "add_more_data",
  UPDATE_BULK_ROWS:"update_bulk_rows",
  HIGHLIGHT_ROW:"highlight_row"
};

export const COMMON_METRIC_HEADERS = [
  {
    name: "Spends",
    key: "cost",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0",
  },
  {
    name: "Spends DRR",
    key: "costDRR",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0.00",
  },
  {
    name: "Impressions",
    key: "impressions",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0",
  },
  {
    name: "Impressions DRR",
    key: "impressionsDRR",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0.00",
  },
  {
    name: "Clicks",
    key: "clicks",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0",
  },
  {
    name: "Clicks DRR",
    key: "clicksDRR",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0.00",
  },
  {
    name: "CTR",
    key: "ctr",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "CTR DRR",
    key: "ctrDRR",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "CPC",
    key: "cpc",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "CPC DRR",
    key: "cpcDRR",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "CPM",
    key: "cpm",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "CPM DRR",
    key: "cpmDRR",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "Orders",
    key: "conversions14d",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0",
  },
  {
    name: "Orders DRR",
    key: "conversions14dDRR",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0.00",
  },
  {
    name: "Conversion Rate",
    key: "conversion_rate",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "0.00%",
  },
  {
    name: "Conversion Rate DRR",
    key: "conversion_rateDRR",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "0.00%",
  },
  {
    name: "Revenue",
    key: "sales14d",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0",
  },
  {
    name: "Revenue DRR",
    key: "sales14dDRR",
    sorting: true,
    showTotal: true,
    showPercentage: true,
    format: "0.00",
  },
  {
    name: "AOV",
    key: "average_order_value",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "0",
  },
  {
    name: "AOV DRR",
    key: "average_order_valueDRR",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "0.00",
  },
  {
    name: "ACOS",
    key: "acos",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "ACOS DRR",
    key: "acosDRR",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "ROAS",
    key: "roas",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
  {
    name: "ROAS DRR",
    key: "roasDRR",
    sorting: true,
    showTotal: true,
    showPercentage: false,
    format: "",
  },
];

export const COMMON_METRICS = [
  "agg_spends",
  "spends",
  "agg_impressions",
  "impressions",
  "agg_clicks",
  "clicks",
  "agg_ctr",
  "ctr",
  "agg_cpc",
  "cpc",
  "agg_cpm",
  "cpm",
  "agg_cvr",
  "cvr",
  "agg_sale_value",
  "sale_value",
  "agg_aov",
  "aov",
  "agg_acos",
  "acos",
  "agg_roas",
  "roas",
  "orders",
  "agg_orders",
];

export const GROUP_BY_CONFIGS = {
  Global: {
    name: "Target Report",
    sub_heading: "",
    type: "table",
    tableHeaders: [
      {
        name: "Action",
        key: "status",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        isSelectAll: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Targeting",
        key: "targeting",
        sorting: true,
        showTotal: false,
        showPercentage: false,
        isSelected: false,
        sort_order: "desc",
        format: "",
        isSelectAll: true,
        isEnabled: true,
      },
      {
        name: "Targeting Type",
        key: "adyogi_targeting_type",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Portfolio",
        key: "portfolio_count",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        suffix: "Portfolios",
        isEnabled: true,
      },
      {
        name: "Campaigns",
        key: "campaign_count",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        suffix: "Campaigns",
        isEnabled: true,
      },
      {
        name: "Ad Group",
        key: "ad_group_count",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        suffix: "Ad Group",
        isEnabled: true,
      },
      {
        name: "Product Set",
        key: "product_set",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Current Bid",
        key: "current_bid",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Bid Strategy",
        key: "strategy",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Campaign Type",
        key: "campaign_type",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Avg. BR",
        key: "agg_br",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "DBR",
        key: "dbr",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "TOSP Bids",
        key: "tosp_bids",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "PDP Bids",
        key: "pdp_bids",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },

      {
        name: "Spends",
        key: "agg_spends",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isSelected: false,
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Spends DRR",
        key: "spends",
        sorting: true,
        showTotal: true,
        isSelected: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Impressions",
        key: "agg_impressions",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Impressions DRR",
        key: "impressions",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Clicks",
        key: "agg_clicks",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Clicks DRR",
        key: "clicks",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "CTR",
        key: "agg_ctr",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "CTR DRR",
        key: "ctr",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "CPC",
        key: "agg_cpc",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "CPC DRR",
        key: "cpc",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "CPM",
        key: "agg_cpm",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "CPM DRR",
        key: "cpm",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Orders",
        key: "agg_orders",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Orders DRR",
        key: "orders",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Conversion Rate",
        key: "agg_cvr",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "0.00%",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Conversion Rate DRR",
        key: "cvr",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "0.00%",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Revenue",
        key: "agg_sale_value",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Revenue DRR",
        key: "sale_value",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "AOV",
        key: "agg_aov",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "AOV DRR",
        key: "aov",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "ACOS",
        key: "agg_acos",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "ACOS DRR",
        key: "acos",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "ROAS",
        key: "agg_roas",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "ROAS DRR",
        key: "roas",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
    ],
    summary: true,
    cube: "AmazonTargetTable",
    charts_config: [
      {
        type: "Cost",
        metric1: "cost",
        metric2: "",
        chart_type: "apexpie",
        data: {},
      },
      {
        type: "Revenue",
        metric1: "sales14d",
        metric2: "",
        chart_type: "apexpie",
        data: {},
      },
    ],
    filters: [
      {
        name: "Campaign Objective",
        default: [],
        multi: true,
        operator: "IN",
        options: ["Retarget", "Prospect"],
        key: "campaign_objective",
        id:"targeting-campaign-objective"
      },
      {
        name: "Campaign Type",
        default: [],
        multi: true,
        operator: "IN",
        options: ["SD", "SP", "SB(V)", "SB"],
        key: "campaign_type",
        id:"targeting-campaign-type"
      },
      {
        name: "Campaign Status",
        default: [],
        multi: true,
        operator: "IN",
        options: ["enabled", "paused"],
        key: "campaign_status",
        id:"targeting-campaign-status"
      },
      {
        name: "Product Set",
        default: [],
        multi: true,
        operator: "IN",
        options: [],
        key: "product_set",
        api: {
          url: "https://adyogiadsapi.adyogi.com/client/${clientId}:product-set?source=amazon",
          key: "product_set",
        },
        id:"targeting-product-set"
      },
      {
        name: "Targeting Type",
        default: [],
        multi: true,
        operator: "IN",
        options: [
          "G.Keyword",
          "C.Keyword",
          "NB.Keyword",
          "Audience",
          "Automatic",
          "NB.Product",
          "B.Keyword",
          "B.Audience",
          "B.Product",
        ],
        key: "targeting_group_type",
        id:"targeting-targeting-type"
      },
      {
        name: "Match Type",
        multi: true,
        operator: "IN",
        default: [],
        options: [
          "Automatic",
          "Exact",
          "Broad",
          "Phrase",
          "Product",
          "Audience",
        ],
        key: "adyogi_targeting_type",
        id:"targeting-match-type"
      },

      {
        name: "Bid Strategy",
        default: [],
        multi: true,
        operator: "IN",
        options: ["down", "up&down"],
        key: "strategy",
        id:"targeting-bid-strategy"
      },
    ],
    tableFilters: [
      {
        placeHolderName: "Campaigns",
        name: "Campaigns",
        masterApiKey: "campaignList",
        stateFilterKey: "campaign_name",
        default: [],
        reqConfig: { source: "amazon", fields: "name,id,platform_id" },
        options: [],
        allData: [],
        field_key: "name",
        multi: true,
        operator: "IN",
        filter_value_key: "platform_id",
        key: "campaign_id",
        different_key: true,
        filterKey: "campaign_name",
        id:"targeting-campaign-name-apply-filter"
      },
      {
        placeHolderName: "Portfolio",
        name: "Portfolio",
        masterApiKey: "getPortfolios",
        stateFilterKey: "campaign_name",
        multi: true,
        default: [],
        operator: "IN",
        reqConfig: { source: "amazon", fields: "name,id,platform_id" },
        options: [],
        allData: [],
        field_key: "name",
        different_key: true,
        filter_value_key: "platform_id",
        key: "portfolio_id",
        filterKey: "portfolio_name",
        id:"targeting-portfolio-name-apply-filter"
      },
      {
        placeHolderName: "Ad Group",
        name: "Ad Group",
        masterApiKey: "adsetList",
        stateFilterKey: "campaign_name",
        multi: true,
        default: [],
        operator: "IN",
        reqConfig: { source: "amazon", fields: "name,id,platform_id" },
        options: [],
        allData: [],
        field_key: "name",
        different_key: true,
        filter_value_key: "platform_id",
        key: "ad_group_id",
        filterKey: "portfolio_name",
        id:"targeting-ad-group-apply-filter"
      },
    ],
    dimensions: ["text", "adyogiTargetingType", "targetStatus"],
    timeDimension: "date",
    order: ["spends", "desc"],
    group_by: {
      name: "Group By",
      default: "Global",
      options: ["Global", "Granular"],
      key: "group_by",
    },
    metrics: [
      "portfolioCount",
      "campaignNameCount",
      "adGroupNameCount",
      "cost",
      "costDRR",
      "impressions",
      "impressionsDRR",
      "clicks",
      "clicksDRR",
      "ctr",
      "ctrDRR",
      "cpc",
      "cpcDRR",
      "cpm",
      "cpmDRR",
      "bid",
      "conversions14d",
      "conversions14dDRR",
      "conversion_rate",
      "conversion_rateDRR",
      "sales14d",
      "sales14dDRR",
      "average_order_value",
      "average_order_valueDRR",
      "acos",
      "acosDRR",
      "roas",
      "roasDRR",
    ],
    isChartRequired: true,
    chart_details: {
      chart_name: "targeting_chart",
    },
    wider_views: ["Aggregate", "DRR"],
    normal_views: ["Comparison", "Custom"],
    searchBarRequired: false,
    child_data: {
      cube: "AmazonTargetTable",
      expand_on: "targeting",
      metrics: [
        "cost",
        "costDRR",
        "impressions",
        "impressionsDRR",
        "clicks",
        "clicksDRR",
        "ctr",
        "ctrDRR",
        "cpc",
        "cpcDRR",
        "cpm",
        "bid",
        "cpmDRR",
        "conversions14d",
        "conversions14dDRR",
        "conversion_rate",
        "conversion_rateDRR",
        "sales14d",
        "sales14dDRR",
        "average_order_value",
        "average_order_valueDRR",
        "acos",
        "acosDRR",
        "roas",
        "roasDRR",
      ],
      tableHeaders: [
        {
          name: "Action",
          key: "status",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          isSelectAll: false,
          format: "",
        },
        {
          name: "Targeting",
          key: "targeting",
          sorting: true,
          showTotal: false,
          showPercentage: false,
          isSelectAll: true,
          format: "",
        },
        {
          name: "Targeting Type",
          key: "adyogi_targeting_type",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
          isSelectAll: false,
        },
        {
          name: "Portfolio",
          key: "portfolio_name",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
        },
        {
          name: "Campaigns",
          key: "campaign_name",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
        },
        {
          name: "Ad Group",
          key: "adgroup_name",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
          suffix: "Ad Group",
        },
        {
          name: "Product Set",
          key: "product_set",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
          isEnabled: true,
        },
        {
          name: "Current Bid",
          key: "current_bid",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
          isEnabled: true,
        },
        {
          name: "Bid Strategy",
          key: "strategy",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
          isEnabled: true,
        },
        {
          name: "Avg. BR",
          key: "agg_br",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
          isEnabled: true,
        },
        {
          name: "DBR",
          key: "dbr",
          sorting: false,
          showTotal: false,
          showPercentage: false,
          format: "",
          isEnabled: true,
        },
        {
          name: "Spends",
          key: "agg_spends",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0",
        },
        {
          name: "Spends DRR",
          key: "spends",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0.00",
        },
        {
          name: "Impressions",
          key: "agg_impressions",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0",
        },
        {
          name: "Impressions DRR",
          key: "impressions",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0.00",
        },
        {
          name: "Clicks",
          key: "agg_clicks",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0",
        },
        {
          name: "Clicks DRR",
          key: "clicks",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0.00",
        },
        {
          name: "CTR",
          key: "agg_ctr",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "CTR DRR",
          key: "ctr",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "CPC",
          key: "agg_cpc",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "CPC DRR",
          key: "cpc",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "CPM",
          key: "agg_cpm",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "CPM DRR",
          key: "cpm",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "Orders",
          key: "agg_orders",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0",
        },
        {
          name: "Orders DRR",
          key: "orders",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0.00",
        },
        {
          name: "Conversion Rate",
          key: "agg_cvr",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "0.00%",
        },
        {
          name: "Conversion Rate DRR",
          key: "cvr",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "0.00%",
        },
        {
          name: "Revenue",
          key: "agg_sale_value",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0",
        },
        {
          name: "Revenue DRR",
          key: "sale_value",
          sorting: true,
          showTotal: true,
          showPercentage: true,
          format: "0.00",
        },
        {
          name: "AOV",
          key: "agg_aov",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "0",
        },
        {
          name: "AOV DRR",
          key: "aov",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "0.00",
        },
        {
          name: "ACOS",
          key: "agg_acos",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "ACOS DRR",
          key: "acos",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "ROAS",
          key: "agg_roas",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
        {
          name: "ROAS DRR",
          key: "roas",
          sorting: true,
          showTotal: true,
          showPercentage: false,
          format: "",
        },
      ],
      dimensions: [
        "text",
        "adyogiTargetingType",
        "portfolioName",
        "campaignName",
        "adGroupName",
        "targetStatus",
      ],
      timeDimension: "date",
      order: ["cost", "desc"],
      carry_forward_parent_filters: true,
    },
    metricsToFormat: [
      "spends",
      "agg_spends",
      "impressions",
      "agg_impressions",
      "clicks",
      "agg_clicks",
      "ctr",
      "agg_ctr",
      "cpc",
      "agg_cpc",
      "cpm",
      "agg_cpm",
      "orders",
      "agg_orders",
      "cvr",
      "agg_cvr",
      "sale_value",
      "agg_sale_value",
      "aov",
      "agg_aov",
      "acos",
      "agg_acos",
      "roas",
      "agg_roas",
      "agg_acos",
    ],
    search_by: "targeting",
    selectAllKey: "targeting",
    isExpandable: true,
    isChildSelectable: true,
    renderEditableField: true,
    editableKey: "current_bid",
    parentRowEditable: false,
    childRowEditable: true,
    pointerKey: "status",
    formatting: {},
    isTableFilter: true,
    fromCube: false,
    fromAPI: true,
    masterApiKey: "getOptimisedBids",
  },
  "Targeting Group": {
    tableHeaders: [
      {
        name: "Targeting Type",
        key: "targetingType",
        sorting: true,
        showTotal: false,
        showPercentage: false,
        isSelected: false,
        sort_order: "desc",
        format: "",
        isEnabled: true,
        // isSelectAll: true,
      },
      ...COMMON_METRIC_HEADERS,
    ],
    filters: [
      {
        name: "Campaign Type",
        default: [],
        options: ["Retarget", "Prospect"],
        key: "campaignType",
      },
      {
        name: "Campaign Format",
        default: [],
        options: ["SD", "SP", "SB(V)", "SB"],
        key: "campaignFormat",
      },
      {
        name: "Targeting Type",
        default: [],
        options: [
          "G.Keyword",
          "C.Keyword",
          "NB.Keyword",
          "Audience",
          "Automatic",
          "NB.Product",
          "B.Keyword",
          "B.Audience",
          "B.Product",
        ],
        key: "targetingType",
      },
      {
        name: "Product Set",
        default: [],
        options: [],
        key: "productSet",
        api: {
          url: "https://adyogiadsapi.adyogi.com/client/${clientId}:product-set?source=amazon",
          key: "product_set",
        },
      },
      {
        name: "Portfolio Name",
        default: [],
        options: [],
        key: "portfolioName",
        api: {
          url: "https://adyogiadsapi.adyogi.com/client/${clientId}/portfolios?source=amazon&fields=name&limit=10000",
          key: "name",
        },
      },
    ],
    cube: "ClientDailyCampaignAmazonTable",
    metrics: [...COMMON_METRICS],
    dimensions: ["targetingType"],
    timeDimension: "obDate",
    link_key: "targetingType",
    search_key: "targetingType",
    isExpandable: false,
    parentRowEditable: false,
    isTableFilter: false,
    tableFilters: [],
  },
  "Match Type": {
    tableHeaders: [
      {
        name: "Targeting Type",
        key: "adyogi_targeting_type",
        sorting: true,
        showTotal: false,
        showPercentage: false,
        sort_order: "desc",
        format: "",
        isEnabled: true,
        // isSelectAll: true,
      },
      ...COMMON_METRIC_HEADERS,
    ],
    filters: [
      {
        name: "Campaign Objective",
        default: [],
        multi: true,
        Operator: "IN",
        options: ["Retarget", "Prospect"],
        key: "campaign_objective",
      },
      {
        name: "Campaign Type",
        default: [],
        Operator: "IN",
        multi: true,
        options: ["SD", "SP", "SB(V)", "SB"],
        key: "campaign_type",
      },
      {
        name: "Campaign Status",
        default: [],
        Operator: "IN",
        multi: true,
        operator: "IN",
        options: ["enabled", "paused"],
        key: "campaign_status",
      },
      {
        name: "Targeting Type",
        default: [],
        multi: true,
        Operator: "IN",
        options: [
          "G.Keyword",
          "C.Keyword",
          "NB.Keyword",
          "Audience",
          "Automatic",
          "NB.Product",
          "B.Keyword",
          "B.Audience",
          "B.Product",
        ],
        key: "targetingType",
      },
      {
        name: "Match Type",
        multi: true,
        default: [],
        Operator: "IN",
        options: [
          "Automatic",
          "Exact",
          "Broad",
          "Phrase",
          "Product",
          "Audience",
        ],
        key: "adyogiTargetingType",
      },
      {
        name: "Product Set",
        default: [],
        Operator: "IN",
        multi: true,
        options: [],
        key: "product_set",
        operator: "IN",
        api: {
          url: "https://adyogiadsapi.adyogi.com/client/${clientId}:product-set?source=amazon",
          key: "product_set",
        },
      },
      {
        name: "Bid Strategy",
        default: [],
        Operator: "IN",
        multi: true,
        options: ["down", "up&down"],
        key: "targetStatus",
      },
    ],
    metrics: [...COMMON_METRICS],
    cube: "AmazonTargetTable",
    dimensions: ["adyogiTargetingType"],
    timeDimension: ["date"],
    link_key: "adyogiTargetingType",
    search_key: "adyogiTargetingType",
    isExpandable: false,
    parentRowEditable: false,
    isTableFilter: false,
    tableFilters: [],
  },
  Granular: {
    tableHeaders: [
      {
        name: "Action",
        key: "status",
        sorting: false,
        isEnabled: true,
        showTotal: false,
        showPercentage: false,
        isSelectAll: false,
        format: "",
      },
      {
        name: "Targeting",
        key: "targeting",
        sorting: true,
        showTotal: false,
        isEnabled: true,
        showPercentage: false,
        isSelectAll: true,
        format: "",
      },
      {
        name: "Targeting Type",
        key: "adyogi_targeting_type",
        sorting: false,
        showTotal: false,
        isEnabled: true,
        showPercentage: false,
        format: "",
        isSelectAll: false,
      },
      {
        name: "Portfolio",
        key: "portfolio_name",
        sorting: false,
        isEnabled: true,
        showTotal: false,
        showPercentage: false,
        format: "",
      },
      {
        name: "Campaigns",
        key: "campaign_name",
        sorting: false,
        isEnabled: true,
        showTotal: false,
        showPercentage: false,
        format: "",
      },
      {
        name: "Ad Group",
        key: "adgroup_name",
        sorting: false,
        isEnabled: true,
        showTotal: false,
        showPercentage: false,
        format: "",
        suffix: "Ad Group",
      },
      {
        name: "Product Set",
        key: "product_set",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Current Bid",
        key: "current_bid",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Bid Strategy",
        key: "strategy",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Campaign Type",
        key: "campaign_type",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "Avg. BR",
        key: "agg_br",
        sorting: true,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
        isMetric: false,
      },
      {
        name: "DBR",
        key: "dbr",
        sorting: true,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
        isMetric: false,
      },
      {
        name: "TOSP Bids",
        key: "tosp_bids",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },
      {
        name: "PDP Bids",
        key: "pdp_bids",
        sorting: false,
        showTotal: false,
        showPercentage: false,
        format: "",
        isEnabled: true,
      },

      {
        name: "Spends",
        key: "agg_spends",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isSelected: false,
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Spends DRR",
        key: "spends",
        sorting: true,
        showTotal: true,
        isSelected: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Impressions",
        key: "agg_impressions",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Impressions DRR",
        key: "impressions",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Clicks",
        key: "agg_clicks",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Clicks DRR",
        key: "clicks",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "CTR",
        key: "agg_ctr",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "CTR DRR",
        key: "ctr",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "CPC",
        key: "agg_cpc",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "CPC DRR",
        key: "cpc",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "CPM",
        key: "agg_cpm",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "CPM DRR",
        key: "cpm",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Orders",
        key: "agg_orders",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Orders DRR",
        key: "orders",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Conversion Rate",
        key: "agg_cvr",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "0.00%",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Conversion Rate DRR",
        key: "cvr",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "0.00%",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "Revenue",
        key: "agg_sale_value",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "Revenue DRR",
        key: "sale_value",
        sorting: true,
        showTotal: true,
        showPercentage: true,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "AOV",
        key: "agg_aov",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "0",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "AOV DRR",
        key: "aov",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "0.00",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "ACOS",
        key: "agg_acos",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "ACOS DRR",
        key: "acos",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: false,
      },
      {
        name: "ROAS",
        key: "agg_roas",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: false,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
      {
        name: "ROAS DRR",
        key: "roas",
        sorting: true,
        showTotal: true,
        showPercentage: false,
        format: "",
        isMetric: true,
        isDRRView: true,
        minValue: "",
        maxValue: "",
        isEnabled: true,
      },
    ],
    dimensions: [
      "text",
      "adyogiTargetingType",
      "portfolioName",
      "campaignName",
      "adGroupName",
      "targetStatus",
    ],
    filters: [
      {
        name: "Campaign Objective",
        default: [],
        multi: true,
        operator: "IN",
        options: ["Retarget", "Prospect"],
        key: "campaign_objective",
        id:"targeting-campaign-objective"
      },
      {
        name: "Campaign Type",
        default: [],
        multi: true,
        operator: "IN",
        options: ["SD", "SP", "SB(V)", "SB"],
        key: "campaign_type",
        id:"targeting-campaign-type"
      },
      {
        name: "Campaign Status",
        default: [],
        multi: true,
        operator: "IN",
        options: ["enabled", "paused"],
        key: "campaign_status",
        id:"targeting-campaign-status"
      },
      {
        name: "Product Set",
        default: [],
        multi: true,
        operator: "IN",
        options: [],
        key: "product_set",
        api: {
          url: "https://adyogiadsapi.adyogi.com/client/${clientId}:product-set?source=amazon",
          key: "product_set",
        },
        id:"targeting-product-set"
      },
      {
        name: "Targeting Type",
        default: [],
        multi: true,
        operator: "IN",
        options: [
          "G.Keyword",
          "C.Keyword",
          "NB.Keyword",
          "Audience",
          "Automatic",
          "NB.Product",
          "B.Keyword",
          "B.Audience",
          "B.Product",
        ],
        key: "targeting_group_type",
        id:"targeting-targeting-type"
      },
      {
        name: "Match Type",
        multi: true,
        operator: "IN",
        default: [],
        options: [
          "Automatic",
          "Exact",
          "Broad",
          "Phrase",
          "Product",
          "Audience",
        ],
        key: "adyogi_targeting_type",
        id:"targeting-match-type"
      },
      {
        name: "Bid Strategy",
        default: [],
        multi: true,
        operator: "IN",
        options: ["down", "up&down"],
        key: "strategy",
        id:"targeting-bid-strategy"
      },
    ],
    metrics: [...COMMON_METRICS, "bid"],
    search_key: "text",
    timeDimension: ["date"],
    cube: "AmazonTargetTable",
    isExpandable: false,
    parentRowEditable: true,
    isTableFilter: true,
    tableFilters: [
      {
        placeHolderName: "Campaigns",
        name: "Campaigns",
        masterApiKey: "campaignList",
        stateFilterKey: "campaign_name",
        default: [],
        reqConfig: { source: "amazon", fields: "name,id,platform_id" },
        options: [],
        allData: [],
        field_key: "name",
        filter_value_key: "platform_id",
        operator: "IN",
        key: "campaign_id",
        multi: true,
        different_key: true,
        filterKey: "campaign_name",
        id:'targeting-campaign-name-apply-filter'
      },
      {
        placeHolderName: "Portfolio",
        name: "Portfolio",
        masterApiKey: "getPortfolios",
        stateFilterKey: "campaign_name",
        default: [],
        reqConfig: { source: "amazon", fields: "name,id,platform_id" },
        options: [],
        allData: [],
        field_key: "name",
        operator: "IN",
        multi: true,
        different_key: true,
        filter_value_key: "platform_id",
        key: "portfolio_id",
        filterKey: "portfolio_name",
        id:'targeting-portfolio-name-apply-filter'
      },
      {
        placeHolderName: "Ad Group",
        name: "Ad Group",
        masterApiKey: "adsetList",
        stateFilterKey: "campaign_name",
        multi: true,
        default: [],
        operator: "IN",
        reqConfig: { source: "amazon", fields: "name,id,platform_id" },
        options: [],
        allData: [],
        field_key: "name",
        different_key: true,
        filter_value_key: "platform_id",
        key: "ad_group_id",
        filterKey: "portfolio_name",
        id:'targeting-ad-group-apply-filter'
      },
    ],
  },
};

export const REPORT_DOWNLOAD_QUERIES = {
  targeting: {
    Global: {
      hidden_headers: [
        "adyogiTargetingType",
        "targetStatus",
        "portfolioCount",
        "campaignNameCount",
        "adGroupNameCount",
        "bid",
      ],
    },
    Granular: {
      hidden_headers: [
        "adyogiTargetingType",
        "portfolioName",
        "campaignName",
        "adGroupName",
        "targetStatus",
        "bid",
      ],
    },
    "Targeting Group": {
      hidden_headers: [],
    },
    "Match Type": {
      hidden_headers: [],
    },
  },
};

export const ALERT_MESSAGES = {
  targeting: {
    Align:
      "Are you sure that you want to align suggested bid to selected targetings?",
    Pause: "Are you sure that you want to pause selected targetings?",
    Enable: "Are you sure that you want to enable selected targetings?",
    "Adjust Bid":
      "Are you sure that you want to adjust the bid of selected targetings?",
  },
};

export const QUICK_FILTERS_DATA = [
  {
    filterName:"Top Performing",
    id:"top-performing-filter-btn",
    disabledText:"Loading...",
    tooltipId:"top-performing-filter",
    tooltipRequired:true,
  },
  {
    filterName:"Mid Performing",
    id:"mid-performing-filter-btn",
    disabledText:"Loading...",
    tooltipId:"mid-performing-filter",
    tooltipRequired:true,
  },
  {
    filterName:"Low Performing",
    id:"low-performing-filter-btn",
    disabledText:"Loading...",
    tooltipId:"low-performing-filter",
    tooltipRequired:true,
  },
  {
    filterName:"Worst Performing",
    id:"worst-performing-filter-btn",
    disabledText:"Loading...",
    tooltipId:"worst-performing-filter",
    tooltipRequired:true,
  },
  {
    filterName:"ROAS Advancers",
    id:"roas-advancers-filter-btn",
    disabledText:"Loading...",
    tooltipId:"roas-advancers-filter",
    tooltipRequired:true,
  },
  {
    filterName:"ROAS Decliners",
    id:"roas-decliners-filter-btn",
    disabledText:"Loading...",
    tooltipId:"roas-decliners-filter",
    tooltipRequired:true,
  },
  {
    filterName:"Spends Advancers",
    id:"spends-advancers-filter-btn",
    disabledText:"Loading...",
    tooltipId:"spends-advancers-filter",
    tooltipRequired:true,
  },
  {
    filterName:"Spends Decliners",
    id:"spends-decliners-filter-btn",
    disabledText:"Loading...",
    tooltipId:"spends-decliners-filter",
    tooltipRequired:true,
  },
  {
    filterName:"↓ CPC, High Performance",
    id:"low-cpc-high-performance-filter-btn",
    disabledText:"Loading...",
    tooltipId:"low-cpc-high-performance-filter",
    tooltipRequired:true,
  },
  {
    filterName:"↑ CPC, Low Performance",
    id:"high-cpc-low-performance-filter-btn",
    disabledText:"Loading...",
    tooltipId:"high-cpc-low-performance-filter",
    tooltipRequired:true,
  },
]

const getSpecificDataFormat = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};

export const getSelectedPresetLabel = (range) => {
  for (let i = 0; i < defaultDateRangesWithLables.length; i++) {
    const staticRange = defaultDateRangesWithLables[i].range();
    if (
      range.startDate.toString() === staticRange.startDate.toString() &&
      range.endDate.toString() === staticRange.endDate.toString()
    ) {
      return { date: defaultDateRangesWithLables[i].label, isCustom: false };
    }
  }

  const formattedStartDate = getSpecificDataFormat(range.startDate);
  const formattedEndDate = getSpecificDataFormat(range.endDate);
  return {
    date: formattedStartDate !== formattedEndDate
      ? formattedStartDate + " - " + formattedEndDate
      : formattedStartDate,
    isCustom: true,
  };
};

export const mergeFilters = (oldFilters, newFilters) => {
  const mergedFilters = [];

  // Create a map to track unique keys in old filters
  const oldFilterKeysMap = new Map(
    oldFilters.map((filter) => [filter.key, filter])
  );

  // Iterate through newFilters
  for (const newFilter of newFilters) {
    // Check if the key exists in oldFilterKeysMap
    if (oldFilterKeysMap.has(newFilter.key)) {
      // If the key exists, use the filter from oldFilters
      mergedFilters.push(oldFilterKeysMap.get(newFilter.key));
    } else {
      // If the key doesn't exist, use the filter from newFilters
      mergedFilters.push(newFilter);
    }
  }

  return mergedFilters;
};

export const generateChipFilters = (
  filters,
  normalMinMaxFilters,
  comparisonMinMaxFilters
) => {
  const dropDownFilters = filters
    .filter((filter) => filter.default.length > 0 && !filter?.hasOwnProperty("not_includes")) // Filter only those with default values
    .map((filter) => ({
      fieldNameOfSelectedBtn: filter.name,
      field_name: filter.key,
      checkedValue: filter.default,
    }));
  const filtersValues = Object.values(normalMinMaxFilters);
  let accFilters = [];
  for (const filter of filtersValues) {
    const minMaxFilters = filter?.reduce((acc, curr) => {
      if ((curr.minValue !== "" || curr.maxValue!=="") && curr?.disabled === false) {
        const mainData = {
          fieldNameOfSelectedBtn: curr?.field_name,
          field_name: curr?.field_key,
          //  checkedValue:[],
          minValue: (curr?.minValue === "" && curr?.maxValue < 0) ? "-Infinity" :( curr?.minValue === "" && curr?.maxValue >= 0) ? 0 :curr?.minValue,
          maxValue: curr?.maxValue === "" ? "Infinity" : curr?.maxValue,
        };
        acc.push(mainData);
      }
      return acc;
    }, []);
    accFilters = accFilters.concat([...minMaxFilters]);
  }
  let comparisonFilters = [];
  const filtersValuesComp = Object.values(comparisonMinMaxFilters);
  for (const filter of filtersValuesComp) {
    const minMaxDiff = filter?.reduce((acc, curr) => {
      if ((curr.minValue !== "" || curr.maxValue!=="") && curr?.disabled === false) {
        const mainData = {
          fieldNameOfSelectedBtn: "Comp_" + curr?.field_name,
          field_name: curr?.field_key,
          //  checkedValue:[],
          minValue: curr?.minValue === "" ? 0 : curr?.minValue,
          maxValue: curr?.maxValue === "" ? "Infinity" : curr?.maxValue,
        };
        acc.push(mainData);
      }
      return acc;
    }, []);
    comparisonFilters = comparisonFilters.concat([...minMaxDiff]);
  }

  const mainData = [...accFilters, ...comparisonFilters];
  return dropDownFilters?.concat(mainData);
};

export const  removeSubstring = (inputString, substringToRemove) => {
  const regex = new RegExp(substringToRemove, 'g');
  
  if (inputString.includes(substringToRemove)) {
    return inputString.replace(regex, '');
  } else {
    // If the substring is not present, return the original string
    return inputString;
  }
}

export const removeSummaryPrefix = (inputObject) => {
  const resultObject = {};
  
  for (const key in inputObject) {
    if (key.startsWith("summary_")) {
      const newKey = key.slice("summary_".length);
      resultObject[newKey] = inputObject[key];
    } else {
      resultObject[key] = inputObject[key];
    }
  }

  return resultObject;
}

export const  getChildVisibleHeaders = (childHeaders,parentHeaders,COMMON_METRICS) => {
  return childHeaders?.map((data) => {
   let newData = {...data}
   if(COMMON_METRICS.includes(data?.key)) {
        const foundedObj = parentHeaders?.find((header) => header?.key === data?.key)
        newData = foundedObj
   }
   return newData
  }) ?? [];
  
}

export const diffDateFormatter = (string) => {
  let date = new Date(string);
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset*60*1000))
  return date.toISOString().split('T')[0].toString()
}

export const sortOrderAndReturnNewData = (data,configData) => {
  const parsedData = JSON.parse(JSON.stringify(configData));
  parsedData["tableHeaders"] = parsedData["tableHeaders"]?.map(
    (tableHead) => {
      if (tableHead.key === data.key) {
        tableHead.sort_order =
          parsedData["order"][1] === "desc" ? "asc" : "desc";
        tableHead.isSelected = true;
      } else {
        tableHead.isSelected = false;
      }
      return tableHead;
    }
  );
  parsedData["order"] = [
    data?.key,
    parsedData["order"][1] === "asc" ? "desc" : "asc",
  ];

  return parsedData
}


export const  convertTableHeadersForModal = (data,keyAppender=false) => {
 const mainData =  data?.map((dataInstance) => {
  const newObj = {};
  let fieldKey;
  if(keyAppender) {
    if(dataInstance?.key?.includes("agg")) {
      const removedSubstring = removeSubstring(dataInstance?.key,"agg_")
      fieldKey = "agg_" +  keyAppender + removedSubstring
    } else {
      fieldKey = keyAppender + dataInstance?.key

    }
  } else {
   fieldKey = dataInstance?.key
  }
  newObj["field_key"] = fieldKey
  newObj["field_name"] = dataInstance?.name
  newObj["minValue"] = ""
  newObj["maxValue"] = "" 
  newObj["disabled"] = !dataInstance?.isEnabled
  return newObj
 })

 return mainData
}

export const reorderArrayBasedOnKeys = (dataArray,keysOrder) => {
  const keyIndexMap = {};
  keysOrder.forEach((key, index) => {
    keyIndexMap[key] = index;
  });

  const sortedArray = dataArray.slice().sort((a, b) => {
    const indexOfA = keyIndexMap[a.key];
    const indexOfB = keyIndexMap[b.key];
    return indexOfA - indexOfB;
  });

  return sortedArray;
}

export const filterChipFilters = (keys,data) => {
  return data?.filter((mainData) => {
    return keys?.includes(mainData?.field_name)
  })
}


export const formatToNumeral = (data) => {
  return typeof data === "number" ? numeral(data)?.format(formatDecimal) : data
}

function parseToNumber (inputStr,key) {
  const twoDeci = ['ctr','agg_ctr','cpc','agg_cpc','cpm','agg_cpm','cvr','agg_cvr','aov','agg_aov','acos','agg_acos','roas','agg_roas','agg_acos'];
  return typeof inputStr === 'number' ? (twoDeci?.includes(key) ? parseFloat(inputStr?.toFixed(2)) : Math.round(inputStr)) : inputStr
}

export const createExcelBody = (data,tableHeaders,metricsToFormat,isComparisonView) => {
  return data?.reduce((acc, curr) => {
    let excelBodyRow = [];
    for (let tableHeader of tableHeaders) {
      if (
        tableHeader?.key === "targeting" ||
        metricsToFormat?.includes(tableHeader?.key)
      ) {
        if (isComparisonView && metricsToFormat?.includes(tableHeader?.key)) {
          const currentMetric = parseToNumber(curr[tableHeader?.key]?.curr,tableHeader?.key)  || "-";
          const previousMetric = parseToNumber(curr[tableHeader?.key]?.prev,tableHeader?.key) || "-";
          const percentageChange =
            curr[tableHeader?.key]?.change === "N/A"
              ? ""
              : curr[tableHeader?.key]?.change ?? "";
          const moment = curr[tableHeader?.key]?.moment || "-"
          const changeObj = {
            color:moment==="up" ? "047857":"B91C1C",
            text:`${percentageChange} ${moment === "up" ? "↑" : "↓"}`
          }
          excelBodyRow.push(currentMetric, changeObj, previousMetric);
        } else {
          excelBodyRow.push(parseToNumber(curr[tableHeader?.key],tableHeader?.key) || "-");
        }
      }
    }
    acc.push(excelBodyRow);
    return acc;
  }, []);
}

export const downloadCsv = async (headings, data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1", {
    views: [{ state: "frozen", xSplit: 1, ySplit: 1 }],
    properties: { tabColor: { argb: "FF00FF00" } },
  });
  const headingRow = worksheet.addRow(headings);
  headingRow.eachCell((cell) => {
    cell.value = cell.text.toUpperCase();
    cell.font = { bold: true };
  });

  data.forEach((rowData) => {
    const dataRow = worksheet.addRow(rowData);
    dataRow.eachCell((cell, index) => {
      const cellValue = cell.text;
      let parsedValue;
      try {
        parsedValue = JSON.parse(cellValue);
        // Check if the parsed value is an object
        if (typeof parsedValue === "object") {
          const textColor = parsedValue.color;
          const textContent = parsedValue.text;
          // Set the cell's value to the text content
          cell.value = textContent;
          cell.font = {
            color: { argb: textColor },
          };
        }
      } catch (error) {
        if (cellValue.includes("https://")) {
          const parts = cellValue.split("||");
          cell.value = parts[1];
        } else {
          cell.value = cellValue;
        }
      }
    });
  });

  worksheet.columns = headings.map((header, index) => ({
    header,
    key: `col${index + 1}`,
    width: index === 0 ? 30 : 10,
    style: { font: { bold: index === 0 } },
  }));
  const blob = await workbook.xlsx.writeBuffer();
  const url = URL.createObjectURL(
    new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  const a = document.createElement("a");
  a.href = url;
  a.download =
    window.location.pathname +
    "-" +
    new Date().toLocaleDateString("en-US", {
      day: "numeric",
      year: "numeric",
      month: "long",
    });
  a.click();
  URL.revokeObjectURL(url);
}

export const createHeadersForExcelDownload = (tableHeaders,isComparisonView,metricsToFormat) => {
  return tableHeaders?.reduce((acc,curr) => {
    if(metricsToFormat.includes(curr?.key) || curr?.key === "targeting") {
      if(isComparisonView && metricsToFormat.includes(curr?.key)) {
         const previousHeader = "Previous " + curr?.name;
         acc.push(curr?.name,"% Change",previousHeader)
      } else {
        acc.push(curr?.name)
      }
    }
    return acc
  },[])
}

export const createCubeQueryFilter = (data,keySuffix,fromAPI) => {
  const cubeQueryFilter = [];

  for (const item of data) {
      // if (!item.disabled) {
          if (item.minValue !== "") {
             let minFilterObject;
             if (fromAPI) {
               minFilterObject = {
                 field_name: `${item?.field_key}`,
                 operator: "GREATER_THAN_EQUAL_TO",
                 value: item?.minValue,
               };
             } else {
               minFilterObject = {
                 member: `${keySuffix}.${item?.field_key}`,
                 operator: "gte",
                 values: [item.minValue],
               };
             }
              
              cubeQueryFilter.push(minFilterObject);
          }

          if (item.maxValue !== "") {
            let maxFilterObject;
            if (fromAPI) {
              maxFilterObject = {
                field_name: `${item?.field_key}`,
                operator: "LESS_THAN_EQUAL_TO",
                value: item?.maxValue,
              };
            } else {
              maxFilterObject = {
                member: `${keySuffix}.${item?.field_key}`,
                operator: "lte",
                values: [item.maxValue],
            };
            }
              
              cubeQueryFilter.push(maxFilterObject);
          // }
      } 
  }

  return cubeQueryFilter;
}

const smartChipFiltersMapper = {
  "Top Performing":{
    field_name:"Top Performing",
    value:"Prospect" 
  },
  "Low Performing":{
    field_name:"Low Performing",
    value:"Prospect"
  },
  "Mid Performing":{
    field_name:"Mid Performing",
    value:"Prospect"
  },
  "Worst Performing":{
    field_name:"Worst Performing",
    value:"Prospect"
  },
  "ROAS Advancers":{
    field_name:"ROAS Advancers",
    value:"Prospect"
  },
  "ROAS Decliners":{
    field_name:"ROAS Decliners",
    value:"Prospect"
  },
  "Spends Advancers":{
    field_name:"Spends Advancers",
    value:"Prospect"
  },
  "Spends Decliners":{
    field_name:"Spends Decliners",
    value:"Prospect"
  },
  "↓ CPC, High Performance":{
    field_name:"↓ CPC, High Performance",
    value:"Prospect"
  },
  "↑ CPC, Low Performance":{
    field_name:"↑ CPC, Low Performance",
    value:"Prospect"
  },

}

export const predefinedSmartFilters = {
  "Top Performing":[{field_name:"agg_dd_orders",operator:"GREATER_THAN_EQUAL_TO",value:3}],
  "Low Performing":[{field_name:"agg_dd_clicks",operator:"GREATER_THAN_EQUAL_TO",value:40}],
  "Mid Performing":[{field_name:"agg_dd_orders",operator:"GREATER_THAN_EQUAL_TO",value:3}],
}

export const getPredefinedQuickFilters = (filterName) => {
  return predefinedSmartFilters[filterName] ?? [];
}

export const toolTipContent = {
  "Top Performing":"1.) ROAS > 1.3 ROAS(avg) (last 7 days) <br/> 2.) No. of Orders >= 3 (last 14 days) <br/> 3.) No. of Orders >=2 (last 7 days)",
  "Low Performing":"1.) ROAS - 0.3 to 0.8 ROAS(avg) (last 7 days) <br/> 2.) Clicks > 40 (14 days)",
  "Worst Performing":"1.) Clicks > 40 <br/> 2.) ROAS < 0.3 Avg ROAS (Last 28 days)",
  "Mid Performing":"1.) ROAS - 0.8 to 1.3 ROAS(avg) <br/> 2.) No. of Orders >= 3 (last 14 days) <br/> 3.) No. of Orders >=2 (last 7 days)",
  "ROAS Advancers":"1.) L7D ROAS > 1.2 L30D ROAS <br/> 2.) Orders 7D DRR > Orders 30D DRR",
  "ROAS Decliners":"1.) L7D ROAS < 0.8 L30D ROAS",
  "Spends Advancers":"1.) L7D Spends DRR > 1.3 L14D DRR",
  "Spends Decliners":"1.) L7D SPEND DRR < 1.3 Previous 14D",
  "↓ CPC, High Performance":"1.) CPC < 0.7 CPC <br/> 2.) ROAS > 1.2 ROAS Avg (Last 7 days)",
  "↑ CPC, Low Performance":"1.) CPC > 1.3 CPC avg <br/> 2.) ROAS < 0.8 ROAS Avg (Last 7 days)"
}

export const requiredFieldsForQuickFilters = {
  targeting: {
    "Top Performing": ["summary_agg_roas"],
    "Low Performing": ["summary_agg_roas"],
    "Mid Performing": ["summary_agg_roas"],
    "Worst Performing":["summary_agg_roas"],
    "ROAS Advancers":["summary_agg_roas","summary_dd_orders"],
    "ROAS Decliners":["summary_agg_roas"],
    "Spends Advancers":["summary_agg_spends","summary_agg_roas"],
    "Spends Decliners":["summary_dd_spends","summary_agg_roas"],
    "↓ CPC, High Performance":["summary_agg_cpc","summary_agg_roas"],
    "↑ CPC, Low Performance":["summary_agg_cpc","summary_agg_roas"],
  },
};

export const doesKeyExists = (parsedData,filterName) => {
  const keysArray = requiredFieldsForQuickFilters["targeting"][filterName]
  const parsedDataKeys = Object.keys(parsedData);
  const excludedFilters = ["ROAS Advancers","ROAS Decliners","Spends Advancers","Spends Decliners"]
  return excludedFilters?.includes(filterName) ? true : keysArray.every((data) => parsedDataKeys?.includes(data))

}

function getQuickFilterDaysDifferenceAccordingToFilter (filterName) {
 return filterName === "Worst Performing" ? 27 : 6
}

export const getQuickFilterDates = (filterName) => {
 const filterDateValue = getQuickFilterDaysDifferenceAccordingToFilter(filterName)
 const startDate = moment().subtract(filterDateValue, "days")._d
 const endDate = moment()._d
 const last14daysVal = ["Top Performing","Low Performing","Mid Performing","Spends Advancers","Spends Decliners"];
 const last28daysVal = ["Worst performing"]
 const last30daysVal = ["ROAS Advancers","ROAS Decliners"];
 const lastToLast7DaysVal = ["↓ CPC, High Performance","↑ CPC, Low Performance"]
 const previousDateFilterVal = last14daysVal?.includes(filterName) ? 13 : last28daysVal?.includes(filterName) ? 28 : last30daysVal?.includes(filterName) ? 30 : 7
 const startDatePrevious = lastToLast7DaysVal?.includes(filterName)  ? moment().subtract(13,"days") : moment().subtract(previousDateFilterVal, "days")._d
 const endDatePrevious = lastToLast7DaysVal?.includes(filterName) ? moment().subtract(7,"days") :  moment()._d
 return {startDate,endDate,startDatePrevious,endDatePrevious}
}

export const parseQuickFilterLogics = (parsedData,filterName) => {
  const {summary_agg_roas,summary_agg_cpc} = parsedData
  switch (filterName) {
    case "Top Performing":
      return [{field_name:"agg_roas",minValue:summary_agg_roas*1.3,maxValue:""},{field_name:"agg_orders",minValue:2,maxValue:""}]
    case "Low Performing":
        return [{field_name:"agg_roas",minValue:summary_agg_roas*0.3,maxValue:summary_agg_roas*0.7}]
    case "Mid Performing":
      return [{field_name:"agg_roas",minValue:summary_agg_roas*0.7,maxValue:summary_agg_roas*1.3},{field_name:"agg_orders",minValue:2,maxValue:""}]
    case "Worst Performing":
      return [{field_name:"agg_roas",minValue:"",maxValue:summary_agg_roas*0.3},{field_name:"agg_clicks",minValue:40,maxValue:""}]
    case "ROAS Advancers":
      return [{field_name:"comp_roas",minValue:20,maxValue:""},{field_name:"comp_orders",minValue:0,maxValue:""}]
    case "Spends Advancers":
      return [{field_name:"comp_spends",minValue:30,maxValue:""}]
    case "Spends Decliners":
      return [{field_name:"comp_spends",minValue:"",maxValue:-30}]
    case "ROAS Decliners":
      return [{field_name:"comp_roas",minValue:"",maxValue:-20}]
    case "↓ CPC, High Performance":
      return [{field_name:"agg_cpc",minValue:"",maxValue:summary_agg_cpc*0.7},{field_name:"agg_roas",minValue:summary_agg_roas*1.2,maxValue:""}]      
    case "↑ CPC, Low Performance":
        return [{field_name:"agg_cpc",minValue:summary_agg_cpc*1.3,maxValue:""},{field_name:"agg_roas",minValue:"",maxValue:summary_agg_roas*0.8}]  
    default:
      break;
  }
}

export const getSmartChipFilters = (existingFilters,typeName,value) => {
   if(smartChipFiltersMapper[typeName]) {
    const {startDate,endDate,startDatePrevious,endDatePrevious} = getQuickFilterDates(typeName)
    const comparisonModeFilters = ["ROAS Advancers","ROAS Decliners","Spends Advancers","Spends Decliners"]
    const formattedDate = comparisonModeFilters?.includes(typeName) ? `${formatDateFn(startDate)} to ${formatDateFn(endDate)} || ${formatDateFn(startDatePrevious)} to ${formatDateFn(endDatePrevious)} ` : `${formatDateFn(startDate)} to ${formatDateFn(endDate)}`
    existingFilters.push({fieldNameOfSelectedBtn:typeName,field_name:smartChipFiltersMapper[typeName].field_name,checkedValue:[value],tooltip:true,toolTipContent:`* Data Shown For:- ${formattedDate}<br> Applied Filters:- <br> ${toolTipContent[typeName]}`})
   }
  return existingFilters
}

export const  removeSelectedFields = (data) => {
   const mainData = JSON.parse(JSON.stringify(data));
   const updatedData = mainData?.map((parentRow) => {
    parentRow.isChecked = false
    if(parentRow?.child_rows?.length) {
      const updatedChildRows = parentRow?.child_rows?.map((childRow) => {
        childRow.isChecked = false
        return childRow
      })
      parentRow.child_rows = updatedChildRows
    }
    return parentRow
   })
   return updatedData
}

export const parseFilters = (filters,cube,fromAPI) => {
  return filters?.reduce((acc, curr) => {
    if (curr?.default?.length) {
      let parsedFilter = {
        member: `${cube}.${curr.key}`,
        operator: "in",
        values: curr.default,
      };
      if(fromAPI) {
        parsedFilter = {
          field_name: `${curr.key}`,
          operator: curr?.operator,
          [curr.multi ? "values":"value"]: keyFunctionParser(curr)
        };
      } else {
        parsedFilter = {
        member: `${cube}.${curr.key}`,
        operator: "in",
        values: curr.default,
      };
      }

      acc.push(parsedFilter);
    }
    return acc;
  }, []);
}

export const getHeadersAccordingtoView = (tableHeaders,viewType) => {
  // ViewType true means DRR view and false means aggregated view
  return tableHeaders?.filter((data) => {
   return viewType === true ? (!data?.hasOwnProperty("isDRRView") || data?.isDRRView === true) : (!data?.hasOwnProperty("isDRRView") || data?.isDRRView === false)
  })
}

function valueMapper (defaultData,key) {
  const strategyData = {
    "down":"legacyForSales",
    "up&down":"autoForSales"
  }
  const targetingTypeData = {
    "Exact":"Keyword Exact Targeting",
    "Broad":"Keyword Broad Targeting",
    "Phrase":"Keyword Phrase Targeting",
    "Audience":"Audience Targeting",
    "Automatic":"Automatic",
    "Product":"Product Targeting"
  }
  const data = {
    "strategy":defaultData?.map?.((key) => strategyData[key]),
    "adyogi_targeting_type":defaultData?.map?.((key) => targetingTypeData[key])
  }
  return data[key]
}

function keyFunctionParser(obj) {
  const parserKeys = ["strategy","adyogi_targeting_type"];
   return obj?.different_key ? obj.valuesToSendInFilters : parserKeys.includes(obj.key) ? valueMapper(obj.default,obj.key) : obj.default;
}

export const removeParticularMinMaxFilter = (data,fieldName) => {
  const comparisonFiltersParsed = JSON.parse(JSON.stringify(data))
  comparisonFiltersParsed["agg"] = comparisonFiltersParsed["agg"]?.map((data) => {
    if(data?.field_key === fieldName) {
      data.minValue = ""
      data.maxValue = ""
    }
    return {...data}
  })
  comparisonFiltersParsed["drr"] = comparisonFiltersParsed["drr"]?.map((data) => {
    if(data?.field_key === fieldName) {
      data.minValue = ""
      data.maxValue = ""
    }

    return {...data}
  })

  return comparisonFiltersParsed
}


export const getCampaignIdWhenClickedOnAdgroupToRedirect = async (
  childData
) => {
  const dataForCampaignList = {
    source: "amazon",

    filtering: encodeData([
      {
        field_name: "name",
        operator: "EQUALS",
        value: childData.campaign_name,
      },
    ]),
    fields: "id,name",
  };

  const result = await masterApi(dataForCampaignList, "campaignList", false);

  const campaignId = result?.data?.data[0];
  if (campaignId) {
    window.open(
      `/edit-adset-targeting?campaignId=${campaignId.id}&adsetId=${childData.targeting_expression.adset_id}&numericCampaignId=${childData.campaign_id}&numericAdsetId=${childData.ad_group_id}&name=${childData?.adgroup_name}&adsetPlatformId=${childData.ad_group_id}&campName=${childData.campaign_name}&targetingName=${childData.targeting}`,
      "_blank",
      "noreferrer"
    );
  }
};



// Format Data For Comparison View With this function.
export const  formatDataForView = (data,viewType,metricsToFormat) => {
  return viewType!=="Comparison" ? data : JSON.parse(JSON.stringify(data))?.map((dataInstance,index) => {
     for ( let key in dataInstance) {
       if(metricsToFormat.includes(key)) {
         const currentDataKey = key
         let previousDataKey = key;
         // Calculating Previous Data For Aggregated Metrics
         if(previousDataKey?.includes("agg")) {
           let removedData = removeSubstring(key,"agg_")
           previousDataKey = `agg_dd_${removedData}`
         }
         // Calculating Previous Data For Non Aggregated Metrics
         if(!currentDataKey?.includes("agg")) {
           previousDataKey = `dd_${key}`
         }
         const currentValue = dataInstance[currentDataKey]  
         const previousValue = dataInstance[previousDataKey]  
         const change = dataInstance[currentDataKey] - previousValue;
         const percentageChange = (change / Math.abs(previousValue)) * 100;
         const formattedPercentage = (percentageChange?.toFixed(2) === "NaN" || percentageChange?.toFixed(2) === "Infinity") ? "N/A" : percentageChange?.toFixed(2) + "%"
        const comparisonObject = {  
         curr: currentValue,
         prev: previousValue,
         change:
           formattedPercentage,
         moment: currentValue > previousValue ? "up" : previousValue > currentValue ? "down" : "N/A",
        }
        dataInstance[key] = comparisonObject
       };
       }
     if(dataInstance?.child_rows?.length) {
       // Recursive Call For child rows to format data according to view.
     dataInstance.child_rows = formatDataForView(dataInstance?.child_rows,viewType,metricsToFormat)
     }
     return dataInstance
   })
 }


// Common State Updater Functions Logics that can be resued across all over tables.
export default function resultSetReducer(state, payload) {
  const { action, data } = payload;

  switch (action) {
    case OPTIMIZER_TABLE_ACTIONS.SET_INITIAL_STATE:
      return [...data];
    case OPTIMIZER_TABLE_ACTIONS.EXPAND_ROW:
      const { parentIndex } = data;
      state[parentIndex].isDisabled = !state[parentIndex]?.isDisabled;
      return [...state];
    case OPTIMIZER_TABLE_ACTIONS.ADD_CHILD_ROWS:
      const { childRows } = data;
      state[data?.parentIndex].child_rows = childRows;
      if (data?.parentTick) {
        state[data?.parentIndex].isChecked = true;
      }
      return [...state];
    case OPTIMIZER_TABLE_ACTIONS.EDIT_TABLE_CELL:
      const { childIndex, isChildRow } = data;
      // if (isChildRow) {
      //   state[data?.parentIndex]["child_rows"][childIndex].isEditable =
      //     !state[data?.parentIndex]["child_rows"][childIndex]?.isEditable;
      // } else {
      //   state[data?.parentIndex].isEditable =
      //     !state[data?.parentIndex]?.isEditable;
      // }
      return [...state]?.map((row,index) => {
        if(isChildRow) {
          // If there are child rows then make already enable rows disabled and recieved Index row enable.
          if(row?.child_rows?.length) {
            row['child_rows'] = row['child_rows']?.map((childRow,childRowIndex) => {
              if(childRow?.isEditable) {
                childRow.isEditable = false
                if(childRow?.hasOwnProperty('targeting_expression')) {
                  childRow['current_bid'] = childRow?.bid_copy
                }
              }
              if(childIndex === childRowIndex && index === data?.parentIndex) {
                childRow.isEditable = true
              }
            return {...childRow}
         })
          }
        } else {
          if(row.isEditable) {
            row.isEditable = false
            row['current_bid'] = row.bid_copy
          }
          if(index === data?.parentIndex) {
            row.isEditable = true 
          }
        }
        return {...row}
      })
    case OPTIMIZER_TABLE_ACTIONS.CANCEL_EDIT_TABLE_CELL:
        if (data?.isChildRow) {
        state[data?.parentIndex]["child_rows"][data?.childIndex].isEditable =
          false
        if(state[data?.parentIndex]["child_rows"][data?.childIndex]?.hasOwnProperty('current_bid')) {
          state[data?.parentIndex]["child_rows"][data?.childIndex]['current_bid'] = state[data?.parentIndex]["child_rows"][data?.childIndex]?.bid_copy
        }
      } else {
        if(state[data?.parentIndex]?.hasOwnProperty('current_bid')) {
          state[data?.parentIndex]['current_bid'] = state[data?.parentIndex]?.bid_copy
        }
        state[data?.parentIndex].isEditable = false
      }
      return [...state]
    case OPTIMIZER_TABLE_ACTIONS.SELECT_SINGLE_ROW:
      if (data?.isChildRow) {
        state[data?.parentIndex].child_rows[data?.childIndex].isChecked =
          !state[data?.parentIndex].child_rows[data?.childIndex].isChecked;
      } else {
        state[data?.parentIndex].isChecked =
          !state[data?.parentIndex].isChecked;
      }
      return [...state];
    case OPTIMIZER_TABLE_ACTIONS.SELECT_ALL_ROWS:
      const {restrictFunc} = RESTRICTION_DISABLE_LOGIC[data?.restrictName]
      const updatedChildRows = state[data?.parentIndex].child_rows?.map(
        (childData) => {
          if(restrictFunc(childData)) {
            childData.isChecked = !state[data?.parentIndex]?.isChecked;
          }
          return childData;
        }
      );
      state[data?.parentIndex].child_rows = updatedChildRows;
      state[data?.parentIndex].isChecked = !state[data?.parentIndex].isChecked;
      state[data?.parentIndex].isDisabled = true;
      return [...state];
    case OPTIMIZER_TABLE_ACTIONS.TOGGLE_ENABLE_PAUSE:
      if (data?.isChildRow) {
        state[data?.parentIndex].child_rows[data?.childIndex][
          data?.pointerKey
        ] =
          state[data?.parentIndex].child_rows[data?.childIndex][
            data?.pointerKey
          ].toLowerCase() === "enabled"
            ? "paused"
            : "enabled";
            return [...state];
      } else {
        state[data?.parentIndex][data?.pointerKey] =
          state[data?.parentIndex][data?.pointerKey].toLowerCase() === "enabled"
            ? "paused"
            : "enabled";
            return [...state];
      }
    
    case OPTIMIZER_TABLE_ACTIONS.SELECT_ALL_ROWS_FROM_HEADER:
      const dataStatus = data?.status;
      let updatedData = [...state];
      if (data?.expandable) {
        updatedData = updatedData?.map((parentRow) => {
          if (parentRow?.child_rows?.length) {
            const updatedChildRows = parentRow.child_rows.map((childRow) => {
              if(data?.restrictFunc(childRow)) {
                childRow.isChecked = dataStatus;
              }
              return childRow;
            });
            parentRow.isChecked = dataStatus;
            parentRow.child_rows = updatedChildRows;
            parentRow.isDisabled = true;
          }
          return parentRow;
        });
        return [...updatedData];
      } else {
        updatedData = updatedData?.map((parentRow) => {
          if(data?.restrictFunc(parentRow)) {
            parentRow.isChecked = dataStatus;
          }
          return parentRow;
        });
        return [...updatedData];
      }
    case OPTIMIZER_TABLE_ACTIONS.KEY_VALUE_CHANGE:
      if (data?.isChildRow) {
        if(isNumeric(data.value)) {
          state[data.parentIndex].child_rows[data?.childIndex][data?.key] =
          data.value;
        }
      } else {
        if(isNumeric(data.value)) {
          state[data.parentIndex][data?.key] = data.value;
        }
      }
      return [...state];
    case OPTIMIZER_TABLE_ACTIONS.ADD_MORE_DATA:
      return [...state, ...data];
    case OPTIMIZER_TABLE_ACTIONS.UPDATE_BULK_ROWS:
    const updatedBulkRows = [...state]?.map((row) => {
        if(data?.isChildRow) {
           if(row?.child_rows?.length) {
            row.child_rows = row.child_rows?.map((childRow) => {
              const foundedRow = data?.selectedRows?.find((selectedRow) => {
                return selectedRow[data?.findKey] === childRow[data?.findKey]
              })
              if(foundedRow) {
                childRow[data?.keyForChange] = data?.keyValue
              }
              return childRow
            })
           }
        } else {
          const foundedRow = data?.selectedRows?.find((selectedRow) => {
            return selectedRow[data?.findKey] === row[data?.findKey]
          })
          if(foundedRow) {
            row[data?.keyForChange] = data?.keyValue
          }
        }
      return {...row}
    })
    return updatedBulkRows
    case OPTIMIZER_TABLE_ACTIONS.HIGHLIGHT_ROW:
      state[data?.index].isHighlighted = !state[data?.index]?.isHighlighted;
      return [...state]
    default:
      return [...state];
  }
}

// Result Set Formatter Function 

export const resultSetFormatter = (data) => {
   return [...data]?.map((row) => {
        if(row.hasOwnProperty('targeting_expression')) {
          row.bid_copy = row?.current_bid
        }
        return {...row}
   })
}

// All Plain JSX Rendering Functions 

export const  renderPointer = (status , classNames) =>  {
  return (
    <td className={classNames}>
    <div className="mt-status-dot-container">
      <div
        className={
          status
            ? "enabled-status-dots-mt"
            : !status
            ? "paused-status-dots-mt"
            : null
        }
      ></div>
    </div>
    </td>
  );
}

export const renderChipCount = (count,text) => {
  return (
    <td className="text-nowrap">
      <div className="campaign-text-column">
        <div className="campaign-count-circle">{count}</div>
        <span>{text}</span>
      </div>
    </td>
  );
 }

export const renderComparisonCells = (data,key,className) => {
  const {curr,prev,change,moment} = data
  return (
    <>
      <td key={key} className={className}>
        <div className="d-flex gap-3 align-items-center justify-content-between">
          <div>{formatToNumeral(curr)}</div>
          <div>
            <div className="percentage-up-down-container reports">
              <span
                className={`percentage-desc ${
                  moment === "down" ? "text-danger" : "text-success"
                }`}
              >
                {change}
              </span>
              {moment === "down" || moment === "up" ? (
                <span className="percentage-icon">
                  <img src={moment === "down" ? movingDown : movingUp} />
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </td>
      <td>{formatToNumeral(prev)}</td>
    </>
  );
}

export const renderEditableCell = (data) => {
  let {key , keyToCompare , isEditable , index , otherData , isChildRow , handleEditableCellClick,handleEditableValueChange,updateEditableCell,isFieldPresent,handleCancelEditableCell} = data
  return key === keyToCompare ? (
    <td key={key}>
      <div className="d-flex justify-content-evenly align-items-center budget-td-container-placement">
        <input
          type="text"
          className={isEditable ? "budget-value-edit adjust-bid-input" : "budget-value"}
          disabled={!data?.isEditable}
          onChange={(e) => {
            e.stopPropagation();
            handleEditableValueChange(e, data);
          }}
          value={otherData[key]}
        ></input>
        <div className="d-flex flex-row">
   
          <span
            className={`edit-budget-icon-container gap-1 ${
              !isCurrentBidPresent(otherData) || !isCampaignNotSB(data) ? "disable-pointer-events" : ""
            }`}
            onClick={(e) => {
              // e.stopPropagation();
              !isEditable
                ? handleEditableCellClick(data)
                : updateEditableCell(data);
            }}
          >
            <img
              src={!isEditable ? editIcon : checkCircle}
              alt={"edit-update"}
            ></img>
        
          </span>
          {   data?.isEditable ? (
                <span
                  className={` ${
                    data?.isDisabled
                      ? "disable-pointer-events"
                      : "edit-budget-icon-container  gap-3"
                  } `}
                  onClick={(e) => {
                    // e.stopPropagation();
                    handleCancelEditableCell(data)
                  }}
                >
                  <img
                    src={cancelIcon}
                    className="cancel-check-circle"
                    alt={"edit-update"}
                  ></img>
                </span>
              ) : null}
       
        </div>
      </div>
    </td>
  ) : null;
 }

const openLinkInNewTab = (data,name) => {
  const url = `/campaign-list?campName=${data?.campaign_name}`
  if(url) {
    window.open(url,"_blank")
  }
}

export const renderLinks = (data,text,name) => {
const functionToRun = name === "campaign_name" ? openLinkInNewTab : getCampaignIdWhenClickedOnAdgroupToRedirect;

return (
  <td className="text-nowrap">
    <div
      className="cursor-pointer-global clickable-text-color"
      title={text}
      onClick={() => functionToRun(data)}
    >
      {text}
    </div>
  </td>
);
}

// ALL API Logics Functions

function formatterFunction(bulkData, actionType) {
  switch (actionType) {
    case "parentToggle":
      const newData = bulkData;
      const status = newData?.status;
      newData["targeting_expression"]["target"][0]["status"] =
        status.toUpperCase() === "ENABLED" ? "PAUSED" : "ENABLED";
      return [newData?.targeting_expression];
    case "childToggle":
      const targetingData = bulkData;
      const newStatus = targetingData?.status;
      targetingData["targeting_expression"]["target"][0]["status"] =
        newStatus.toUpperCase() === "ENABLED" ? "PAUSED" : "ENABLED";
      return [targetingData?.targeting_expression];
    case "bulkPause":
      const bulkPausedData = bulkData?.map((data) => {
        const newUpdatedExpression = data?.targeting_expression;
        newUpdatedExpression["target"][0]["status"] = "PAUSED";
        return newUpdatedExpression;
      });
      return bulkPausedData;
    case "bulkEnable":
      const bulkEnabledData = bulkData?.map((data) => {
        const newUpdatedExpression = data?.targeting_expression;
        newUpdatedExpression["target"][0]["status"] = "ENABLED";
        return newUpdatedExpression;
      });
      return bulkEnabledData;
    case "adjustBid":
      const { selectedRows, adjustBidInfo } = bulkData;
      const { selectedAction, valuePercentage, setAsValue } = adjustBidInfo;
      const adjustedBidData = selectedRows?.filter((data) => {
        return (data?.hasOwnProperty('current_bid') && data?.current_bid!==null && data?.current_bid!==undefined && data?.current_bid!=="" && data?.campaign_type!=="SB")
      })?.map((data) => {
        const dataInstance = data?.targeting_expression;
        if (dataInstance) {
          dataInstance["target"][0]["bid"] =
            selectedAction === "set_bid_as"
              ? parseInt(setAsValue)
              : modifyValueByPercentage(
                  selectedAction,
                  valuePercentage,
                  data?.current_bid
                );
        }
        return dataInstance;
      });
      return adjustedBidData;
    case "updateCellValue":
      const mainData = bulkData?.targeting_expression;
      mainData["target"][0]["bid"] = Number(bulkData["current_bid"]);
      return [mainData];
    default:
      break;
  }
}

export const isNumeric = (input) => {
  return !isNaN(input)
}

export async function updateTargetings(data) {
  try {
    const response = await masterApi(
      data,
      "updatetargetingsinbulk",
      false,
      "post"
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const API_DATA_LOGIC = {
  targeting: {
    formatter: formatterFunction,
    updateFunction: updateTargetings,
  },
};

function isCampaignNotSB (data) {
  return data?.campaign_type!=="SB"
}

function isCurrentBidPresent (data) {
 return  data?.hasOwnProperty('current_bid') && data?.current_bid!==null && data?.current_bid!==undefined && data?.current_bid!==""
}

export const RESTRICTION_DISABLE_LOGIC = {
  targeting:{
    restrictFunc:isCampaignNotSB
  }
}
